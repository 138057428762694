import React, { useContext, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link, useHistory, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import {
  updateAcademicSessionDetails,
  getAdvertisementScreenDetails,
} from "../../../store/actions/AcademicAction";
import {
  getExamCategory,
  loadingToggleAction,
} from "../../../store/actions/ExamCategoryAction";
import {
  getBoardData,
  loadingBoardAction,
} from "../../../store/actions/BoardAction";
import {
  getExamType,
  loadingAction,
} from "../../../store/actions/ExamTypeAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

const UpdateAcademicSession = (props) => {
  const { state } = useLocation();
  let history = useHistory();
  const dispatch = useDispatch();
  const academyCalendarRef = useRef(null);
  const courseCalendarRef = useRef(null);

  let errorsObj = {
    category: "",
    boardType: "",
    name: "",
    academyDate: "",
    courseDate: "",
  };

  const [errors, setErrors] = useState(errorsObj);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [board, setBoard] = useState("");
  const [type, setType] = useState("");
  const [academyStartDate, setAcademyStartDate] = useState("");
  const [academyEndDate, setAcademyEndDate] = useState(null);

  const [courseStartDate, setCourseStartDate] = useState("");
  const [courseEndDate, setCourseEndDate] = useState(null);

   // // Get the current year
   const currentYear = new Date().getFullYear();
   const startOfYear = new Date(currentYear, 0, 1); // January 1st
   const endOfYear = new Date(currentYear, 11, 31); // December 31st

  useEffect(() => {
    dispatch(globalLoadingAction(true));
    dispatch(getExamCategory(props.history));
    dispatch(getBoardData(props.history));
    dispatch(getExamType(props.history));
  }, []);

  useEffect(() => {
    console.log("state...",state);
    setName(state?.session_name);
    setCategory(state?.exam_category_id);
    setAcademyStartDate(new Date(state?.academy_start_date));
    setAcademyEndDate(new Date(state?.academy_end_date));
    setCourseStartDate(new Date(state?.course_start_date));
    setCourseEndDate(new Date(state?.course_end_date));
    if (state?.exam_category_id == 1) {
      setBoard(state?.exam_board_type);
    } else {
      setType(state?.exam_board_type);
    }
  }, [state]);

  /* form submit */
  function onSubmit(e) {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    if (category === "") {
      errorObj.category = "Category is Required";
      error = true;
    }
    if (category == "" && board == "" && type == "") {
      errorObj.boardType = "Board/Exam Type is Required";
      error = true;
    }
    if (category == 1 && board == "") {
      errorObj.boardType = "Board is Required";
      error = true;
    }
    if (category == 2 && type == "") {
      errorObj.boardType = "Exam Type is Required";
      error = true;
    }
    if (name === "") {
      errorObj.name = "Session Name is Required";
      error = true;
    }
    if (academyStartDate === "" && academyEndDate === null) {
      errorObj.academyDate = "Academic Year is Required";
      error = true;
    }else if(academyStartDate=== null || academyEndDate === null){
      errorObj.academyDate = "Academic Year is Required";
    }
    if (courseStartDate === "" && courseEndDate === null) {
      errorObj.courseDate = "Course Validity is Required";
      error = true;
    }else if(courseStartDate=== null || courseEndDate === null){
      errorObj.courseDate = "Course Validity is Required";
    }
    
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(globalLoadingAction(true));
    dispatch(
      updateAcademicSessionDetails(
        state.id,
        category ? parseInt(category) : 0,
        category == 1 ? parseInt(board) : category == 2 ? parseInt(type) : 0,
        name,
        academyStartDate ? moment(academyStartDate).format("YYYY-MM") : "",
        academyEndDate ? moment(academyEndDate).format("YYYY-MM") : "",
        courseStartDate ? moment(courseStartDate).format("YYYY-MM-DD") : "",
        courseEndDate ? moment(courseEndDate).format("YYYY-MM-DD") : "",
        cleardata,
        props.history
      )
    );
  }

  /* clear form data */
  const cleardata = () => {
    setName("");
    setCategory("");
    setBoard("");
    setType("");
    setAcademyStartDate("");
    setAcademyEndDate(null);
    setCourseStartDate("");
    setCourseEndDate(null);
    history.push({
      pathname: "/academic-session",
      state: { filterData: state.filterData },
    });
  };

  /* date handler */
  const onAcademyHandler = (dates) => {
    const [start, end] = dates;
    setAcademyStartDate(start);
    setAcademyEndDate(end);
    setCourseStartDate('');
    setCourseEndDate(null);
  };

  /* date handler */
  const onCourseDateHandler = (dates) => {
    const [start, end] = dates;
    setCourseStartDate(start);
    setCourseEndDate(end);
  };

  /* nevigation back */
  const onGoBackHandler = () => {
    cleardata();
  };

  function getMonthEndDate(date) {
    const year = date.getFullYear();
    const month = date.getMonth(); // 0-based, so 0 = January, 11 = December

    // Create a new Date object for the first day of the next month
    const nextMonth = new Date(year, month + 1, 1);

    // Subtract one day to get the last day of the current month
    const lastDayOfMonth = new Date(nextMonth - 1);

    return lastDayOfMonth;
}

const academysessionEndDate = new Date(academyEndDate);
const maxDate = getMonthEndDate(academysessionEndDate);

const handleRawChange = (e) => {
  e.preventDefault(); // Prevent typing in the input field
};

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="row">
                  <div className="col-xl-3">
                    <div className="back-arrow-container">
                      <button onClick={onGoBackHandler}>
                        <i class="fa-solid fa-arrow-left"></i>
                      </button>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={onSubmit}>
                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Category
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="basic-dropdown">
                              <span className="drop_down_arrow">
                                <i className="bi bi-caret-down-fill"></i>
                              </span>
                              <select
                                defaultValue={"option"}
                                className="form-control form-control"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                              >
                                <option value="">Select Category</option>
                                {props.categoryList.map((item, index) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.category}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errors.category && (
                              <div className="text-danger fs-12">
                                {errors.category}
                              </div>
                            )}
                          </div>
                          <div className="form-group mb-3">
                            {category == 1 ? (
                              <>
                                <label className="mb-2">
                                  <strong>
                                    Board<span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <div className="basic-dropdown">
                                  <span className="drop_down_arrow">
                                    <i className="bi bi-caret-down-fill"></i>
                                  </span>
                                  <select
                                    defaultValue={"option"}
                                    className="form-control form-control"
                                    value={board}
                                    onChange={(e) => setBoard(e.target.value)}
                                  >
                                    <option value="">Select Board</option>
                                    {props.boardList.map((item, index) => {
                                      return (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </>
                            ) : category == 2 ? (
                              <>
                                <label className="mb-2">
                                  <strong>
                                    Exam Type
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <div className="basic-dropdown">
                                  <span className="drop_down_arrow">
                                    <i className="bi bi-caret-down-fill"></i>
                                  </span>
                                  <select
                                    defaultValue={"option"}
                                    className="form-control form-control"
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                  >
                                    <option value="">Select Exam Type</option>
                                    {props.typeList.map((item, index) => {
                                      return (
                                        <option key={index} value={item.id}>
                                          {item.type_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </>
                            ) : (
                              <>
                                <label className="mb-2">
                                  <strong>
                                    Board/Exam Type
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <div className="basic-dropdown">
                                  <span className="drop_down_arrow">
                                    <i className="bi bi-caret-down-fill"></i>
                                  </span>
                                  <select
                                    disabled
                                    defaultValue={"option"}
                                    className="form-control"
                                  >
                                    <option value="">
                                      Select Board/Exam Type
                                    </option>
                                  </select>
                                </div>
                              </>
                            )}
                            {errors.boardType && (
                              <div className="text-danger fs-12">
                                {errors.boardType}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Session Name
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter the Session Name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                            {errors.name && (
                              <div className="text-danger fs-12">
                                {errors.name}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Academic Year
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="date-control-view">
                              <DatePicker
                                ref={academyCalendarRef}
                                placeholderText="Select Academic Year"
                                selected={academyStartDate}
                                onChange={onAcademyHandler}
                                selectsRange
                                startDate={academyStartDate}
                                endDate={academyEndDate}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                minDate={startOfYear}
                                onChangeRaw={handleRawChange} // Prevent typing
                                customInput={
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={
                                      academyStartDate
                                        ? `${academyStartDate.toLocaleDateString(
                                            "en-US",
                                            {
                                              month: "2-digit",
                                              year: "numeric",
                                            }
                                          )} - ${
                                            academyEndDate
                                              ? academyEndDate.toLocaleDateString(
                                                  "en-US",
                                                  {
                                                    month: "2-digit",
                                                    year: "numeric",
                                                  }
                                                )
                                              : ""
                                          }`
                                        : ""
                                    }
                                    readOnly
                                    placeholder="Select Academic Year"
                                  />
                                }
                              />
                              <button
                                type="button"
                                className="picker-btn"
                                onClick={() =>
                                  academyCalendarRef.current.setOpen(true)
                                }
                              >
                                <i class="fa-solid fa-calendar-days"></i>
                              </button>
                            </div>
                            {errors.academyDate && (
                              <div className="text-danger fs-12">
                                {errors.academyDate}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Course Validity
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="date-control-view">
                              <DatePicker
                                ref={courseCalendarRef}
                                placeholderText="Select Course Validity"
                                selected={courseStartDate}
                                onChange={onCourseDateHandler}
                                startDate={courseStartDate}
                                endDate={courseEndDate}
                                selectsRange
                                showMonthDropdown
                                showYearDropdown
                                minDate={new Date(academyStartDate)}
                                maxDate={maxDate}
                                onChangeRaw={handleRawChange}
                                customInput={
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={
                                      courseStartDate
                                        ? `${courseStartDate.toLocaleDateString(
                                            "en-US",
                                            {
                                              month: "2-digit",
                                              year: "numeric",
                                            }
                                          )} - ${
                                            courseEndDate
                                              ? courseEndDate.toLocaleDateString(
                                                  "en-US",
                                                  {
                                                    month: "2-digit",
                                                    year: "numeric",
                                                  }
                                                )
                                              : ""
                                          }`
                                        : ""
                                    }
                                    readOnly
                                    placeholder="Select Course Validity"
                                  />
                                }
                                disabled={
                                  academyStartDate && academyEndDate
                                    ? false
                                    : true
                                }
                              />
                              <button
                                type="button"
                                className="picker-btn"
                                onClick={() =>
                                  courseCalendarRef.current.setOpen(true)
                                }
                              >
                                <i class="fa-solid fa-calendar-days"></i>
                              </button>
                            </div>
                            {errors.courseDate && (
                              <div className="text-danger fs-12">
                                {errors.courseDate}
                              </div>
                            )}
                          </div>

                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block category-btn"
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    categoryList: state.category.list,
    boardList: state.board.list,
    typeList: state.type.list,
  };
};
export default connect(mapStateToProps)(UpdateAcademicSession);
