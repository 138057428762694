import { ReactChild, Fragment, useEffect, useState, useRef } from "react";
import { Row, Col, Card, Table, Modal, Button } from "react-bootstrap";
import { Link, useLocation, useHistory } from "react-router-dom";

import moment from "moment";

import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import {
  getMisRegisterUserData,
  misSchoolListData,
  misRegisterStudentEmailListData,
  misRegisterStudentLocationPinListData,
  misRegisterStudentListExcelData,
} from "../../../store/actions/MisRegUserAction";
import { getClassData } from "../../../store/actions/ClassAction";
import { getBoardData } from "../../../store/actions/BoardAction";
import { useDispatch, connect, useSelector } from "react-redux";

import periodList from "./json/period.json";
import mediaviaList from "./json/mediavia.json";
import subscruptionstatusList from "./json/subscruptionstatus.json";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ReactPaginate from "react-paginate";

//images
import sort from "../../../images/dashboard_image/sort.png";
import export_details from "../../../images/dashboard_image/export.png";

import AutocompleteSearch from "../AutocompleteSearch/AutocompleteSearch";

const MisRegUser = (props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  /* filter const */
  const [period, setPeriod] = useState("");
  const [registrationVia, setRegistrationVia] = useState("");
  const [board, setboard] = useState("");
  const [className, setClassName] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [subscruptionStatus, setSubscruptionStatus] = useState("");
  const [email, setEmail] = useState("");
  const [locationPin, setLocationPin] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(1);
  const [viewCurrentStudentDetails, setViewCurrentStudentDetails] =
    useState("");

  const [modal, setModal] = useState(false);
  const [setudentDetailsModal, setSetudentDetailsModal] = useState(false);

  const [schoolData, setSchoolData] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [locationPinData, setLocationPinData] = useState([]);

  // misSchoolList
  const misSchoolList = useSelector(
    (state) => state.MisDashboardInfo.misSchoolList
  );
  const misRegisteredStudentEmail = useSelector(
    (state) => state.MisDashboardInfo.misRegisteredStudentEmail
  );
  const misRegisteredStudentLocationPin = useSelector(
    (state) => state.MisDashboardInfo.misRegisteredStudentLocationPin
  );
  const classList = useSelector((state) => state.class.list);
  const boardList = useSelector((state) => state.board.list);

  const calendarRef = useRef();
  const childref = useRef();

  const [tempPeriod, setTempPeriod] = useState("");
  const [temPregistrationVia, setTemPregistrationVia] = useState("");
  const [temboard, setTemboard] = useState("");
  const [temclassName, setTemclassName] = useState("");
  const [tempSchoolName, setTempSchoolName] = useState("");
  const [tempSubscruptionStatus, setTempSubscruptionStatus] = useState("");
  const [tempEmail, setTempEmail] = useState("");
  const [tempLocationPin, setTempLocationPin] = useState("");
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");

  const [isFiltered, setIsFiltered] = useState(false);
  const [permission, setPermission] = useState("");

  useEffect(() => {
    dispatch(getClassData());
    dispatch(getBoardData());
    dispatch(misSchoolListData());
    dispatch(misRegisterStudentEmailListData());
    dispatch(misRegisterStudentLocationPinListData());
  }, []);

  useEffect(() => {
    if (period != "select_period" && period != "" && period != undefined) {
      setIsFiltered(true);
    } else if (
      registrationVia != "select_reg_via" &&
      registrationVia != "" &&
      registrationVia != undefined
    ) {
      setIsFiltered(true);
    } else if (board != "select_baord" && board != "" && board != undefined) {
      setIsFiltered(true);
    } else if (
      className != "select_class" &&
      className != "" &&
      className != undefined
    ) {
      setIsFiltered(true);
    } else if (locationPin != "" && locationPin != undefined) {
      setIsFiltered(true);
    } else if (
      subscruptionStatus != "select_subscription_status" &&
      subscruptionStatus != "" &&
      subscruptionStatus != undefined
    ) {
      setIsFiltered(true);
    } else if (schoolName != "" && schoolName != undefined) {
      setIsFiltered(true);
    } else if (email != "" && email != undefined) {
      setIsFiltered(true);
    } else if (
      startDate === "" &&
      startDate === null &&
      startDate != undefined
    ) {
      setIsFiltered(true);
    } else if (endDate === "" && endDate === null && endDate != undefined) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
  }, [
    period,
    registrationVia,
    board,
    className,
    locationPin,
    subscruptionStatus,
    schoolName,
    email,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    let schoolName = [];
    let schoolNameObj = {};

    misSchoolList.forEach((elm) => {
      schoolNameObj = {};
      schoolNameObj = elm.school_name;
      schoolName.push(schoolNameObj);
    });

    setSchoolData(schoolName);

    /* -------- */
    let email = [];
    let emailObj = {};
    misRegisteredStudentEmail.forEach((elm) => {
      emailObj = {};
      emailObj = elm.email;
      email.push(emailObj);
    });
    setEmailData(email);

    /* --------------- */
    let locationPinData = [];
    let locationPinObj = {};
    misRegisteredStudentLocationPin.forEach((elm) => {
      locationPinObj = {};
      locationPinObj = elm.pincode.toString();
      locationPinData.push(locationPinObj);
    });
    setLocationPinData(locationPinData);
  }, [misSchoolList]);

  useEffect(() => {
    getList(
      period,
      board,
      className,
      locationPin,
      subscruptionStatus,
      page,
      registrationVia,
      schoolName,
      email,
      startDate,
      endDate
    );
    permissionCheck();
  }, []);

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 79) {
        console.log("79----------", item)
        setPermission(item);
      }
    });
  };

  const getList = (
    period,
    board,
    className,
    locationPin,
    subscruptionStatus,
    page,
    registrationVia,
    schoolName,
    email,
    startDate,
    endDate
  ) => {
    dispatch(globalLoadingAction(true));
    dispatch(
      getMisRegisterUserData(
        period == "select_period" ? "" : period,
        board == "select_baord" ? "" : board,
        className == "select_class" ? "" : className,
        locationPin,
        subscruptionStatus == "select_subscription_status"
          ? ""
          : subscruptionStatus,
        page,
        registrationVia == "select_reg_via" ? "" : registrationVia,
        schoolName,
        email,
        startDate === "" || startDate === null
          ? ""
          : moment(startDate).format("YYYY-MM-DD"),
        endDate === "" || endDate === null
          ? ""
          : moment(endDate).format("YYYY-MM-DD")
      )
    );
  };

  const onResetHandler = () => {
    setPeriod("");
    setRegistrationVia("");
    setboard("");
    setClassName("");
    setSchoolName("");
    setSubscruptionStatus("");
    setEmail("");
    setLocationPin("");
    setStartDate(null);
    setEndDate(null);
    setPage(1);
    setTempPeriod("");
    setTemPregistrationVia("");
    setTemboard("");
    setTemclassName("");
    setTempSchoolName("");
    setTempSubscruptionStatus("");
    setTempEmail("");
    setTempLocationPin("");
    setTempStartDate("");
    setTempEndDate("");
    dispatch(getMisRegisterUserData("", "", "", "", "", 1, "", "", "", "", ""));
    setIsFiltered(false);
    setModal(false);
  };

  /* delete handler */
  const onDateHandler = (date) => {
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  const filterRegisteredHandler = () => {
    setModal(false);
    setPage(1);
    getList(
      period,
      board,
      className,
      locationPin,
      subscruptionStatus,
      1,
      registrationVia,
      schoolName,
      email,
      startDate,
      endDate
    );
    setTempPeriod(period);
    setTemPregistrationVia(registrationVia);
    setTemboard(board);
    setTemclassName(className);
    setTempSchoolName(schoolName);
    setTempSubscruptionStatus(subscruptionStatus);
    setTempEmail(email);
    setTempLocationPin(locationPin);
    setTempStartDate(startDate);
    setTempEndDate(endDate);
  };

  const onDownloadRegisteredStudentHandler = () => {
    dispatch(
      misRegisterStudentListExcelData(
        period,
        board,
        className,
        locationPin,
        subscruptionStatus,
        0,
        registrationVia,
        schoolName,
        email,
        startDate === "" || startDate === null
          ? ""
          : moment(startDate).format("YYYY-MM-DD"),
        endDate === "" || endDate === null
          ? ""
          : moment(endDate).format("YYYY-MM-DD")
      )
    );
  };

  const viewStudentDetails = (item) => {
    setViewCurrentStudentDetails(item);
    setSetudentDetailsModal(true);
  };

  /* pagination */
  const pageHandler = (data) => {
    let page = data.selected + 1;
    setPage(data.selected + 1);
    dispatch(globalLoadingAction(true));
    getList(
      period,
      board,
      className,
      locationPin,
      subscruptionStatus,
      page,
      registrationVia,
      schoolName,
      email,
      startDate,
      endDate
    );
    /* dispatch(
      getMisRegisterUserData(
        period, board, className, locationPin, subscruptionStatus, data.selected + 1, registrationVia, schoolName, email, startDate === '' || startDate === null ? '' : moment(startDate).format("YYYY-MM-DD"), endDate === '' || endDate === null ? '' : moment(endDate).format("YYYY-MM-DD")
      )
    ); */
  };

  const cancelModal = () => {
    setModal(false);

    if (tempPeriod == "") {
      setPeriod("");
    } else if (
      period != "select_period" &&
      period != "" &&
      period != undefined
    ) {
      setPeriod(tempPeriod);
    } else if (period == "select_period" || period == "") {
      setPeriod(tempPeriod);
    }

    if (temPregistrationVia == "") {
      setRegistrationVia("");
    } else if (
      registrationVia != "select_reg_via" &&
      registrationVia != "" &&
      registrationVia != undefined
    ) {
      setRegistrationVia(temPregistrationVia);
    } else if (registrationVia == "select_reg_via" || registrationVia == "") {
      setRegistrationVia(temPregistrationVia);
    }

    if (temboard == "") {
      setboard("");
    } else if (board != "select_baord" && board != "" && board != undefined) {
      setboard(temboard);
    } else if (board == "select_baord" || board == "") {
      setboard(temboard);
    }

    if (temclassName == "") {
      setClassName("");
    } else if (
      className != "select_class" &&
      className != "" &&
      className != undefined
    ) {
      setClassName(temclassName);
    } else if (className == "select_class" || className == "") {
      setClassName(temclassName);
    }

    if (tempSchoolName == "") {
      setSchoolName("");
    } else if (schoolName != "" && schoolName != undefined) {
      setSchoolName(tempSchoolName);
    } else if (schoolName == "") {
      setSchoolName(tempSchoolName);
    }

    if (tempSubscruptionStatus == "") {
      setSubscruptionStatus("");
    } else if (
      subscruptionStatus != "select_subscription_status" &&
      subscruptionStatus != "" &&
      subscruptionStatus != undefined
    ) {
      setSubscruptionStatus(tempSubscruptionStatus);
    } else if (
      subscruptionStatus == "select_subscription_status" ||
      subscruptionStatus == ""
    ) {
      setSubscruptionStatus(tempSubscruptionStatus);
    }

    if (tempEmail == "") {
      setEmail("");
    } else if (email != "" && email != undefined) {
      setEmail(tempEmail);
    } else if (email == "") {
      setEmail(tempEmail);
    }

    if (tempLocationPin == "") {
      setLocationPin("");
    } else if (locationPin != "" && locationPin != undefined) {
      setLocationPin(tempLocationPin);
    } else if (locationPin == "") {
      setLocationPin(tempLocationPin);
    }

    if (tempStartDate == "") {
      setStartDate("");
    } else if (startDate != "" && startDate != null && startDate != undefined) {
      setStartDate(tempStartDate);
    } else if (startDate == "" || startDate == null) {
      setStartDate(tempStartDate);
    }

    if (tempEndDate == "") {
      setEndDate("");
    } else if (endDate != "" && endDate != null && endDate != undefined) {
      setEndDate(tempEndDate);
    } else if (endDate == "" || endDate == null) {
      setEndDate(tempEndDate);
    }
  };

  const periodHandle = (value) => {
    if (value != 2) {
      setStartDate("");
      setEndDate("");
    }
    setPeriod(value);
  };

  const isDisabled =
    (period === "" || period === "select_period") &&
    (board === "" || board === "select_baord") &&
    (className === "" || className === "select_class") &&
    locationPin === "" &&
    (subscruptionStatus === "" ||
      subscruptionStatus === "select_subscription_status") &&
    (registrationVia === "" || registrationVia === "select_reg_via") &&
    schoolName === "" &&
    email === "";

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <div className="filtersAlign mt-3" style={{ marginRight: 25 }}>
              <div
                className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 me-3 filter_background rounded cursorPointer ${isFiltered ? "border_red" : null
                  }`}
                onClick={() => setModal(true)}
              >
                <div className="image_container">
                  <img src={sort} alt="" className="image_style" />
                </div>
                <div className="button_text font_color_black">Filter</div>
              </div>
              {(permission.download == "true") && (
                <div
                  className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 export_background rounded blue_border cursorPointer ${props.list?.data?.length == 0 ||
                    props.list?.data?.length == undefined
                    ? "content_disable"
                    : null
                    }`}
                  onClick={() => onDownloadRegisteredStudentHandler()}
                >
                  <div className="image_container">
                    <img src={export_details} alt="" className="image_style" />
                  </div>
                  <div className="button_text font_color_blue cursorPointer">
                    Export
                  </div>
                </div>
              )}
            </div>

            <Card.Body>
              {props.list?.data?.length == 0 ||
                props.list?.data?.length == undefined ? (
                <div className="no_data">
                  <strong>No data found</strong>
                </div>
              ) : (
                <>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>
                          <strong>Sl no.</strong>
                        </th>
                        <th>
                          <strong>Name</strong>
                        </th>
                        <th>
                          <strong>Class</strong>
                        </th>
                        <th>
                          <strong>Board</strong>
                        </th>
                        <th>
                          <strong>Email</strong>
                        </th>
                        <th>
                          <strong>Location Pin</strong>
                        </th>
                        <th width={"80px"}>
                          <strong>Registration Via</strong>
                        </th>
                        <th>
                          <strong>Registration date</strong>
                        </th>
                        <th>
                          <strong>School Name</strong>
                        </th>
                        <th width={"80px"}>
                          <strong>Subscription Status</strong>
                        </th>
                        <th>
                          <strong>View</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.list?.data?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.sl_no}</td>
                            <td>{item.student_name}</td>
                            <td>{item.class == 0 ? '-' : item.class}</td>
                            <td>{item.board ? item.board : '-'}</td>
                            <td>{item.email}</td>
                            <td>{item.location_pin == 0 ? '-' : item.location_pin}</td>
                            <td>{item.via_media}</td>
                            <td>{item.registration_date}</td>
                            <td>{item.school_name ? item.school_name : '-'}</td>
                            <td>{item.sub_status}</td>
                            <td>
                              <div className="d-flex">
                                <Button
                                  variant="info shadow btn-xs sharp"
                                  onClick={() => viewStudentDetails(item)}
                                >
                                  <i className="fa fa-eye"></i>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              )}

              {/* ------------------ */}

              {props.list?.data?.length != 0 &&
                props.list?.data != undefined ? (
                <div className="pagination-container">
                  <div className="page-result">
                    <p>
                      Showing: {(page - 1) * 10 + 1} -{" "}
                      {10 * page - (10 - props.list?.data?.length)} of{" "}
                      {props.list.total_records}
                    </p>
                  </div>
                  {props.list.total_records > 10 ? (
                    <ReactPaginate
                      containerClassName="pagination-gutter  false pagination-circle pagination pagination-sm"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      nextLabel={<i className="la la-angle-right"></i>}
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      previousLabel={<i className="la la-angle-left"></i>}
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      renderOnZeroPageCount={null}
                      activeClassName="active"
                      onPageChange={pageHandler}
                      pageRangeDisplayed={3}
                      pageCount={props.list.total_page_no}
                      forcePage={page - 1}
                    />
                  ) : null}
                </div>
              ) : null}
            </Card.Body>
          </Card>
        </Col>
        {/* <!-- Modal --> */}
        <Modal className="fade" show={modal} size="md">
          <Modal.Header>
            <Modal.Title>Filter</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => cancelModal()}
            ></Button>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="question-modal-form">
              <div className="reset-filtter-value">
                <button
                  disabled={isDisabled}
                  className="btn btn-info btn-xs"
                  onClick={() => onResetHandler()}
                >
                  Reset
                </button>
              </div>

              <div className="row">
                {/* <div className={`form-group col-sm-6 px-1 mb-3 ${startDate == null ? null : 'content_disable'}`}> */}
                <div className={`form-group col-sm-6 px-1 mb-3`}>
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      value={period}
                      // onChange={(e) => setPeriod(e.target.value)}
                      onChange={(e) => periodHandle(e.target.value)}
                    >
                      <option value="select_period">Select Period</option>
                      {periodList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.showValue}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      value={registrationVia}
                      onChange={(e) => setRegistrationVia(e.target.value)}
                    >
                      <option value="select_reg_via">
                        Select Registration Via
                      </option>
                      {mediaviaList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.showValue}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      value={board}
                      onChange={(e) => setboard(e.target.value)}
                    >
                      <option value="select_baord">Select Board</option>
                      {boardList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      value={className}
                      onChange={(e) => setClassName(e.target.value)}
                    >
                      <option value="select_class">Select Class</option>
                      {classList?.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.class_no}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <AutocompleteSearch
                    dataDetails={schoolData}
                    placeholder={`Enter School Name`}
                    setNewValue={(e) => setSchoolName(e)}
                    selectValue={schoolName}
                  />
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      style={{ fontSize: 14 }}
                      className="form-control"
                      value={subscruptionStatus}
                      onChange={(e) => setSubscruptionStatus(e.target.value)}
                    >
                      <option value="select_subscription_status">
                        Select Subscription Status
                      </option>
                      {subscruptionstatusList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.showValue}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <AutocompleteSearch
                    dataDetails={emailData}
                    placeholder={`Enter Email`}
                    setNewValue={(e) => setEmail(e)}
                    selectValue={email}
                  />
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <AutocompleteSearch
                    dataDetails={locationPinData}
                    placeholder={`Enter Location Pin`}
                    setNewValue={(e) => setLocationPin(e)}
                    selectValue={locationPin}
                  />
                </div>

                {period == 2 ? (
                  <div className={`col-sm-6 px-1`}>
                    <div className="date-control-view session-date-control">
                      <DatePicker
                        ref={calendarRef}
                        placeholderText="Select Date Range"
                        selectsRange
                        selected={startDate}
                        onChange={onDateHandler}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        startDate={startDate}
                        endDate={endDate}
                        dropdownMode="select"
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      />
                      <button
                        className="picker-btn"
                        onClick={() => calendarRef.current.setOpen(true)}
                      >
                        <i className="fa-solid fa-calendar-days"></i>
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => cancelModal()} variant="danger light">
              Cancel
            </Button>
            <Button
              disabled={isDisabled}
              onClick={() => filterRegisteredHandler()}
              variant="primary"
            >
              Filter
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ------------------ */}

        {/* <!-- Modal --> */}
        <Modal className="fade" show={setudentDetailsModal} size="md">
          <Modal.Header>
            <Modal.Title>Student Details</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setSetudentDetailsModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="question-modal-form">
              <div className="user_details_modal_container">
                <div className="leftBox_width">DOB :</div>
                <div className="box_width">{viewCurrentStudentDetails.dob == 'Invalid date' && viewCurrentStudentDetails.dob != '' ? "NA" : viewCurrentStudentDetails.dob}</div>

              </div>
              <div className="user_details_modal_container">
                <div className="leftBox_width">Gender :</div>
                <div className="box_width">
                  {viewCurrentStudentDetails.gender != "" && viewCurrentStudentDetails.gender != undefined ? viewCurrentStudentDetails.gender : "NA"}
                </div>

              </div>
              <div className="user_details_modal_container">
                <div className="leftBox_width">Mobile :</div>
                <div className="box_width">
                  {viewCurrentStudentDetails.mobile_no != "" && viewCurrentStudentDetails.mobile_no != undefined ? viewCurrentStudentDetails.mobile_no : "NA"}
                </div>
              </div>
              <div className="user_details_modal_container">
                <div className="leftBox_width">School Address :</div>
                <div className="box_width">
                  {viewCurrentStudentDetails.school_add != "" && viewCurrentStudentDetails.school_add != undefined ? viewCurrentStudentDetails.school_add : "NA"}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="footer_text">
              This details will be included in the export of Excel
            </div>
          </Modal.Footer>
        </Modal>
      </Row>
    </Fragment>
  );
};

// export default MisRegUser;

const mapStateToProps = (state) => {
  return {
    list: state.MisRegUser.list,
    classList: state.class.list,
    boardList: state.board.list,
  };
};

export default connect(mapStateToProps)(MisRegUser);
