import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';

import ReactApexChart from "react-apexcharts";
import Spinner from 'react-bootstrap/Spinner';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

//images
import sort from '../../../images/dashboard_image/sort.png'
import export_details from '../../../images/dashboard_image/export.png'

import {
	dashboardDataAction,
	dashboardDataDownloadAction,
	dashboardDetailsAction,
} from '../../../store/actions/DashboardAction';

import {
	Modal,
	Button,
} from "react-bootstrap";

// import { useFocusEffect } from '@react-navigation/native';


const Home = (props) => {

	const dispatch = useDispatch();

	const [selectOption, setSelectOption] = useState('')
	const [isShowDropList, setIsShowDropList] = useState(false)
	const [barchartColor, setBarchartColor] = useState('')
	const [userName, setUserName] = useState('')
	const [dashboardBoxDetails, setDashboardBoxDetails] = useState()
	const [sevenDaysRecords, setSevenDaysRecords] = useState()
	const [dashboardBoxId, setDashboardBoxId] = useState(1)
	const [categories, setCategories] = useState('')
	const [modal, setModal] = useState(false);
	// const [submissionDate, setSubmissionDate] = useState(new Date());
	const [filterFromDate, setFilterFromDate] = useState("");
	const [filterToDate, setFilterToDate] = useState(null);
	const [leftSideChartLabel, setLeftSideChartLabel] = useState('');
	const [bottomSideChartLabel, setBottomSideChartLabel] = useState('');
	const [viewDetails, setViewDetails] = useState(0);
	const [permission, setPermission] = useState("");
	const [display, setDisplay] = useState(true);

	var _leftSideChartLabel = '';
	var _bottomSideChartLabel = '';

	const calendarRef = useRef();
	const toCalendarRef = useRef();

	const [chartDataSeries, setChartDataSeries] = useState([])
	/* const [chartDataSeries, setChartDataSeries] = useState([{
		name: "ICSE",
		data: [10, 35, 24, 55, 10, 60, 70, 90, 10, 36, 0, 45],
	},
	{
		name: "CBSE",
		data: [90, 5, 28, 15, 66, 35, 45, 55, 80, 75, 40, 20],
	},
	{
		name: "WBBSE",
		data: [55, 44, 32, 60, 44, 78, 10, 19, 30, 55, 90, 12],
	}
	]) */

	const dashboardDetailsList = useSelector((state) => state.dashboard.dashboardDetailsList);

	useEffect(() => {
		// dispatch(dashboardloadingAction(true));
		// dispatch(dashboardDataAction(selectOption.id, dashboardBoxId, filterFromDate ? moment(filterFromDate).format("YYYY-MM-DD") : "", filterToDate ? moment(filterToDate).format("YYYY-MM-DD") : "", props.history));
		dashBoardDataCall();
	}, [selectOption, dashboardBoxId,]);

	useEffect(() => {
		if (filterFromDate === "" || filterFromDate === null) {
			setFilterToDate("")
		}
	}, [filterFromDate]);

	useEffect(() => {
		return () => {
			dispatch(dashboardDetailsAction([]))
		}
	}, [filterFromDate]);

	useEffect(() => {
		permissionCheck()
	}, []);




	useEffect(() => {
		// dispatch(dashboardloadingAction(true));
		if (dashboardDetailsList != '' && dashboardDetailsList?.series != undefined && dashboardDetailsList?.series != '') {
			setDashboardBoxDetails(dashboardDetailsList?.dashboard_box)
			setSevenDaysRecords(dashboardDetailsList?.last_seven_days_records)
			setChartDataSeries(dashboardDetailsList?.series)
			setCategories(dashboardDetailsList?.categories)
			setLeftSideChartLabel(dashboardDetailsList?.left_side_lebel)
			setBottomSideChartLabel(dashboardDetailsList?.bottom_side_lebel)
		}

	}, [dashboardDetailsList, dashboardBoxId]);

	useEffect(() => {

		setSelectOption(dropdownList[0])
		// setBarchartColor(dashboardList[0])
		let getData = localStorage.getItem("userDetails");
		let name = JSON.parse(getData).name;
		setUserName(name)
	}, []);

	useEffect(() => {
		// console.log("useEffect----", dashboardDetailsList, display)
		if (dashboardDetailsList != undefined && dashboardDetailsList != '' && display) {
			console.log("useEffect--33--", dashboardDetailsList, display)
			setBarchartColor(dashboardDetailsList?.dashboard_box[0])
			setDisplay(false)
		}

	}, [display, dashboardDetailsList]);



	useEffect(() => {

		return () => {
			setDisplay(true)
		}
	}, []);

	const permissionCheck = () => {
		const userDetails = localStorage.getItem("userDetails");
		const tokenDetails = JSON.parse(userDetails);
		const menuList = tokenDetails.menu_list;
		menuList.map((item) => {
			if (item.id == 1) {
				// console.log("1-----------", item)
				setPermission(item);
			}
		});
	};


	const dashBoardDataCall = () => {

		dispatch(dashboardDataAction(selectOption?.id, dashboardBoxId, filterFromDate ? moment(filterFromDate).format("YYYY-MM-DD") : "", filterToDate ? moment(filterToDate).format("YYYY-MM-DD") : "", pageViewAccess, props.history));
	}

	const pageViewAccess = () => {
		setViewDetails(1)
	}

	const dashboardList = [
		{
			id: 1,
			iconImage: require('../../../images/dashboard_image/total_registration.png'),
			iconBaseColor: '#26BF94',
			heading: 'Total Registration',
			countValue: '1,23,456',
			viewText: 'View All',
			viewArrowIcon: '',
			graphicIcseColor: '#13795C',
			graphicCbseColor: '#23A37F',
			graphicWbbseColor: '#2FE7B3',
			isClick: true,
		},
		{
			id: 2,
			iconImage: require('../../../images/dashboard_image/total_subscription.png'),
			iconBaseColor: '#23B7E5',
			heading: 'Total Subscription',
			countValue: '1,23,456',
			viewText: 'View All',
			viewArrowIcon: '',
			graphicIcseColor: '#1C87A9',
			graphicCbseColor: '#24A9D2',
			graphicWbbseColor: '#5AD8FF',
			isClick: true,
		},
		{
			id: 3,
			iconImage: require('../../../images/dashboard_image/total_school.png'),
			iconBaseColor: '#845ADF',
			heading: 'Total Content',
			countValue: '1,23,456',
			viewText: 'View All',
			viewArrowIcon: '',
			graphicIcseColor: '#423095',
			graphicCbseColor: '#6D5AC3',
			graphicWbbseColor: '#AF9CE7',
			isClick: true,
		},
		{
			id: 4,
			iconImage: require('../../../images/dashboard_image/total_content.png'),
			iconBaseColor: '#F5B849',
			heading: 'Total School',
			countValue: '1,23,456',
			viewText: 'View All',
			viewArrowIcon: '',
			graphicIcseColor: '#94630B',
			graphicCbseColor: '#FDAC18',
			graphicWbbseColor: '#F6DAA5',
			isClick: true,
		},
		{
			id: 5,
			iconImage: require('../../../images/dashboard_image/marketing.png'),
			iconBaseColor: '#2688BF',
			heading: 'Marketing',
			countValue: '1,23,456',
			viewText: 'View All',
			viewArrowIcon: '',
			graphicIcseColor: '#13795C',
			graphicCbseColor: '#23A37F',
			graphicWbbseColor: '#2FE7B3',
			isClick: false,
		},
		{
			id: 6,
			iconImage: require('../../../images/dashboard_image/services.png'),
			iconBaseColor: '#FD7E95',
			heading: 'Services',
			countValue: '1,23,456',
			viewText: 'View All',
			viewArrowIcon: '',
			graphicIcseColor: '#13795C',
			graphicCbseColor: '#23A37F',
			graphicWbbseColor: '#2FE7B3',
			isClick: false,
		},
		{
			id: 7,
			iconImage: require('../../../images/dashboard_image/account.png'),
			iconBaseColor: '#96D869',
			heading: 'Account',
			countValue: '1,23,456',
			viewText: 'View All',
			viewArrowIcon: '',
			graphicIcseColor: '#13795C',
			graphicCbseColor: '#23A37F',
			graphicWbbseColor: '#2FE7B3',
			isClick: false,
		},
		{
			id: 8,
			iconImage: require('../../../images/dashboard_image/it.png'),
			iconBaseColor: '#A7A7A7',
			heading: 'IT',
			countValue: '1,23,456',
			viewText: 'View All',
			viewArrowIcon: '',
			graphicIcseColor: '#13795C',
			graphicCbseColor: '#23A37F',
			graphicWbbseColor: '#2FE7B3',
			isClick: false,
		},

	]


	const registration = [
		{
			board: 'ICSE',
			user: '7',
			r_data: '20/04/2024',
		},
		{
			board: 'CBSE',
			user: '9',
			r_data: '21/04/2024',
		},
		{
			board: 'ICSE',
			user: '5',
			r_data: '22/04/2024',
		},
		{
			board: 'CBSE',
			user: '8',
			r_data: '23/04/2024',
		},
		{
			board: 'ICSE',
			user: '3',
			r_data: '24/04/2024',
		},
		{
			board: 'CBSE',
			user: '8',
			r_data: '25/04/2024',
		},
		{
			board: 'ICSE',
			user: '3',
			r_data: '26/04/2024',
		},
	]

	_leftSideChartLabel = dashboardDetailsList?.left_side_lebel;
	_bottomSideChartLabel = dashboardDetailsList?.bottom_side_lebel;

	const [dropdownList, setDropdownList] = useState([
		{
			id: 3,
			name: 'This Year',
			isSelect: 1,
		},
		{
			id: 2,
			name: 'This Month',
			isSelect: 0,
		},
		{
			id: 1,
			name: 'This Week',
			isSelect: 0,
		},
	])

	/* graphic details */
	const barChartDetails = {

		series: chartDataSeries, /* series require minimum length of 2  */
		/* series: [{
			name: "ICSE11",
			data: [10, 35, 24, 55, 10, 60, 70, 90, 10, 36, 0, 45],
		},
		 {
			name: "CBSE22",
			data: [90, 5, 28, 15, 66, 35, 45, 55, 80, 75, 40, 20],
		},
		{
			name: "WBBSE33",
			data: [55, 44, 32, 60, 44, 78, 10, 19, 30, 55, 90, 12],
		}
		], */
		options: {
			chart: {
				type: "bar",
				height: 100,
				toolbar: {
					show: false,
				},
			},

			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: "70%",
					// endingShape: "rounded",
					borderRadius: 2,
					borderRadiusApplication: 'end',
					// distributed: true,
				},
			},
			dataLabels: {
				enabled: false,
			},

			legend: {
				show: true,
				fontSize: "10px",
				fontWeight: 300,

				labels: {
					colors: "black",
				},

				position: "bottom",
				horizontalAlign: "center",
				markers: {
					width: 10,
					height: 10,
					strokeWidth: 0,
					radius: 19,
					strokeColor: "#fff",
					// fillColors: ["#13795C", "#23A37F", "#2FE7B3"],
					fillColors: [barchartColor.graphicIcseColor, barchartColor.graphicCbseColor, barchartColor.graphicWbbseColor],
					offsetX: 0,
					offsetY: 0,
				},
				onItemClick: {
					toggleDataSeries: false
				},
			},

			yaxis: {
				labels: {
					style: {
						colors: "#3e4954",
						fontSize: "14px",
						fontFamily: "Poppins",
						fontWeight: 100,
					},
				},
				title: {
					// text: 'No. of User',
					// text: (leftSideChartLabel != '' && leftSideChartLabel != undefined) ? 'leftSideChartLabel' : 'ppp',
					text: _leftSideChartLabel,
					style: {
						colors: "#2C2C2C50",
						fontSize: "9px",
						fontFamily: "Poppins",
						fontWeight: 100,
					}
				},
			},
			stroke: {
				show: true,
				width: 2,
				colors: ["transparent"],
			},
			xaxis: {
				categories: categories != '' && categories != undefined ? categories : '',
				// categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
				title: {
					// text: 'Period',
					text: _bottomSideChartLabel,
					// text: bottomSideChartLabel !== '' || bottomSideChartLabel !== undefined || bottomSideChartLabel !== null ? bottomSideChartLabel : '',
					style: {
						colors: "#2C2C2C50",
						fontSize: "9px",
						fontFamily: "Poppins",
						fontWeight: 100,
					},
					offsetX: 0,
					offsetY: 80,
				},
			},
			fill: {
				// colors: ["#13795C", "#23A37F", "#2FE7B3"],
				// colors: [barchartColor.graphicIcseColor, barchartColor.graphicCbseColor, barchartColor.graphicWbbseColor],
				colors: [barchartColor.graphicIcseColor, barchartColor.graphicCbseColor, barchartColor.graphicWbbseColor],
				opacity: 1,
			},

			/* tooltip: {
				y: {
					formatter: function (val) {
						return "$ " + val + " thousands";
					},
				},
			}, */
		},
	};

	/* const [chartTestData, setChartTestData] = useState({
		series: [{
			name: "ICSE11",
			data: [10, 35, 24, 55, 10, 60, 70, 90, 10, 36, 0, 45],
		},
		{
			name: "CBSE22",
			data: [90, 5, 28, 15, 66, 35, 45, 55, 80, 75, 40, 20],
		},
		{
			name: "WBBSE33",
			data: [55, 44, 32, 60, 44, 78, 10, 19, 30, 55, 90, 12],
		}
		]
	}) */





	/* dropdown list select */
	const selectItem = (item) => {
		setSelectOption(item)
		dropdownList.map(u => u.isSelect = u.id == item.id ? 1 : 0);
		setIsShowDropList(!isShowDropList)
	}

	/* dropdown list show */
	const showList = () => {
		setIsShowDropList(!isShowDropList)
	}

	/* change card item */
	const cardItem = (item) => {
		setBarchartColor(item)
		setDashboardBoxId(item.id)
	}

	const onDownloadDashboardFile = () => {
		dispatch(dashboardDataDownloadAction(selectOption.id, filterFromDate ? moment(filterFromDate).format("YYYY-MM-DD") : "", filterToDate ? moment(filterToDate).format("YYYY-MM-DD") : "", props.history));
	}

	const viewPage = (item) => {
		props.history.push(item.page_link);
	}

	const closeDateFilter = () => {
		setModal(false);
		setFilterFromDate()
		setFilterToDate()
		dispatch(dashboardDataAction(selectOption.id, dashboardBoxId, "", "", props.history));
		// dashBoardDataCall()
	}

	const fromDateHandler = (date) => {
		setFilterFromDate(date)
		setFilterToDate()
	}

	const onResetHandler = () => {
		setFilterFromDate()
		setFilterToDate()
	}

	const filterDashboardHandler = () => {
		setModal(false);
		dashBoardDataCall()
	}

	return (
		<>
			<div className="row">
				<div className="col-xl-6 col-xl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="card dashboard_card_height">

								{viewDetails == 0 ?
									<>
										{/* top */}
										{dashboardBoxDetails != '' && dashboardBoxDetails != undefined ?
											< div className='d-flex flex-row justify-content-between align-items-center m-4'>
												<div>
													<p className='welcome_text'>Welcome back, {userName}! </p>
												</div>
												<div className='d-flex flex-row'>
													<div className='btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 me-3 filter_background rounded cursorPointer' onClick={() => setModal(true)}>
														<div className='image_container'>
															<img src={sort} alt="" className='image_style' />
														</div>
														<div className='button_text font_color_black'>
															Filter
														</div>

													</div>

													{(permission?.download == "true") && (
														<div className='btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 export_background rounded blue_border cursorPointer' onClick={() => onDownloadDashboardFile()}>
															<div className='image_container'>
																<img src={export_details} alt="" className='image_style' />
															</div>
															<div className='button_text font_color_blue' >
																Export
															</div>
														</div>
													)}
												</div>
											</div>
											: null}
										{/* middle */}

										<div className='d-flex flex-row m-4 justify-content-start flex-wrap'>

											{dashboardBoxDetails != '' && dashboardBoxDetails != undefined ?
												dashboardBoxDetails?.map((item, index) => {
													return (
														<div className='position-relative me-4' key={index}>
															<div className={`small_card d-flex flex-row rounded mb-4 p-3  ${barchartColor.id === item.id ? 'border_red' : null} ${item.isClick ? 'cursorPointer' : null}`} key={index} onClick={() => item.isClick ? cardItem(item) : null}>
																<div className={`card_icon_container`} style={{ backgroundColor: item.iconBaseColor }}>
																	<img src={item.iconImage} alt="" className='icon_image_style' />
																</div>
																<div className='d-flex flex-column justify-content-between right_box_width'>
																	<div className='card_heading'>{item.heading}</div>
																	<div className='card_counter'>{item.countValue}</div>
																</div>
															</div>
															{
																item.viewText != '' && item.viewText != undefined ?
																	<div className={`d-flex align-self-end card_view_all view_text_position ${item.isClick ? 'cursorPointer' : null}`} style={{ color: item.iconBaseColor }} onClick={() => viewPage(item)} >{item.viewText} <i className="bi bi-arrow-right arrow_icon_size" style={{ color: item.iconBaseColor, marginLeft: '5px' }} ></i></div>
																	: null
															}
														</div>
													)
												})
												:
												<div className='d-flex flex-row m-4 justify-content-center flex-wrap w-100'>
													<Spinner
														as="span"
														animation="border"
														size="large"
														role="status"
														variant="danger"
														aria-hidden="true"
													/>

												</div>
											}
										</div>

										{dashboardBoxDetails != '' && dashboardBoxDetails != undefined ?
											<div className='d-flex flex-row m-4 justify-content-between flex-wrap'>
												<div className='registration_container rounded d-flex flex-column'>
													{/* Registration */}

													<div className='d-flex flex-row justify-content-center align-items-center w-100 m-2'>
														{/* <div className='registration_heading'>Registration</div> */}

														<div className='registration_heading'>{barchartColor != '' && barchartColor != undefined ? barchartColor?.heading?.split(' ')[1] : ''}</div>
														<div className='day_text'>(Last 7 Days)</div>
													</div>
													<div className='d-flex flex-row justify-content-around align-items-center w-100 bottom_line_table_heading'>

														<div className={`table_heading_text width_registration d-flex `}>{dashboardBoxId == 1 ? 'Registration Date' : dashboardBoxId == 2 ? 'Subscription Date' : dashboardBoxId == 3 ? 'Date' : dashboardBoxId == 4 ? 'Date' : null}</div> {/* justify-content-center align-items-center */}
														{dashboardBoxId != 3 ?
															<div className='table_heading_text width_board d-flex align-items-center '>{dashboardBoxId == 1 ? 'Board' : dashboardBoxId == 2 ? 'Product' : dashboardBoxId == 4 ? 'Board' : null}</div>
															: null}
														{/*  c ? 'Content Type' : */}

														<div className={`table_heading_text ${dashboardBoxId == 3 ? 'width_user_110' : 'width_user'} d-flex justify-content-center align-items-center`}>{dashboardBoxId == 1 ? 'No. of User' : dashboardBoxId == 2 ? 'No. of subscription' : dashboardBoxId == 3 ? 'No. of questions uploaded' : dashboardBoxId == 4 ? 'No. of School' : null}</div>

														{/* <div className='table_heading_text width_registration d-flex justify-content-center align-items-center'>Registration Date</div> */}

													</div>
													<div className='d-flex flex-column justify-content-around w-100 last_seven_days_container mx-1' >
														{/* {registration.map((item, index) => { */}
														{sevenDaysRecords != '' ?
															sevenDaysRecords?.map((item, index) => {
																return (
																	<div className='d-flex flex-row my-2' key={index}>
																		<div className={`${dashboardBoxId == 3 ? 'padding-right-32' : null} table_heading_text width_registration d-flex`}>{item.date}</div> {/* justify-content-center align-items-center */}
																		{dashboardBoxId != 3 ?
																			<div className={`table_heading_text width_board d-flex ps-2 `}>{item.board}</div> /* justify-content-center align-items-center */
																			: null}
																		<div className={`table_heading_text width_user d-flex ${dashboardBoxId == 2 ? 'ps-2' : 'ps-3'}`}>{item.count}</div> {/* justify-content-center align-items-center */}
																	</div>
																)
															})
															:
															<div className="no_dataContainer">
																No Data Found
															</div>
														}
													</div>
												</div>
												<div className='registration_analytics_container rounded d-flex flex-column'>
													<div className='d-flex justify-content-between w-100 m-2 registration_analytics_top_container position-relative '> {/* opacity_ninty_nine */}
														<div className='registration_heading'>{barchartColor != '' && barchartColor != undefined ? barchartColor?.heading?.split(' ')[1] : ''} Analytics</div>
														<div className={`d-flex flex-column  align-items-center dorpdown_container me-4 rounded dropDownZindex ${!isShowDropList ? 'dorpdown_container_single_item_height' : 'dorpdown_container_all_item_height'} dropdown_container_absolute_position`}>
															<div className='d-flex flex-row justify-content-between align-items-center dropdown_font w-100 select_value cursorPointer' onClick={() => showList()}>
																<div>

																	{selectOption.name}
																</div>
																<div>
																	<i className={isShowDropList ? "bi bi-chevron-up" : "bi bi-chevron-down"} ></i> {/*  down*/}
																</div>
															</div>

															{isShowDropList && dropdownList.map((item, index) => {
																return (
																	<div className='d-flex justify-content-between align-items-center dropdown_font w-100 my-1 cursorPointer' key={index} onClick={() => selectItem(item)}> {/* cursor_context_menu */}
																		<div>
																			{item.name}
																		</div>
																		<div className='dropdown_circle'>
																			{item.isSelect == 1 ? <div className='dropdown_circle_fill'></div> : null}
																		</div>
																	</div>
																)
															})
															}
														</div>
													</div>
													<div>
														<div className="chart_body">
															{/* {console.log("barChartDetails----", barChartDetails)} */}
															{/* {barChartDetails != '' && barChartDetails?.series != undefined && barChartDetails?.series.length > 0 && barChartDetails?.options?.xaxis?.categories != '' && barChartDetails?.options?.fill?.colors[0] != undefined && barChartDetails?.options?.fill?.colors[0] != 'undefined' ? */}
															{barChartDetails != '' && barChartDetails?.series != undefined ?
																<ReactApexChart
																	options={barChartDetails?.options}
																	series={barChartDetails?.series}
																	type="bar"
																	height={260}
																/>

																: null}


														</div>
													</div>

												</div>

											</div>
											: null}
									</>
									:
									<div className='no-permission'>
										You do not have access to any dashboards. <br /> For more information, contact Admin
									</div>
								}

							</div>

						</div>
					</div>
				</div>
				{/* </div> */}
				{/* <!-- Modal --> */}

				<Modal className="fade" show={modal} size="md">
					<Modal.Header>
						<Modal.Title>Filter</Modal.Title>
						<Button
							variant=""
							className="btn-close"
							onClick={() => closeDateFilter()}
						></Button>
					</Modal.Header>
					<Modal.Body className="p-0">
						<div className="question-modal-form">
							{/* <div className="reset-filtter-value">
							<button
								className="btn btn-info btn-xs"
							// onClick={() => onResetHandler()}
							>
								Reset
							</button>
						</div> */}

							<div className="row">
								<div className="form-group mb-3">
									<label className="mb-2">
										<strong>From Date<span className="text-danger">*</span></strong>
									</label>
									<div className="date-control-view">
										<DatePicker
											ref={calendarRef}
											selected={filterFromDate}
											placeholderText="From Date"
											onChange={(date) => fromDateHandler(date)}
											showYearDropdown
											showMonthDropdown
										// minDate={new Date()}
										// disabled
										/>
										<button type='button' className="picker-btn"
											onClick={() => calendarRef.current.setOpen(true)}
										>
											<i class="fa-solid fa-calendar-days"></i>
										</button>
									</div>
									{/* {errors.date && <div className="text-danger fs-12">{errors.date}</div>} */}
								</div>

								<div className={`form-group mb-3  ${filterFromDate === '' || filterFromDate === undefined || filterFromDate === null ? 'content_disable' : null}`}>
									<label className="mb-2">
										<strong>To Date<span className="text-danger">*</span></strong>
									</label>
									<div className="date-control-view">
										<DatePicker
											ref={toCalendarRef}
											selected={filterToDate}
											placeholderText="To Date"
											onChange={(date) => setFilterToDate(date)}
											showYearDropdown
											showMonthDropdown
											minDate={new Date(filterFromDate)}
										// disabled={ true }
										/>
										<button type='button' className="picker-btn"
											onClick={() => toCalendarRef.current.setOpen(true)}
										>
											<i class="fa-solid fa-calendar-days"></i>
										</button>
									</div>
								</div>
							</div>
							{/* {"FDate:" + filterFromDate + "TDate:" + filterToDate} */}
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={() => onResetHandler()} variant={`btn btn-info light ${filterFromDate === '' || filterFromDate === undefined || filterFromDate === null ? 'content_disable' : null}`}>
							Reset
						</Button>
						<Button onClick={() => filterDashboardHandler()} variant="primary" >
							Filter
						</Button>
					</Modal.Footer>
				</Modal>
				{/* </div> */}
			</div >
		</>
	)
}
export default Home;