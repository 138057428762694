import { Fragment, useEffect, useState, useRef } from "react";
import { Row, Col, Card, Table, Modal, Button } from "react-bootstrap";
import { useDispatch, connect, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";

import { globalLoadingAction } from "../../../store/actions/GlobalAction";

import {
  misTransactionListData,
  misTransactionExcelDownloadData,
  misPaymentRefundData,
  transactionListUpdatedAfterRefundAction,
  misAutoSuggestOrderIdData,
  misAutoSuggestMobileNoData,
  misAutoSuggestTransactionNoData
} from "../../../store/actions/MisDashboardInfoAction";

import paymentmodeList from './json/paymentmode.json';
import transactionstatusList from './json/transactionstatus.json';

import ReactPaginate from 'react-paginate';

//images
import sort from '../../../images/dashboard_image/sort.png'
import export_details from '../../../images/dashboard_image/export.png'

import AutocompleteSearch from '../AutocompleteSearch/AutocompleteSearch'

import {
  misRegisterStudentEmailListData,
} from "../../../store/actions/MisRegUserAction";

const MisTransactionList = (props) => {
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  const [studentId, setStudentId] = useState("")
  const [mobileNo, setmobileNo] = useState("")
  const [transactionId, setTransactionId] = useState("")
  const [orderId, setOrderId] = useState("")
  const [paymentMode, setPaymentMode] = useState('')
  const [transactionstatus, setTransactionstatus] = useState('')
  const [page, setPage] = useState(1)

  let errorsObj = {
    refundTransactionId: "",
    refundOrderId: "",
    refundAmount: "",
    refundPaymentMode: "",
  };

  const [errors, setErrors] = useState(errorsObj);
  const [refundModal, setRefundModal] = useState(false)

  const [refundTransactionId, setRefundTransactionId] = useState('')
  const [refundOrderId, setRefundOrderId] = useState('')
  const [refundAmount, setRefundAmount] = useState('')
  const [refundPaymentMode, setRefundPaymentMode] = useState('')
  const [refundRecordId, setRefundRecordId] = useState('')

  const [emailData, setEmailData] = useState([])
  const [autoSuggestOrderIdData, setAutoSuggestOrderIdData] = useState([])
  const [autoSuggestMobileNoData, setAutoSuggestMobileNoData] = useState([])
  const [autoSuggestTransactionNoData, setAutoSuggestTransactionNoData] = useState([])

  const misRegisteredStudentEmail = useSelector((state) => state.MisDashboardInfo.misRegisteredStudentEmail);
  const misTransactionList = useSelector((state) => state.MisDashboardInfo.misTransactionList);
  const isRefreshMisTransactionList = useSelector((state) => state.MisDashboardInfo.isRefreshMisTransactionList);
  const misAutoSuggestTransactionNoList = useSelector((state) => state.MisDashboardInfo.misAutoSuggestTransactionNoList);
  const misAutoSuggestMobileNoList = useSelector((state) => state.MisDashboardInfo.misAutoSuggestMobileNoList);
  const misAutoSuggestOrderIdList = useSelector((state) => state.MisDashboardInfo.misAutoSuggestOrderIdList);

  const [tempTransactionstatus, setTempTransactionstatus] = useState('')
  const [tempPaymentMode, setTempPaymentMode] = useState('')
  const [tempOrderId, setTempOrderId] = useState("")
  const [tempTransactionId, setTempTransactionId] = useState("")
  const [temMobileNo, setTemMobileNo] = useState("")
  const [tempStudentId, setTempStudentId] = useState("")

  const [isFiltered, setIsFiltered] = useState(false)

  const [permission, setPermission] = useState("");

  useEffect(() => {
    dispatch(misRegisterStudentEmailListData())
    dispatch(misAutoSuggestOrderIdData())
    dispatch(misAutoSuggestMobileNoData())
    dispatch(misAutoSuggestTransactionNoData())
    permissionCheck();
  }, []);

  useEffect(() => {

    if (transactionId != '' && transactionId != undefined) {
      setIsFiltered(true)
    } else if (orderId != '' && orderId != undefined) {
      setIsFiltered(true)
    } else if (studentId != '' && studentId != undefined) {
      setIsFiltered(true)
    } else if (transactionstatus != 'select_transaction_status' && transactionstatus != '' && transactionstatus != undefined) {
      setIsFiltered(true)
    } else if (mobileNo != '' && mobileNo != undefined) {
      setIsFiltered(true)
    } else if (paymentMode != 'select_payment_mode' && paymentMode != '' && paymentMode != undefined) {
      setIsFiltered(true)
    } else {
      setIsFiltered(false)
    }

  }, [transactionId, orderId, studentId, transactionstatus, mobileNo, paymentMode]);

  useEffect(() => {
    setAutoSuggestOrderIdData(misAutoSuggestOrderIdList);
    setAutoSuggestMobileNoData(misAutoSuggestMobileNoList);
    setAutoSuggestTransactionNoData(misAutoSuggestTransactionNoList)
  }, [misAutoSuggestOrderIdList, misAutoSuggestMobileNoList, misAutoSuggestTransactionNoList]);

  useEffect(() => {

    let email = [];
    let emailObj = {}
    misRegisteredStudentEmail.forEach((elm) => {
      emailObj = {}
      emailObj = elm.email
      email.push(emailObj);
    })
    setEmailData(email)

  }, [misRegisteredStudentEmail]);

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 95) {
        console.log("95----------", item)
        setPermission(item);
      }
    });
  };

  const onResetHandler = () => {
    setStudentId("");
    setmobileNo("");
    setTransactionId("");
    setOrderId("");
    setPaymentMode("");
    setTransactionstatus("");
    setPage(1);
    getList('', '', '', '', '', '', 1);
    setModal(false);
    setTempTransactionstatus('')
    setTempPaymentMode('')
    setTempOrderId('')
    setTempTransactionId('')
    setTemMobileNo('')
    setTempStudentId('')
  };

  useEffect(() => {
    getList(transactionId, orderId, studentId, transactionstatus, mobileNo, paymentMode, page)
  }, []);

  useEffect(() => {
    if (isRefreshMisTransactionList == 1) {
      let page = 1;
      getList(transactionId, orderId, studentId, transactionstatus, mobileNo, paymentMode, page)
      dispatch(transactionListUpdatedAfterRefundAction(0))
      setPage(1)
    }

  }, [isRefreshMisTransactionList]);


  const getList = (transactionId, orderId, studentId, transactionstatus, mobileNo, paymentMode, page) => {
    dispatch(globalLoadingAction(true));
    dispatch(
      misTransactionListData(
        transactionId,
        orderId,
        studentId,
        transactionstatus == 'select_transaction_status' ? '' : transactionstatus,
        mobileNo,
        paymentMode == 'select_payment_mode' ? '' : paymentMode,
        page
      )
    );
  };

  const filterMisTransactionListHandler = () => {
    let page = 1;
    setModal(false)
    setPage(1)
    getList(transactionId, orderId, studentId, transactionstatus, mobileNo, paymentMode, page);

    setTempTransactionstatus(transactionstatus)
    setTempPaymentMode(paymentMode)
    setTempOrderId(orderId)
    setTempTransactionId(transactionId)
    setTemMobileNo(mobileNo)
    setTempStudentId(studentId)
  }

  /* pagination */
  const pageHandler = (data) => {
    let page = data.selected + 1;
    setPage(data.selected + 1);
    getList(transactionId, orderId, studentId, transactionstatus, mobileNo, paymentMode, page);
  }

  const onDownloadMisTransactionListHandler = () => {
    let page = 0;
    dispatch(globalLoadingAction(true));
    dispatch(
      misTransactionExcelDownloadData(
        transactionId,
        orderId,
        studentId,
        transactionstatus,
        mobileNo,
        paymentMode,
        page
      )
    );
  }

  /* form submit */
  function onSubmit(e) {
    e.preventDefault();
    let error = false;

    const errorObj = { ...errorsObj };

    if (refundTransactionId === "") {
      errorObj.refundTransactionId = "Transaction Id is Required";
      error = true;
    }

    if (refundOrderId === "") {
      errorObj.refundOrderId = "Order Id is Required";
      error = true;
    }

    if (refundAmount === "") {
      errorObj.refundAmount = "Amount is Required";
      error = true;
    }

    if (refundPaymentMode === "") {
      errorObj.refundPaymentMode = "Please Select Payment Mode";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    } else {
      setRefundModal(false)
      dispatch(globalLoadingAction(true));
      dispatch(
        misPaymentRefundData(
          refundTransactionId,
          refundOrderId,
          refundAmount,
          refundPaymentMode,
          refundRecordId
        )
      );
    }
  }

  const refundHandler = (item) => {
    setRefundPaymentMode("")
    setRefundTransactionId("")
    setRefundModal(true)
    setRefundOrderId(item.order_id)
    setRefundAmount(item.amount)
    setRefundRecordId(item.id)
  }

  const cancelModal = () => {
    setModal(false)

    if (tempTransactionstatus == '') {
      setTransactionstatus("")
    } else if (transactionstatus != 'select_transaction_status' && transactionstatus != '' && transactionstatus != undefined) {
      setTransactionstatus(tempTransactionstatus)
    } else if (transactionstatus == 'select_transaction_status' || transactionstatus == '') {
      setTransactionstatus(tempTransactionstatus)
    }

    if (tempPaymentMode == '') {
      setPaymentMode("")
    } else if (paymentMode != 'select_payment_mode' && paymentMode != '' && paymentMode != undefined) {
      setPaymentMode(tempPaymentMode)
    } else if (paymentMode == 'select_payment_mode' || paymentMode == '') {
      setPaymentMode(tempPaymentMode)
    }

    if (tempOrderId == '') {
      setOrderId("")
    } else if (orderId != '' && orderId != undefined) {
      setOrderId(tempOrderId)
    } else if (orderId == '') {
      setOrderId(tempOrderId)
    }

    if (tempTransactionId == '') {
      setTransactionId("")
    } else if (transactionId != '' && transactionId != undefined) {
      setTransactionId(tempTransactionId)
    } else if (transactionId == '') {
      setTransactionId(tempTransactionId)
    }

    if (temMobileNo == '') {
      setmobileNo("")
    } else if (mobileNo != '' && mobileNo != undefined) {
      setmobileNo(temMobileNo)
    } else if (mobileNo == '') {
      setmobileNo(temMobileNo)
    }

    if (tempStudentId == '') {
      setStudentId("")
    } else if (studentId != '' && studentId != undefined) {
      setStudentId(tempStudentId)
    } else if (studentId == '') {
      setStudentId(tempStudentId)
    }

  }

  const isDisabled =
    (transactionstatus === "" || transactionstatus === "select_transaction_status") &&
    (paymentMode === "" || paymentMode === "select_payment_mode") &&
    transactionId === "" &&
    orderId === "" &&
    mobileNo === "" &&
    studentId === "";

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>

              <div className="filtersAlign mt-3" style={{ marginRight: 25 }}>
                <div className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 me-3 filter_background rounded cursorPointer ${isFiltered ? 'border_red' : null}`} onClick={() => setModal(true)}>
                  <div className='image_container'>
                    <img src={sort} alt="" className='image_style' />
                  </div>
                  <div className='button_text font_color_black'>
                    Filter
                  </div>
                </div>
                {(permission.download == "true") && (
                  <div className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 export_background rounded blue_border cursorPointer ${misTransactionList?.data?.length == 0 || misTransactionList?.data?.length == undefined ? 'content_disable' : null}`}
                    onClick={() => onDownloadMisTransactionListHandler()}
                  >
                    <div className='image_container'>
                      <img src={export_details} alt="" className='image_style' />
                    </div>
                    <div className='button_text font_color_blue cursorPointer'>
                      Export
                    </div>
                  </div>
                )}
              </div>
              {/* </div> */}

              {misTransactionList?.data?.length == 0 || misTransactionList?.data?.length == undefined ? (
                <div className="no_data">
                  <strong>No data found</strong>
                </div>
              ) : (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Sl no.</strong>
                      </th>
                      <th>
                        <strong>Student name</strong>
                      </th>
                      <th>
                        <strong>User Id</strong>
                      </th>
                      <th>
                        <strong>Mobile</strong>
                      </th>
                      <th>
                        <strong>Transaction Id</strong>
                      </th>
                      <th>
                        <strong>Order Id</strong>
                      </th>
                      <th>
                        <strong>Bank ref no</strong>
                      </th>
                      <th>
                        <strong>Payment Mode</strong>
                      </th>
                      <th>
                        <strong>Amount</strong>
                      </th>
                      <th>
                        <strong>Date & time</strong>
                      </th>
                      <th>
                        <strong>Transaction status</strong>
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    {/* 
                  <tr>
                    <td> <div className='unSuccessBox'>Refunded</div></td>
                  </tr> */}

                    {/* <tr>
                    
                    <td>
                      <div className="d-flex flex-row justify-content-around">
                        <div className='successBox'>Success</div>
                        <div className='refundBox' onClick={() => setRefundModal(true)}>Refund</div>
                      </div>
                    </td>
                  </tr> */}

                    {/* 1 = Success, 2 = Fail, 3= Abort, 4 = illegal 5 = Refund */}
                    {/* {item.trans_status == 1 ? <div className='successBox'>Success</div> : item.trans_status == 2 ? <div className='unSuccessBox'>Fail</div> : item.trans_status == 3 ? <div className='unSuccessBox'>Abort</div> : item.trans_status == 4 ? <div className='unSuccessBox'>illegal</div> : null} */}

                    {misTransactionList?.data?.map((item, index) => {
                      return (
                        <tr key={item.sl_no}>
                          <td>{item.sl_no}</td>
                          <td>{item.fname + ' ' + item.lname}</td>
                          <td>{item.email}</td>
                          <td>{item.mobile}</td>
                          <td>{item.payment_trans_id}</td>
                          <td>{item.order_id}</td>
                          <td>{item.bank_ref_no}</td>
                          <td>{item.payment_mode}</td>
                          <td>{item.amount}</td>
                          <td>{item.trans_date}</td>
                          {/* <td>{item.trans_status}</td> */}
                          <td>{item.trans_status == 1 ?
                            <div className="d-flex flex-row justify-content-around">
                              <div className='resung_successBox'>Success</div>

                              {(permission.refund == "true") && (
                                <div className='refundBox' onClick={() => refundHandler(item)}>Refund</div>
                              )}

                            </div> : item.trans_status == 2 ? <div className='unSuccessBox'>Fail</div> : item.trans_status == 3 ? <div className='unSuccessBox'>Abort</div> : item.trans_status == 4 ? <div className='unSuccessBox'>illegal</div> : item.trans_status == 5 ? <div className='unSuccessBox'>Refunded</div> : item.trans_status == 6 ? <div className='resung_successBox'>Success</div> : null} </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}

              {misTransactionList?.data?.length != 0 && misTransactionList?.data != undefined ?
                <div className="pagination-container">
                  <div className="page-result">
                    <p>Showing: {(page - 1) * (10) + 1} - {(10 * page) - (10 - misTransactionList?.data?.length)} of {misTransactionList.total_record}</p>
                  </div>
                  {misTransactionList.total_record > 10 ?
                    < ReactPaginate
                      containerClassName='pagination-gutter  false pagination-circle pagination pagination-sm'
                      pageClassName='page-item'
                      pageLinkClassName='page-link'
                      nextLabel={<i className="la la-angle-right"></i>}
                      nextClassName='page-item'
                      nextLinkClassName='page-link'
                      breakLabel="..."
                      previousLabel={<i className="la la-angle-left"></i>}
                      previousClassName='page-item'
                      previousLinkClassName='page-link'
                      breakClassName='page-item'
                      breakLinkClassName='page-link'
                      renderOnZeroPageCount={null}
                      activeClassName="active"
                      onPageChange={pageHandler}
                      pageRangeDisplayed={3}
                      pageCount={misTransactionList.total_page}
                      forcePage={page - 1}
                    />
                    : null}
                </div>
                : null}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <!-- Modal --> */}
      <Modal className="fade" show={modal} size="md">
        <Modal.Header>
          <Modal.Title>Filter</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => cancelModal()}
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="question-modal-form">
            <div className="reset-filtter-value">
              <button
                disabled={isDisabled}
                className="btn btn-info btn-xs"
                onClick={() => onResetHandler()}
              >
                Reset
              </button>
            </div>

            <div className="row">

              <div className="form-group col-sm-6 px-1 mb-3">
                <AutocompleteSearch
                  dataDetails={autoSuggestOrderIdData}
                  // dataDetails={misAutoSuggestOrderIdList}
                  placeholder={`Order Id`}
                  setNewValue={(e) => setOrderId(e)}
                  selectValue={orderId}
                />
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <AutocompleteSearch
                  dataDetails={autoSuggestTransactionNoData}
                  placeholder={`Transaction Id`}
                  setNewValue={(e) => setTransactionId(e)}
                  selectValue={transactionId}
                />
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <AutocompleteSearch
                  dataDetails={autoSuggestMobileNoData}
                  placeholder={`Mobile No.`}
                  setNewValue={(e) => setmobileNo(e)}
                  selectValue={mobileNo}
                />
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <AutocompleteSearch
                  dataDetails={emailData}
                  placeholder={`Student Id`}
                  setNewValue={(e) => setStudentId(e)}
                  selectValue={studentId}
                />
              </div>

              <div className={`form-group col-sm-6 px-1 mb-3`}>
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={transactionstatus}
                    onChange={(e) => setTransactionstatus(e.target.value)}
                  >
                    <option value="select_transaction_status">Select Transaction Status</option>
                    {transactionstatusList.map((item, index) => {
                      return <option key={index} value={item.id}>{item.showValue}</option>;
                    })}
                  </select>
                </div>
              </div>

              <div className={`form-group col-sm-6 px-1 mb-3`}>
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={paymentMode}
                    onChange={(e) => setPaymentMode(e.target.value)}
                  >
                    <option value="select_payment_mode">Select Payment Mode</option>
                    {paymentmodeList.map((item, index) => {
                      return <option key={index} value={item.id}>{item.showValue}</option>;
                    })}
                  </select>
                </div>
              </div>

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => cancelModal()} variant="danger light">
            Cancel
          </Button>
          <Button
            disabled={isDisabled}
            onClick={() => filterMisTransactionListHandler()} variant="primary">
            Filter
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <!-- Modal --> */}
      <Modal className="fade" show={refundModal} size="md">
        <Modal.Header>
          <Modal.Title>Refund Form</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setRefundModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-0">


          {/* <div className="col-xl-3"></div> */}
          {/* <div className="col-xl-6"> */}
          <div className="card-body">
            <div className="basic-form">
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="form-group col-xl-6 mb-3">
                    <label className="mb-2">
                      <strong>Transaction ID<span className="text-danger">*</span></strong>
                    </label>
                    <input
                      className="form-control"
                      placeholder="Enter Transaction ID"
                      value={refundTransactionId}
                      onChange={(e) => setRefundTransactionId(e.target.value)}
                    />
                    {errors.refundTransactionId && (
                      <div className="text-danger fs-12">
                        {errors.refundTransactionId}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-xl-6 mb-3">
                    <label className="mb-2">
                      <strong>Order ID<span className="text-danger">*</span></strong>
                    </label>
                    <input
                      className="form-control"
                      placeholder="Enter Order ID"
                      value={refundOrderId}
                      onChange={(e) => setRefundOrderId(e.target.value)}
                      disabled
                    />
                    {errors.refundOrderId && (
                      <div className="text-danger fs-12">
                        {errors.refundOrderId}
                      </div>
                    )}
                  </div>

                  <div className="form-group col-xl-6 mb-3">
                    <label className="mb-2">
                      <strong>Amount<span className="text-danger">*</span></strong>
                    </label>
                    <input
                      className="form-control"
                      placeholder="Enter Amount"
                      value={refundAmount}
                      onChange={(e) => setRefundAmount(e.target.value)}
                      disabled
                    />
                    {errors.refundAmount && (
                      <div className="text-danger fs-12">
                        {errors.refundAmount}
                      </div>
                    )}
                  </div>

                  <div className={`form-group col-sm-6 px-1 mb-3`}>
                    <label className="mb-2">
                      <strong>Payment Mode<span className="text-danger">*</span></strong>
                    </label>
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow add-Question-arrow-position">
                        <i className="bi bi-caret-down-fill"></i>
                      </span>
                      <select
                        defaultValue={"option"}
                        className="form-control"
                        value={refundPaymentMode}
                        onChange={(e) => setRefundPaymentMode(e.target.value)}
                      >
                        <option value="">Select Payment Mode</option>
                        {paymentmodeList.map((item, index) => {
                          return <option key={index} value={item.showValue}>{item.showValue}</option>;
                        })}
                      </select>
                    </div>
                    {errors.refundPaymentMode && (
                      <div className="text-danger fs-12">
                        {errors.refundPaymentMode}
                      </div>
                    )}
                  </div>

                  <div className="text-center col-md-4  mx-auto mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary btn-block category-btn"
                      disabled={props.showLoading}
                    >
                      Submit
                      {props.showLoading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : null}
                    </button>
                  </div>
                </div>
              </form>
            </div>

            {/* </div> */}
            {/* <div className="col-xl-3"></div> */}


          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={() => setModal(false)} variant="danger light">
            Cancel
          </Button>
          <Button onClick={() => filterRegisteredHandler()} variant="primary">
            Filter
          </Button>
        </Modal.Footer> */}
      </Modal>

    </Fragment>
  );
};

const mapstateToProps = (state) => {
  return {
    boardList: state.board.list,
    classList: state.class.list,
    categoryList: state.category.list,
    subjectList: state.subject.subjectList,
    subscriptionDetailsList: state.subscriptiondetails.list,
  };
};

export default connect(mapstateToProps)(MisTransactionList);
