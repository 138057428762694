import swal from "sweetalert";
import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "../actions/GlobalAction";
import { SubscriptionDetails, SubscriptionDownloadExcelDetails } from "../../services/SubscriptionDetailsService";
import { GET_SUBSCRIPTION_DETAILS_LIST } from "../constants";

export function SubscriptionDetailsData(period, category, board, className, service, nbf, subscriptionMethod, locationPin, subject, page, startDate, endDate, exam_type) {
  return (dispatch) => {
    SubscriptionDetails(period, category, board, className, service, nbf, subscriptionMethod, locationPin, subject, page, startDate, endDate, exam_type)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(SubscriptionDetailsAction(response.data))
          dispatch(globalLoadingAction(false));
        } else {

        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}


export function subscriptionDownloadExcelData(period, category, board, className, service, nbf, subscriptionMethod, locationPin, subject, page, startDate, endDate, exam_type) {
  return (dispatch) => {
    SubscriptionDownloadExcelDetails(period, category, board, className, service, nbf, subscriptionMethod, locationPin, subject, page, startDate, endDate, exam_type)
      .then((response) => {
        window.location.href = response.data.filename;
        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));

      });
  };
}


export function SubscriptionDetailsAction(data) {
  return {
    type: GET_SUBSCRIPTION_DETAILS_LIST,
    payload: data
  }
}