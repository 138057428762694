import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import * as utility from "../../../utility/Utility";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import {
  getPermisstionData,
  loadingPermissionAction,
} from "../../../store/actions/PermissionAction";
import { addRoleData } from "../../../store/actions/UserAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

const CreateRole = (props) => {
  const dispatch = useDispatch();

  const [permissionList, setPermissionList] = useState([]);
  const chackbox = document.querySelectorAll(".form-check input");
  const [roleName, setRollName] = useState("");
  const [description, setDescription] = useState("");
  const [tabPermissionList, setTabPermissionList] = useState([]);
  const elArr=['checkAdd', 'checkEdit', 'checkDelete','checkStatus','checkDownload','checkMake_attendent']; 
  let errorsObj = {
    roleName: "",
    description: "",
    permissionList: "",
  };

  const [errors, setErrors] = useState(errorsObj);

  const history = useHistory();

  useEffect(() => {
    dispatch(getPermisstionData(true));
  }, []);

  /* permission handler */
  const onMainPermissionHandler = (event, item) => {  //alert('first');
    
    let dataArray = [...props.permissionList];
    let arry = [...permissionList];
    if (event.target.checked) { 
      arry.push({
        id: item.id,
        edit: true,
        delete: true,
        status: true,
        download: true,
        add: true,
        pay_now: true,
        sync: true,
        refund: true,
        make_attendent: true,
      });

      for (let i = 0; dataArray.length > i; i++) {  
        if (dataArray[i].parent_menu == item.id) {  
          arry.push({
            id: dataArray[i].id,
            edit:true,
            delete: true,
            status: true,
            download: true,
            add:true,
            pay_now: true,
            sync: true,
            refund: true,
            make_attendent: true,
          });

          elArr.forEach((els) => {
            let el='#'+els+dataArray[i].id
            if(document.querySelector(el) !== null )
            document.querySelector(el).checked = true;
          });
         
          document.querySelector(`#check${dataArray[i].id}`).checked = true;

        }
      }

      elArr.forEach((els) => {
         let el='#'+els+item.id
         if(document.querySelector(el) !== null )
         document.querySelector(el).checked = true;
      });


    } else {
      const index = arry.findIndex((val) => val.id === item.id);
      arry.splice(index, 1);
      for (let i = 0; dataArray.length > i; i++) {
        if (dataArray[i].parent_menu == item.id) {
          const chilsIndex = arry.findIndex(
            (val) => val.id === dataArray[i].id
          );
          arry.splice(chilsIndex, 1);
          document.querySelector(`#check${dataArray[i].id}`).checked = false;

          elArr.forEach((els) => {
             let el='#'+els+dataArray[i].id;
             if(document.querySelector(el) !== null )
             document.querySelector(el).checked = false;
          });

        }
      }

      elArr.forEach((els) => {
         let el='#'+els+item.id;
         if(document.querySelector(el) !== null )
         document.querySelector(el).checked = false;
      });

    }

    setPermissionList(arry);
  };

  /* subpermission handler */
  const onSubPermissionHandler = (event, item, data) => {   //alert("2nd")

    console.log("event, item, data----", item)
    let dataArray = [...props.permissionList];
    let arry = [...permissionList];
    let parentId = false;

    for (let i = 0; arry.length > i; i++) {
      if (arry[i].id == item.id) {
        parentId = true;
        break;
      }
    }
    if (event.target.checked) { 

      //check all child checkbox
      elArr.forEach((els) => {
         let el='#'+els+data.id;
         if(document.querySelector(el) !== null )
         document.querySelector(el).checked = true;
      });
  
      if (parentId) { 
        console.log("@parentId----", parentId)
        arry.push({
          id: data.id,
          edit: true,
          delete: true,
          status: true,
          download: true,
          add:true,
          pay_now: true,
          sync: true,
          refund: true,
          make_attendent: true
        });
      } else {
        document.querySelector(`#check${item.id}`).checked = true;
        console.log("@#check--item--", item,)
        console.log("@#check--data--", data)

        arry.push(
          {
            id: item.id,
            edit: true,
            delete: true,
            status: true,
            download: true,
            add: true,
            pay_now: true,
            sync: true,
            refund: true,
            make_attendent: true,
          },
          {
            id: data.id,
            edit: true,
            delete: true,
            status: true,
            download: true,
            add:true,
            pay_now: true,
            sync: true,
            refund:true,
            make_attendent: true
          }
        );
      }
    } else {
      
      //uncheck all child checkbox
      elArr.forEach((els) => {
        let el='#'+els+data.id;
        if(document.querySelector(el) !== null )
        document.querySelector(el).checked = false;
       });

      let ins = null;
      const subIndex = arry.findIndex((val) => val.id === data.id);
      arry.splice(subIndex, 1);

      console.log("======ptest======");
      console.log(arry);

      for (let i = 0; dataArray.length > i; i++) {
        if (dataArray[i].parent_menu == item.id) {
          for (let j = 0; arry.length > j; j++) {
            if (arry[j].id == dataArray[i].id) {
              ins = true;
              break;
            }
          }
        }
      }

      if(ins == null)
        ins = true;
      if (!ins) { 
        const parentIndex = arry.findIndex((val) => val.id === item.id);
        arry.splice(parentIndex, 1);
        document.querySelector(`#check${item.id}`).checked = false;
      }
    }

    setPermissionList(arry);

  };

  /* on status handler */
  const onStatusHandler = (event, status, item) => {  

    document.querySelector(`#check${item.id}`).checked = true;
    if(document.querySelector(`#check${item.parent_menu}`) !== null )
    document.querySelector(`#check${item.parent_menu}`).checked = true;
    //props.permissionList.

    console.log("@123--------", status, item)
  
    
    let arry = [...permissionList];

    let index = arry.findIndex((val) => val.id === item.id);

    if(index<0){

      arry.push({
        id: item.id,
        edit: true,
        delete: true,
        status: true,
        download: true,
        add: true,
        pay_now: true,
        sync: true,
        refund:true,
        make_attendent: true
      });

      index = (arry.length-1)
      
    }
    
    arry[index].edit= arry[index].delete= arry[index].status=arry[index].add=arry[index].pay_now= arry[index].sync=arry[index].refund=arry[index].make_attendent=false;

    if(document.querySelector(`#checkEdit${item.id}`) !== null )
    arry[index].edit= document.querySelector(`#checkEdit${item.id}`).checked;
    else
    arry[index].edit = false;

    if(document.querySelector(`#checkDelete${item.id}`) !== null )
    arry[index].delete= document.querySelector(`#checkDelete${item.id}`).checked;
    else
    arry[index].delete =  false;

    if(document.querySelector(`#checkStatus${item.id}`) !== null )     
    arry[index].status= document.querySelector(`#checkStatus${item.id}`).checked; 
    else
    arry[index].status= false;

    if(document.querySelector(`#checkAdd${item.id}`) !== null ) 
    arry[index].add= document.querySelector(`#checkAdd${item.id}`).checked; 
    else
    arry[index].add= false;

    if(document.querySelector(`#checkPaynow${item.id}`) !== null ) 
    arry[index].pay_now= document.querySelector(`#checkPaynow${item.id}`).checked; 
    else
    arry[index].pay_now= false;

    if(document.querySelector(`#checkSync${item.id}`) !== null ) 
    arry[index].sync= document.querySelector(`#checkSync${item.id}`).checked; 
    else
    arry[index].sync= false;

    if(document.querySelector(`#checkSync${item.id}`) !== null ) 
    arry[index].refund= document.querySelector(`#checkRefund${item.id}`).checked; 
    else
    arry[index].refund= false;

    if(document.querySelector(`#checkMake_attendent${item.id}`) !== null ) 
    arry[index].make_attendent= document.querySelector(`#checkMake_attendent${item.id}`).checked; 
    else
    arry[index].make_attendent= false;


    if (status == 1) {
      arry[index].edit = event.target.checked;
    } else if (status == 2) {
      arry[index].delete = event.target.checked;
    } else if (status == 3) {
      arry[index].status = event.target.checked;
    } else if (status == 4) {
      arry[index].download = event.target.checked;
    } else if (status == 5) {
      arry[index].add = event.target.checked;
    } else if (status == 6) {
      arry[index].pay_now = event.target.checked;
    } else if (status == 7) {
      arry[index].sync = event.target.checked;
    } else if (status == 8) {
      arry[index].refund = event.target.checked;
    } else if (status == 9) {
      arry[index].make_attendent = event.target.checked;
    }
    console.log("arry----", arry)
    setPermissionList(arry);

  };

  /* add
pay_now
sync
refund
make_attendent */

  /* sub and Tab menu id Collection */
  const onTabMenuPermissionHandler = (event, item, data) => { 
    document.querySelector(`#check${data.parent_menu}`).checked = true; 
    document.querySelector(`#check${item.id}`).checked = true; 
    let tabPermission = [...tabPermissionList];
    const tabpermissionIndex = tabPermission.findIndex((item) => item.id === data.id);
    if (tabpermissionIndex !== -1) {
      tabPermission.splice(tabpermissionIndex, 1);
    } else {
      let obj = {}
      const newSelect = data.id;
      obj.id = newSelect
      obj.parentId=data.parent_menu
      tabPermission.push(obj);
    }
    setTabPermissionList(tabPermission)
    
    let arry = [...permissionList];
    let index = arry.findIndex((val) => val.id === item.id);

    if(index<0){

      arry.push({
        id: item.id,
        edit: false,
        delete: false,
        status: false,
        download: false,
        add: false,
        pay_now: false,
        sync: false,
        refund:false,
        make_attendent: false
      });    
   }  
   
   index = arry.findIndex((val) => val.id === data.parent_menu);

   let tabExist=false
   if(tabPermission.length>0){

      for(let r=0;r<tabPermission.length;r++){

          if(tabPermission[r].parentId==data.parent_menu){

            tabExist=true
            break;

          }     

      }
   }

   if(document.querySelector(`#checkDownload${data.parent_menu}`) !== null ) 
    if (document.querySelector(`#checkDownload${data.parent_menu}`).checked) 
      tabExist=true

   if(tabExist){

      document.querySelector(`#check${data.parent_menu}`).checked = true; 
      if(index<0){

        arry.push({
          id: data.parent_menu,
          edit: false,
          delete: false,
          status: false,
          download: false,
          add: false,
          pay_now: false,
          sync: false,
          refund:false,
          make_attendent: false
        });
      }
      
  }   
  else{
    
      if(index>0) 
      arry.splice(index, 1);
      document.querySelector(`#check${data.parent_menu}`).checked = false; 

  }
  
   setPermissionList(arry);

  }



  /* form submit */
  const onSubmit = (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    if (roleName.trim() === "") {
      errorObj.roleName = "Role is Required";
      error = true;
    }
    if (description.trim() === "") {
      errorObj.description = "Description is Required";
      error = true;
    }
    if (permissionList.length == 0) {
      errorObj.permissionList = "Permission is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    let finalArr=[]; let duplicateArr=[]; let duplicatEl=[];
    for(let $k=0;$k<permissionList.length;$k++){
          
         if(duplicateArr.includes(permissionList[$k].id)){
            duplicatEl.push(permissionList[$k].id)
            continue;
         }
         
         finalArr.push(permissionList[$k]); 
         duplicateArr.push(permissionList[$k].id); 

    }

    console.log('=========duplicatElement',duplicatEl);
    console.log('=========tabPermissionListl',tabPermissionList);
    console.log('=========submissionList',finalArr);

    dispatch(globalLoadingAction(true));
    dispatch(addRoleData(roleName, description, finalArr, tabPermissionList, history));

    console.log("data ready for submit.....", permissionList);
  };

  return (
    <>
      <div className="row">
        { console.log('ps...list.....', props.permissionList)} 
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="basic-form fromContainer">
                    <form onSubmit={onSubmit}>
                      <div className="row RoleDescription">
                        <div className="form-group mb-3 col-md-6">
                          <label className="mb-2">
                            <strong>
                              Role Name
                              <span className="text-danger">*</span>
                            </strong>
                          </label>
                          <input
                            className="form-control"
                            placeholder="Enter role name"
                            value={roleName}
                            onChange={(e) => setRollName(e.target.value)}
                          />
                          {errors.roleName && (
                            <div className="text-danger fs-12">
                              {errors.roleName}
                            </div>
                          )}
                        </div>

                        <div className="form-group mb-3 col-md-6">
                          <label className="mb-2">
                            <strong>
                              Description
                              <span className="text-danger">*</span>
                            </strong>
                          </label>
                          <input
                            className="form-control"
                            placeholder="Enter description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                          {errors.description && (
                            <div className="text-danger fs-12">
                              {errors.description}
                            </div>
                          )}
                        </div>
                      </div>
                      <label className="mb-2">
                        <strong>
                          Permission
                          <span className="text-danger">*</span>
                        </strong>
                      </label>
                      <div
                        className="permission-list-view"
                        style={{ backgroundColor: "#8300000a" }}
                      >
                        {props.permissionList.map((item, i) => {
                          if (item.parent_menu == 0) {
                            return (
                              <div
                                key={item.id + i}
                                className="main-form-check-view"
                              >
                                <div className="form-check mt-2 mb-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`check${item.id}`}
                                    name={`check${item.id}`}
                                    
                                    //value={props.totalModules}
                                    //checked={isLibrary}
                                    onChange={(e) =>
                                      onMainPermissionHandler(e, item) // First level click event
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`check${item.id}`}
                                  >
                                    {item.menu_name} 
                                  </label>
                                </div>

                                <div className="sub-form-check-view colorPaddingSubFrom">
                                  {props.permissionList.map((data, j) => {
                                    {/* show tab menu  */ }
                                    if (data?.tab_menu?.length > 0 && item.id == data.tab_menu[0].parent_menu) {
                                      // {
                                      return (
                                        <>
                                          <div className="tab-menu" key={j}>
                                            {data.tab_menu.map((tabName, k) =>
                                              <>
                                                {/* <p key={k}>{tabName.menu_name}</p> */}
                                                <div
                                                  key={tabName.id + k}
                                                  className="form-check mt-2 me-3"
                                                >
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`check${tabName.id}`}
                                                    name={`check${tabName.id}`}
                                                    //value={props.totalModules}
                                                    //checked={isLibrary}
                                                    onChange={(e) =>
                                                      onTabMenuPermissionHandler(   ////  Dashboard tab click event
                                                        e,
                                                        item,
                                                        tabName
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`check${tabName.id}`}
                                                  >
                                                    {tabName.menu_name}
                                                  </label>
                                                </div>
                                              </>
                                            )}

                                          </div>


                                          {data.action ? (
                                            <div className="permission-submenu">

                                              {data.add.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkAdd${data.id}`}
                                                    name={`checkAdd${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        5,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkAdd${data.id}`}
                                                  >
                                                    Add
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.edit.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkEdit${data.id}`}
                                                    name={`checkEdit${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        1,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkEdit${data.id}`}
                                                  >
                                                    Edit
                                                  </label>
                                                </div>
                                              ) : null}
                                              {data.delete.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkDelete${data.id}`}
                                                    name={`checkDelete${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        2,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkDelete${data.id}`}
                                                  >
                                                    Delete
                                                  </label>
                                                </div>
                                              ) : null}
                                              {data.status.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkStatus${data.id}`}
                                                    name={`checkStatus${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        3,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkStatus${data.id}`}
                                                  >
                                                    Status
                                                  </label>
                                                </div>
                                              ) : null}
                                              {data.download.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkDownload${data.id}`}
                                                    name={`checkDownload${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        4,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkDownload${data.id}`}
                                                  >
                                                    Download
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.pay_now.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkPaynow${data.id}`}
                                                    name={`checkPaynow${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        6,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkPaynow${data.id}`}
                                                  >
                                                    Pay now
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.sync.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkSync${data.id}`}
                                                    name={`checkSync${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        7,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkEdit${data.id}`}
                                                  >
                                                    Sync
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.refund.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkRefund${data.id}`}
                                                    name={`checkRefund${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        8,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkRefund${data.id}`}
                                                  >
                                                    Refund
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.make_attendent.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkMake_attendent${data.id}`}
                                                    name={`checkMake_attendent${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        9,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkMake_attendent${data.id}`}
                                                  >
                                                    Mark as Attended
                                                  </label>
                                                </div>
                                              ) : null}

                                            </div>
                                          ) : null}
                                        </>
                                      )
                                    } else if (item.id == data.parent_menu) {
                                      return (
                                        <div
                                          key={data.id + j}
                                          className="form-check mt-2 me-3"
                                        >
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={`check${data.id}`}
                                            name={`check${data.id}`}
                                            //value={props.totalModules}
                                            //checked={isLibrary}
                                            onChange={(e) =>
                                              onSubPermissionHandler(    //2nd permission click
                                                e,
                                                item,
                                                data
                                              )
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={`check${data.id}`}
                                          >
                                            {data.menu_name}
                                          </label>
                                          {data?.tab_menu?.length > 0 && data.id == data.tab_menu[0].parent_menu ?

                                            <>
                                              <div className="tab-menu permission-submenu" key={j}>
                                                {data.tab_menu.map((subTabName, k) =>
                                                  <>
                                                    {/* <p key={k}>{tabName.menu_name}</p> */}
                                                    <div
                                                      key={subTabName.id + k}
                                                      className="form-check mt-2 me-3"
                                                    >
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={`check${subTabName.id}`}
                                                        name={`check${subTabName.id}`}
                                                        //value={props.totalModules}
                                                        //checked={isLibrary}
                                                        onChange={(e) =>
                                                          onTabMenuPermissionHandler(
                                                            e,
                                                            item,
                                                            subTabName
                                                          )
                                                        }
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor={`check${subTabName.id}`}
                                                      >
                                                        {subTabName.menu_name}
                                                      </label>
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </>
                                            : null
                                          }

                                          {data.action ? (
                                            <div className="permission-submenu">

                                              {data.add.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkAdd${data.id}`}
                                                    name={`checkAdd${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        5,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkAdd${data.id}`}
                                                  >
                                                    Add
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.edit.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkEdit${data.id}`}
                                                    name={`checkEdit${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        1,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkEdit${data.id}`}
                                                  >
                                                    Edit
                                                  </label>
                                                </div>
                                              ) : null}
                                              {data.delete.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkDelete${data.id}`}
                                                    name={`checkDelete${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        2,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkDelete${data.id}`}
                                                  >
                                                    Delete
                                                  </label>
                                                </div>
                                              ) : null}
                                              {data.status.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkStatus${data.id}`}
                                                    name={`checkStatus${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        3,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkStatus${data.id}`}
                                                  >
                                                    Status
                                                  </label>
                                                </div>
                                              ) : null}
                                              {data.download.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkDownload${data.id}`}
                                                    name={`checkDownload${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        4,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkDownload${data.id}`}
                                                  >
                                                    Download
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.pay_now.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkEdit${data.id}`}
                                                    name={`checkEdit${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        6,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkEdit${data.id}`}
                                                  >
                                                    Pay now
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.sync.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkEdit${data.id}`}
                                                    name={`checkEdit${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        7,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkEdit${data.id}`}
                                                  >
                                                    Sync
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.refund.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkEdit${data.id}`}
                                                    name={`checkEdit${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        8,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkEdit${data.id}`}
                                                  >
                                                    Refund
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.make_attendent.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkEdit${data.id}`}
                                                    name={`checkEdit${data.id}`}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        9,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkEdit${data.id}`}
                                                  >
                                                    Mark as Attended
                                                  </label>
                                                </div>
                                              ) : null}

                                            </div>
                                          ) : null}
                                        </div>
                                      );
                                    } else {

                                      return (
                                        item.id == data.id && data.parent_menu == 0 && data.action ? (
                                          <div className="permission-submenu">
                                            {data.add.active ? (
                                              <div className="form-check me-3">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={`checkAdd${data.id}`}
                                                  name={`checkAdd${data.id}`}
                                                  onChange={(e) =>
                                                    onStatusHandler(
                                                      e,
                                                      5,
                                                      data
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`checkAdd${data.id}`}
                                                >
                                                  Add
                                                </label>
                                              </div>
                                            ) : null}

                                            {data.edit.active ? (
                                              <div className="form-check me-3">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={`checkEdit${data.id}`}
                                                  name={`checkEdit${data.id}`}
                                                  onChange={(e) =>
                                                    onStatusHandler(
                                                      e,
                                                      1,
                                                      data
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`checkEdit${data.id}`}
                                                >
                                                  Edit
                                                </label>
                                              </div>
                                            ) : null}
                                            {data.delete.active ? (
                                              <div className="form-check me-3">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={`checkDelete${data.id}`}
                                                  name={`checkDelete${data.id}`}
                                                  onChange={(e) =>
                                                    onStatusHandler(
                                                      e,
                                                      2,
                                                      data
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`checkDelete${data.id}`}
                                                >
                                                  Delete
                                                </label>
                                              </div>
                                            ) : null}
                                            {data.status.active ? (
                                              <div className="form-check me-3">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={`checkStatus${data.id}`}
                                                  name={`checkStatus${data.id}`}
                                                  onChange={(e) =>
                                                    onStatusHandler(
                                                      e,
                                                      3,
                                                      data
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`checkStatus${data.id}`}
                                                >
                                                  Status
                                                </label>
                                              </div>
                                            ) : null}
                                            {data.download.active ? (
                                              <div className="form-check me-3">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={`checkDownload${data.id}`}
                                                  name={`checkDownload${data.id}`}
                                                  onChange={(e) =>
                                                    onStatusHandler(
                                                      e,
                                                      4,
                                                      data
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`checkDownload${data.id}`}
                                                >
                                                  Download
                                                </label>
                                              </div>
                                            ) : null}

                                            {data.pay_now.active ? (
                                              <div className="form-check me-3">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={`checkPaynow${data.id}`}
                                                  name={`checkPaynow${data.id}`}
                                                  onChange={(e) =>
                                                    onStatusHandler(
                                                      e,
                                                      6,
                                                      data
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`checkPaynow${data.id}`}
                                                >
                                                  Pay now
                                                </label>
                                              </div>
                                            ) : null}

                                            {data.sync.active ? (
                                              <div className="form-check me-3">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={`checkSync${data.id}`}
                                                  name={`checkSync${data.id}`}
                                                  onChange={(e) =>
                                                    onStatusHandler(
                                                      e,
                                                      7,
                                                      data
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`checkEdit${data.id}`}
                                                >
                                                  Sync
                                                </label>
                                              </div>
                                            ) : null}

                                            {data.refund.active ? (
                                              <div className="form-check me-3">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={`checkRefund${data.id}`}
                                                  name={`checkRefund${data.id}`}
                                                  onChange={(e) =>
                                                    onStatusHandler(
                                                      e,
                                                      8,
                                                      data
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`checkRefund${data.id}`}
                                                >
                                                  Refund
                                                </label>
                                              </div>
                                            ) : null}

                                            {data.make_attendent.active ? (
                                              <div className="form-check me-3">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={`checkMake_attendent${data.id}`}
                                                  name={`checkMake_attendent${data.id}`}
                                                  onChange={(e) =>
                                                    onStatusHandler(
                                                      e,
                                                      9,
                                                      data
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`checkEdit${data.id}`}
                                                >
                                                  Mark as Attended
                                                </label>
                                              </div>
                                            ) : null}

                                          </div>
                                        ) : null
                                      )
                                      // }
                                    }
                                  })}
                                </div>
                              </div>
                            );
                          }
                        })}

                        {errors.permissionList && (
                          <div className="text-danger fs-12">
                            {errors.permissionList}
                          </div>
                        )}
                      </div>
                      <div className="text-center mt-4 submitButtonRole">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block category-btn"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </>
  );
};
const mapStateToProps = (state) => {
  // console.log("checking permission list",state.permission.list)
  return {
    permissionList: state.permission.list,
  };
};

export default connect(mapStateToProps)(CreateRole);
