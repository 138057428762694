import React, { Fragment, useEffect, useState, } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import Spinner from 'react-bootstrap/Spinner';
import {
	Row,
	Col,
	Card,
	Table,
	Modal,
	Button,
} from "react-bootstrap";

import { globalLoadingAction } from '../../store/actions/GlobalAction';

import ReactPaginate from "react-paginate";

import { PaymentTransactionData } from '../../store/actions/MisUserActivityAction';


const TransactionList = (props) => {

	const dispatch = useDispatch();
	const [studentDetailsModal, setStudentDetailsModal] = useState('');

	const [start, setStart] = useState(1)
	const [end, setEnd] = useState()
	const [pageRangeDisplayed, setPageRangeDisplayed] = useState(10)


	const [active, setActive] = useState(1);
	const [transId, setTransId] = useState('');
	const [orderId, setOrderId] = useState('');
	const [studentId, setstudentId] = useState('');
	const [transStatus, setTransStatus] = useState(''); /* 1 = Success,2 = Fail,3= Abort,4 = illegal */
	const [mobile, setMobile] = useState('');
	const [selectStudent, setSelectStudent] = useState('');
	const [permission, setPermission] = useState("");


	const transactionList = useSelector((state) => state.subscribedUserActivity.transactionList);
	const studentList = useSelector((state) => state.subscribedUserActivity.studentList);
	const totalPage = useSelector((state) => state.subscribedUserActivity.totalPage);
	const totalRecord = useSelector((state) => state.subscribedUserActivity.totalRecord);

	useEffect(() => {
		dispatch(globalLoadingAction(true));
		permissionCheck();
	}, []);

	useEffect(() => {
		dispatch(PaymentTransactionData(active, transId, orderId, studentId, transStatus, mobile, props.history));
	}, [active, transId, orderId, studentId, transStatus, mobile]);

	const onResetHandler = () => {
		setActive(1);
		setTransId('');
		setOrderId('');
		setstudentId('');
		setTransStatus('');
		setMobile('');
	}

	/* pagination */
	const pageHandler = (data) => {
		setActive(data.selected + 1);
		dispatch(PaymentTransactionData(active, transId, orderId, studentId, transStatus, mobile, props.history));
		setStart(((data.selected + 1) - 1) * pageRangeDisplayed + 1)
		setEnd((data.selected + 1) * pageRangeDisplayed)
		if (((data.selected + 1) * pageRangeDisplayed) > props.contentListTotalPage) {
			setEnd(props.contentListTotalPage);
		}
	}

	const closestudentDetailsModal = () => {
		setStudentDetailsModal(false)
	}

	/* change student item  */
	const studentDetails = (item) => {
		setStudentDetailsModal(true)
		setSelectStudent(item)
	}

	const permissionCheck = () => {
		const userDetails = localStorage.getItem("userDetails");
		const tokenDetails = JSON.parse(userDetails);
		const menuList = tokenDetails.menu_list;
		menuList.map((item) => {
		  if (item.id == 89) {
			console.log("89----------", item)
			setPermission(item);
		  }
		});
	  };

	return (
		<Fragment>
			{/* {console.log("transactionList==========", transactionList)} */}
			{/* {console.log("studentList==========", studentList)} */}
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Body>
							<div className="row chapter-list-view">
								{/* <div className="col-sm-12 px-1 reset-btn-view">
									<button onClick={onResetHandler} className="btn btn-secondary">Reset</button>
								</div> */}

								<div className="col-sm-2 px-1">
									<div className="basic-dropdown ">
										<input type="text"
											className="form-control height_40 border_radius_6 font_size_12"
											// className="form-control filter-form-control-input me-2"
											name='trans_id'
											value={orderId}
											onChange={(e) => setOrderId(e.target.value)}
											placeholder='Order Id'
											autoComplete="off"
										/>
									</div>
								</div>

								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<input type="text"
											className="form-control height_40 border_radius_6 font_size_12"
											name='trans_id'
											value={transId}
											onChange={(e) => setTransId(e.target.value)}
											placeholder='Transaction Id'
											autoComplete="off"
										/>
									</div>
								</div>

								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<input type="text"
											className="form-control height_40 border_radius_6 font_size_12"
											name='trans_id'
											value={mobile}
											onChange={(e) => setMobile(e.target.value)}
											placeholder='Mobile No.'
											autoComplete="off"
										/>
									</div>
								</div>

								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow">
											{props.showLoadingSubject ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>}
										</span>
										<select
											// defaultValue={"option"}
											className="form-control filter-form-control"
											value={studentId}
											onChange={(e) => setstudentId(e.target.value)}
											isSearchable={true}
										>
											<option value="">Select Student Id</option>
											{studentList != '' ?

												studentList.map((item, index) => {
													return <option key={index} value={item.id}>{item.email}</option>
												})

												: null}
										</select>
									</div>
								</div>



								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow">
											{props.subjectLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>}
										</span>
										<select
											// defaultValue={"option"}
											className="form-control filter-form-control"
											value={transStatus}
											onChange={(e) => setTransStatus(e.target.value)}
										>
											<option value="">Select Trans Status</option>
											<option value="1">Success</option>
											<option value="2">Fail</option>
											<option value="3">Abort</option>
											<option value="4">illegal</option>

										</select>
									</div>
								</div>
								<div style={{ display: 'flex', justifyContent: 'flex-end' }} className="col-sm-2 px-1 ">
									<button onClick={onResetHandler} className={`btn btn-secondary ${orderId == '' && transId == '' && mobile == '' && studentId == '' && transStatus == '' ? 'content_disable' : null}`}>Reset</button>
								</div>

							</div>
							<Table responsive>
								<thead>
									<tr>
										<th className='col_heading'>
											<strong>Student Name</strong>
										</th>
										<th className='col_heading'>
											<strong>User Id</strong>
										</th>
										<th className='col_heading'>
											<strong>Mobile</strong>
										</th>
										<th className='col_heading'>
											<strong>Transaction Id</strong>
										</th>
										<th className='col_heading'>
											<strong>Order Id</strong>
										</th>
										<th className='col_heading'>
											<strong>Bank Ref No</strong>
										</th>
										<th className='chapter_col col_heading'>
											<strong>Payment Mode</strong>
										</th>
										<th className='col_heading'>
											<strong>Amount</strong>
										</th>
										<th className='col_heading'>
											<strong>Transaction Date</strong>
										</th>
										<th className='chapter_col col_heading'>
											<strong>Transaction Status</strong>
										</th>
									</tr>
									{/* //13 items */}
								</thead>
								{transactionList != '' ?
									<tbody>
										{

											transactionList.map((item, index) => {
												return <tr key={index}>
													{/* <td>{item.serial_no}</td> */}
													<td className='cursorPointer linkColor' onClick={() => studentDetails(item)}>{item.fname} {item.lname}</td>
													<td>{item.email}</td>
													<td>{item.mobile}</td>
													<td>{item.payment_trans_id}</td>
													<td>{item.order_id}</td>
													<td>{item.bank_ref_no}</td>
													<td>
														{item.payment_mode}
													</td>
													<td>{item.amount}</td>
													{/* <td><Moment format="D MMM YYYY" withTitle>{item.trans_date}</Moment></td> */}
													<td>{item.trans_date}</td>
													<td>
														{/* 1 = Success,2 = Fail,3= Abort,4 = illegal */}
														{item.trans_status == 1 ? <div className='successBox'>Success</div> : item.trans_status == 2 ? <div className='unSuccessBox'>Fail</div> : item.trans_status == 3 ? <div className='unSuccessBox'>Abort</div> : item.trans_status == 4 ? <div className='unSuccessBox'>illegal</div> : null}
													</td>
												</tr>
											})
										}
									</tbody>
									: <tbody>
										<td colSpan={12}>
											<div className='no_data'>
												<strong >No data found</strong>
											</div>
										</td>
									</tbody>}
							</Table>

							<div className="pagination-container">
								<div className="page-result">
									{totalRecord > 0 ?
										<p>Showing: {(active - 1) * (pageRangeDisplayed) + 1} - {(pageRangeDisplayed * active) - (10 - transactionList.length)} of {totalRecord}</p>
										: null}
								</div>
								{totalRecord > 10 ?
									<ReactPaginate
										containerClassName='pagination-gutter  false pagination-circle pagination pagination-sm'
										pageClassName='page-item'
										pageLinkClassName='page-link'
										nextLabel={<i className="la la-angle-right"></i>}
										nextClassName='page-item'
										nextLinkClassName='page-link'
										breakLabel="..."
										previousLabel={<i className="la la-angle-left"></i>}
										previousClassName='page-item'
										previousLinkClassName='page-link'
										breakClassName='page-item'
										breakLinkClassName='page-link'
										renderOnZeroPageCount={null}
										activeClassName="active"
										onPageChange={pageHandler}
										pageRangeDisplayed={3}
										pageCount={totalPage}
										forcePage={active - 1}
									/>
									: null}
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<Modal className="fade" show={studentDetailsModal}>
				<Modal.Header>
					<Modal.Title className="modal_title">Student Details</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{selectStudent != '' ?
						<div className='studentDetailsContainer'>
							<div className='imagStudentDetailsContainer'>
								{selectStudent.profile_pic != '' && selectStudent.profile_pic != null ?
									<img src={selectStudent.profile_pic} alt="" className='imageContainer' />
									: <div className='imageContaineNnameText'>{selectStudent.fname.split('')[0]}</div>}
							</div>
							<div className='rowLableContainer'>
								<div className='leftLabel studentLable'>Name</div>
								<div className='middleLable studentLable'>:</div>
								<div className='rightData studentLableData'>{selectStudent.fname} {selectStudent.lname}</div>
							</div>
							<div className='rowLableContainer'>
								<div className='leftLabel studentLable'>Address</div>
								<div className='middleLable studentLable'>:</div>
								<div className='rightData studentLableData'>{selectStudent.address}</div>
							</div>
							<div className='rowLableContainer'>
								<div className='leftLabel studentLable'>Pincode</div>
								<div className='middleLable studentLable'>:</div>
								<div className='rightData studentLableData'>{selectStudent.pincode}</div>
							</div>
							<div className='rowLableContainer'>
								<div className='leftLabel studentLable'>Gender</div>
								<div className='middleLable studentLable'>:</div>
								<div className='rightData studentLableData'>{selectStudent.gender}</div>
							</div>
							<div className='rowLableContainer'>
								<div className='leftLabel studentLable'>School Name</div>
								<div className='middleLable studentLable'>:</div>
								<div className='rightData studentLableData'>{selectStudent.school_name}</div>
							</div>
							<div className='rowLableContainer'>
								<div className='leftLabel studentLable'>Board Name</div>
								<div className='middleLable studentLable'>:</div>
								<div className='rightData studentLableData'>{selectStudent.board_name}</div>
							</div>
							<div className='rowLableContainer'>
								<div className='leftLabel studentLable'>Class</div>
								<div className='middleLable studentLable'>:</div>
								<div className='rightData studentLableData'>{selectStudent.standard}</div>
							</div>

							<div className='rowLableContainer'>
								<div className='leftLabel studentLable'>School Address</div>
								<div className='middleLable studentLable'>:</div>
								<div className='rightData studentLableData'>{selectStudent.school_address}</div>
							</div>
						</div>
						: null}
				</Modal.Body>
				<Modal.Footer>
					<div className="modal_footer_right_container show_right">
						<Button
							variant="warning text-center m-1"
							onClick={() => closestudentDetailsModal()}

						>
							OK
						</Button>
					</div>
				</Modal.Footer>
			</Modal>

		</Fragment >
	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.elibrary.showLoading,
		elibraryList: state.elibrary.list,
		categoryList: state.category.list,
		categoryLoading: state.category.showLoading,
		branchList: state.branch.branchList,
		branchLoading: state.branch.showLoading,
		boardLoading: state.board.showLoading,
		boardList: state.board.list,
		totalPage: state.elibrary.totalPage,
		totalRecord: state.elibrary.totalRecord,
		assignList: state.assign.eLibraryContentlist,
		typeList: state.type.list,
		classList: state.class.list,
		subjectList: state.subject.nonGroupSubjectList,
		chapterList: state.chapter.typelist,
		showLoadingSubject: state.subject.showLoading,
		showLoadingChapter: state.chapter.showLoading,
		getRejectedListIdWise: state.assign.getRejectedListIdWise,
		contentListTotalRecord: state.assign.contentListTotalRecord,
		contentListTotalPage: state.assign.contentListTotalPage,
		filterDataList: state.assign.filterDataList,
	};
};

export default connect(mapStateToProps)(TransactionList);