import swal from "sweetalert";
import {
  addCategoty,
  getCategoty,
  removeCategoty,
  updateStatusCategoty,
  updateCategoty,
} from "../../services/ExamCategoryServices";

import {
  ADD_EXAM_CATEGORY_CONFIRMED,
  CATEGOTY_LOADING_TOGGLE,
  GET_EXAM_CATEGORY,
  REMOVE_EXAM_CATEGORY,
  UPDATE_STATUS_EXAM_CATEGORY,
  UPDATE_EXAM_CATEGORY,
} from "../constants";

import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "../actions/GlobalAction";

export function addExamCategory(
  category,
  code,
  guestExam,
  registerUserExam,
  guestLibrary,
  registerLibrary,
  performanceScore,
  libraryPerformanceScore,
  photoLiveExamFile,
  photoLibraryFile,
  photoPerformanceFile,
  photoPerformanceLFile,
  history
) {
  return (dispatch) => {
    addCategoty(
      category,
      code,
      guestExam,
      registerUserExam,
      guestLibrary,
      registerLibrary,
      performanceScore,
      libraryPerformanceScore,
      photoLiveExamFile,
      photoLibraryFile,
      photoPerformanceFile,
      photoPerformanceLFile,
      history
    )
      .then((response) => {
        console.log(response)
        dispatch(addCategotyAction(false));
        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
        //  history.push('/category-list')
      })
      .catch((error) => {
        //console.log(error);
        // utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getExamCategory(history) {
  return (dispatch) => {
    getCategoty()
      .then((response) => {
        // console.log("*****----->", response.data)
        if (response.data.status == 200) {
          dispatch(getCategotyAction(response.data.data));
        }
        dispatch(globalLoadingAction(false));
        dispatch(loadingToggleAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
        dispatch(loadingToggleAction(false));
      });
  };
}

export function updateExamCategory(
  id,
  category,
  code,
  guestExam,
  registerUserExam,
  guestLibrary,
  registerLibrary,
  performanceScore,
  libraryPerformanceScore,
  photoLiveExamFile,
  photoLibraryFile,
  photoPerformanceFile,
  photoPerformanceLFile,
  history
) {
  return (dispatch) => {
    updateCategoty(
      id,
      category,
      code,
      guestExam,
      registerUserExam,
      guestLibrary,
      registerLibrary,
      performanceScore,
      libraryPerformanceScore,
      photoLiveExamFile,
      photoLibraryFile,
      photoPerformanceFile,
      photoPerformanceLFile
    )
      .then((response) => {
        //console.log(response)
        //dispatch(updateCategotyAction(response.data.data));
        //dispatch(globalLoadingAction(false));
        utility.showSuccess(response.data.msg);
        history.goBack();
      })
      .catch((error) => {
        //console.log(error);
        dispatch(loadingToggleAction(true));
        //utility.showError(error.response.data);
      });
  };
}

export function updateStatusExamCategory(item, history) {
  return (dispatch) => {
    updateStatusCategoty(item.id, parseInt(item.status) === 1 ? 0 : 1)
      .then((response) => {
        dispatch(updateStatusCategotyAction(item));
        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function removeExamCategory(id, history) {
  return (dispatch) => {
    removeCategoty(id)
      .then((response) => {
        dispatch(removeCategotyAction(id));
        swal(response.data.msg, { icon: "success" });
      })
      .catch((error) => {
        //console.log(error);
        swal("Oops", "Something went wrong!", "error");
      });
  };
}

export function addCategotyAction(data) {
  return {
    type: ADD_EXAM_CATEGORY_CONFIRMED,
    payload: data,
  };
}
export function getCategotyAction(data) {
  return {
    type: GET_EXAM_CATEGORY,
    payload: data,
  };
}
export function updateCategotyAction(data) {
  return {
    type: UPDATE_EXAM_CATEGORY,
    payload: data,
  };
}
export function updateStatusCategotyAction(item) {
  return {
    type: UPDATE_STATUS_EXAM_CATEGORY,
    payload: item,
  };
}

export function removeCategotyAction(data) {
  return {
    type: REMOVE_EXAM_CATEGORY,
    payload: data,
  };
}

export function loadingToggleAction(status) {
  return {
    type: CATEGOTY_LOADING_TOGGLE,
    payload: status,
  };
}
