import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import * as utility from "../../../utility/Utility";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

import {
  getPermisstionData,
  loadingPermissionAction,
} from "../../../store/actions/PermissionAction";


import { addRoleData, updateRoleData } from "../../../store/actions/UserAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
const elArr=['checkAdd', 'checkEdit', 'checkDelete','checkStatus','checkDownload','checkMake_attendent']; 

let dataRole = [
  {
    id: 11,
    edit: false,
    delete: false,
    status: false,
  },
  {
    id: 12,
    edit: false,
    delete: false,
    status: false,
  },
  {
    id: 13,
    edit: true,
    delete: true,
    status: false,
  },
  {
    id: 14,
    edit: false,
    delete: false,
    status: false,
  },
  {
    id: 15,
    edit: false,
    delete: false,
    status: false,
  },
  {
    id: 16,
    edit: true,
    delete: false,
    status: true,
  },
  {
    id: 17,
    edit: false,
    delete: false,
    status: false,
  },
  {
    id: 18,
    edit: false,
    delete: false,
    status: false,
  },
  {
    id: 19,
    edit: false,
    delete: true,
    status: true,
  },
];

const UpdateRole = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();

  let errorsObj = { roleName: "", description: "", permissionList: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [permissionList, setPermissionList] = useState([...state.menu_lists]);
  const chackbox = document.querySelectorAll(".form-check input");
  const [roleName, setRollName] = useState(state.role_name);
  const [description, setDescription] = useState(state.description);
  const [tabPermissionList, setTabPermissionList] = useState([...state.dash_tab_list, ...state.mis_dash_tab_list]);
  const [subtabPermissionList, setSubtabPermissionList] = useState([...state.mis_dash_tab_list]);

  useEffect(() => {
    dispatch(getPermisstionData(true));
  }, []);

  /* form submit */
  const onSubmit = (e) => { 
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    if (roleName.trim() === "") {
      errorObj.roleName = "Role is Required";
      error = true;
    }
    if (description.trim() === "") {
      errorObj.description = "Description is Required";
      error = true;
    }
    if (permissionList.length == 0) {
      errorObj.permissionList = "Permission is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    
    let finalArr=[]; let duplicateArr=[]; let duplicatEl=[];
    for(let $k=0;$k<permissionList.length;$k++){
          
         if(duplicateArr.includes(permissionList[$k].id)){
            duplicatEl.push(permissionList[$k].id)
            continue;
         }
         
         finalArr.push(permissionList[$k]); 
         duplicateArr.push(permissionList[$k].id); 

    }

    console.log('=========duplicatElement',duplicatEl);
    console.log('=========tabPermissionListl',tabPermissionList);
    console.log('=========submissionList',finalArr);

    dispatch(globalLoadingAction(true));
    dispatch(
      updateRoleData(state.id, roleName, description, finalArr, tabPermissionList, history)
    );

    console.log("data  submit.....", permissionList);
  };

  /* permission handler */
  const onMainPermissionHandler = (event, item) => {  
    let dataArray = [...props.permissionList];
    let arry = [...permissionList];
    if (event.target.checked) {
      arry.push({
        id: item.id,
        edit: true,
        delete: true,
        status: true,
        download: true,
        add:true,
        pay_now: true,
        sync: true,
        refund: true,
        make_attendent: true,
      });
      for (let i = 0; dataArray.length > i; i++) {
        if (dataArray[i].parent_menu == item.id) {
          arry.push({
            id: dataArray[i].id,
            edit: true,
            delete: true,
            status: true,
            download: true,
            add: true,
            pay_now: true,
            sync: true,
            refund: true,
            make_attendent: true,
          });
          document.querySelector(`#check${dataArray[i].id}`).checked = true;
          elArr.forEach((els) => {
            let el='#'+els+dataArray[i].id
            if(document.querySelector(el) !== null )
            document.querySelector(el).checked = true;
          });
  
        }
      }

      elArr.forEach((els) => {
        let el='#'+els+item.id
        if(document.querySelector(el) !== null )
        document.querySelector(el).checked = true;
     });


    } else {
      const index = arry.findIndex((val) => val.id === item.id);
      arry.splice(index, 1);
      for (let i = 0; dataArray.length > i; i++) {
        if (dataArray[i].parent_menu == item.id) {
          const chilsIndex = arry.findIndex(
            (val) => val.id === dataArray[i].id
          );
          arry.splice(chilsIndex, 1);
          document.querySelector(`#check${dataArray[i].id}`).checked = false;
          elArr.forEach((els) => {
            let el='#'+els+dataArray[i].id;
            if(document.querySelector(el) !== null )
            document.querySelector(el).checked = false;
         });

        }
      }

      elArr.forEach((els) => {
        let el='#'+els+item.id;
        if(document.querySelector(el) !== null )
        document.querySelector(el).checked = false;
     });


    }

    setPermissionList(arry);
  };

  /* subpermission */
  const onSubPermissionHandler = (event, item, data) => {  
  
    let dataArray = [...props.permissionList];
    let arry = [...permissionList];
    let parentId = false;

    for (let i = 0; arry.length > i; i++) {
      if (arry[i].id == item.id) {
        parentId = true;
        break;
      }
    }
    if (event.target.checked) {

      //check all child checkbox
      elArr.forEach((els) => {
        let el='#'+els+data.id;
        if(document.querySelector(el) !== null )
        document.querySelector(el).checked = true;
     });
    
      
      if (parentId) {
        arry.push({
          id: data.id,
          edit: true,
          delete: true,
          status: true,
          download: true,
          add: true,
          pay_now: true,
          sync: true,
          refund:true,
          make_attendent: true
        });
      } else {
        document.querySelector(`#check${item.id}`).checked = true;
        arry.push(
          {
            id: item.id,
            edit: true,
            delete: true,
            status: true,
            download: true,
            add: true,
            pay_now: true,
            sync:true,
            refund: true,
            make_attendent: true
          },
          {
            id: data.id,
            edit: true,
            delete: true,
            status: true,
            download: true,
            add: true,
            pay_now: true,
            sync: true,
            refund: true,
            make_attendent: true
          }
        );
      }
    } else {

      //uncheck all child checkbox
      elArr.forEach((els) => {
        let el='#'+els+data.id;
        if(document.querySelector(el) !== null )
        document.querySelector(el).checked = false;
      });
      let ins = null;
      const subIndex = arry.findIndex((val) => val.id === data.id);
      arry.splice(subIndex, 1);

      for (let i = 0; dataArray.length > i; i++) {
        if (dataArray[i].parent_menu == item.id) {
          for (let j = 0; arry.length > j; j++) {
            if (arry[j].id == dataArray[i].id) {
              ins = true;
              break;
            }
          }
        }
      }

      if(ins == null)
        ins = true;
      if (!ins) {
        const parentIndex = arry.findIndex((val) => val.id === item.id);
        arry.splice(parentIndex, 1);
        document.querySelector(`#check${item.id}`).checked = false;
      }
    }
    setPermissionList(arry);
  };

  /* status change */
  const onStatusHandler = (event, status, item) => {  
    document.querySelector(`#check${item.id}`).checked = true;
    if(document.querySelector(`#check${item.parent_menu}`) !== null )
    document.querySelector(`#check${item.parent_menu}`).checked = true;

    let arry = [...permissionList];
    const parentObj = props.permissionList.filter((val) => val.id == item.parent_menu);

 
    let index = arry.findIndex((val) => val.id === item.id);

    if(index<0){

      arry.push({
        id: item.id,
        edit: true,
        delete: true,
        status: true,
        download: true,
        add: true,
        pay_now: true,
        sync: true,
        refund:true,
        make_attendent: true
      });

      index = (arry.length-1)
      
    }
    
    arry[index].edit= arry[index].delete= arry[index].status=arry[index].add=arry[index].pay_now= arry[index].sync=arry[index].refund=arry[index].make_attendent=false;

    if(document.querySelector(`#checkEdit${item.id}`) !== null )
    arry[index].edit= document.querySelector(`#checkEdit${item.id}`).checked;
    else
    arry[index].edit = onCheckAddHandler(item.id);

    if(document.querySelector(`#checkDelete${item.id}`) !== null )
    arry[index].delete= document.querySelector(`#checkDelete${item.id}`).checked;
    else
    arry[index].delete = onCheckDeleteHandler(item.id);

    if(document.querySelector(`#checkStatus${item.id}`) !== null )     
    arry[index].status= document.querySelector(`#checkStatus${item.id}`).checked; 
    else
    arry[index].status= onCheckStatusHandler(item.id);

    if(document.querySelector(`#checkAdd${item.id}`) !== null ) 
    arry[index].add= document.querySelector(`#checkAdd${item.id}`).checked; 
    else
    arry[index].add= onCheckAddHandler(item.id);  

    arry[index].pay_now= onCheckPaynowHandler(item.id);
    arry[index].sync= onCheckSyncHandler(item.id);
    arry[index].refund= onCheckRefundHandler(item.id);
    arry[index].make_attendent= onCheckMakeAttendentHandler(item.id);

    if (status == 1) {
      arry[index].edit = event.target.checked;
    } else if (status == 2) {
      arry[index].delete = event.target.checked;
    } else if (status == 3) {
      arry[index].status = event.target.checked;
    } else if (status == 4) {
      arry[index].download = event.target.checked;
    } else if (status == 5) {
      arry[index].add = event.target.checked;
    } else if (status == 6) {
      arry[index].pay_now = event.target.checked;
    } else if (status == 7) {
      arry[index].sync = event.target.checked;
    } else if (status == 8) {
      arry[index].refund = event.target.checked;
    } else if (status == 9) {
      arry[index].make_attendent = event.target.checked;
    }

   // console.log("================test",arry);
 
    setPermissionList(arry);
    //onSubPermissionHandler(event,item,item);
    
  };

  /* status change */
  const onStatusDirectMenuHandler = (event, status, item) => { 

    document.querySelector(`#check${item.id}`).checked = true; 
    let arry = [...permissionList];
    const parentObj = props.permissionList.filter((val) => val.id == item.id);
    console.log("parent obj id", parentObj[0].id);
    console.log("chiled menu object id", item.id);
    console.log("the array to be submited", arry);

    let index = arry.findIndex((val) => val.id === item.id);

    if(index<0){

      arry.push({
        id: item.id,
        edit: true,
        delete: true,
        status: true,
        download: true,
        add: true,
        pay_now: true,
        sync: true,
        refund:true,
        make_attendent: true
      });

      index = (arry.length-1)
      
    }

    if(document.querySelector(`#checkEdit${item.id}`) !== null )
    arry[index].edit= document.querySelector(`#checkEdit${item.id}`).checked;
    else
    arry[index].edit = onCheckAddHandler(item.id);

    if(document.querySelector(`#checkDelete${item.id}`) !== null )
    arry[index].delete= document.querySelector(`#checkDelete${item.id}`).checked;
    else
    arry[index].delete = onCheckDeleteHandler(item.id);

    if(document.querySelector(`#checkStatus${item.id}`) !== null )     
    arry[index].status= document.querySelector(`#checkStatus${item.id}`).checked; 
    else
    arry[index].status= onCheckStatusHandler(item.id);

    if(document.querySelector(`#checkAdd${item.id}`) !== null ) 
    arry[index].add= document.querySelector(`#checkAdd${item.id}`).checked; 
    else
    arry[index].add= onCheckAddHandler(item.id);  

    if(document.querySelector(`#checkPaynow${item.id}`) !== null ) 
    arry[index].add= document.querySelector(`#checkPaynow${item.id}`).checked; 
    else
    arry[index].add= onCheckPaynowHandler(item.id);  

    if(document.querySelector(`#checkSync${item.id}`) !== null ) 
    arry[index].add= document.querySelector(`#checkSync${item.id}`).checked; 
    else
    arry[index].add= onCheckSyncHandler(item.id);  

    if(document.querySelector(`#checkRefund${item.id}`) !== null ) 
    arry[index].add= document.querySelector(`#checkRefund${item.id}`).checked; 
    else
    arry[index].add= onCheckRefundHandler(item.id);  

    if(document.querySelector(`#checkMake_attendent${item.id}`) !== null ) 
    arry[index].add= document.querySelector(`#checkMake_attendent${item.id}`).checked; 
    else
    arry[index].add= onCheckMakeAttendentHandler(item.id);  


    if (status == 1) {
      arry[index].edit = event.target.checked;
    } else if (status == 2) {
      arry[index].delete = event.target.checked;
    } else if (status == 3) {
      arry[index].status = event.target.checked;
    } else if (status == 4) {
      arry[index].download = event.target.checked;
    } else if (status == 5) {
      arry[index].add = event.target.checked;
    } else if (status == 6) {
      arry[index].pay_now = event.target.checked;
    } else if (status == 7) {
      arry[index].sync = event.target.checked;
    } else if (status == 8) {
      arry[index].refund = event.target.checked;
    } else if (status == 9) {
      arry[index].make_attendent = event.target.checked;
    }

    setPermissionList(arry);
  };

  /* check box handler */
  const onCheckHandler = (id) => {
    let array = [...permissionList];
    let status = null;
    const index = array.findIndex((val) => val.id === id);
    if (index >= 0) {
      status = true;
    } else {
      status = false;
    }
    return status;
  };

  /* check Tab box handler */
  const onTabCheckHandler = (id) => {
    let array = [...tabPermissionList];
    let status = null;
    const index = array.findIndex((val) => val.id === id);
    if (index >= 0) {
      status = true;
    } else {
      status = false;
    }
    return status;
  };


  /* edit check handler */
  const onCheckEditHandler = (id) => {
  
   let array = state.menu_lists;
    let status = false;
    for (let x = 0; array.length > x; x++) {
      if (array[x].id == id) {
        status = array[x].edit;
      }
    }
    return status;

  };

  /* on check delete */
  const onCheckDeleteHandler = (id) => { 

    let array =  state.menu_lists;
    let status = false;
    for (let x = 0; array.length > x; x++) {
      if (array[x].id == id) {
        status = array[x].delete;
      }
    }
    return status;
  };

  /* check box status */
  const onCheckStatusHandler = (id) => {

    let array = state.menu_lists;
    let status = false;
    for (let x = 0; array.length > x; x++) {
      if (array[x].id == id) {
        status = array[x].status;
      }
    }
    return status;
  };

  /* check box Downloader status */
  const onCheckDownloadHandler = (id) => {
    let array = state.menu_lists;
    let status = false;
    for (let x = 0; array.length > x; x++) {
      if (array[x].id == id) {
        status = array[x].download;
      }
    }
    return status;
  };

  /* check box Add status */
  const onCheckAddHandler = (id) => {

    let array =  state.menu_lists;
    let status = false;
    for (let x = 0; array.length > x; x++) {
      if (array[x].id == id) {
        status = array[x].add;
      }
    }
    return status;
  };

  /* check box Paynow status */
  const onCheckPaynowHandler = (id) => {
    let array =   state.menu_lists;
    let status = false;
    for (let x = 0; array.length > x; x++) {
      if (array[x].id == id) {
        status = array[x].pay_now;
      }
    }
    return status;
  };

  /* check box sync status */
  const onCheckSyncHandler = (id) => {

    let array =  state.menu_lists;
    let status = false;
    for (let x = 0; array.length > x; x++) {
      if (array[x].id == id) {
        status = array[x].sync;
      }
    }
    return status;
  };

  /* check box refund status */
  const onCheckRefundHandler = (id) => {

    let array = state.menu_lists;
    let status = false;
    for (let x = 0; array.length > x; x++) {
      if (array[x].id == id) {
        status = array[x].refund;
      }
    }
    return status;
  };

  /* check box make_attendent status */
  const onCheckMakeAttendentHandler = (id) => {

    let array = state.menu_lists;
    let status = false;
    for (let x = 0; array.length > x; x++) {
      if (array[x].id == id) {
        status = array[x].make_attendent;
      }
    }
    return status;
  };





  /* navigation go back */
  const onGoBackHandler = () => {
    history.push({
      pathname: "/role-list",
      // state: previousPageFilterData,
    });

  }

  /* sub and Tab menu id Collection */
  const onTabMenuPermissionHandler = (event, item, data) => {  

    document.querySelector(`#check${data.parent_menu}`).checked = true; 
    document.querySelector(`#check${item.id}`).checked = true; 
    let tabPermission = [...tabPermissionList];

    console.log(tabPermission)
    const tabpermissionIndex = tabPermission.findIndex((item) => item.id === data.id);
    if (tabpermissionIndex !== -1) {
      tabPermission.splice(tabpermissionIndex, 1);
    } else {
      let obj = {}
      const newSelect = data.id;
      obj.id = newSelect
      obj.parentId=data.parent_menu
      tabPermission.push(obj);
    }

    setTabPermissionList(tabPermission)

    let arry = [...permissionList];
    let index = arry.findIndex((val) => val.id === item.id);

    if(index<0){

      arry.push({
        id: item.id,
        edit: false,
        delete: false,
        status: false,
        download: false,
        add: false,
        pay_now: false,
        sync: false,
        refund:false,
        make_attendent: false
      });    
   }  
   
   index = arry.findIndex((val) => val.id === data.parent_menu);

   let tabExist=false
   if(tabPermission.length>0){

      for(let r=0;r<tabPermission.length;r++){

          if(tabPermission[r].parentId==data.parent_menu){

            tabExist=true
            break;

          }     

      }
   }

   if(document.querySelector(`#checkDownload${data.parent_menu}`) !== null ) 
    if (document.querySelector(`#checkDownload${data.parent_menu}`).checked) 
      tabExist=true

   if(tabExist){

      document.querySelector(`#check${data.parent_menu}`).checked = true; 
      if(index<0){

        arry.push({
          id: data.parent_menu,
          edit: false,
          delete: false,
          status: false,
          download: false,
          add: false,
          pay_now: false,
          sync: false,
          refund:false,
          make_attendent: false
        });
      }

  }   
  else{
    
      if(index>0) 
      arry.splice(index, 1);
      document.querySelector(`#check${data.parent_menu}`).checked = false; 

  }
  

   setPermissionList(arry);

  }

  return (
    <>
      <div className="row">
      { console.log('permission...list.....', props.permissionList)} 
        {/* {console.log("state...", state.menu_lists)} */}
        <div className="col-xl-6 col-xl-12">

          <div className="row">
            <div className="col-xl-12">

              <div className="card">
                <div className="card-body">
                  <div className="col-xl-2">
                    <div className="back-arrow-container">
                      <Link onClick={onGoBackHandler} >
                        <i class="fa-solid fa-arrow-left"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="basic-form fromContainer">

                    <form onSubmit={onSubmit}>
                      <div className="row RoleDescription">
                        <div className="form-group mb-3 col-md-6">
                          <label className="mb-2">
                            <strong>
                              Role Name
                              <span className="text-danger">*</span>
                            </strong>
                          </label>
                          <input
                            className="form-control"
                            placeholder="Enter role name"
                            value={roleName}
                            onChange={(e) => setRollName(e.target.value)}
                          />
                          {errors.roleName && (
                            <div className="text-danger fs-12">
                              {errors.roleName}
                            </div>
                          )}
                        </div>

                        <div className="form-group mb-3 col-md-6">
                          <label className="mb-2">
                            <strong>
                              Description
                              <span className="text-danger">*</span>
                            </strong>
                          </label>
                          <input
                            className="form-control"
                            placeholder="Enter description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          />
                          {errors.description && (
                            <div className="text-danger fs-12">
                              {errors.description}
                            </div>
                          )}
                        </div>
                      </div>
                      <label className="mb-2">
                        <strong>
                          Permission
                          <span className="text-danger">*</span>
                        </strong>
                      </label>
                      <div
                        className="permission-list-view"
                        style={{ backgroundColor: "#8300000a" }}
                      >
                        {props.permissionList.map((item, i) => {
                          if (item.parent_menu == 0) {
                            return (
                              <div
                                key={item.id + i}
                                className="main-form-check-view"
                              >
                                <div className="form-check mt-2 mb-2">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`check${item.id}`}
                                    name={`check${item.id}`}
                                    defaultChecked={onCheckHandler(item.id)}
                                    onChange={(e) =>
                                      onMainPermissionHandler(e, item)
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`check${item.id}`}
                                  >
                                    {item.menu_name}
                                  </label>
                                </div>

                                <div className="sub-form-check-view colorPaddingSubFrom">
                                  {props.permissionList.map((data, j) => {

                                    {/* show tab menu  */ }
                                    if (data?.tab_menu?.length > 0 && item.id == data.tab_menu[0].parent_menu) {
                                      {
                                        return (
                                          <>
                                            <div className="tab-menu" key={j}>
                                              {data.tab_menu.map((tabName, k) =>
                                                <>
                                                  <div
                                                    key={tabName.id + k}
                                                    className="form-check mt-2 me-3"
                                                  >
                                                    <input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      id={`check${tabName.id}`}
                                                      name={`check${tabName.id}`}
                                                      defaultChecked={onTabCheckHandler(tabName.id)}
                                                      onChange={(e) =>
                                                        onTabMenuPermissionHandler(
                                                          e,
                                                          item,
                                                          tabName
                                                        )
                                                      }
                                                    />
                                                    <label
                                                      className="form-check-label"
                                                      htmlFor={`check${tabName.id}`}
                                                    >
                                                      {tabName.menu_name}
                                                    </label>
                                                  </div>
                                                </>
                                              )}
                                            </div>

                                            <div className="permission-submenu mt-2">
                                              {data.add.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkAdd${data.id}`}
                                                    name={`checkAdd${data.id}`}
                                                    defaultChecked={onCheckAddHandler(
                                                      data.id
                                                    )}
                                                    onChange={(e) =>
                                                      onStatusDirectMenuHandler(
                                                        e,
                                                        5,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkAdd${data.id}`}
                                                  >
                                                    Add
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.edit.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkEdit${data.id}`}
                                                    name={`checkEdit${data.id}`}
                                                    defaultChecked={onCheckEditHandler(
                                                      data.id
                                                    )}
                                                    onChange={(e) =>
                                                      onStatusDirectMenuHandler(
                                                        e,
                                                        1,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkEdit${data.id}`}
                                                  >
                                                    Edit
                                                  </label>
                                                </div>
                                              ) : null}
                                              {data.delete.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkDelete${data.id}`}
                                                    name={`checkDelete${data.id}`}
                                                    defaultChecked={onCheckDeleteHandler(
                                                      data.id
                                                    )}
                                                    onChange={(e) =>
                                                      onStatusDirectMenuHandler(
                                                        e,
                                                        2,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkDelete${data.id}`}
                                                  >
                                                    Delete
                                                  </label>
                                                </div>
                                              ) : null}
                                              {data.status.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkStatus${data.id}`}
                                                    name={`checkStatus${data.id}`}
                                                    defaultChecked={onCheckStatusHandler(
                                                      data.id
                                                    )}
                                                    onChange={(e) =>
                                                      onStatusDirectMenuHandler(
                                                        e,
                                                        3,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkStatus${data.id}`}
                                                  >
                                                    Status
                                                  </label>
                                                </div>
                                              ) : null}
                                              {data.download.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkDownload${data.id}`}
                                                    name={`checkDownload${data.id}`}
                                                    defaultChecked={onCheckDownloadHandler(
                                                      data.id
                                                    )}
                                                    onChange={(e) =>
                                                      onStatusDirectMenuHandler(
                                                        e,
                                                        4,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkDownload${data.id}`}
                                                  >
                                                    Download
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.pay_now.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkPaynow${data.id}`}
                                                    name={`checkPaynow${data.id}`}
                                                    defaultChecked={onCheckPaynowHandler(
                                                      data.id
                                                    )}
                                                    onChange={(e) =>
                                                      onStatusDirectMenuHandler(
                                                        e,
                                                        6,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkPaynow${data.id}`}
                                                  >
                                                    Pay now
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.sync.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkSync${data.id}`}
                                                    name={`checkSync${data.id}`}
                                                    defaultChecked={onCheckSyncHandler(
                                                      data.id
                                                    )}
                                                    onChange={(e) =>
                                                      onStatusDirectMenuHandler(
                                                        e,
                                                        7,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkEdit${data.id}`}
                                                  >
                                                    Sync
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.refund.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkRefund${data.id}`}
                                                    name={`checkRefund${data.id}`}
                                                    defaultChecked={onCheckRefundHandler(
                                                      data.id
                                                    )}
                                                    onChange={(e) =>
                                                      onStatusDirectMenuHandler(
                                                        e,
                                                        8,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkRefund${data.id}`}
                                                  >
                                                    Refund
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.make_attendent.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkMake_attendent${data.id}`}
                                                    name={`checkMake_attendent${data.id}`}
                                                    defaultChecked={onCheckMakeAttendentHandler(
                                                      data.id
                                                    )}
                                                    onChange={(e) =>
                                                      onStatusDirectMenuHandler(
                                                        e,
                                                        9,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkMake_attendent${data.id}`}
                                                  >
                                                    Mark as Attended
                                                  </label>
                                                </div>
                                              ) : null}

                                            </div>
                                          </>
                                        )
                                      }
                                    } else if (item.id == data.parent_menu) {
                                      return (
                                        <div
                                          key={data.id + j}
                                          className="form-check mt-2 me-3"
                                        >
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={`check${data.id}`}
                                            name={`check${data.id}`}
                                            defaultChecked={onCheckHandler(
                                              data.id
                                            )}
                                            onChange={(e) =>
                                              onSubPermissionHandler(
                                                e,
                                                item,
                                                data
                                              )
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={`check${data.id}`}
                                          >
                                            {data.menu_name}
                                          </label>
                                          {data?.tab_menu?.length > 0 && data.id == data.tab_menu[0].parent_menu ?
                                            <>
                                              <div className="tab-menu permission-submenu" key={j}>
                                                {data.tab_menu.map((subTabName, k) =>
                                                  <>
                                                    <div
                                                      key={subTabName.id + k}
                                                      className="form-check mt-2 me-3"
                                                    >
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={`check${subTabName.id}`}
                                                        name={`check${subTabName.id}`}
                                                        defaultChecked={onTabCheckHandler(subTabName.id)}
                                                        onChange={(e) =>
                                                          onTabMenuPermissionHandler(
                                                            e,
                                                            item,
                                                            subTabName
                                                          )
                                                        }
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor={`check${subTabName.id}`}
                                                      >
                                                        {subTabName.menu_name}
                                                      </label>
                                                    </div>
                                                  </>
                                                )}
                                              </div>
                                            </>
                                            :
                                            null
                                          }
                                          {data.action ? (
                                            <div className="permission-submenu">
                                              {data.add.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkAdd${data.id}`}
                                                    name={`checkAdd${data.id}`}
                                                    defaultChecked={onCheckAddHandler(
                                                      data.id
                                                    )}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        5,
                                                        data,
                                                        item
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkAdd${data.id}`}
                                                  >
                                                    Add
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.edit.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkEdit${data.id}`}
                                                    name={`checkEdit${data.id}`}
                                                    defaultChecked={onCheckEditHandler(
                                                      data.id
                                                    )}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        1,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkEdit${data.id}`}
                                                  >
                                                    Edit
                                                  </label>
                                                </div>
                                              ) : null}
                                              {data.delete.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkDelete${data.id}`}
                                                    name={`checkDelete${data.id}`}
                                                    defaultChecked={onCheckDeleteHandler(
                                                      data.id
                                                    )}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        2,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkDelete${data.id}`}
                                                  >
                                                    Delete
                                                  </label>
                                                </div>
                                              ) : null}
                                              {data.status.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkStatus${data.id}`}
                                                    name={`checkStatus${data.id}`}
                                                    defaultChecked={onCheckStatusHandler(
                                                      data.id
                                                    )}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        3,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkStatus${data.id}`}
                                                  >
                                                    Status
                                                  </label>
                                                </div>
                                              ) : null}
                                              {data.download.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkDownload${data.id}`}
                                                    name={`checkDownload${data.id}`}
                                                    defaultChecked={onCheckDownloadHandler(
                                                      data.id
                                                    )}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        4,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkDownload${data.id}`}
                                                  >
                                                    Download
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.pay_now.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkEdit${data.id}`}
                                                    name={`checkEdit${data.id}`}
                                                    defaultChecked={onCheckPaynowHandler(
                                                      data.id
                                                    )}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        6,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkEdit${data.id}`}
                                                  >
                                                    Pay now
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.sync.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkEdit${data.id}`}
                                                    name={`checkEdit${data.id}`}
                                                    defaultChecked={onCheckSyncHandler(
                                                      data.id
                                                    )}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        7,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkEdit${data.id}`}
                                                  >
                                                    Sync
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.refund.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkEdit${data.id}`}
                                                    name={`checkEdit${data.id}`}
                                                    defaultChecked={onCheckRefundHandler(
                                                      data.id
                                                    )}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        8,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkEdit${data.id}`}
                                                  >
                                                    Refund
                                                  </label>
                                                </div>
                                              ) : null}

                                              {data.make_attendent.active ? (
                                                <div className="form-check me-3">
                                                  <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`checkEdit${data.id}`}
                                                    name={`checkEdit${data.id}`}
                                                    defaultChecked={onCheckMakeAttendentHandler(
                                                      data.id
                                                    )}
                                                    onChange={(e) =>
                                                      onStatusHandler(
                                                        e,
                                                        9,
                                                        data
                                                      )
                                                    }
                                                  />
                                                  <label
                                                    className="form-check-label"
                                                    htmlFor={`checkEdit${data.id}`}
                                                  >
                                                    Mark as Attended
                                                  </label>
                                                </div>
                                              ) : null}

                                            </div>
                                          ) : null}
                                        </div>
                                      );
                                    } else {

                                      return (
                                        item.id == data.id && data.parent_menu == 0 && data.action ? (
                                          <div className="permission-submenu">
                                            {data.add.active ? (
                                              <div className="form-check me-3">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={`checkAdd${data.id}`}
                                                  name={`checkAdd${data.id}`}
                                                  defaultChecked={onCheckAddHandler(
                                                    data.id
                                                  )}
                                                  onChange={(e) =>
                                                    onStatusDirectMenuHandler(
                                                      e,
                                                      5,
                                                      data
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`checkAdd${data.id}`}
                                                >
                                                  Add
                                                </label>
                                              </div>
                                            ) : null}

                                            {data.edit.active ? (
                                              <div className="form-check me-3">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={`checkEdit${data.id}`}
                                                  name={`checkEdit${data.id}`}
                                                  defaultChecked={onCheckEditHandler(
                                                    data.id
                                                  )}
                                                  onChange={(e) =>
                                                    onStatusDirectMenuHandler(
                                                      e,
                                                      1,
                                                      data
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`checkEdit${data.id}`}
                                                >
                                                  Edit
                                                </label>
                                              </div>
                                            ) : null}
                                            {data.delete.active ? (
                                              <div className="form-check me-3">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={`checkDelete${data.id}`}
                                                  name={`checkDelete${data.id}`}
                                                  defaultChecked={onCheckDeleteHandler(
                                                    data.id
                                                  )}
                                                  onChange={(e) =>
                                                    onStatusDirectMenuHandler(
                                                      e,
                                                      2,
                                                      data
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`checkDelete${data.id}`}
                                                >
                                                  Delete
                                                </label>
                                              </div>
                                            ) : null}
                                            {data.status.active ? (
                                              <div className="form-check me-3">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={`checkStatus${data.id}`}
                                                  name={`checkStatus${data.id}`}
                                                  defaultChecked={onCheckStatusHandler(
                                                    data.id
                                                  )}
                                                  onChange={(e) =>
                                                    onStatusDirectMenuHandler(
                                                      e,
                                                      3,
                                                      data
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`checkStatus${data.id}`}
                                                >
                                                  Status
                                                </label>
                                              </div>
                                            ) : null}
                                            {data.download.active ? (
                                              <div className="form-check me-3">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={`checkDownload${data.id}`}
                                                  name={`checkDownload${data.id}`}
                                                  defaultChecked={onCheckDownloadHandler(
                                                    data.id
                                                  )}
                                                  onChange={(e) =>
                                                    onStatusDirectMenuHandler(
                                                      e,
                                                      4,
                                                      data
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`checkDownload${data.id}`}
                                                >
                                                  Download
                                                </label>
                                              </div>
                                            ) : null}

                                            {data.pay_now.active ? (
                                              <div className="form-check me-3">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={`checkPaynow${data.id}`}
                                                  name={`checkPaynow${data.id}`}
                                                  defaultChecked={onCheckPaynowHandler(
                                                    data.id
                                                  )}
                                                  onChange={(e) =>
                                                    onStatusDirectMenuHandler(
                                                      e,
                                                      6,
                                                      data
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`checkPaynow${data.id}`}
                                                >
                                                  Pay now
                                                </label>
                                              </div>
                                            ) : null}

                                            {data.sync.active ? (
                                              <div className="form-check me-3">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={`checkSync${data.id}`}
                                                  name={`checkSync${data.id}`}
                                                  defaultChecked={onCheckSyncHandler(
                                                    data.id
                                                  )}
                                                  onChange={(e) =>
                                                    onStatusDirectMenuHandler(
                                                      e,
                                                      7,
                                                      data
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`checkEdit${data.id}`}
                                                >
                                                  Sync
                                                </label>
                                              </div>
                                            ) : null}

                                            {data.refund.active ? (
                                              <div className="form-check me-3">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={`checkRefund${data.id}`}
                                                  name={`checkRefund${data.id}`}
                                                  defaultChecked={onCheckRefundHandler(
                                                    data.id
                                                  )}
                                                  onChange={(e) =>
                                                    onStatusDirectMenuHandler(
                                                      e,
                                                      8,
                                                      data
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`checkRefund${data.id}`}
                                                >
                                                  Refund
                                                </label>
                                              </div>
                                            ) : null}

                                            {data.make_attendent.active ? (
                                              <div className="form-check me-3">
                                                <input
                                                  type="checkbox"
                                                  className="form-check-input"
                                                  id={`checkMake_attendent${data.id}`}
                                                  name={`checkMake_attendent${data.id}`}
                                                  defaultChecked={onCheckMakeAttendentHandler(
                                                    data.id
                                                  )}
                                                  onChange={(e) =>
                                                    onStatusDirectMenuHandler(
                                                      e,
                                                      9,
                                                      data
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label"
                                                  htmlFor={`checkEdit${data.id}`}
                                                >
                                                  Mark as Attended
                                                </label>
                                              </div>
                                            ) : null}

                                          </div>
                                        ) : null
                                      )
                                      // }
                                    }
                                  })}
                                </div>
                              </div>
                            );
                          }
                        })}

                        {errors.permissionList && (
                          <div className="text-danger fs-12">
                            {errors.permissionList}
                          </div>
                        )}
                      </div>
                      <div className="text-center mt-4 submitButtonRole">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block category-btn"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    permissionList: state.permission.list,
    roleList: state.user.rolesList,
  };
};

export default connect(mapStateToProps)(UpdateRole);
