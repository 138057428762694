import { Fragment, useEffect, useState, useRef } from "react";
import { Row, Col, Card, Table, Modal, Button } from "react-bootstrap";
import { useDispatch, connect, useSelector } from "react-redux";
import { ProgressBar } from 'react-bootstrap';
import { Rating } from 'react-simple-star-rating'
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import {
  misMarketingFeedbackRatingData,
  misMarketingFeedbackRatingByquestionsData,
  misMarketingFeedbackRatingExcelDownloadData
} from "../../../store/actions/MisDashboardInfoAction";

import feedbackserviceList from './json/feedbackservice.json';

import ReactPaginate from 'react-paginate';

//images
import sort from '../../../images/dashboard_image/sort.png'
import export_details from '../../../images/dashboard_image/export.png'

const MarketingFeedback = (props) => {

  const dispatch = useDispatch();


  const [modal, setModal] = useState(false);
  const [ratingModal, setRatingModal] = useState(false)
  const [feedServices, setFeedServices] = useState("")
  const [currentRatingDetails, setCurrentRatingDetails] = useState("")
  const [page, setPage] = useState(1)

  const [tempFeedServices, settempFeedServices] = useState('');

  const [isFiltered, setIsFiltered] = useState(false)
  const [permission, setPermission] = useState("");

  const marketingFeedbackRatingList = useSelector((state) => state.MisDashboardInfo.marketingFeedbackRatingList);
  const marketingFeedbackRatingByquestionsDetails = useSelector((state) => state.MisDashboardInfo.marketingFeedbackRatingByquestionsDetails);

  useEffect(() => {

    if (feedServices != '' && feedServices != undefined) {
      setIsFiltered(true)
    } else {
      setIsFiltered(false)
    }

  }, [feedServices]);

  const onResetHandler = () => {
    setFeedServices("");
    setPage(1)
    getList('');
    settempFeedServices('');
    setModal(false);
  };

  useEffect(() => {
    getList(feedServices)
    permissionCheck()
  }, []);

  const getList = (feedServices) => {
    dispatch(globalLoadingAction(true));
    dispatch(
      misMarketingFeedbackRatingData(
        feedServices
      )
    );
  };

  const filterRegisteredHandler = () => {
    let page = 1;
    setModal(false)
    setPage(1)
    getList(feedServices);
    settempFeedServices(feedServices)
  }

  /* pagination */
  const pageHandler = (data) => {
    let page = data.selected + 1;
    setPage(data.selected + 1);
    getList(feedServices);
  }

  const onDownloadRegisteredStudentHandler = () => {
    dispatch(globalLoadingAction(true));
    dispatch(
      misMarketingFeedbackRatingExcelDownloadData(
        feedServices
      )
    );
  }

  const openRatingModal = (item) => {
    setCurrentRatingDetails(item)
    setRatingModal(true)
    dispatch(misMarketingFeedbackRatingByquestionsData(item.id, item.services_id))
  }

  const cancelToModalClose = () => {
    setModal(false)
    if (tempFeedServices != feedServices) {
      setFeedServices(tempFeedServices)
    }
  }

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 96) {
        console.log("96----------", item)
        setPermission(item);
      }
    });
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>

              <div className="filtersAlign mt-3" style={{ marginRight: 25 }}>
                <div className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 me-3 filter_background rounded cursorPointer ${isFiltered ? 'border_red' : null}`} onClick={() => setModal(true)}>
                  <div className='image_container'>
                    <img src={sort} alt="" className='image_style' />
                  </div>
                  <div className='button_text font_color_black'>
                    Filter
                  </div>
                </div>
                {(permission.download == "true") && (
                  <div className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 export_background rounded blue_border cursorPointer ${marketingFeedbackRatingList?.length == 0 || marketingFeedbackRatingList?.length == undefined ? 'content_disable' : null}`}
                    onClick={() => onDownloadRegisteredStudentHandler()}
                  >
                    <div className='image_container'>
                      <img src={export_details} alt="" className='image_style' />
                    </div>
                    <div className='button_text font_color_blue cursorPointer'>
                      Export
                    </div>
                  </div>
                )}
              </div>
              {/* </div> */}

              {marketingFeedbackRatingList?.length == 0 || marketingFeedbackRatingList?.length == undefined ? (
                <div className="no_data">
                  <strong>No data found</strong>
                </div>
              ) : (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Sl no.</strong>
                      </th>
                      <th>
                        <strong>Services</strong>
                      </th>
                      <th>
                        <strong>Questions</strong>
                      </th>
                      <th>
                        <strong>Feedback User</strong>
                      </th>
                      <th>
                        <strong>Rating</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>

                    {marketingFeedbackRatingList.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.sl_no}</td>
                          <td>{item.services}</td>
                          <td>{item.questions}</td>
                          <td>{item.feedback_user}</td>
                          <td>
                            <div className="cursorPointer link_color" onClick={() => openRatingModal(item)}>
                              {item.rating}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
              {/* {props.list?.data?.length != 0 && props.list?.data != undefined ?
                <div className="pagination-container">
                  <div className="page-result">
                    <p>Showing: {(page - 1) * (10) + 1} - {(10 * page) - (10 - props.list?.data?.length)} of {props.list.total_records}</p>
                  </div>
                  {props.list.total_records > 10 ?
                    < ReactPaginate
                      containerClassName='pagination-gutter  false pagination-circle pagination pagination-sm'
                      pageClassName='page-item'
                      pageLinkClassName='page-link'
                      nextLabel={<i className="la la-angle-right"></i>}
                      nextClassName='page-item'
                      nextLinkClassName='page-link'
                      breakLabel="..."
                      previousLabel={<i className="la la-angle-left"></i>}
                      previousClassName='page-item'
                      previousLinkClassName='page-link'
                      breakClassName='page-item'
                      breakLinkClassName='page-link'
                      renderOnZeroPageCount={null}
                      activeClassName="active"
                      onPageChange={pageHandler}
                      pageRangeDisplayed={3}
                      pageCount={props.list.total_page_no}
                      forcePage={page - 1}
                    />
                    : null}
                </div>
                : null} */}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <!-- Modal --> */}
      <Modal className="fade" show={modal} size="md">
        <Modal.Header>
          <Modal.Title>Filter</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => cancelToModalClose()}
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="question-modal-form">
            <div className="reset-filtter-value">
              <button
                disabled={!feedServices}
                className="btn btn-info btn-xs"
                onClick={() => onResetHandler()}
              >
                Reset
              </button>
            </div>

            <div className="row">

              <div className={`form-group col-sm-6 mx-auto px-1 mb-3`}>
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={feedServices}
                    onChange={(e) => setFeedServices(e.target.value)}
                  >
                    <option value="">Select Services</option>
                    {feedbackserviceList.filter(i => i.id < 4).map((item, index) => {
                      return <option key={index} value={item.id}>{item.showValue}</option>;
                    })}
                  </select>
                </div>
              </div>

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => cancelToModalClose()} variant="danger light">
            Cancel
          </Button>
          <Button
            disabled={!feedServices}
            onClick={() => filterRegisteredHandler()} variant="primary">
            Filter
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <!-- Modal --> */}
      <Modal className="fade" show={ratingModal} size="md">
        <Modal.Header>
          <Modal.Title>Rating</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setRatingModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="question-modal-form d-flex justify-content-center align-items-center">
            <div className="d-flex justify-content-center align-items-center">
              <div className="rating_left_container">
                <div className="rating_heading">
                  {marketingFeedbackRatingByquestionsDetails && marketingFeedbackRatingByquestionsDetails?.rating_text}
                </div>
                <div className="rating_star_container d-flex justify-content-center align-items-center">
                  <Rating
                    initialValue={parseFloat(marketingFeedbackRatingByquestionsDetails && marketingFeedbackRatingByquestionsDetails?.rating)}
                    // initialValue={3.4}
                    size={25}
                    allowFraction={true}
                    readonly={true}
                  />
                </div>
                <div className="rating_counter">
                  {marketingFeedbackRatingByquestionsDetails && marketingFeedbackRatingByquestionsDetails?.total_rating_counter + ' ' + 'ratings'}
                </div>
              </div>
              <div className="rating_right_container ">
                <div className="progress_bar_container mb-2">
                  <div className="star_left_container">
                    <div className="start_number_container">5</div> <i className="fa-solid fa-star"></i>
                  </div>

                  <div className="star_middle_container">
                    <ProgressBar variant="info" now={parseFloat(marketingFeedbackRatingByquestionsDetails.length != 0 && marketingFeedbackRatingByquestionsDetails?.rating_wise_counter_percentage[5])} />
                  </div>
                  <div className="star_right_container">
                    {marketingFeedbackRatingByquestionsDetails.length != 0 && marketingFeedbackRatingByquestionsDetails?.rating_counter[5]}
                  </div>
                </div>
                <div className="progress_bar_container mb-2">
                  <div className="star_left_container">
                    <div className="start_number_container">4</div> <i className="fa-solid fa-star"></i>
                  </div>
                  <div className="star_middle_container">
                    <ProgressBar variant="info" now={parseInt(marketingFeedbackRatingByquestionsDetails.length != 0 && marketingFeedbackRatingByquestionsDetails?.rating_wise_counter_percentage[4])} />
                  </div>
                  <div className="star_right_container">
                    {marketingFeedbackRatingByquestionsDetails.length != 0 && marketingFeedbackRatingByquestionsDetails?.rating_counter[4]}
                  </div>
                </div>

                <div className="progress_bar_container mb-2">
                  <div className="star_left_container">
                    <div className="start_number_container">3</div> <i className="fa-solid fa-star"></i>
                  </div>
                  <div className="star_middle_container">
                    <ProgressBar variant="info" now={parseInt(marketingFeedbackRatingByquestionsDetails.length != 0 && marketingFeedbackRatingByquestionsDetails?.rating_wise_counter_percentage[3])} />
                  </div>
                  <div className="star_right_container">
                    {marketingFeedbackRatingByquestionsDetails.length != 0 && marketingFeedbackRatingByquestionsDetails?.rating_counter[3]}
                  </div>
                </div>

                <div className="progress_bar_container mb-2">
                  <div className="star_left_container">
                    <div className="start_number_container">2</div> <i className="fa-solid fa-star"></i>
                  </div>
                  <div className="star_middle_container">
                    <ProgressBar variant="info" now={parseInt(marketingFeedbackRatingByquestionsDetails.length != 0 && marketingFeedbackRatingByquestionsDetails?.rating_wise_counter_percentage[2])} />
                  </div>
                  <div className="star_right_container">
                    {marketingFeedbackRatingByquestionsDetails.length != 0 && marketingFeedbackRatingByquestionsDetails?.rating_counter[2]}
                  </div>
                </div>

                <div className="progress_bar_container mb-2">
                  <div className="star_left_container">
                    <div className="start_number_container">1</div> <i className="fa-solid fa-star"></i>
                  </div>
                  <div className="star_middle_container">
                    <ProgressBar variant="info" now={parseInt(marketingFeedbackRatingByquestionsDetails.length != 0 && marketingFeedbackRatingByquestionsDetails?.rating_wise_counter_percentage[1])} />
                  </div>
                  <div className="star_right_container">
                    {marketingFeedbackRatingByquestionsDetails.length != 0 && marketingFeedbackRatingByquestionsDetails?.rating_counter[1]}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={() => setModal(false)} variant="danger light">
            Cancel
          </Button>
          <Button onClick={() => filterRegisteredHandler()} variant="primary">
            Filter
          </Button>
        </Modal.Footer> */}
      </Modal>

    </Fragment>
  );
};

const mapstateToProps = (state) => {
  return {
    boardList: state.board.list,
    classList: state.class.list,
    categoryList: state.category.list,
    subjectList: state.subject.subjectList,
    subscriptionDetailsList: state.subscriptiondetails.list,
  };
};

export default connect(mapstateToProps)(MarketingFeedback);
