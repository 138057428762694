import {
  GET_PAYMENT_DETAILS_LIST_ACTION,
  PAYMENT_LOADING_ACTION,
} from "../constants";

const initialState = {
  list: [],
  totalRecord: 0,
  totalPage: 0,
  showLoading: false,
};
export function PaymentReducer(state = initialState, action) {
  if (action.type === GET_PAYMENT_DETAILS_LIST_ACTION) {
    return {
      ...state,
      list: action.payload.data,
      totalRecord: action.payload.total_record,
      totalPage: action.payload.page_count,
      showLoading: false,
    };
  }
  if (action.type === PAYMENT_LOADING_ACTION) {
    return {
      ...state,
      showLoading: action.payload,
    };
  }

  return state;
}
