import React, { useContext, useEffect, useState, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link, useLocation, useHistory } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import axios from "axios";
import GlobalConfigs from "../../../configs/GlobalConfigs";
import * as Apis from '../../../apis/Apis';

import Loader from "../../components/Loader"

import { validateTextOnly, validateShortCode } from '../../../utility/Utility';
import { getExamCategory, loadingToggleAction } from '../../../store/actions/ExamCategoryAction';
import { getBoardData, loadingBoardAction } from '../../../store/actions/BoardAction';
import { getExamType, loadingAction, } from '../../../store/actions/ExamTypeAction';
import { getExamSubType, loadingSubTypeAction } from '../../../store/actions/ExamSubTypeAction';
import { getClassData, loadingClassAction } from '../../../store/actions/ClassAction';
import { getSubjects, subjectLoading, clearSubjectList } from '../../../store/actions/SujectActions';
import { getQuestionPattern, loadingPatternAction } from '../../../store/actions/QuestionPatternAction';
import { editCompetitiveExamDetails, loadingExamDetailsAction } from '../../../store/actions/ExamDetailsAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const UpdateCompetitiveExamDetails = (props) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { state } = useLocation();
    const calendarRef = useRef();

    let errorsObj = { title: '', type: '', subtype: '', class: '', subject: '', syllabus: '', academicSession: '', examDate: '', pattern: '', totalTime: '' };

    const [errors, setErrors] = useState(errorsObj);
    const [title, setTitle] = useState(state.item?.title);
    const [examType, setExamType] = useState(state.item?.exam_type_id);
    const [subType, setSubType] = useState(state.item?.exam_subtype_id);
    const [fullExamTypeName, setFullExamTypeName] = useState('');
    const [conductedBy, setConductedBy] = useState('');
    const [examTypeShortCode, setExamTypeShortCode] = useState('');
    const [className, setClassName] = useState(state.item?.class_id);
    const [subject, setSubject] = useState('');
    const [syllabus, setSyllabus] = useState('');
    const [academicSession, setAcademicSession] = useState(state.item?.academic_session);
    const [examDate, setExamDate] = useState('');

    const [totalQuestion, setTotalQuestion] = useState(state.item?.total_no_question);
    const [totalMarks, setTotalMarks] = useState('');
    const [totalTime, setTotalTime] = useState(state.item?.total_time);
    const [subjectArray, setSubjectArray] = useState(
        [
            {
                "id": "",
                "details": [{ "type": "", "value": "" }]
            }
        ]
    );

    const [fileUpload, setFileUpload] = useState(null)
    const [oldPdfFile, setOldPdfFile] = useState(state.item?.syllabus_file_path.split('/')[state.item?.syllabus_file_path.split('/').length - 1])

    const [filterData, setFilterData] = useState('')
    const [fileExt, setFileExt] = useState('');

    useEffect(() => {
        let previousPdfFile = state.item?.syllabus_file_path.split('/')[state.item?.syllabus_file_path.split('/').length - 1]
        if (previousPdfFile != '' && previousPdfFile != undefined) {
            setFileExt(previousPdfFile.substring(previousPdfFile.lastIndexOf('.') + 1))
        }

        setExamDate(isDate(state.item?.exam_date) ? new Date(state.item?.exam_date) : new Date())
        setFilterData(props?.location?.state?.filterData)

        getDetails();
    }, []);

    const isDate = str => {
        let [y, M, d, h, m, s] = str.split(/[- : T Z]/);
        return (y && M <= 12 && d <= 31) ? true : false;
    }

    /* form submit */
    function onSubmit(e) {

        e.preventDefault();
        // console.log('subject',totalTime);
        // return 

        let error = false;
        const errorObj = { ...errorsObj };

        if (title === '') {
            errorObj.title = 'Title is Required';
            error = true;
        }
        if (examType === '') {
            errorObj.type = 'Exam type name is Required';
            error = true;
        }
        if (subType === '' && examType == 1) {
            errorObj.subtype = 'Exam sub type is Required';
            error = true;
        }
        if (className === '' && examType == 2) {
            errorObj.class = 'Class name is Required';
            error = true;
        }
        // if (oldPdfFile === '' || oldPdfFile === undefined || oldPdfFile === 'undefined') {
        //     if (syllabus === '') {
        //         errorObj.syllabus = 'Syllabus is Required';
        //         error = true;
        //     }
        //     if (syllabus !== '' && fileExt != "pdf") {
        //         errorObj.syllabus = 'Syllabus File Required in PDF Formate';
        //         error = true;
        //     }
        // }
        if (academicSession === '') {
            errorObj.academicSession = 'Syllabus for academic session is Required';
            error = true;
        }
        if (examDate === '') {
            errorObj.examDate = 'Tentative exam date is Required';
            error = true;
        }
        if (totalTime === '') {
            errorObj.totalTime = 'Total time is Required';
            error = true;
        }

        setErrors(errorObj);
        if (error) {
            return;
        }

        dispatch(globalLoadingAction(true));
        dispatch(editCompetitiveExamDetails(
            state.item?.id,
            title,
            2,
            examType,
            examType == 1 ? subType : 0,
            examType == 2 ? className : 0,
            syllabus,
            academicSession,
            examDate,
            subjectArray,
            totalQuestion,
            onTotalMarkHandler(),
            totalTime,
            props.history));

        setTitle('');
        //setExamType('');
        setSubType('');
        setFullExamTypeName('');
        setConductedBy('');
        setExamTypeShortCode('');
        setClassName('');
        setSyllabus('');
        setAcademicSession('');
        setExamDate('');
        setTotalQuestion('');
        setTotalTime('');
        setSubjectArray([
            {
                "id": "",
                "details": [{ "type": "", "value": "" }]
            }
        ]);
        setFileExt('')
    }

    /* get subject data */
    const getDetails = () => {

        getExamTypeHandler();
        getClassHandler();
        getSubTypeHandler();
        getQuesionsPatternHandler();

        // if (props.typeList.length > 0) {
        //     const index = props.typeList.findIndex((item) => item.id === parseInt(state.item?.exam_type_id));
        //     setFullExamTypeName(props.typeList[index].full_name);
        //     setConductedBy(props.typeList[index].conducted_by);
        //     setExamTypeShortCode(props.typeList[index].short_code);
        // }

        let parse = JSON.parse(state.item?.subjects_ary);
        setSubjectArray(parse);
    }

    /* get exam type list */
    const getExamTypeHandler = () => {
        if (props.typeList.length == 0) {
            dispatch(loadingAction(true));
            dispatch(getExamType(props.history));
        }
    }

    /* change exam type */
    const onExamTypeHandler = (id) => {
        setExamType(id);
        dispatch(clearSubjectList([]));
        const index = props.typeList.findIndex((item) => item.id === parseInt(id));
        setFullExamTypeName(props.typeList[index].full_name);
        setConductedBy(props.typeList[index].conducted_by);
        setExamTypeShortCode(props.typeList[index].short_code);

    }

    /* get subtype list */
    const getSubTypeHandler = () => {

        if (props.subTypeList.length == 0) {
            dispatch(loadingSubTypeAction(true));
            dispatch(getExamSubType(props.history));
        }
    }

    /* change subtype */
    const onSubTypeHandler = (id) => {
        setSubType(id);
        dispatch(clearSubjectList([]));
    }

    /* get class list */
    const getClassHandler = () => {
        if (props.classlist == 0) {
            dispatch(loadingClassAction(true));
            dispatch(getClassData(props.history));
        }
    }

    /* get subject list */
    const getSubjectHandler = () => {
        if (examType == 1 && subType) {
            dispatch(subjectLoading(true));
            dispatch(getSubjects(0, 0, examType, subType, props.history));
        } else if (examType != 1) {
            dispatch(subjectLoading(true));
            dispatch(getSubjects(0, 0, examType, 0, props.history));
        }
    }

    /* subject add */
    const onAddSubjectHandler = () => {

        let arry = [...subjectArray];
        let obj = {
            "id": "",
            "subject": "",
            "details": [{ "type": "", "value": "" }]
        }
        arry.push(obj);
        setSubjectArray(arry);

    }

    /* remove subject */
    const onRemoveSubjectHandler = (index) => {
        let arry = [...subjectArray];
        arry.splice(index, 1);
        setSubjectArray(arry);
    }

    /* change subject */
    const onSubjectHandler = (val, index) => {
        let arry = [...subjectArray];
        arry[index].id = val;
        setSubjectArray(arry);
    }

    /* get question list */
    const getQuesionsPatternHandler = () => {
        if (props.patternList == 0) {
            dispatch(loadingPatternAction(true));
            dispatch(getQuestionPattern(props.history));
        }
    }

    /* change qws type */
    const onChangeQwsType = (val, i, index) => {
        let arry = [...subjectArray];
        let subArray = arry[index].details;
        subArray[i].type = val;
        arry[index].details = subArray;
        setSubjectArray(arry);
    }

    /* change qws type value */
    const onChangeQwsTypeValue = (val, i, index) => {

        const re = /^[0-9\b]+$/;
        let arry = [...subjectArray];
        let subArray = arry[index].details;
        if (val === '' || re.test(val)) {
            subArray[i].value = val;
            arry[index].details = subArray;
            setSubjectArray(arry);
        }
    }

    /* change question */
    const onQuestionHandler = (index) => {

        let arry = [...subjectArray];
        let obj = {
            'type': '',
            'value': '',
        };
        arry[index].details.push(obj);
        setSubjectArray(arry);
    }

    /* remove question */
    const onRemoveQuestionHandler = (i, index) => {

        let arry = [...subjectArray];
        let subArray = arry[index].details;
        subArray.splice(i, 1);
        arry[index].details = subArray;
        setSubjectArray(arry);
    }

    /* change subject status */
    const onSubjectStatusItem = () => {

        let arry = [...subjectArray];
        let status;

        for (let x = 0; arry.length > x; x++) {

            let inArray = arry[x].details;
            for (let j = 0; inArray.length > j; j++) {
                if (arry[x].id === '' || inArray[j].type === '' || inArray[j].value === '') {
                    status = true;
                } else {
                    status = false;
                }

            }
        }
        return status;
    }

    /* change question status */
    const onQuestionStatusItem = (index) => {

        let status;
        let arry = [...subjectArray];
        let inArray = arry[index].details;

        for (let x = 0; inArray.length > x; x++) {
            if (inArray[x].type === '' || inArray[x].value === '') {
                status = true;
            } else {
                status = false;
            }
        }

        return status;
    }

    /* on change subject type */
    const onSujectType = (val) => {

        let status;
        let arry = [...subjectArray];
        let temp = arry.find(element => element.id == val);
        if (temp == undefined) {
            status = false;
        } else {
            status = true;
        }
        return status;
    }

    /* change Qws type */
    const onQwsType = (val, index) => {

        let status;
        let arry = [...subjectArray];
        let inArray = arry[index].details;
        let temp = inArray.find(element => element.type == val);
        if (temp == undefined) {
            status = false;
        } else {
            status = true;
        }
        return status;
    }

    /* change on blur qws type */
    const onBlurQwsTypeValue = (e) => {
        let arry = [...subjectArray];
        let number = 0;
        for (let x = 0; arry.length > x; x++) {
            let inArray = arry[x].details;
            for (let j = 0; inArray.length > j; j++) {
                number += parseInt(inArray[j].value);
            }
        }
        setTotalQuestion(number);
    }

    /* change total mark */
    const onTotalMarkHandler = () => {

        let arry = [...subjectArray];
        let total = 0;

        for (let x = 0; arry.length > x; x++) {

            let inArray = arry[x].details;

            inArray.forEach((item, index) => {
                if (item.type && item.value) {
                    props.patternList.forEach((data, i) => {
                        if (item.type == data.short_code) {
                            total += parseInt(item.value) * parseInt(data.marks);
                        }
                    });

                }
            });

        }
        return total;
    }

/* file upload */
    const onFileHandler = (file) => {
        setFileUpload(0)
        let fileName = file.name
        var ext = fileName.substring(fileName.lastIndexOf('.') + 1);
        setFileExt(ext)
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append("tag_pdf", file);
            axios({
                url: GlobalConfigs.API_URL + Apis.UPLOAD_PDF_API,
                method: "POST",
                headers: {
                    ContentType: "multipart/form-data",
                },
                data: formData,
            }).then((res) => {
                setSyllabus(res.data.filepath);
                setFileUpload(null)
            }).then((json) => {
                //console.log('result2', json);
            })

        });

    }
    /* get full name */
    const getFullname = (id) => {
        let str = '';
        if (props.typeList.length > 0) {
            const index = props.typeList.findIndex((item) => item.id === parseInt(id));
            str = props.typeList[index].full_name;
        }
        return str
    }

    /*  */
    const getConducted = (id) => {
        let str = '';
        if (props.typeList.length > 0) {
            const index = props.typeList.findIndex((item) => item.id === parseInt(id));
            str = props.typeList[index].conducted_by;
        }
        return str
    }

    const getUsableCode = (id) => {
        let str = '';
        if (props.typeList.length > 0) {
            const index = props.typeList.findIndex((item) => item.id === parseInt(id));
            str = props.typeList[index].short_code;
        }
        return str
    }

    /* navigation go back with state */
    const onGoBackHandler = () => {
        history.push({
            pathname: '/competitive-exam-details-list',
            state: filterData
        });

        // history.goBack();
    }

    return (
        <>
            <div className="row">
                <div className="col-xl-6 col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card pt-5 pb-5">
                                <div className="row">
                                    <div className="col-xl-3">
                                        <div className="back-arrow-container">
                                            <button
                                                onClick={onGoBackHandler}>
                                                <i class="fa-solid fa-arrow-left"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="card-body">
                                            <div className="basic-form">

                                                <form onSubmit={onSubmit}>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Title<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <input className="form-control" placeholder="Enter the title" value={title} onChange={(e) => setTitle(e.target.value)} />
                                                        {errors.title && <div className="text-danger fs-12">{errors.title}</div>}
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Exam type<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <div className="basic-dropdown">
                                                            <span className="drop_down_arrow add-Question-arrow-position">
                                                                {props.typeLoading ?
                                                                    <Spinner animation="border" size="sm" />
                                                                    : <i className="bi bi-caret-down-fill"></i>}
                                                            </span>
                                                            <select
                                                                defaultValue={"option"}
                                                                className="form-control form-control"
                                                                value={examType}
                                                                onClick={getExamTypeHandler}
                                                                onChange={(e) => onExamTypeHandler(e.target.value)}
                                                            >
                                                                <option value="">Select exam type</option>
                                                                {
                                                                    props.typeList.map((item, index) => {
                                                                        return <option key={index} value={item.id}>{item.type_name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        {errors.type && <div className="text-danger fs-12">{errors.type}</div>}
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Full exam type name</strong>
                                                        </label>
                                                        <input className="form-control" placeholder="Enter the full exam type name" value={getFullname(examType)} disabled />
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Conducted by</strong>
                                                        </label>
                                                        <input className="form-control" placeholder="Enter the conducted by" value={getConducted(examType)} disabled />
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Usable code</strong>
                                                        </label>
                                                        <input className="form-control" placeholder="Enter the short code" value={getUsableCode(examType)} disabled />
                                                    </div>

                                                    {examType == 1 ?
                                                        <div className="form-group mb-3">
                                                            <label className="mb-2">
                                                                <strong>Exam sub type<span className="text-danger">*</span></strong>
                                                            </label>
                                                            <div className="basic-dropdown">
                                                                <span className="drop_down_arrow add-Question-arrow-position">
                                                                    {props.subTypeLoading ?
                                                                        <Spinner animation="border" size="sm" />
                                                                        : <i className="bi bi-caret-down-fill"></i>}
                                                                </span>
                                                                <select
                                                                    defaultValue={"option"}
                                                                    className="form-control form-control"
                                                                    value={subType}
                                                                    onClick={getSubTypeHandler}
                                                                    onChange={(e) => onSubTypeHandler(e.target.value)}
                                                                >
                                                                    <option value="">Select exam sub type</option>
                                                                    {
                                                                        props.subTypeList.map((item, index) => {
                                                                            return <option key={index} value={item.id}>{item.subtype_name}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            {errors.subtype && <div className="text-danger fs-12">{errors.subtype}</div>}
                                                        </div>
                                                        : null}

                                                    {examType == 2 ?
                                                        <div className="form-group mb-3">
                                                            <label className="mb-2">
                                                                <strong>Class<span className="text-danger">*</span></strong>
                                                            </label>
                                                            <div className="basic-dropdown">
                                                                <span className="drop_down_arrow add-Question-arrow-position">
                                                                    {props.classLoading ?
                                                                        <Spinner animation="border" size="sm" />
                                                                        : <i className="bi bi-caret-down-fill"></i>}
                                                                </span>
                                                                <select
                                                                    defaultValue={"option"}
                                                                    className="form-control form-control"
                                                                    value={className}
                                                                    onClick={getClassHandler}
                                                                    onChange={(e) => setClassName(e.target.value)}
                                                                >
                                                                    <option value="">Select class</option>
                                                                    {
                                                                        props.classlist.map((item, index) => {
                                                                            return <option key={index} value={item.id}>{item.short_code}</option>
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                            {errors.class && <div className="text-danger fs-12">{errors.class}</div>}
                                                        </div>
                                                        : null}

                                                    <div className="mb-3">
                                                        <label className="form-label"><strong>Syllabus</strong></label>
                                                        <input className="form-control"
                                                            type="file"
                                                            id="formFile"
                                                            onChange={(e) => onFileHandler(e.target.files[0])}
                                                            accept="application/pdf"
                                                        //accept="image/*"
                                                        />
                                                        <label className="form-label"><strong>Uploaded file : {oldPdfFile}</strong></label>
                                                        {errors.syllabus && <div className="text-danger fs-12">{errors.syllabus}</div>}
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Syllabus for academic session<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <input className="form-control" placeholder="Enter the academic session" value={academicSession} onChange={(e) => setAcademicSession(e.target.value)} />
                                                        {errors.academicSession && <div className="text-danger fs-12">{errors.academicSession}</div>}
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Tentative exam date<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <div className="date-control-view">
                                                            <DatePicker
                                                                ref={calendarRef}
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={examDate}
                                                                placeholderText="Enter the exam date"
                                                                onChange={(date) => setExamDate(date)}
                                                            />
                                                            <button type='button' className="picker-btn"
                                                                onClick={() => calendarRef.current.setOpen(true)}
                                                            >
                                                                <i class="fa-solid fa-calendar-days"></i>
                                                            </button>
                                                        </div>

                                                        {/* <input className="form-control" placeholder="Enter the exam date" value={examDate} onChange={(e) => setExamDate(e.target.value)} /> */}
                                                        {errors.examDate && <div className="text-danger fs-12">{errors.examDate}</div>}
                                                    </div>

                                                    <div className="add-com-subject-details-container">

                                                        {
                                                            subjectArray.map((data, index) => {
                                                                return (
                                                                    <div key={index} className="add-com-subject-details">
                                                                        <div className="form-group mb-3">
                                                                            <label className="mb-2">
                                                                                <strong>Subjects</strong>
                                                                            </label>
                                                                            <div className="add-multiple-subject">
                                                                                <div className="basic-dropdown flex-1 me-2">
                                                                                    <span className="drop_down_arrow add-Question-arrow-position">
                                                                                        {props.subjectLoading ?
                                                                                            <Spinner animation="border" size="sm" />
                                                                                            : <i className="bi bi-caret-down-fill"></i>}
                                                                                    </span>
                                                                                    <select
                                                                                        defaultValue={"option"}
                                                                                        className="form-control form-control"
                                                                                        value={data.id}
                                                                                        onClick={getSubjectHandler}
                                                                                        onChange={(e) => onSubjectHandler(e.target.value, index)}
                                                                                    >
                                                                                        <option value="">Select subjects</option>
                                                                                        {
                                                                                            props.subjectList.map((item, index) => {
                                                                                                return <option key={index} value={item.id} disabled={onSujectType(item.id)}>{item.name}</option>
                                                                                            })
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                                <div>
                                                                                    {index === 0 ?
                                                                                        <button type="button" className="plus-btn" onClick={onAddSubjectHandler} disabled={onSubjectStatusItem()}><i class="fa-solid fa-plus"></i></button>
                                                                                        :
                                                                                        <button type="button" onClick={() => onRemoveSubjectHandler(index)} className="plus-btn"><i class="fa-solid fa-minus"></i></button>

                                                                                    }
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                        <div className="form-group mb-3">
                                                                            <div className="question-form-group-view">
                                                                                <label className="type-question-label">
                                                                                    <strong>Type of Question</strong>
                                                                                </label>
                                                                                <label className="number-question-label">
                                                                                    <strong>No. of Question Type</strong>
                                                                                </label>
                                                                            </div>

                                                                            {
                                                                                data.details.map((item, i) => {
                                                                                    return (
                                                                                        <div key={i} className="inline-form-group-view">
                                                                                            <div className="basic-dropdown flex-1">
                                                                                                <span className="drop_down_arrow add-Question-arrow-position">
                                                                                                    {props.patternLoading ?
                                                                                                        <Spinner animation="border" size="sm" />
                                                                                                        : <i className="bi bi-caret-down-fill"></i>}
                                                                                                </span>
                                                                                                <select
                                                                                                    defaultValue={"option"}
                                                                                                    className="form-control"
                                                                                                    value={item.type}
                                                                                                    onClick={getQuesionsPatternHandler}
                                                                                                    onChange={(e) => onChangeQwsType(e.target.value, i, index)}
                                                                                                >
                                                                                                    <option value="">Select question type</option>
                                                                                                    {
                                                                                                        props.patternList.map((i, idex) => {
                                                                                                            return <option key={idex} value={i.short_code} disabled={onQwsType(i.short_code, index)}>{i.short_code}</option>
                                                                                                        })
                                                                                                    }
                                                                                                </select>
                                                                                            </div>
                                                                                            <input type="number" className="form-control form-control-pattan" placeholder="Enter value" value={item.value} onChange={(e) => onChangeQwsTypeValue(e.target.value, i, index)} onBlur={(e) => onBlurQwsTypeValue(e)} maxLength="3" />
                                                                                            {i === 0 ?
                                                                                                <button type="button" className="plus-btn" onClick={() => onQuestionHandler(index)} disabled={onQuestionStatusItem(index)}><i class="fa-solid fa-plus"></i></button>
                                                                                                :
                                                                                                <button type="button" onClick={() => onRemoveQuestionHandler(i, index)} className="plus-btn"><i class="fa-solid fa-minus"></i></button>

                                                                                            }
                                                                                        </div>
                                                                                    );
                                                                                })
                                                                            }

                                                                        </div>

                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                        {errors.pattern && <div className="text-danger fs-12">{errors.pattern}</div>}
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Total number of Questions</strong>
                                                        </label>
                                                        <input className="form-control" placeholder="Total number of Questions" disabled value={totalQuestion} />
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Total Marks</strong>
                                                        </label>
                                                        <input className="form-control" placeholder="Total marks" disabled value={onTotalMarkHandler()} />
                                                    </div>

                                                    <div className="form-group mb-3">
                                                        <label className="mb-2">
                                                            <strong>Total time (mins)<span className="text-danger">*</span></strong>
                                                        </label>
                                                        <input className="form-control" placeholder="Total time" value={totalTime} onChange={(e) => setTotalTime(e.target.value)} />
                                                        {errors.totalTime && <div className="text-danger fs-12">{errors.totalTime}</div>}
                                                    </div>


                                                    <div className="text-center mt-4">
                                                        <button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>
                                                            Submit
                                                        </button>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-xl-3"></div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div>
                    {fileUpload === 0 ?
                        <Loader />
                        :
                        null
                    }
                </div>

            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        categoryLoading: state.category.showLoading,
        categoryList: state.category.list,
        typeLoading: state.type.showLoading,
        typeList: state.type.list,
        subTypeLoading: state.subtype.showLoading,
        subTypeList: state.subtype.list,
        classLoading: state.class.showLoading,
        classlist: state.class.list,
        subjectLoading: state.subject.showLoading,
        subjectList: state.subject.subjectList,
        patternLoading: state.pattern.showLoading,
        patternList: state.pattern.list,
    };
};
export default connect(mapStateToProps)(UpdateCompetitiveExamDetails);