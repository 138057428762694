import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { connect, useDispatch } from "react-redux";
import Moment from "react-moment";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Modal,
  Button,
} from "react-bootstrap";
import sort from "../../../../images/dashboard_image/sort.png";
import { getAcademicSessionHistoryData } from "../../../../store/actions/AcademicAction";
import { globalLoadingAction } from "../../../../store/actions/GlobalAction";

const SessionHistory = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const calendarRef = useRef();
  const academyCalendarRef = useRef(null);

  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [board, setBoard] = useState("");
  const [type, setType] = useState("");
  const [academic, setAcademic] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState("");
  const [academyStartDate, setAcademyStartDate] = useState("");
  const [academyEndDate, setAcademyEndDate] = useState(null);
  const [active, setActive] = useState(1);
  const [isFiltered, setIsFiltered] = useState(false);
  const [modal, setModal] = useState(false);
  const [filterObj, setFilterObj] = useState({
    name: "",
    category: "",
    board: "",
    type: "",
    academic: "",
    startDate: "",
    endDate: "",
    status: "",
  });

  useEffect(() => {
    dispatch(globalLoadingAction(true));
    dispatch(
      getAcademicSessionHistoryData(
        category,
        category == 1 ? board : category == 2 ? type : "",
        name,
        academyStartDate ? moment(academyStartDate).format("YYYY-MM") : "",
        academyEndDate ? moment(academyEndDate).format("YYYY-MM") : "",
        startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        status,
        active,
        props.history
      )
    );
  }, []);

  /* pagination */
  const pageHandler = (data) => {
    setActive(data.selected + 1);
    dispatch(globalLoadingAction(true));
    dispatch(
      getAcademicSessionHistoryData(
        category,
        category == 1 ? board : category == 2 ? type : "",
        name,
        academyStartDate ? moment(academyStartDate).format("YYYY-MM") : "",
        academyEndDate ? moment(academyEndDate).format("YYYY-MM") : "",
        startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        status,
        data.selected + 1,
        props.history
      )
    );
  };

  /* delete handler */
  const onDateHandler = (date) => {
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  /* Category change */
  const onChangeCategoryHandler = (val) => {
    setCategory(val);
    setBoard("");
    setType("");
  };

  /* data Filter */
  const filterHandler = () => {
    dispatch(globalLoadingAction(true));
    dispatch(
      getAcademicSessionHistoryData(
        category,
        category == 1 ? board : category == 2 ? type : "",
        name,
        academyStartDate ? moment(academyStartDate).format("YYYY-MM") : "",
        academyEndDate ? moment(academyEndDate).format("YYYY-MM") : "",
        startDate ? moment(startDate).format("YYYY-MM-DD") : "",
        endDate ? moment(endDate).format("YYYY-MM-DD") : "",
        status,
        1,
        props.history
      )
    );
    setFilterObj((prev) => ({
      ...prev,
      name: name,
      category: category,
      board: board,
      type: type,
      academic: academic,
      startDate: startDate,
      endDate: endDate,
      status: status,
    }));
    setActive(1);
    setModal(false);
    setIsFiltered(true);
  };

  /* data reset */
  const onResetHandler = () => {
    setIsFiltered(false);
    setModal(false);
    dispatch(globalLoadingAction(true));
    dispatch(
      getAcademicSessionHistoryData(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        1,
        props.history
      )
    );
    clearFilterdata();
  };

  /* clear filter data */
  const clearFilterdata = () => {
    setCategory("");
    setName("");
    setBoard("");
    setType("");
    setAcademic("");
    setStartDate("");
    setEndDate(null);
    setAcademyStartDate("");
    setAcademyEndDate(null);
    setStatus("");
    setActive(1);
    setFilterObj((prev) => ({
      ...prev,
      name: "",
      category: "",
      board: "",
      type: "",
      academic: "",
      startDate: "",
      endDate: "",
      status: "",
    }));
  };

  const cancelModalHandler = () => {
    setModal(false);
  };

  const getCount = (val) => {
    let record = (active - 1) * 10 + val;
    return record;
  };

  const getAcademicYear = (yearString) => {
    let academicYearParts = yearString.split("-");
    if (academicYearParts.length !== 4) {
      throw new Error("Invalid academic year format");
    }
    return academicYearParts[0] + "-" + academicYearParts[2];
  };

  /* date handler */
  const onAcademyHandler = (dates) => {
    const [start, end] = dates;
    setAcademyStartDate(start);
    setAcademyEndDate(end);
  };

  const handleRawChange = (e) => {
    e.preventDefault(); // Prevent typing in the input field
  };

  const isDisabled =
    name || category || board || type || academyEndDate || status || endDate;

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <div className="filtersAlign">
            <div
              className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 ms-2 filter_background rounded cursorPointer ${
                isFiltered ? "border_red" : null
              }`}
              onClick={() => setModal(true)}
            >
              <div className="image_container">
                <img src={sort} alt="" className="image_style" />
              </div>
              <div className="button_text font_color_black">Filter</div>
            </div>
          </div>

          {props.list.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>
                    <strong>SI no.</strong>
                  </th>
                  <th>
                    <strong>Session Name</strong>
                  </th>
                  <th>
                    <strong>Category</strong>
                  </th>
                  <th>
                    <strong>Board/Type</strong>
                  </th>
                  <th>
                    <strong>Academic year</strong>
                  </th>
                  <th>
                    <strong>Course validity</strong>
                  </th>
                  <th>
                    <strong>Status</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.list.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{getCount(index + 1)}</td>
                      <td>{item.session_name}</td>
                      <td>{item.category}</td>
                      <td>{item.exam_board_type_name}</td>
                      <td>
                        {moment(item.academy_start_date).format("YYYY")} -{" "}
                        {moment(item.academy_end_date).format("YYYY")}
                      </td>
                      <td>
                        {moment(item.course_start_date).format("DD/MM/YYYY")} -{" "}
                        {moment(item.course_end_date).format("DD/MM/YYYY")}
                      </td>
                      <td>
                        <Button disabled className="btn-xs" variant={"danger"}>
                          Expired
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <div style={{ marginTop: "50px" }} className="no_data">
              <strong>No data found</strong>
            </div>
          )}

          {props.list.length > 0 && (
            <div className="pagination-container">
              {props.list.length > 0 ? (
                <div className="page-result">
                  <p>
                    Showing: {(active - 1) * 10 + 1} -{" "}
                    {10 * active - (10 - props.list.length)} of({" "}
                    {props.totalRecode} items)
                  </p>
                </div>
              ) : (
                <div></div>
              )}
              {props.totalPage > 1 ? (
                <ReactPaginate
                  containerClassName="pagination-gutter  false pagination-circle pagination pagination-sm"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  nextLabel={<i class="la la-angle-right"></i>}
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  previousLabel={<i class="la la-angle-left"></i>}
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  renderOnZeroPageCount={null}
                  activeClassName="active"
                  onPageChange={(data) => pageHandler(data)}
                  pageRangeDisplayed={3}
                  forcePage={active - 1}
                  pageCount={props.totalPage}
                />
              ) : null}
            </div>
          )}
        </Col>
      </Row>
      {/* <!-- Modal --> */}
      <Modal className="fade" show={modal} size="lg">
        <Modal.Header>
          <Modal.Title>Filter</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => cancelModalHandler()}
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="question-modal-form">
            <div className="reset-filtter-value">
              <button
                disabled={!isDisabled}
                className="btn btn-info btn-xs"
                onClick={() => onResetHandler()}
              >
                Reset
              </button>
            </div>

            <div className="row">
              <div className="form-group col-sm-6 px-1 mb-3">
                <input
                  className="form-control"
                  placeholder="Enter Session Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-status-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    // defaultValue={"option"}
                    className="form-control"
                    value={category}
                    onChange={(e) => onChangeCategoryHandler(e.target.value)}
                  >
                    <option value="">Category</option>
                    {props.categoryList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.category}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {category == 1 ? (
                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      value={board}
                      onChange={(e) => setBoard(e.target.value)}
                    >
                      <option value="select_baord">Baord</option>
                      {props.boardList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              ) : category == 2 ? (
                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-status-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      // defaultValue={"option"}
                      className="form-control"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option value="">Exam Type</option>
                      {props.typeList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.type_name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              ) : (
                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-status-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select className="form-control" disabled>
                      <option value="">Board/Exam Type</option>
                    </select>
                  </div>
                </div>
              )}

              {/* <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-status-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      className="form-control"
                      value={academic}
                      onChange={(e) => setAcademic(e.target.value)}
                    >
                      <option value="">Select Academic Year</option>
                      {props.yearList.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {getAcademicYear(item)}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div> */}

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="date-control-view">
                  <DatePicker
                    ref={academyCalendarRef}
                    placeholderText="Select Academic Year"
                    selected={academyStartDate}
                    onChange={onAcademyHandler}
                    selectsRange
                    startDate={academyStartDate}
                    endDate={academyEndDate}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    //minDate={startOfYear} // Disable previous years
                    //maxDate={endOfYear}
                    onChangeRaw={handleRawChange} // Prevent typing
                    customInput={
                      <input
                        type="text"
                        className="form-control"
                        value={
                          academyStartDate
                            ? `${academyStartDate.toLocaleDateString("en-US", {
                                month: "2-digit",
                                year: "numeric",
                              })} - ${
                                academyEndDate
                                  ? academyEndDate.toLocaleDateString("en-US", {
                                      month: "2-digit",
                                      year: "numeric",
                                    })
                                  : ""
                              }`
                            : ""
                        }
                        readOnly
                        placeholder="Select Academic Year"
                      />
                    }
                  />
                    <button
                      className="picker-btn"
                      onClick={() => academyCalendarRef.current.setOpen(true)}
                    >
                      <i class="fa-solid fa-calendar-days"></i>
                    </button>
                  
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="date-control-view">
                  <DatePicker
                    ref={calendarRef}
                    placeholderText="Course Validity"
                    selectsRange
                    selected={startDate}
                    onChange={onDateHandler}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    startDate={startDate}
                    endDate={endDate}
                    dropdownMode="select"
                    onChangeRaw={handleRawChange}
                  />
                    <button
                      className="picker-btn"
                      onClick={() => calendarRef.current.setOpen(true)}
                    >
                      <i class="fa-solid fa-calendar-days"></i>
                    </button>
               
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => cancelModalHandler()} variant="danger light">
            Cancel
          </Button>
          <Button
            disabled={!isDisabled}
            onClick={() => filterHandler()}
            variant="primary"
          >
            Filter
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.academic.historyList,
    totalPage: state.academic.historyTotalPage,
    totalRecode: state.academic.historyTotalRecode,
    categoryList: state.category.list,
    categoryLoading: state.category.showLoading,
    boardLoading: state.board.showLoading,
    boardList: state.board.list,
    typeList: state.type.list,
    yearList: state.academic.academicYear,
  };
};

export default connect(mapStateToProps)(SessionHistory);
