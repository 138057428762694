import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

import { getSettingsData, updateSettingsData, callMigrationTasksData } from '../../../store/actions/SettingsAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

import {
	Modal,
	Button,
} from "react-bootstrap";

const Settings = (props) => {

	const dispatch = useDispatch();

	let errorsObj = { gst: '', text: '' };
	const [errors, setErrors] = useState(errorsObj);
	// const [gst, setGst] = useState(props?.gstRate);
	// const [text, setText] = useState(props?.scrollingText);
	const [gst, setGst] = useState('');
	const [text, setText] = useState('');
	const [modal, setModal] = useState(false);
	const [permission, setPermission] = useState("");

	useEffect(() => {
		dispatch(globalLoadingAction(true));
		dispatch(getSettingsData(props.history));
		permissionCheck();
	}, []);

	useEffect(() => {
		setGst(props.gstRate);
		setText(props.scrollingText)

	}, [props?.gstRate, props?.scrollingText]);

	/* form submit */
	function onSubmit(e) {

		e.preventDefault();
		let error = false;
		const errorObj = { ...errorsObj };

		if (gst === '') {
			errorObj.gst = 'GST is Required';
			error = true;

		}
		if (text === '') {
			errorObj.text = 'Scroll text is Required';
			error = true;

		}
		setErrors(errorObj);
		if (error) {
			return;
		}
		dispatch(globalLoadingAction(true));
		dispatch(updateSettingsData(gst, text, props.history));
	}

	const onCloseHandler = () => {
		setModal(false)
	}

	const syncLive = () => {
		setModal(true)
		// dispatch(globalLoadingAction(true));
		// dispatch(callMigrationTasksData(props.history));
	}

	const syncLiveHandler = () => {
		// console.log("syncLiveHandler")
		setModal(false)
		dispatch(globalLoadingAction(true));
		dispatch(callMigrationTasksData(props.history));
	}


	const permissionCheck = () => {
		const userDetails = localStorage.getItem("userDetails");
		const tokenDetails = JSON.parse(userDetails);
		const menuList = tokenDetails.menu_list;
		menuList.map((item) => {
			if (item.id == 69) {
				console.log("69-------", item)
				setPermission(item);
			}
		});
	};
	
	

	return (
		<>
			<div className="row">

				<div className="col-xl-6 col-xl-12">

					<div className="row">
						<div className="col-xl-12">

							<div className="card pt-5 pb-5">
								{process.env.REACT_APP_APIHOSTURL == "https://api.clvdev.in/api/" && permission.sync == "true" ?
									<div className='col-xl-11 d-flex justify-content-end mr-4'>
										<button type="button" class="btn btn-success" onClick={() => syncLive()}>Sync with Live</button>
									</div>
									: null}

								<div className="row">
									<div className="col-xl-3"></div>
									<div className="col-xl-6">
										<div className="card-body">
											<div className="basic-form">
												<form onSubmit={onSubmit}>

													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>GST<span className="text-danger">*</span></strong>
														</label>
														<input className="form-control" placeholder="Enter the GST" value={gst} onChange={(e) => setGst(e.target.value)} />
														{errors.gst && <div className="text-danger fs-12">{errors.gst}</div>}
													</div>
													<div className="form-group mb-3">
														<label className="mb-2">
															<strong>Scroll text<span className="text-danger">*</span></strong>
														</label>
														<textarea className="form-control" rows={3} placeholder="Enter the test" value={text} onChange={(e) => setText(e.target.value)} />
														{errors.text && <div className="text-danger fs-12">{errors.text}</div>}
													</div>

													<div className="text-center mt-4">
														<button type="submit" className="btn btn-primary btn-block category-btn" disabled={props.showLoading}>
															Submit
															{props.showLoading ?
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
																: null}
														</button>
													</div>


												</form>
											</div>
										</div>

									</div>
									<div className="col-xl-3"></div>
								</div>
							</div>

						</div>

					</div>
				</div>
				{/* <!-- Modal --> */}

				<Modal className="fade" show={modal} size="md">
					<Modal.Header>
						<Modal.Title>Sync With Live Alert</Modal.Title>
						<Button
							variant=""
							className="btn-close"
							onClick={() => onCloseHandler()}
						></Button>
					</Modal.Header>
					<Modal.Body className="p-0">
						<div className="question-modal-form">

							<div className="row">
								<div className="form-group ">
									<h4>Crestest live system will be blocked for 15 mins</h4>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={() => onCloseHandler()} variant={`btn btn-info light`}>
							Cancel
						</Button>
						<Button onClick={() => syncLiveHandler()} variant="primary" >
							Ok
						</Button>
					</Modal.Footer>
				</Modal>

			</div>
		</>
	)
}

const mapStateToProps = (state) => {
	return {
		showLoading: state.settings.showLoading,
		gstRate: state.settings.gst,
		scrollingText: state.settings.scrollingData
	};
};
export default connect(mapStateToProps)(Settings);
