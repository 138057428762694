import swal from "sweetalert";
import { getPaymentDetailsList,paymentDamUserbyAdmin,paymentTransactionDetailsbyAdmin } from "../../services/PaymentServices";
import { GET_PAYMENT_DETAILS_LIST_ACTION,PAYMENT_LOADING_ACTION } from "../constants";
import { globalLoadingAction } from "../../store/actions/GlobalAction";
import * as utility from "../../utility/Utility";

export function getPaymentDetailsListData(user_id,content_type,creator_type,category_id,class_no,board,exam_type,subject,chapter,payment_status,page,history) {
  return (dispatch) => {
    getPaymentDetailsList(user_id,content_type,creator_type,category_id,class_no,board,exam_type,subject,chapter,payment_status,page)
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(getPaymentDetailsListAction(response.data));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function paymentDamUserbyAdminData(id,user_id,paid_amount,due_amount,transction_id,submitCallback,history) {
  return (dispatch) => {
    paymentDamUserbyAdmin(id,user_id,paid_amount,due_amount,transction_id)
      .then((response) => {
        if (response.data.status === 200) {
          utility.showSuccess(response.data.msg);
          submitCallback();
        }
        dispatch(paymentLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        dispatch(paymentLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function paymentTransactionDetailsbyAdminList(id,user_id,getTransactionDetailsCallBack,history) {
  return (dispatch) => {
    paymentTransactionDetailsbyAdmin(id,user_id)
      .then((response) => {
        if (response.data.status === 200) {
          getTransactionDetailsCallBack(response.data);
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function getPaymentDetailsListAction(data) {
  return {
    type: GET_PAYMENT_DETAILS_LIST_ACTION,
    payload: data,
  };
}

export function paymentLoadingAction(status) {
  return {
    type: PAYMENT_LOADING_ACTION,
    payload: status,
  };
}
