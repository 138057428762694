import {
  GET_DASHBOARD_INFO,
  MIS_SCHOOL_LIST,
  REG_STUDENT_PINCODE_LIST,
  REG_STUDENT_EMAIL_LIST,
  REG_MIS_INVOICES_LIST,
  MIS_TRANSCTION_LIST,
  TRANSACTION_LIST_UPDATED_AFTER_REFUND,
  AUTO_SUGGEST_ORDER_ID_LIST,
  AUTO_SUGGEST_MOBILE_NO_LIST,
  AUTO_SUGGEST_TRANSACTION_NO_LIST,
  MIS_MARKETING_FEEDBACK_RATING_LIST,
  MIS_MARKETING_FEEDBACK_RATING_BY_QUESTIONS,
  MIS_SUBSCRIBED_USERS_LIST,
  SUBSCRIBED_USERS_STUDENT_SUBSCRIPTION_LIST,
  STUDENT_ACTIVITIES_LIST,
  MIS_SUBSCRIBED_USERS_STUDENT_SUBSCRIPTION_LIST_LOADER,
  CONTENT_MIS_LIST,
  MIS_CONTENT_MIS_CONTENT_DETAILS_LIST,
  MIS_CONCURRENT_ACTIVE_USER_LIST,
  MIS_CONCURRENT_ACTIVE_USER_DETAILS_LIST,
  MIS_CONCURRENT_ACTIVE_USER_DETAILS_STUDENT_ACTIVITY_LIST,
  MIS_AUTOSUGGEST_ASSIGNTO_LIST,
  MIS_AUTOSUGGEST_CHAPTERCODE_LIST,
  MIS_DASHBOARD_DETAILS_LIST
} from "../constants";

const initial = {
  data: [],
  misSchoolList: [],
  misRegisteredStudentEmail: [],
  misRegisteredStudentLocationPin: [],
  misInvoicesList: [],
  misTransactionList: [],
  isRefreshMisTransactionList: 0,
  misAutoSuggestTransactionNoList: [],
  misAutoSuggestMobileNoList: [],
  misAutoSuggestOrderIdList: [],
  marketingFeedbackRatingList: [],
  marketingFeedbackRatingByquestionsDetails: [],
  misSubscribedUsersList: [],
  misSubscribedUsersStudentSubscriptionList: [],
  misSubscribedUsersStudentSubscriptionListLoader: false,
  misSubscribedUsersStudentActivitiesList: [],
  contentMisList: [],
  misContentMisContentDetailsList: [],
  misConcurrentActiveUserList: [],
  misConcurrentActiveUserDetailsList: [],
  misConcurrentActiveUserDetailsStudentActivityList: [],
  misAutosuggestAssignto: [],
  misAutosuggestChaptercode: [],
  misDashboardDetailssList:[],
};

export function MisDashboardInfoReducer(state = initial, action) {
  if (action.type == GET_DASHBOARD_INFO) {
    return {
      ...state,
      data: action.payload,
    };
  }

  if (action.type === MIS_SCHOOL_LIST) {
    return {
      ...state,
      misSchoolList: action.payload,
      showLoading: false,
    };
  }

  if (action.type === REG_STUDENT_PINCODE_LIST) {
    return {
      ...state,
      misRegisteredStudentLocationPin: action.payload,
      showLoading: false,
    };
  }

  if (action.type === REG_STUDENT_EMAIL_LIST) {
    return {
      ...state,
      misRegisteredStudentEmail: action.payload,
      showLoading: false,
    };
  }

  if (action.type === REG_MIS_INVOICES_LIST) {
    return {
      ...state,
      misInvoicesList: action.payload,
      showLoading: false,
    };
  }

  if (action.type === MIS_TRANSCTION_LIST) {
    return {
      ...state,
      misTransactionList: action.payload,
      showLoading: false,
    };
  }

  if (action.type === TRANSACTION_LIST_UPDATED_AFTER_REFUND) {
    return {
      ...state,
      isRefreshMisTransactionList: action.payload,
      showLoading: false,
    };
  }

  if (action.type === AUTO_SUGGEST_ORDER_ID_LIST) {
    return {
      ...state,
      misAutoSuggestOrderIdList: action.payload,
      showLoading: false,
    };
  }

  if (action.type === AUTO_SUGGEST_MOBILE_NO_LIST) {
    return {
      ...state,
      misAutoSuggestMobileNoList: action.payload,
      showLoading: false,
    };
  }

  if (action.type === AUTO_SUGGEST_TRANSACTION_NO_LIST) {
    return {
      ...state,
      misAutoSuggestTransactionNoList: action.payload,
      showLoading: false,
    };
  }

  if (action.type === MIS_MARKETING_FEEDBACK_RATING_LIST) {
    return {
      ...state,
      marketingFeedbackRatingList: action.payload,
      showLoading: false,
    };
  }

  if (action.type === MIS_MARKETING_FEEDBACK_RATING_BY_QUESTIONS) {
    return {
      ...state,
      marketingFeedbackRatingByquestionsDetails: action.payload,
      showLoading: false,
    };
  }

  if (action.type === MIS_SUBSCRIBED_USERS_LIST) {
    return {
      ...state,
      misSubscribedUsersList: action.payload,
      showLoading: false,
    };
  }

  if (action.type === SUBSCRIBED_USERS_STUDENT_SUBSCRIPTION_LIST) {
    return {
      ...state,
      misSubscribedUsersStudentSubscriptionList: action.payload,
      misSubscribedUsersStudentSubscriptionListLoader: false,
    };
  }
  if (action.type === STUDENT_ACTIVITIES_LIST) {
    return {
      ...state,
      misSubscribedUsersStudentActivitiesList: action.payload,
      showLoading: false,
    };
  }

  if (action.type === MIS_SUBSCRIBED_USERS_STUDENT_SUBSCRIPTION_LIST_LOADER) {
    return {
      ...state,
      misSubscribedUsersStudentSubscriptionListLoader: action.payload,

    };
  }

  if (action.type === CONTENT_MIS_LIST) {
    return {
      ...state,
      contentMisList: action.payload,
      showLoading: false,
    };
  }

  if (action.type === MIS_CONTENT_MIS_CONTENT_DETAILS_LIST) {
    return {
      ...state,
      misContentMisContentDetailsList: action.payload,
      showLoading: false,
    };
  }

  if (action.type === MIS_CONCURRENT_ACTIVE_USER_LIST) {
    return {
      ...state,
      misConcurrentActiveUserList: action.payload,
      showLoading: false,
    };
  }
  if (action.type === MIS_CONCURRENT_ACTIVE_USER_DETAILS_STUDENT_ACTIVITY_LIST) {
    return {
      ...state,
      misConcurrentActiveUserDetailsStudentActivityList: action.payload,
      showLoading: false,
    };
  }

  if (action.type === MIS_AUTOSUGGEST_ASSIGNTO_LIST) {
    return {
      ...state,
      misAutosuggestAssignto: action.payload,
      showLoading: false,
    };
  }
  if (action.type === MIS_AUTOSUGGEST_CHAPTERCODE_LIST) {
    return {
      ...state,
      misAutosuggestChaptercode: action.payload,
      showLoading: false,
    };
  }
if (action.type === MIS_CONCURRENT_ACTIVE_USER_DETAILS_LIST) {
    return {
      ...state,
      misConcurrentActiveUserDetailsList: action.payload,
      showLoading: false,
    };
  }
if (action.type === MIS_DASHBOARD_DETAILS_LIST) {
    return {
      ...state,
      misDashboardDetailssList: action.payload,
      showLoading: false,
    };
  }
  return state;

}