import {
  login,
  saveTokenInLocalStorage,
  checkToken,
  forgotPassword,
  resetPassword,
  resendOTP,
  updateOldPasswordDetails
} from "../../services/AuthService";

import { globalLoadingAction } from '../../store/actions/GlobalAction';

import {
  LOGIN_CONFIRMED,
  LOADING_TOGGLE,
  LOGIN_FAILED,
  LOGOUT,
  SIGNUP_CONFIRMED,
  SIGNUP_FAILED,
  SET_OTP,
  GET_MENU_LIST
} from "../constants";
import * as utility from "../../utility/Utility";

export function logout(history) {
  localStorage.removeItem("userDetails");
  history.push("/login");
  return {
    type: LOGOUT,
  };
}

export function loginAction(email, password, history) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        if (response.data.status === 200) {
         
          dispatch(loadingToggleAction(false));
          dispatch(setOTPAction(response.data.data[0].mobile_otp));
          history.replace({
            pathname: "/otp",
            state: response.data.data[0],
          });
        } else {
          utility.showError(response.data.msg);
          dispatch(loadingToggleAction(false));
        }
      })
      .catch((error) => {
        //console.log('hello', error.data);
        dispatch(loadingToggleAction(false));
      });
  };
}

export function checkTokenAction(tokenDetails, history) {
  return (dispatch) => {
    checkToken()
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(loginConfirmedAction(tokenDetails));
          //history.replace('/dashboard');
        } else {
          dispatch(logout(history));
        }
      })
      .catch((error) => {
        dispatch(logout(history));
      });
  };
}

export function forgotPasswordAction(email, history) {
  return (dispatch) => {
    forgotPassword(email)
      .then((response) => {
        dispatch(loadingToggleAction(false));
        utility.showSuccess(response.data.msg);
      })
      .catch((error) => {
        dispatch(loadingToggleAction(false));
      });
  };
}

export function resetPasswordAction(message, password, history) {
  return (dispatch) => {
    resetPassword(message, password)
      .then((response) => {
        dispatch(loadingToggleAction(false));
        utility.showSuccess(response.data.msg);
      })
      .catch((error) => {
        dispatch(loadingToggleAction(false));
      });
  };
}

export function resendOTPData(mobile, history) {
  return (dispatch) => {
    resendOTP(mobile)
      .then((response) => {
        dispatch(setOTPAction(response.data.data));
        dispatch(loadingToggleAction(false));
        utility.showSuccess(response.data.msg);
      })
      .catch((error) => {
        dispatch(loadingToggleAction(false));
      });
  };
}

export function verifyOtpAction(data, history) {
  console.log("data-----", data)
  return (dispatch) => {
    saveTokenInLocalStorage(data);
    dispatch(loginConfirmedAction(data));
    dispatch(loadingToggleAction(false));
    history.replace("/dashboard");
    utility.showSuccess("Logged in successfully.");
  };
}

export function updateOldPasswordData(old_password, new_password, history) {
  return (dispatch) => {
    updateOldPasswordDetails(old_password, new_password)
      .then((response) => {

        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
        dispatch(logout(history));
        
      })
      .catch((error) => {
        dispatch(globalLoadingAction(false));
      });
  };
}

export function setOTPAction(data) {
  return {
    type: SET_OTP,
    payload: data,
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED,
    payload: message,
  };
}


export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE,
    payload: status,
  };
}
