import swal from "sweetalert";
import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "../actions/GlobalAction";
import {
  MisDashboardInfo,
  misInvoicesApi,
  invoiceDownloadExcelDataApi,
  misTransactionListApi,
  misTransactionExcelDownloadApi,
  misPaymentRefundApi,
  misAutoSuggestOrderIdApi,
  misAutoSuggestMobileNoApi,
  misAutoSuggestTransactionNoApi,
  misMarketingFeedbackRatingApi,
  misMarketingFeedbackRatingByquestionsApi,
  misMarketingFeedbackRatingExcelDownloadApi,
  misSubscribedUsersApi,
  misSubscribedUsersExcelDownloadDataApi,
  misSubscribedUsersStudentSubscriptionApi,
  misSubscribedUsersStudentActivitiesApi,
  misSubscribedUserstudentActivitiesExcelDownloadApi,
  misContentMisListDataApi,
  misConcurrentActiveUserApi,
  misConcurrentActiveUserDetailsApi,
  misConcurrentUserDetailsStudentActivityApi,
  misStudentSubscriptionDetailsExcelDownloadApi,
  misContentMisExcelApi,
  misContentMisContentDetailsApi,
  misAutoSuggestAssigntoApi,
  misAutosuggestChaptercodeApi,
  concurrentActiveUserDownloadExcelApi,
  misConcurrentActiveUserDetailsExcelApi,
  MisDashboardDetailsApi,
  misdashboardDataDownloadApi
} from "../../services/MisDashBoardInfoService";
import {
  GET_DASHBOARD_INFO,
  REG_MIS_INVOICES_LIST,
  MIS_TRANSCTION_LIST,
  TRANSACTION_LIST_UPDATED_AFTER_REFUND,
  AUTO_SUGGEST_ORDER_ID_LIST,
  AUTO_SUGGEST_MOBILE_NO_LIST,
  AUTO_SUGGEST_TRANSACTION_NO_LIST,
  MIS_MARKETING_FEEDBACK_RATING_LIST,
  MIS_MARKETING_FEEDBACK_RATING_BY_QUESTIONS,
  MIS_SUBSCRIBED_USERS_LIST,
  SUBSCRIBED_USERS_STUDENT_SUBSCRIPTION_LIST,
  STUDENT_ACTIVITIES_LIST,
  MIS_SUBSCRIBED_USERS_STUDENT_SUBSCRIPTION_LIST_LOADER,
  CONTENT_MIS_LIST,
  MIS_CONTENT_MIS_CONTENT_DETAILS_LIST,
  MIS_CONCURRENT_ACTIVE_USER_LIST,
  MIS_CONCURRENT_ACTIVE_USER_DETAILS_LIST,
  MIS_CONCURRENT_ACTIVE_USER_DETAILS_STUDENT_ACTIVITY_LIST,
  MIS_AUTOSUGGEST_ASSIGNTO_LIST,
  MIS_AUTOSUGGEST_CHAPTERCODE_LIST,
  MIS_DASHBOARD_DETAILS_LIST
} from "../constants";

export function MisDashboardInfoData() {
  return (dispatch) => {
    MisDashboardInfo()
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(MisDashboardInfoAction(response.data.data))
          dispatch(globalLoadingAction(false));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function misInvoicesData(start_date, end_date, transaction_id, order_id, payment_mode, page) {
  return (dispatch) => {
    misInvoicesApi(start_date, end_date, transaction_id, order_id, payment_mode, page)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(misInvoicesAction(response.data))
          dispatch(globalLoadingAction(false));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function invoiceDownloadExcelData(startDate, endDate, transactionId, orderId, paymentMode, page) {
  return (dispatch) => {
    invoiceDownloadExcelDataApi(startDate, endDate, transactionId, orderId, paymentMode, page)
      .then((response) => {
        window.location.href = response.data.filename;
        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));

      });
  };
}

export function misTransactionListData(transactionId, orderId, studentId, transactionstatus, mobileNo, paymentMode, page) {
  return (dispatch) => {
    misTransactionListApi(transactionId, orderId, studentId, transactionstatus, mobileNo, paymentMode, page)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(misTransactionListAction(response.data))
          dispatch(globalLoadingAction(false));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}

export function misTransactionExcelDownloadData(transactionId, orderId, studentId, transactionstatus, mobileNo, paymentMode, page) {
  return (dispatch) => {
    misTransactionExcelDownloadApi(transactionId, orderId, studentId, transactionstatus, mobileNo, paymentMode, page)
      .then((response) => {
        window.location.href = response.data.filename;
        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));

      });
  };
}

export function misPaymentRefundData(trans_id, order_id, amount, pay_mode, id) {
  return (dispatch) => {
    misPaymentRefundApi(trans_id, order_id, amount, pay_mode, id)
      .then((response) => {
        dispatch(transactionListUpdatedAfterRefundAction(1));
        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));

      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));

      });
  };
}

export function misAutoSuggestOrderIdData() {
  return (dispatch) => {
    misAutoSuggestOrderIdApi()
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(misAutoSuggestOrderIdAction(response.data.data));
        } else {
          dispatch(misAutoSuggestOrderIdAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}

export function misAutoSuggestMobileNoData() {
  return (dispatch) => {
    misAutoSuggestMobileNoApi()
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(misAutoSuggestMobileNoAction(response.data.data));
        } else {
          dispatch(misAutoSuggestMobileNoAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}

export function misAutoSuggestTransactionNoData() {
  return (dispatch) => {
    misAutoSuggestTransactionNoApi()
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(misAutoSuggestTransactionNoAction(response.data.data));
        } else {
          dispatch(misAutoSuggestTransactionNoAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}

export function misMarketingFeedbackRatingData(select_services) {
  return (dispatch) => {
    misMarketingFeedbackRatingApi(select_services)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(misMarketingFeedbackRatingAction(response.data.data));
        } else {
          dispatch(misMarketingFeedbackRatingAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}


export function misMarketingFeedbackRatingByquestionsData(question_id, select_services) {
  return (dispatch) => {
    misMarketingFeedbackRatingByquestionsApi(question_id, select_services)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(misMarketingFeedbackRatingByquestionsAction(response.data.data));
        } else {
          dispatch(misMarketingFeedbackRatingByquestionsAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}

export function misMarketingFeedbackRatingExcelDownloadData(select_services) {
  return (dispatch) => {
    misMarketingFeedbackRatingExcelDownloadApi(select_services)
      .then((response) => {
        window.location.href = response.data.filename;
        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));

      });
  };
}

export function misSubscribedUsersData(select_board, select_class, course_completion, select_services, email, subscribe_type, min_price, max_price, page) {
  return (dispatch) => {
    misSubscribedUsersApi(select_board, select_class, course_completion, select_services, email, subscribe_type, min_price, max_price, page)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(misSubscribedUsersApiAction(response.data));
        } else {
          dispatch(misSubscribedUsersApiAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}



export function misSubscribedUsersExcelDownloadData(select_board, select_class, course_completion, select_services, email, subscribe_type, min_price, max_price, page) {
  return (dispatch) => {
    misSubscribedUsersExcelDownloadDataApi(select_board, select_class, course_completion, select_services, email, subscribe_type, min_price, max_price, page)
      .then((response) => {
        window.location.href = response.data.filename;
        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));

      });
  };
}


export function misStudentSubscriptionDetailsExcelDownloadData(student_id) {
  return (dispatch) => {
    misStudentSubscriptionDetailsExcelDownloadApi(student_id)
      .then((response) => {
        window.location.href = response.data.filename;
        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));

      });
  };
}




export function misSubscribedUsersStudentSubscriptionData(student_id) {
  return (dispatch) => {
    misSubscribedUsersStudentSubscriptionApi(student_id)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(misSubscribedUsersStudentSubscriptionAction(response.data.data));
        } else {
          dispatch(misSubscribedUsersStudentSubscriptionAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}

export function misSubscribedUsersStudentActivitiesData(student_id) {
  return (dispatch) => {
    misSubscribedUsersStudentActivitiesApi(student_id)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(misSubscribedUsersStudentActivitiesAction(response.data.data));
        } else {
          dispatch(misSubscribedUsersStudentActivitiesAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}

export function misSubscribedUserstudentActivitiesExcelDownloadData(student_id) {
  return (dispatch) => {
    misSubscribedUserstudentActivitiesExcelDownloadApi(student_id)
      .then((response) => {
        window.location.href = response.data.filename;
        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));

      });
  };
}

export function misContentMisListData(category, board, type, subtype, className, subject, chapterCode, assignTo, stage, status, contentType, chapter, page) {
  return (dispatch) => {
    misContentMisListDataApi(category, board, type, subtype, className, subject, chapterCode, assignTo, stage, status, contentType, chapter, page)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(misContentMisListAction(response.data));
        } else {
          dispatch(misContentMisListAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}

export function misContentMisExcelData(category, board, type, subtype, className, subject, chapterCode, assignTo, stage, status, contentType, chapter, page) {
  return (dispatch) => {
    misContentMisExcelApi(category, board, type, subtype, className, subject, chapterCode, assignTo, stage, status, contentType, chapter, page)
      .then((response) => {
        window.location.href = response.data.filename;
        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));

      });
  };
}

export function misContentMisContentDetailsData(content_id, request_type) {
  return (dispatch) => {
    misContentMisContentDetailsApi(content_id, request_type)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(misContentMisContentDetailsAction(response.data.data));
        } else {
          dispatch(misContentMisContentDetailsAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}

export function misConcurrentActiveUserData(period, activity, startDate, endDate, startTime, endTime, page) {
  return (dispatch) => {
    misConcurrentActiveUserApi(period, activity, startDate, endDate, startTime, endTime, page)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(misConcurrentActiveUserAction(response.data));
        } else {
          dispatch(misConcurrentActiveUserAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}

export function concurrentActiveUserDownloadExcelData(period, activity, startDate, endDate, startTime, endTime, page) {
  return (dispatch) => {
    concurrentActiveUserDownloadExcelApi(period, activity, startDate, endDate, startTime, endTime, page)
      .then((response) => {
        window.location.href = response.data.filename;
        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));

      });
  };
}

//concurrentActiveUserDownloadExcelData

export function misConcurrentActiveUserDetailsData(board, className, schoolName, page, student_ids, activity_id, search_date) {
  return (dispatch) => {
    misConcurrentActiveUserDetailsApi(board, className, schoolName, page, student_ids, activity_id, search_date)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(misConcurrentActiveUserDetailsAction(response.data.data));
        } else {
          dispatch(misConcurrentActiveUserDetailsAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}

export function misAutoSuggestAssigntoData() {
  return (dispatch) => {
    misAutoSuggestAssigntoApi()
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(misAutoSuggestAssigntoAction(response.data.data));
        } else {
          dispatch(misAutoSuggestAssigntoAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}

export function misConcurrentUserDetailsStudentActivityData(activity, search_date, to_time, from_time, student_id) {
  return (dispatch) => {
    misConcurrentUserDetailsStudentActivityApi(activity, search_date, to_time, from_time, student_id)
      .then((response) => {
        console.log("response-------------", response.data.data)
        if (response.data.status == 200) {
          dispatch(misConcurrentUserDetailsStudentActivityAction(response.data.data));
        } else {
          dispatch(misConcurrentUserDetailsStudentActivityAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}

export function misAutosuggestChaptercodeData() {
  return (dispatch) => {
    misAutosuggestChaptercodeApi()
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(misAutosuggestChaptercodeAction(response.data.data));
        } else {
          dispatch(misAutosuggestChaptercodeAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}

export function misConcurrentActiveUserDetailsExcelData(board, className, schoolName, page, student_ids, activity_id, search_date) {
  return (dispatch) => {
    misConcurrentActiveUserDetailsExcelApi(board, className, schoolName, page, student_ids, activity_id, search_date)
      .then((response) => {
        window.location.href = response.data.filename;
        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));

      });
  };
}

export function MisDashboardDetailsData(dashboardbox_id, from_date, to_date, record_type, callBack, history) {
  return (dispatch) => {
    MisDashboardDetailsApi(dashboardbox_id, from_date, to_date, record_type)
      .then((response) => {
        console.log("MisDashboardDetailsData--Action------", response.data)
        if (response.data.status == 200) {
          if (response.data.dashboard_box != '') {
            dispatch(MisDashboardDetailsAction(response.data))
            dispatch(globalLoadingAction(false));
          } else {
            callBack();
          }
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}


export function misdashboardDataDownloadAction(dashboardbox_id, from_date, to_date, record_type, history) {
  return (dispatch) => {
    misdashboardDataDownloadApi(dashboardbox_id, from_date, to_date, record_type,)
      .then((response) => {
        window.location.href = response.data.filename;
        utility.showSuccess(response.data.msg);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}


export function MisDashboardInfoAction(data) {
  return {
    type: GET_DASHBOARD_INFO,
    payload: data
  }
}

export function misInvoicesAction(data) {
  return {
    type: REG_MIS_INVOICES_LIST,
    payload: data
  }
}

export function misTransactionListAction(data) {
  return {
    type: MIS_TRANSCTION_LIST,
    payload: data
  }
}

export function transactionListUpdatedAfterRefundAction(data) {
  return {
    type: TRANSACTION_LIST_UPDATED_AFTER_REFUND,
    payload: data
  }
}

export function misAutoSuggestOrderIdAction(data) {
  return {
    type: AUTO_SUGGEST_ORDER_ID_LIST,
    payload: data
  }
}

export function misAutoSuggestMobileNoAction(data) {
  return {
    type: AUTO_SUGGEST_MOBILE_NO_LIST,
    payload: data
  }
}

export function misAutoSuggestTransactionNoAction(data) {
  return {
    type: AUTO_SUGGEST_TRANSACTION_NO_LIST,
    payload: data
  }
}

export function misMarketingFeedbackRatingAction(data) {
  return {
    type: MIS_MARKETING_FEEDBACK_RATING_LIST,
    payload: data
  }
}

export function misMarketingFeedbackRatingByquestionsAction(data) {
  return {
    type: MIS_MARKETING_FEEDBACK_RATING_BY_QUESTIONS,
    payload: data
  }
}

export function misSubscribedUsersApiAction(data) {
  return {
    type: MIS_SUBSCRIBED_USERS_LIST,
    payload: data
  }
}

export function misSubscribedUsersStudentSubscriptionAction(data) {
  return {
    type: SUBSCRIBED_USERS_STUDENT_SUBSCRIPTION_LIST,
    payload: data
  }
}

export function misSubscribedUsersStudentActivitiesAction(data) {
  return {
    type: STUDENT_ACTIVITIES_LIST,
    payload: data
  }
}

export function misSubscribedUsersStudentSubscriptionListLoaderAction(data) {
  return {
    type: MIS_SUBSCRIBED_USERS_STUDENT_SUBSCRIPTION_LIST_LOADER,
    payload: data
  }
}

export function misContentMisListAction(data) {
  return {
    type: CONTENT_MIS_LIST,
    payload: data
  }
}

export function misContentMisContentDetailsAction(data) {
  return {
    type: MIS_CONTENT_MIS_CONTENT_DETAILS_LIST,
    payload: data
  }
}

export function misConcurrentActiveUserAction(data) {
  return {
    type: MIS_CONCURRENT_ACTIVE_USER_LIST,
    payload: data
  }
}

export function misConcurrentActiveUserDetailsAction(data) {
  return {
    type: MIS_CONCURRENT_ACTIVE_USER_DETAILS_LIST,
    payload: data
  }
}
export function misConcurrentUserDetailsStudentActivityAction(data) {
  return {
    type: MIS_CONCURRENT_ACTIVE_USER_DETAILS_STUDENT_ACTIVITY_LIST,
    payload: data
  }
}
export function misAutoSuggestAssigntoAction(data) {
  return {
    type: MIS_AUTOSUGGEST_ASSIGNTO_LIST,
    payload: data
  }
}

export function misAutosuggestChaptercodeAction(data) {
  return {
    type: MIS_AUTOSUGGEST_CHAPTERCODE_LIST,
    payload: data
  }
}

export function MisDashboardDetailsAction(data) {
  return {
    type: MIS_DASHBOARD_DETAILS_LIST,
    payload: data
  }
}