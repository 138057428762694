import swal from "sweetalert";
import {
  MisRegisterUser,
  misSchoolList,
  misRegisterStudentEmailList,
  misRegisterStudentLocationPinList,
  misRegisterStudentListExcel,
  subscribedUsersApi
} from "../../services/MisRegUserServices";
import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "../actions/GlobalAction";
import {
  GET_MIS_REGISTER_USER,
  MIS_SCHOOL_LIST,
  REG_STUDENT_PINCODE_LIST,
  REG_STUDENT_EMAIL_LIST
} from "../constants";

export function getMisRegisterUserData(
  period, board, className, locationPin, subscruptionStatus, page, registrationVia, schoolName, email, startDate, endDate
) {
  return (dispatch) => {
    MisRegisterUser(
      period, board, className, locationPin, subscruptionStatus, page, registrationVia, schoolName, email, startDate, endDate
    )
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(getRegUserAction(response.data));
        } else {
          dispatch(getRegUserAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function misSchoolListData() {
  return (dispatch) => {
    misSchoolList()
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(misSchoolListAction(response.data.data));
        } else {
          dispatch(misSchoolListAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}

export function misRegisterStudentEmailListData() {
  return (dispatch) => {
    misRegisterStudentEmailList()
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(misRegisterStudentEmailListAction(response.data.data));
        } else {
          dispatch(misRegisterStudentEmailListAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}

export function misRegisterStudentLocationPinListData() {
  return (dispatch) => {
    misRegisterStudentLocationPinList()
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(misRegisterStudentLocationPinListAction(response.data.data));
        } else {
          dispatch(misRegisterStudentLocationPinListAction([]));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        // utility.showError(error.response.data);
      });
  };
}

export function misRegisterStudentListExcelData(period, board, className, locationPin, subscruptionStatus, page, registrationVia, schoolName, email, startDate, endDate) {
  return (dispatch) => {
    misRegisterStudentListExcel(period, board, className, locationPin, subscruptionStatus, page, registrationVia, schoolName, email, startDate, endDate)
      .then((response) => {
        window.location.href = response.data.filename;
        utility.showSuccess(response.data.msg);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}


export function getRegUserAction(data) {
  return {
    type: GET_MIS_REGISTER_USER,
    payload: data,
  };
}

export function misSchoolListAction(data) {
  return {
    type: MIS_SCHOOL_LIST,
    payload: data,
  };
}

export function misRegisterStudentEmailListAction(data) {
  return {
    type: REG_STUDENT_EMAIL_LIST,
    payload: data,
  };
}

export function misRegisterStudentLocationPinListAction(data) {
  return {
    type: REG_STUDENT_PINCODE_LIST,
    payload: data,
  };
}
