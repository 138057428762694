import { Fragment, useEffect, useState, useRef } from "react";
import { Row, Col, Card, Table, Modal, Button } from "react-bootstrap";
import { useDispatch, connect, useSelector } from "react-redux";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import {
  misConcurrentActiveUserData,
  invoiceDownloadExcelData,
  concurrentActiveUserDownloadExcelData,
} from "../../../store/actions/MisDashboardInfoAction";
import { Link, useLocation, useHistory } from "react-router-dom";

import paymentmodeList from "./json/paymentmode.json";

import periodList from "./json/period.json";
import concurrentperiodList from "./json/concurrentperiod.json";
import activityList from "./json/activity.json";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import ReactPaginate from "react-paginate";

//images
import sort from "../../../images/dashboard_image/sort.png";
import export_details from "../../../images/dashboard_image/export.png";

const ConcurrentActiveUser = (props) => {
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();

  const [period, setPeriod] = useState(state?.filterData?.period ?? "-1");
  const [activity, setActivity] = useState(state?.filterData?.activity ?? "");
  const [startDate, setStartDate] = useState(
    state?.filterData?.startDate ?? null
  );
  const [endDate, setEndDate] = useState(state?.filterData?.endDate ?? null);

  const [startTime, setStartTime] = useState(
    state?.filterData?.startTime ?? null
  );
  const [endTime, setEndTime] = useState(state?.filterData?.endTime ?? null);
  const [preStartTime, setPreStartTime] = useState(null);
  const [preEndTime, setPreEndTime] = useState(null);

  const [page, setPage] = useState(state?.page ?? 1);

  const calendarRef1 = useRef();
  const calendarRef2 = useRef();
  const calendarRef3 = useRef();

  const [tempPeriod, setTempPeriod] = useState("");
  const [tempActivity, setTempActivity] = useState("");
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");

  const [tempStartTime, setTempStartTime] = useState("");
  const [tempEndTime, setTempEndTime] = useState("");

  const [isFiltered, setIsFiltered] = useState(false);
  const [permission, setPermission] = useState("");

  const misConcurrentActiveUserList = useSelector(
    (state) => state.MisDashboardInfo.misConcurrentActiveUserList
  );

  const onResetHandler = () => {
    setPeriod("-1");
    setActivity("");
    setStartDate(null);
    setEndDate("");
    setPage(1);
    defaultTimeSet();
    getList('-1', '', '', '', '', '', 1);
    setTempPeriod('');
    setTempActivity('');
    setTempStartDate('');
    setTempEndDate('');
    setTempStartTime('');
    setTempEndTime('');
    setModal(false);
    const newState = {};
    history.replace({ state: newState });
  };

  useEffect(() => {
    // console.log("state?.filterData?.startTime-----", state?.filterData?.startTime)
    if (state?.filterData?.startTime === undefined) {
      defaultTimeSet();
    }

    getList(period, activity, startDate, endDate, startTime, endTime, page);
    permissionCheck()
  }, []);

  const defaultTimeSet = () => {
    let start_time = moment()
      .subtract(30, "minutes")
      .format("YYYY-MM-DD HH:mm:ss");
    // let to_time = moment().add(5, 'minutes').format('YYYY-MM-DD HH:mm:ss');
    setStartTime(new Date(start_time));
    setEndTime(new Date());
    setPreStartTime(new Date(start_time));
    setPreEndTime(new Date());
  };

  useEffect(() => {
    if (
      period != "select_period" &&
      period != "-1" &&
      period != "" &&
      period != undefined
    ) {
      setIsFiltered(true);
    } else if (
      activity != "select_activity" &&
      activity != "" &&
      activity != undefined
    ) {
      setIsFiltered(true);
    } else if (
      startDate === "" &&
      startDate === null &&
      startDate != undefined
    ) {
      setIsFiltered(true);
    } else if (endDate === "" && endDate === null && endDate != undefined) {
      setIsFiltered(true);
    } else if (
      startTime !== "" &&
      startTime !== null &&
      startTime != undefined &&
      !areDatesEqual(startTime, preStartTime)
    ) {
      setIsFiltered(true);
    } else if (
      endTime !== "" &&
      endTime !== null &&
      endTime != undefined &&
      !areDatesEqual(endTime, preEndTime)
    ) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
  }, [period, activity, startDate, endDate, startTime, endTime]);

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 94) {
        console.log("94----------", item)
        setPermission(item);
      }
    });
  };

  const getList = (
    period,
    activity,
    startDate,
    endDate,
    startTime,
    endTime,
    page
  ) => {
    dispatch(globalLoadingAction(true));
    dispatch(
      misConcurrentActiveUserData(
        period == "select_period" ? "" : period,
        activity == "select_activity" ? "" : activity,
        startDate === "" || startDate === null
          ? ""
          : moment(startDate).format("YYYY-MM-DD"),
        endDate === "" || endDate === null
          ? ""
          : moment(endDate).format("YYYY-MM-DD"),
        startTime === "" || startTime === null
          ? ""
          : moment(startTime).format("HH:mm:ss"),
        endTime === "" || endTime === null
          ? ""
          : moment(endTime).format("HH:mm:ss"),
        page
      )
    );
  };

  /* date handler */
  const onDateHandler = (date) => {
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  /* date handler */
  const timeHandleChange = (date) => {
    setStartTime(date);
  };

  /* date handler */
  const toTimeHandleChange = (date) => {
    setEndTime(date);
  };

  const filterConcurrentActiveUserHandler = () => {
    // defaultTimeSet();
    let page = 1;
    setModal(false);
    setPage(1);
    getList(period, activity, startDate, endDate, startTime, endTime, page);
    setTempPeriod(period);
    setTempActivity(activity);
    setTempStartDate(startDate);
    setTempEndDate(endDate);
    setTempStartTime(startTime);
    setTempEndTime(endTime);
  };

  /* pagination */
  const pageHandler = (data) => {
    let page = data.selected + 1;
    setPage(data.selected + 1);
    getList(period, activity, startDate, endDate, startTime, endTime, page);
  };

  const concurrentActiveUserDownloadExcelHandler = () => {
    let page = 0;
    dispatch(globalLoadingAction(true));
    dispatch(
      concurrentActiveUserDownloadExcelData(
        period,
        activity,
        startDate === "" || startDate === null
          ? ""
          : moment(startDate).format("YYYY-MM-DD"),
        endDate === "" || endDate === null
          ? ""
          : moment(endDate).format("YYYY-MM-DD"),
        startTime === "" || startTime === null
          ? ""
          : moment(startTime).format("HH:mm:ss"),
        endTime === "" || endTime === null
          ? ""
          : moment(endTime).format("HH:mm:ss"),
        page
      )
    );
  };

  const cancelModal = () => {
    setModal(false);
    setStartTime(null);
    setEndTime(null);

    if (tempPeriod == "") {
      setPeriod("-1");
    } else if (
      period != "select_period" &&
      period != "" &&
      period != undefined
    ) {
      setPeriod(tempPeriod);
    } else if (period == "select_period" || period == "") {
      setPeriod(tempPeriod);
    }

    if (tempActivity == "") {
      setActivity("");
    } else if (
      activity != "select_activity" &&
      activity != "" &&
      activity != undefined
    ) {
      setActivity(tempActivity);
    } else if (activity == "select_activity" || activity == "") {
      setActivity(tempActivity);
    }

    if (tempStartDate == "") {
      setStartDate("");
    } else if (startDate != "" && startDate != null && startDate != undefined) {
      setStartDate(tempStartDate);
    } else if (startDate == "" || startDate == null) {
      setStartDate(tempStartDate);
    }

    if (tempEndDate == "") {
      setEndDate("");
    } else if (endDate != "" && endDate != null && endDate != undefined) {
      setEndDate(tempEndDate);
    } else if (endDate == "" || endDate == null) {
      setEndDate(tempEndDate);
    }

    if (tempStartTime == "") {
      setStartTime("");
    } else if (startTime != "" && startTime != null && startTime != undefined) {
      setStartTime(tempStartTime);
    } else if (startTime == "" || startTime == null) {
      setStartTime(tempStartTime);
    }

    if (tempEndTime == "") {
      setEndTime("");
    } else if (endTime != "" && endTime != null && endTime != undefined) {
      setEndTime(tempEndTime);
    } else if (endTime == "" || endTime == null) {
      setEndTime(tempEndTime);
    }
  };

  const userDetailsPageHandeler = (item) => {
    props.history.push({
      pathname: "/user-details",
      state: {
        item,
        page,
        filterData: {
          period,
          activity,
          startDate,
          endDate,
          startTime,
          endTime,
        },
      },
    });
  };

  const openFilter = () => {
    setModal(true);
    // defaultTimeSet();
  };
  const areDatesEqual = (date1, date2) => {
    return new Date(date1).getTime() === new Date(date2).getTime();
  };
  const isDisabled =
    (period === "" || period === "select_period" || period == "-1") &&
    (activity === "" || activity === "select_activity") &&
    (startTime === "" || areDatesEqual(startTime, preStartTime)) &&
    (endTime === "" || areDatesEqual(endTime, preEndTime));

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="filtersAlign mt-3" style={{ marginRight: 25 }}>
                <div
                  className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 me-3 filter_background rounded cursorPointer ${isFiltered ? "border_red" : null
                    }`}
                  onClick={() => openFilter()}
                >
                  <div className="image_container">
                    <img src={sort} alt="" className="image_style" />
                  </div>
                  <div className="button_text font_color_black">Filter</div>
                </div>

                {(permission.download == "true") && (
                  <div
                    className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 export_background rounded blue_border cursorPointer ${misConcurrentActiveUserList?.data?.length == 0 &&
                        misConcurrentActiveUserList?.data?.length != undefined
                        ? "content_disable"
                        : null
                      }`}
                    onClick={() => concurrentActiveUserDownloadExcelHandler()}
                  >
                    <div className="image_container">
                      <img src={export_details} alt="" className="image_style" />
                    </div>
                    <div className="button_text font_color_blue cursorPointer">
                      Export
                    </div>
                  </div>
                )}
                
              </div>

              {/* {console.log("misConcurrentActiveUserList?.data?.length---------", misConcurrentActiveUserList?.data?.length)} */}
              {misConcurrentActiveUserList?.data?.length == 0 &&
                misConcurrentActiveUserList?.data?.length != undefined ? (
                <div className="no_data">
                  <strong>No data found</strong>
                </div>
              ) : (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Sl no.</strong>
                      </th>
                      <th>
                        <strong>Date</strong>
                      </th>
                      <th>
                        <strong>Number Of Users</strong>
                      </th>
                      <th>
                        <strong>Activity</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {misConcurrentActiveUserList?.data?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.sl_no}</td>
                          <td>{item.date}</td>
                          <td>
                            <div
                              className="cursorPointer link_color"
                              onClick={() => userDetailsPageHandeler(item)}
                            >
                              {item.no_of_users}
                            </div>
                          </td>
                          <td>{item.acitivity}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}

              {misConcurrentActiveUserList?.data?.length != 0 &&
                misConcurrentActiveUserList?.data?.length != undefined ? (
                <div className="pagination-container">
                  <div className="page-result">
                    <p>
                      Showing: {(page - 1) * 10 + 1} -{" "}
                      {10 * page -
                        (10 - misConcurrentActiveUserList?.data?.length)}{" "}
                      of {misConcurrentActiveUserList?.total_record}
                    </p>
                  </div>
                  {misConcurrentActiveUserList?.total_record > 10 ? (
                    <ReactPaginate
                      containerClassName="pagination-gutter  false pagination-circle pagination pagination-sm"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      nextLabel={<i className="la la-angle-right"></i>}
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      previousLabel={<i className="la la-angle-left"></i>}
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      renderOnZeroPageCount={null}
                      activeClassName="active"
                      onPageChange={pageHandler}
                      pageRangeDisplayed={3}
                      pageCount={misConcurrentActiveUserList?.total_page}
                      forcePage={page - 1}
                    />
                  ) : null}
                </div>
              ) : null}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <!-- Modal --> */}
      <Modal className="fade" show={modal} size="md">
        <Modal.Header>
          <Modal.Title>Filter</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => cancelModal()}
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="question-modal-form">
            <div className="reset-filtter-value">
              <button
                disabled={isDisabled}
                className="btn btn-info btn-xs"
                onClick={() => onResetHandler()}
              >
                Reset
              </button>
            </div>

            <div className="row">
              <div className={`form-group col-sm-6 px-1 mb-3`}>
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={period}
                    onChange={(e) => setPeriod(e.target.value)}
                  >
                    {/* <option value="select_period">Select Period</option> */}
                    {concurrentperiodList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.showValue}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className={`form-group col-sm-6 px-1 mb-3`}>
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={activity}
                    onChange={(e) => setActivity(e.target.value)}
                  >
                    <option value="select_activity">Select Activity</option>
                    {activityList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.showValue}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {period == 2 ? (
                <div className={`col-sm-6 px-1`}>
                  <div className="date-control-view session-date-control date_picker_box_height">
                    <DatePicker
                      ref={calendarRef1}
                      placeholderText="Select Date"
                      selectsRange
                      selected={startDate}
                      onChange={onDateHandler}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      startDate={startDate}
                      endDate={endDate}
                      dropdownMode="select"
                      dateFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                    />
                    <button
                      className="picker-btn"
                      onClick={() => calendarRef1.current.setOpen(true)}
                    >
                      <i className="fa-solid fa-calendar-days"></i>
                    </button>
                  </div>
                </div>
              ) : null}

              <div className={`col-sm-6 px-1`}>
                <div className="date-control-view session-date-control date_picker_box_height">
                  <DatePicker
                    ref={calendarRef2}
                    placeholderText="Select from time"
                    showTimeSelect
                    selected={startTime}
                    onChange={(date) => setStartTime(date)}
                    showTimeSelectOnly
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="h:mm aa"
                    timeCaption="From time"
                  />
                  <button
                    className="picker-btn"
                    onClick={() => calendarRef2.current.setOpen(true)}
                  >
                    <i className="fa-solid fa-clock"></i>
                  </button>
                </div>
              </div>

              <div className={`col-sm-6 px-1`}>
                <div className="date-control-view session-date-control date_picker_box_height">
                  <DatePicker
                    ref={calendarRef3}
                    placeholderText="Select to time"
                    showTimeSelect
                    selected={endTime}
                    // onChange={toTimeHandleChange}
                    onChange={(date) => setEndTime(date)}
                    showTimeSelectOnly
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="h:mm aa"
                    timeCaption="To time"
                  // minTime={startTime}
                  // maxTime={new Date()}
                  />
                  <button
                    className="picker-btn"
                    onClick={() => calendarRef3.current.setOpen(true)}
                  >
                    <i className="fa-solid fa-clock"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => cancelModal()} variant="danger light">
            Cancel
          </Button>
          <Button
            disabled={isDisabled}
            onClick={() => filterConcurrentActiveUserHandler()}
            variant="primary"
          >
            Filter
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const mapstateToProps = (state) => {
  return {
    boardList: state.board.list,
    classList: state.class.list,
    categoryList: state.category.list,
    subjectList: state.subject.subjectList,
    subscriptionDetailsList: state.subscriptiondetails.list,
  };
};

export default connect(mapstateToProps)(ConcurrentActiveUser);
