import axios from "axios";
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function MisRegisterUser(period, board, className, locationPin, subscruptionStatus, page, registrationVia, schoolName, email, startDate, endDate) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    period: period,
    select_baord: board,
    select_class: className,
    pin: locationPin,
    sub_status: subscruptionStatus,
    page: page,
    reg_via: registrationVia,
    school_name: schoolName,
    e_mail: email,
    from_date: startDate,
    to_date: endDate,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_REGISTER_USER_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}


export function misSchoolList() {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_SCHOOL_LIST_API,
    method: "GET",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },

  });
}


export function misRegisterStudentEmailList() {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_REG_STUDENT_EMAIL_LIST_API,
    method: "GET",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },

  });
}

export function misRegisterStudentLocationPinList() {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_REG_STUDENT_PINCODE_LIST_API,
    method: "GET",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },

  });
}

export function misRegisterStudentListExcel( period, board, className, locationPin, subscruptionStatus, page, registrationVia, schoolName, email, startDate, endDate
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    period: period,
    select_baord: board,
    select_class: className,
    pin: locationPin,
    sub_status: subscruptionStatus,
    page: page,
    reg_via: registrationVia,
    school_name: schoolName,
    e_mail: email,
    from_date: startDate,
    to_date: endDate,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_REG_STUDENT_EXCEL_EXPORT_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}