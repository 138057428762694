import React, { useContext } from "react";

/// React router dom
import { Switch, Route, Redirect } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

//page not found
import PageNotFound from "./pages/PageNotFound";

/// User Creation
import CreateUser from "./components/UserCreation/CreateUser";
import AdminList from "./components/UserCreation/AdminList";
import ContentDeveloperList from "./components/UserCreation/ContentDeveloperList";
import StudentList from "./components/UserCreation/StudentList";
import TeacherList from "./components/UserCreation/TeacherList";
import UpdateAdmin from "./components/UserCreation/UpdateAdmin";
import UpdateContentDeveloper from "./components/UserCreation/UpdateContentDeveloper";
import UpdateStudent from "./components/UserCreation/UpdateStudent";
import UpdateTeacher from "./components/UserCreation/UpdateTeacher";
import ContentValidatorList from "./components/UserCreation/ContentValidatorList";
import ContentLanguageValidatorList from "./components/UserCreation/ContentLanguageValidatorList";
import ContentDesignList from "./components/UserCreation/ContentDesignList";
import UpdateContentValidator from "./components/UserCreation/UpdateContentValidator";
import UpdateContentDesigner from "./components/UserCreation/UpdateContentDesigner";
import UpdateLanguageValidator from "./components/UserCreation/UpdateLanguageValidator";

/// Exam Category
import CreateCategory from "./components/ExamCategory/CreateExamCategory";
import CategoryList from "./components/ExamCategory/ExamCategoryList";
import UpdateExamCategory from "./components/ExamCategory/UpdateExamCategory";

/// Exam Type
import CreateType from "./components/ExamType/CreateExamtype";
import TypeList from "./components/ExamType/ExamtypeList";
import UpdateType from "./components/ExamType/UpdateExamtype";

/// Exam SubType
import CreateSubType from "./components/ExamSubtype/CreateExamSubtype";
import SubTypeList from "./components/ExamSubtype/ExamSubtypeList";
import UpdateSubType from "./components/ExamSubtype/UpdateExamSubtype";

/// Board
import CreateBoard from "./components/Board/CreateBoard";
import BoardList from "./components/Board/BoardList";
import UpdateBoard from "./components/Board/UpdateBoard";

/// Role Management
import CreateRole from "./components/RoleManagement/CreateRole";
import RoleList from "./components/RoleManagement/RoleList";
import UpdateRole from "./components/RoleManagement/updateRole";

/// Standard
import CreateStandard from "./components/Standard/CreateStandard";
import ClassList from "./components/Standard/ClassList";
import UpdateStandard from "./components/Standard/UpdateStandard";

/// Subjects
import CreateSubject from "./components/Subjects/CreateSubject";
import SubjectList from "./components/Subjects/SubjectList";
import UpdateSubject from "./components/Subjects/UpdateSubject";

/// Branch
import CreateBranch from "./components/Branch/CreateBranch";
import BranchList from "./components/Branch/BranchList";
import UpdateBranch from "./components/Branch/UpdateBranch";

/// Chapter
import CreateChapter from "./components/Chapter/CreateChapter";
import ChapterList from "./components/Chapter/ChapterList";
import UpdateChapter from "./components/Chapter/UpdateChapter";

/// Type of exam
import CreateTypeExam from "./components/TypeExam/CreateTypeExam";
import TypeExamList from "./components/TypeExam/TypeExamList";
import UpdateTypeExam from "./components/TypeExam/UpdateTypeExam";

/// Question pattern
import CreateQuestionPattern from "./components/QuestionPattern/CreateQuestionPattern";
import QuestionPatternList from "./components/QuestionPattern/QuestionPatternList";
import UpdateQuestionPattern from "./components/QuestionPattern/UpdateQuestionPattern";

/// Exam details
import CreateScholasticExamDetails from "./components/ExamDetails/CreateScholasticExamDetails";
import ScholasticExamDetailsList from "./components/ExamDetails/ScholasticExamDetailsList";
import UpdateScholasticExamDetails from "./components/ExamDetails/UpdateScholasticExamDetails";
import CreateCompetitiveExamDetails from "./components/ExamDetails/CreateCompetitiveExamDetails";
import CompetitiveExamDetailsList from "./components/ExamDetails/CompetitiveExamDetailsList";
import UpdateCompetitiveExamDetails from "./components/ExamDetails/UpdateCompetitiveExamDetails";

/// Exam Content
import CreateExamContent from "./components/ExamContent/CreateExamContent";
import ExamContentList from "./components/ExamContent/ExamContentList";
import UpdateQuestion from "./components/ExamContent/UpdateQuestion";
import ExamContentError from "./components/ExamContent/QuestionErrorLog";
import AddQuestion from "./components/ExamContent/AddQuestion";
import BulkQuestionImage from "./components/ExamContent/BulkQuestionImage";

/// Exam Time Configuration
import CreateTimeConfiguration from "./components/ExamTimeConfiguration/CreateTimeConfiguration";
import TimeConfigurationList from "./components/ExamTimeConfiguration/TimeConfigurationList";
import UpdateTimeConfiguration from "./components/ExamTimeConfiguration/UpdateTimeConfiguration";

/// Exam Configuration
import CreateExamConfiguration from "./components/ExamConfiguration/CreateExamConfiguration";
import ExamConfigurationList from "./components/ExamConfiguration/ExamConfigurationList";
import UpdateExamConfiguration from "./components/ExamConfiguration/UpdateExamConfiguration";

/// Subscription
import AddCompetetiveSubscription from "./components/Subscription/AddCompetetiveSubscription";
import AddScholasticSubscription from "./components/Subscription/AddScholasticSubscription";
import CompetetiveSubscriptionList from "./components/Subscription/CompetetiveSubscriptionList";
import ScholasticSubscriptionList from "./components/Subscription/ScholasticSubscriptionList";
import UpdateCompetetiveSubscription from "./components/Subscription/UpdateCompetetiveSubscription";
import UpdateScholasticSubscription from "./components/Subscription/UpdateScholasticSubscription";
import LibrarySubscriptionList from "./components/Subscription/LibrarySubscriptionList";
import UpdateLibrarySubscription from "./components/Subscription/UpdateLibrarySubscription";
import AddIntegratedSubscription from "./components/Subscription/AddIntegratedSubscription";
import IntegratedSubscriptionList from "./components/Subscription/IntegratedSubscriptionList";
import UpdateIntegratedSubscription from "./components/Subscription/UpdateIntegratedSubscription";

/// Assign
import AssignDeveloper from "./components/Assign/AssignDeveloper";
import AssignElibraryList from "./components/Assign/AssignElibraryList";
import EditDeveloper from "./components/Assign/EditDeveloper";
import HistoryDeveloper from "./components/Assign/HistoryDeveloper";
import AssignQuestionList from "./components/Assign/AssignQuestionList";
import ApprovedContentList from "./components/Assign/ApprovedContentList";
import AssignElibraryDetails from "./components/Assign/AssignElibraryDetails";
import AssignQuestionDetailsList from "./components/Assign/AssignQuestionDetailsList";
import AssignQuestionDetails from "./components/Assign/AssignQuestionDetails";
import UpdateAssignQuestion from "./components/Assign/UpdateAssignQuestion";
import ViewAssignQuestion from "./components/Assign/ViewAssignQuestion";
import AssignQuestionHistoryList from "./components/Assign/AssignQuestionHistoryList";

/// E-library
import ElibraryContentList from "./components/E-Library/ElibraryContentList";
import UploadContent from "./components/E-Library/UploadContent";
import ElibraryContentDetails from "./components/E-Library/ElibraryContentDetails";
import ConceptMapPdfView from "./components/E-Library/ConceptMapPdfView";
import AssignLibrary from "./components/E-Library/AssignLibrary";
import ElibraryContentStatus from "./components/E-Library/ElibraryContentStatus";
import PdfViewElibraryAdmin from "./components/E-Library/PdfViewElibraryAdmin";
import ElibraryDashboard from "./components/E-Library/ElibraryDashboard";

/// Event History
import CreateEventHistory from "./components/History/CreateEventHistory";
import EventHistoryList from "./components/History/EventHistoryList";
import UpdateEventHistory from "./components/History/UpdateEventHistory";

/// State
import CreateState from "./components/State/CreateState";
import StateList from "./components/State/StateList";
import UpdateState from "./components/State/UpdateState";

/// School Details
import UploadSchool from "./components/SchoolDetails/UploadSchool";
import SchoolList from "./components/SchoolDetails/SchoolList";
import UpdateSchool from "./components/SchoolDetails/UpdateSchool";
import AddNewSchool from "./components/SchoolDetails/AddNewSchool";

/// Career Guidance
import UploadCareer from "./components/CareerGuide/UploadCareer";

/// General Settings
import Settings from "./components/Settings/Settings";
import PrivacyPolicy from "./components/Settings/PrivacyPolicy";
import TermsCondition from "./components/Settings/TermsCondition";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";

/// Dashboard
import Home from "./components/Dashboard/Home";
import ChangePassword from "./components/Dashboard/ChangePassword";
import Profile from "./components/Dashboard/Profile";

//database backup
import DatabaseBackup from "./components/DatabaseBackup/databasebackup";
import Display from "./components/DatabaseBackup/display";

//Feedback
import AddFeedbackQuestion from "./components/Feedback/AddQuestion";
import FeedbackQuestionList from "./components/Feedback/QuestionList";
import UpdateFeedback from "./components/Feedback/UpdateFeedback";

//Mis
import MisRegUser from "./components/MIS/MisRegUser";
import FailedRegistration from "./components/MIS/FailedRegistration";
import SubscriptionDatabase from "./components/MIS/SubscriptionDatabase";
import FailedSubscription from "./components/MIS/FailedSubscription";
// import UserActivity from "./components/MIS/UserActivity";
import SubscribedUsers from "./components/MIS/SubscribedUsers";
import StudentActivities from "./components/MIS/StudentActivities";
import ContentDetails from "./components/MIS/ContentDetails";
import UserDetails from "./components/MIS/UserDetails";
import MisDashBoard from "./components/MIS/MisDashBoard";
import TransactionList from "./components/TransactionList";
import ContentMis from "./components/MIS/ContentMis";
import Invoice from "./components/MIS/Invoice";
import ConcurrentActiveUser from "./components/MIS/ConcurrentActiveUser";
import MarketingFeedback from "./components/MIS/MarketingFeedback";
import MisTransactionList from "./components/MIS/MisTransactionList";
import StudentActivity from "./components/MIS/StudentActivity";

//Resume List
import ResumeList from "./components/ResumeList/ResumeList";
import ResumeDetails from "./components/ResumeList/ResumeDetails";

//Advertisement
import AdvertisementList from "./components/Advertisement/AdvertisementList";
import AddAdvertisement from "./components/Advertisement/AddAdvertisement";
import UpdateAdvertisement from "./components/Advertisement/UpdateAdvertisement";

//Academic session
import AcademicSession from "./components/AcademicSession/AcademicSession";
import AddAcademicSession from "./components/AcademicSession/AddAcademicSession";
import UpdateAcademicSession from "./components/AcademicSession/UpdateAcademicSession";

import { ThemeContext } from "../context/ThemeContext";

const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);

  const routes = [
    //page not found
    { url: "page-not-found", component: PageNotFound },
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "change-password", component: ChangePassword },

    /// profile
    { url: "profile", component: Profile },

    /// User Creation
    { url: "create-user", component: CreateUser },
    { url: "admin-user-list", component: AdminList },
    { url: "content-developer-list", component: ContentDeveloperList },
    { url: "student-list", component: StudentList },
    { url: "teacher-list", component: TeacherList },
    { url: "content-validator-list", component: ContentValidatorList },
    { url: "language-validator-list", component: ContentLanguageValidatorList },
    { url: "update-admin", component: UpdateAdmin },
    { url: "update-content-developer", component: UpdateContentDeveloper },
    { url: "update-student", component: UpdateStudent },
    { url: "update-teacher", component: UpdateTeacher },
    { url: "content-designer-list", component: ContentDesignList },
    { url: "update-content-validator", component: UpdateContentValidator },
    { url: "update-content-designer", component: UpdateContentDesigner },
    { url: "update-language-validator", component: UpdateLanguageValidator },

    /// Exam Category
    { url: "create-category", component: CreateCategory },
    { url: "category-list", component: CategoryList },
    { url: "update-category", component: UpdateExamCategory },

    /// Exam Type
    // { url: "create-type", component: CreateType },
    { url: "create-exam-type", component: CreateType },
    // { url: "type-list", component: TypeList },
    { url: "exam-type-list", component: TypeList },
    { url: "update-type", component: UpdateType },

    /// Exam Sub Type
    { url: "create-sub-type", component: CreateSubType },
    { url: "subtype-list", component: SubTypeList },
    { url: "update-subtype", component: UpdateSubType },

    /// Board
    { url: "create-board", component: CreateBoard },
    { url: "board-list", component: BoardList },
    { url: "update-board", component: UpdateBoard },

    /// Role Management
    { url: "create-role", component: CreateRole },
    { url: "role-list", component: RoleList },
    { url: "update-role", component: UpdateRole },

    /// Standard
    { url: "create-class", component: CreateStandard },
    { url: "class-list", component: ClassList },
    { url: "update-class", component: UpdateStandard },

    /// Subjects
    { url: "create-subject", component: CreateSubject },
    { url: "subject-list", component: SubjectList },
    { url: "update-subject", component: UpdateSubject },

    /// Branch
    { url: "create-branch", component: CreateBranch },
    { url: "branch-list", component: BranchList },
    { url: "update-branch", component: UpdateBranch },

    /// Chapter
    { url: "create-chapter", component: CreateChapter },
    { url: "chapter-list", component: ChapterList },
    { url: "update-chapter", component: UpdateChapter },

    /// Type of exam
    { url: "create-type-of-exam", component: CreateTypeExam },
    { url: "type-of-exam-list", component: TypeExamList },
    { url: "update-type-of-exam", component: UpdateTypeExam },

    /// Question pattern
    { url: "create-question-type", component: CreateQuestionPattern },
    { url: "question-type-list", component: QuestionPatternList },
    { url: "update-question-type", component: UpdateQuestionPattern },

    /// Exam Details
    {
      url: "create-scholastic-exam-details",
      component: CreateScholasticExamDetails,
    },
    {
      url: "scholastic-exam-details-list",
      component: ScholasticExamDetailsList,
    },
    {
      url: "update-scholastic-exam-details",
      component: UpdateScholasticExamDetails,
    },
    {
      url: "create-competitive-exam-details",
      component: CreateCompetitiveExamDetails,
    },
    {
      url: "competitive-exam-details-list",
      component: CompetitiveExamDetailsList,
    },
    {
      url: "update-competitive-exam-details",
      component: UpdateCompetitiveExamDetails,
    },

    /// Exam Content
    { url: "exam-content", component: CreateExamContent },
    { url: "exam-content-list", component: ExamContentList },
    { url: "add-question", component: AddQuestion },
    { url: "update-question", component: UpdateQuestion },
    { url: "upload-question-bulk-image", component: BulkQuestionImage },
    { url: "question-error-log", component: ExamContentError },

    /// Exam Time Configuration
    { url: "time-configuration", component: CreateTimeConfiguration },
    { url: "time-configuration-list", component: TimeConfigurationList },
    { url: "update-time-configuration", component: UpdateTimeConfiguration },

    /// Exam Configuration
    { url: "exam-configuration", component: CreateExamConfiguration },
    { url: "exam-configuration-list", component: ExamConfigurationList },
    { url: "update-configuration-list", component: UpdateExamConfiguration },

    /// Subscription

    {
      url: "add-competitive-subscription",
      component: AddCompetetiveSubscription,
    },
    {
      url: "add-scholastic-subscription",
      component: AddScholasticSubscription,
    },
    {
      url: "competitive-subscription-list",
      component: CompetetiveSubscriptionList,
    },
    {
      url: "scholastic-subscription-list",
      component: ScholasticSubscriptionList,
    },
    {
      url: "update-competitive-subscription",
      component: UpdateCompetetiveSubscription,
    },
    {
      url: "update-scholastic-subscription",
      component: UpdateScholasticSubscription,
    },
    { url: "library-subscription-list", component: LibrarySubscriptionList },
    /* {
      url: "update-subscription-subscription",
      component: UpdateLibrarySubscription,
    }, */
    {
      url: "update-library-subscription",
      component: UpdateLibrarySubscription,
    },
    {
      url: "add-integrated-subscription",
      component: AddIntegratedSubscription,
    },
    {
      url: "integrated-subscription-list",
      component: IntegratedSubscriptionList,
    },
    {
      url: "update-integrated-subscription",
      component: UpdateIntegratedSubscription,
    },

    /// Assign
    { url: "assign-developer", component: AssignDeveloper },
    { url: "assign-elibrary-list", component: AssignElibraryList },
    { url: "assign-developer-edit", component: EditDeveloper },
    // { url: "assign-developer-history", component: HistoryDeveloper },
    { url: "assigned-content-history-list", component: HistoryDeveloper },
    { url: "assign-question-list", component: AssignQuestionList },
    { url: "approved-content-list", component: ApprovedContentList },
    { url: "assign-elibrary-details", component: AssignElibraryDetails },
    { url: "assign-question-details-list",component: AssignQuestionDetailsList},
    { url: "assign-question-details", component: AssignQuestionDetails },
    { url: "update-assign-question-developer", component: UpdateAssignQuestion },
    { url: "view-question-details", component: ViewAssignQuestion },
    { url: "assign-question-history", component: AssignQuestionHistoryList },

    /// E-library
    { url: "elibrary-content-list", component: ElibraryContentList },
    { url: "elibrary-upload-content", component: UploadContent },
    // { url: "elibrary-content-detail", component: ElibraryContentDetails },
    { url: "elibrary-content-details", component: ElibraryContentDetails },
    { url: "elibrary-concept-map", component: ConceptMapPdfView },
    { url: "elibrary-assign-library", component: AssignLibrary },
    { url: "elibrary-content-status", component: ElibraryContentStatus },
    {
      url: "elibrary-content-pdf-view/:infoid",
      component: PdfViewElibraryAdmin,
    },
    { url: "elibrary-dashboard", component: ElibraryDashboard },
    // { url: "elibrary-content-pdf-view/:infoid", component: PdfViewElibraryAdmin },
    //{ url: "elibrary-content-pdf-view", component: PdfViewElibraryAdmin },

    /// Event History
    { url: "create-event-history", component: CreateEventHistory },
    { url: "event-history-list", component: EventHistoryList },
    { url: "update-event-history", component: UpdateEventHistory },

    /// State
    { url: "error-state", component: CreateState },
    { url: "error-state-list", component: StateList },
    { url: "update-error-state", component: UpdateState },

    /// School details
    { url: "upload-school", component: UploadSchool },
    { url: "school-list", component: SchoolList },
    { url: "update-school", component: UpdateSchool },
    { url: "add-school", component: AddNewSchool },

    /// Career Guidance
    // { url: "upload-career-guide", component: UploadCareer },
    { url: "upload-documents", component: UploadCareer },
    // { url: "upload-documents", component: UploadCareer },

    /// General Settings
    { url: "settings", component: Settings },
    { url: "privacy-policy", component: PrivacyPolicy },
    { url: "terms-&-conditions", component: TermsCondition },

    ///Database Backup
    { url: "database-backup", component: DatabaseBackup },
    { url: "display", component: Display },

    //Feedback Question
    { url: "add-feedback-question", component: AddFeedbackQuestion },
    { url: "feedback-question-list", component: FeedbackQuestionList },
    { url: "feedback-update", component: UpdateFeedback },

    //Mis
    { url: "mis-dashboard", component: MisDashBoard }, /* new */
    { url: "registered-users", component: MisRegUser }, /* new */
    { url: "failed-registration", component: FailedRegistration },
    { url: "content-mis", component: ContentMis }, /* new */
    { url: "subscription", component: SubscriptionDatabase },  /* new */
    { url: "Invoice", component: Invoice }, /* new */
    // { url: "subscribed-users", component: UserActivity },
    { url: "subscribed-users", component: SubscribedUsers },
    { url: "student-activities", component: StudentActivities },
    { url: "content-details", component: ContentDetails },
    { url: "user-details", component: UserDetails },
    { url: "concurrent-active-user", component: ConcurrentActiveUser }, /* new */
    { url: "mis-transaction-list", component: MisTransactionList }, /* new */
    { url: "marketing-feedback", component: MarketingFeedback }, /* new */
    { url: "user-activity", component: StudentActivity }, /* new */

    //

    // { url: "mis-reg-user", component: MisRegUser },
    // { url: "subscription-user-details", component: SubscriptionDatabase },
    // { url: "failed-subscription", component: FailedSubscription },
    // { url: "subscribed-user-activity", component: UserActivity },
    // { url: "dashboard-mis", component: MisDashBoard },
    // { url: "transaction-list", component: TransactionList },

    { url: "transaction-list", component: TransactionList }, /* new */

    //Resume list
    { url: "resume-list", component: ResumeList },
    { url: "resume-details", component: ResumeDetails },

    //AdvertisementList
    { url: "advertisement-list", component: AdvertisementList },
    { url: "add-advertisement", component: AddAdvertisement },
    { url: "update-advertisement", component: UpdateAdvertisement },

    //Academic session
    { url: "academic-session", component: AcademicSession },
    { url: "add-academic-session", component: AddAcademicSession },
    { url: "update-academic-session", component: UpdateAcademicSession },

  ];

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div
          className={`${!pagePath ? "content-body" : ""}`}
          style={{ minHeight: window.screen.height - 45 }}
        >
          <div className={`${!pagePath ? "container-fluid" : ""}`}>
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
              <Redirect to="/page-not-found" />
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
