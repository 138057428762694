import {
    ADD_ASSIGN_CONTENT,
    GET_ASSIGN_E_LIBRARY_CONTENT_LIST,
    GET_ASSIGN_QUESTION_CONTENT_LIST,
    GET_E_LIBRARY_CONTENT_REQUEST_DETAILS,
    GET_QUESTION_CONTENT_REQUEST_DETAILS,
    ASSIGN_CONTENT_LOADING,
    REMOVE_ASSIGN_CONTENT_LIST,
    REMOVE_SUCCESSFULLY_FROM_LIST,
    GET_REJECTED_LIST,
    GET_ADDED_DOCUMENT_DETAILS,
    RE_RENDERING_CONTENT_LIST,
    CONTENT_LIST_TOTAL_RECORD,
    CONTENT_LIST_TOTAL_PAGE,
    GET_LIBRARY_HISTORY_LOG,
    NOTIFICATION_DETAILS,
    NOTIFICATION_PAGINATION,
    ASSIGN_DATA_CLEAR,
    FILTER_LIST_DATA,
    GET_ASSIGN_QUESTION_DETAILS_BY_ID,
    GET_QUESTION_HISTORY_LOG
} from '../constants';

const initialState = {
    eLibraryContentlist: [],
    eLibraryContentDetails: {},
    questionContentDetailsList: [],
    questionContentlist: [],
    questionTotalRecord: 0,
    questionTotalPage: 0,
    showLoading: false,
    againCallListData: 0,
    getRejectedListIdWise: [],
    documentDetails: [],
    reRenderingContentList: 0,
    contentListTotalRecord: 0,
    contentListTotalPage: 0,
    historyLog: '',
    notificationDetails: [],
    totalNotificationRecord:0,
    totalNotificationPage:0,
    assignDataClear: 0,
    filterDataList:'',
    questionHistory: [],
    questionDocumentDetails: null,
};

export function AssignContentReducer(state = initialState, action) {

    if (action.type === ADD_ASSIGN_CONTENT) {
        return {
            ...state,
            showLoading: false,
        };
    }

    if (action.type === GET_ASSIGN_E_LIBRARY_CONTENT_LIST) {
        return {
            ...state,
            eLibraryContentlist: action.payload,
            showLoading: false,
        };
    }
    if (action.type === GET_ASSIGN_QUESTION_CONTENT_LIST) {
        return {
            ...state,
            questionContentlist: action.payload.result,
            questionTotalRecord: action.payload.total_record,
            questionTotalPage: action.payload.page_count,
            showLoading: false,
        };
    }
    if (action.type === GET_E_LIBRARY_CONTENT_REQUEST_DETAILS) {
        return {
            ...state,
            eLibraryContentDetails: action.payload,
            showLoading: false,
        };
    }
    if (action.type === GET_QUESTION_CONTENT_REQUEST_DETAILS) {
        return {
            ...state,
            questionContentDetailsList: action.payload,
            showLoading: false,
        };
    }
    if (action.type === ASSIGN_CONTENT_LOADING) {
        return {
            ...state,
            showLoading: action.payload,
        };
    }
    if (action.type === REMOVE_ASSIGN_CONTENT_LIST) {
        const contentlist = [...state.eLibraryContentlist];
        const index = contentlist.findIndex((item) => item.id === action.payload);
        contentlist.splice(index, 1);
        return {
            ...state,
            eLibraryContentlist: contentlist,
            showLoading: false,
        };
    }
    if (action.type === REMOVE_SUCCESSFULLY_FROM_LIST) {
        return {
            ...state,
            againCallListData: action.payload,
            showLoading: false,
        };
    }

    if (action.type === GET_REJECTED_LIST) {
        return {
            ...state,
            getRejectedListIdWise: action.payload,
            showLoading: false,
        };
    }

    if (action.type === GET_ADDED_DOCUMENT_DETAILS) {
        return {
            ...state,
            documentDetails: action.payload,
            showLoading: false,
        };
    }
    if (action.type === GET_ASSIGN_QUESTION_DETAILS_BY_ID) {
        return {
            ...state,
            questionDocumentDetails: action.payload,
            showLoading: false,
        };
    }
    if (action.type === RE_RENDERING_CONTENT_LIST) {
        return {
            ...state,
            reRenderingContentList: action.payload,
            showLoading: false,
        };
    }
    if (action.type === CONTENT_LIST_TOTAL_RECORD) {
        return {
            ...state,
            contentListTotalRecord: action.payload,
            showLoading: false,
        };
    }
    if (action.type === CONTENT_LIST_TOTAL_PAGE) {
        return {
            ...state,
            contentListTotalPage: action.payload,
            showLoading: false,
        };
    }

    if (action.type === GET_LIBRARY_HISTORY_LOG) {
        return {
            ...state,
            historyLog: action.payload,
            showLoading: false,
        };
    }
    if (action.type === NOTIFICATION_DETAILS) {
        return {
            ...state,
            notificationDetails: action.payload.data,
            totalNotificationRecord:action.payload.total_record,
            totalNotificationPage:action.payload.page_count,
            showLoading: false,
        };
    }

    if (action.type === NOTIFICATION_PAGINATION) { 
        return {
            ...state,
            notificationDetails: [...state.notificationDetails, ...action.payload.data],
            showLoading: false,
        };
    }

    if (action.type === ASSIGN_DATA_CLEAR) {
        return {
            ...state,
            assignDataClear: action.payload,
            showLoading: false,
        };
    }

    if (action.type === GET_QUESTION_HISTORY_LOG) {
        return {
            ...state,
            questionHistory: action.payload,
            showLoading: false,
        };
    }

    if (action.type === FILTER_LIST_DATA) {
        return {
            ...state,
            filterDataList: action.payload,
            showLoading: false,
        };
    }
    return state;
}


