import axios from "axios";
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function MisFailedRegistration(period, board, className, locationPin, registrationFailed, page, registrationVia, schoolName, email, startDate, endDate, status) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    period: period,
    reg_via: registrationVia,
    select_baord: board,
    select_class: className,
    school_name: schoolName,
    sub_failed_reason:registrationFailed,
    e_mail: email,
    pin: locationPin,
    from_date: startDate,
    to_date: endDate,
    page: page,
    status
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_FAILED_REGISTRATION_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}


export function UpdateFailedRegistration(id, message, reply_mail_date, status) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    id, message, reply_mail_date, status
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.UPDATE_FAILED_REGISTRATION_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misFailedStudentListExcel(period, board, className, locationPin, registrationFailed, page, registrationVia, schoolName, email, startDate, endDate, status) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    period: period,
    reg_via: registrationVia,
    select_baord: board,
    select_class: className,
    school_name: schoolName,
    sub_failed_reason:registrationFailed,
    e_mail: email,
    pin: locationPin,
    from_date: startDate,
    to_date: endDate,
    page: page,
    status
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_FAILED_REG_STUDENT_EXCEL_EXPORT_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misApprovedFailedRegistration(id) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    id
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MISREPORT_APPROVED_FAILED_REGISTRATION_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}