import React, { useEffect, useState, useRef } from "react";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { MisDashboardInfoData, MisDashboardDetailsData, misdashboardDataDownloadAction, MisDashboardDetailsAction } from "../../../store/actions/MisDashboardInfoAction";
import { useDispatch, connect, useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";
import Spinner from 'react-bootstrap/Spinner';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";


//images
import sort from '../../../images/dashboard_image/sort.png'
import export_details from '../../../images/dashboard_image/export.png'

import {
  Modal,
  Button,
} from "react-bootstrap";

const MisDashBoard = (props) => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState(1);
  const [userName, setUserName] = useState('')
  const [isShowDropList, setIsShowDropList] = useState(false)
  const [selectOption, setSelectOption] = useState('')
  const [barchartColor, setBarchartColor] = useState('')
  const [dashboardBoxDetails, setDashboardBoxDetails] = useState() //
  const [sevenDaysRecords, setSevenDaysRecords] = useState()//
  const [dashboardBoxId, setDashboardBoxId] = useState(1)
  const [categories, setCategories] = useState('')//

  const [modal, setModal] = useState(false);
  const [filterFromDate, setFilterFromDate] = useState("");
  const [filterToDate, setFilterToDate] = useState(null);

  const [leftSideChartLabel, setLeftSideChartLabel] = useState('');
  const [bottomSideChartLabel, setBottomSideChartLabel] = useState('');
  const [viewDetails, setViewDetails] = useState(0);
  const [permission, setPermission] = useState("");
  const [isPageRefresh, setIsPageRefresh] = useState(false);
  const [display, setDisplay] = useState(true);

  var _leftSideChartLabel = '';
  var _bottomSideChartLabel = '';

  const calendarRef = useRef();
  const toCalendarRef = useRef();

  let errorsObj = { filterFromDate: '', filterToDate: '' };

  const [errors, setErrors] = useState(errorsObj);

  const misDashboardDetailssList = useSelector((state) => state.MisDashboardInfo.misDashboardDetailssList);

  useEffect(() => {
    // dispatch(dashboardloadingAction(true));
    if (misDashboardDetailssList != '' && misDashboardDetailssList?.series != undefined && misDashboardDetailssList?.series != '') {
      setDashboardBoxDetails(misDashboardDetailssList?.dashboard_box)
      setSevenDaysRecords(misDashboardDetailssList?.last_seven_days_records)
      setChartDataSeries(misDashboardDetailssList?.series)
      setCategories(misDashboardDetailssList?.categories)
      setLeftSideChartLabel(misDashboardDetailssList?.left_side_lebel)
      setBottomSideChartLabel(misDashboardDetailssList?.bottom_side_lebel)
    }

  }, [misDashboardDetailssList, dashboardBoxId]);

  useEffect(() => {
    setSelectOption(dropdownList[0])
    // setBarchartColor(misDashboardDetailssList && misDashboardDetailssList?.dashboard_box[0])
    // setBarchartColor(misDashboardData[0])
    permissionCheck()

    let getData = localStorage.getItem("userDetails");
    let name = JSON.parse(getData).name;
    setUserName(name)
  }, []);

  useEffect(() => {
    dashBoardDataCall()
  }, [selectOption, dashboardBoxId]);


  /* useEffect(() => {
    setBarchartColor(misDashboardDetailssList?.dashboard_box != undefined && misDashboardDetailssList?.dashboard_box[0])
    setIsPageRefresh(!isPageRefresh)
  }, []); */
  //misDashboardDetailssList

  useEffect(() => {
    if (misDashboardDetailssList != undefined && misDashboardDetailssList != '' && display) {
      setBarchartColor(misDashboardDetailssList?.dashboard_box[0])
      setDisplay(false)
    }

  }, [display, misDashboardDetailssList]);

  useEffect(() => {

    return () => {
      // console.log("setDisplay(true)")
      setDisplay(true)
    }
  }, []);

  useEffect(() => {
    return () => {
      // console.log("useEffect---return")
      dispatch(MisDashboardDetailsAction([]))
    }
  }, []);

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 78) {
        console.log("78----------", item)
        setPermission(item);
      }
    });
  };

  const dashBoardDataCall = () => {
    dispatch(MisDashboardDetailsData(dashboardBoxId, filterFromDate ? moment(filterFromDate).format("YYYY-MM-DD") : "", filterToDate ? moment(filterToDate).format("YYYY-MM-DD") : "", selectOption?.id, pageViewAccess, props.history));
  }

  const pageViewAccess = () => {
    setViewDetails(1)
  }

  const [chartDataSeries, setChartDataSeries] = useState([{
    name: "ICSE",
    data: [10, 35, 24, 55, 10, 60, 70, 90, 10, 36, 0, 45],
  },
  {
    name: "CBSE",
    data: [90, 5, 28, 15, 66, 35, 45, 55, 80, 75, 40, 20],
  },
  {
    name: "WBBSE",
    data: [55, 44, 32, 60, 44, 78, 10, 19, 30, 55, 90, 12],
  }
  ])

  const [dropdownList, setDropdownList] = useState([
    {
      id: 3,
      name: 'This Year',
      isSelect: 1,
    },
    {
      id: 2,
      name: 'This Month',
      isSelect: 0,
    },
    {
      id: 1,
      name: 'This Week',
      isSelect: 0,
    },
  ])
  const [misDashboardData, setMisDashboardData] = useState([
    {
      "id": 1,
      "heading": "Total Registered Users",
      "countValue": 82,
      "viewText": "View All",
      "graphicIcseColor": "#13795C",
      "graphicCbseColor": "#23A37F",
      "graphicWbbseColor": "#2FE7B3",
      "isClick": true,
      "page_link": "/registered-users"
    },
    {
      "id": 2,
      "heading": "Total Failed Registration",
      "countValue": 11,
      "viewText": "View All",
      "graphicIcseColor": "#1C87A9",
      "graphicCbseColor": "#24A9D2",
      "graphicWbbseColor": "#5AD8FF",
      "isClick": true,
      "page_link": "/failed-registration"
    },
    {
      "id": 3,
      "iconBaseColor": "#845ADF",
      "heading": "Total Content MIS",
      "countValue": 12,
      "viewText": "View All",
      "viewArrowIcon": "",
      "graphicIcseColor": "#423095",
      "graphicCbseColor": "#6D5AC3",
      "graphicWbbseColor": "#AF9CE7",
      "isClick": true,
      "page_link": "/content-mis"
    },
    {
      "id": 4,
      "iconBaseColor": "#F5B849",
      "heading": "Total Subscriptions",
      "countValue": 899744,
      "viewText": "View All",
      "viewArrowIcon": "",
      "graphicIcseColor": "#13795C",
      "graphicCbseColor": "#23A37F",
      "graphicWbbseColor": "#2FE7B3",
      "isClick": true,
      "page_link": "/subscription"
    },
    {
      "id": 5,
      "heading": "Total Invoice",
      "countValue": 641,
      "viewText": "View All",
      "viewArrowIcon": "",
      "graphicIcseColor": "#13795C",
      "graphicCbseColor": "#23A37F",
      "graphicWbbseColor": "#2FE7B3",
      "isClick": true,
      "page_link": "/invoice"
    },
    {
      "id": 6,
      "iconImage": "https://admin.clvdev.in/assets/special_charectors/services.png",
      "iconBaseColor": "#FD7E95",
      "heading": "Total Subscribed Users",
      "countValue": 61,
      "viewText": "View All",
      "viewArrowIcon": "",
      "graphicIcseColor": "#13795C",
      "graphicCbseColor": "#23A37F",
      "graphicWbbseColor": "#2FE7B3",
      "isClick": "/subscribed-users"
    },
    {
      "id": 7,
      "iconImage": "https://admin.clvdev.in/assets/special_charectors/account.png",
      "iconBaseColor": "#96D869",
      "heading": "Total Concurrent Subscribers",
      "countValue": 0,
      "viewText": "View All",
      "viewArrowIcon": "",
      "graphicIcseColor": "#13795C",
      "graphicCbseColor": "#23A37F",
      "graphicWbbseColor": "#2FE7B3",
      "isClick": "/concurrent-active-user"
    },
    {
      "id": 8,
      "iconImage": "https://admin.clvdev.in/assets/special_charectors/it.png",
      "iconBaseColor": "#A7A7A7",
      "heading": "Total Transactions",
      "countValue": 672,
      "viewText": "View All",
      "viewArrowIcon": "",
      "graphicIcseColor": "#13795C",
      "graphicCbseColor": "#23A37F",
      "graphicWbbseColor": "#2FE7B3",
      "isClick": "/mis-transaction-list"
    },
    {
      "id": 9,
      "iconImage": "https://admin.clvdev.in/assets/special_charectors/it.png",
      "iconBaseColor": "#A7A7A7",
      "heading": "Total Marketing Feedback",
      "countValue": 410,
      "viewText": "View All",
      "viewArrowIcon": "",
      "graphicIcseColor": "#13795C",
      "graphicCbseColor": "#23A37F",
      "graphicWbbseColor": "#2FE7B3",
      "isClick": "/marketing-feedback"
    }
  ],)
  _leftSideChartLabel = misDashboardDetailssList?.left_side_lebel;
  _bottomSideChartLabel = misDashboardDetailssList?.bottom_side_lebel;
  /* graphic details */
  const barChartDetails = {

    series: chartDataSeries,
    /* series: [{
      name: "ICSE11",
      data: [10, 35, 24, 55, 10, 60, 70, 90, 10, 36, 0, 45],
    },
    {
      name: "CBSE22",
      data: [90, 5, 28, 15, 66, 35, 45, 55, 80, 75, 40, 20],
    },
    {
      name: "WBBSE33",
      data: [55, 44, 32, 60, 44, 78, 10, 19, 30, 55, 90, 12],
    }
    ], */
    options: {
      chart: {
        type: "bar",
        height: 100,
        toolbar: {
          show: false,
        },
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          // endingShape: "rounded",
          borderRadius: 2,
          borderRadiusApplication: 'end',
          // distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },

      legend: {
        show: true,
        fontSize: "10px",
        fontWeight: 300,

        labels: {
          colors: "black",
        },

        position: "bottom",
        horizontalAlign: "center",
        markers: {
          width: 10,
          height: 10,
          strokeWidth: 0,
          radius: 19,
          strokeColor: "#fff",
          // fillColors: ["#13795C", "#23A37F", "#2FE7B3"],
          fillColors: [barchartColor.graphicIcseColor, barchartColor.graphicCbseColor, barchartColor.graphicWbbseColor],
          offsetX: 0,
          offsetY: 0,
        },
        onItemClick: {
          toggleDataSeries: false
        },
      },

      yaxis: {
        labels: {
          style: {
            colors: "#3e4954",
            fontSize: "14px",
            fontFamily: "Poppins",
            fontWeight: 100,
          },
        },
        title: {
          // text: 'No. of User',
          // text: (leftSideChartLabel != '' && leftSideChartLabel != undefined) ? 'leftSideChartLabel' : 'ppp',
          text: _leftSideChartLabel,
          style: {
            colors: "#2C2C2C50",
            fontSize: "9px",
            fontFamily: "Poppins",
            fontWeight: 100,
          }
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: categories != '' && categories != undefined ? categories : '',
        // categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        title: {
          // text: 'Period',
          text: _bottomSideChartLabel,
          // text: bottomSideChartLabel !== '' || bottomSideChartLabel !== undefined || bottomSideChartLabel !== null ? bottomSideChartLabel : '',
          style: {
            colors: "#2C2C2C50",
            fontSize: "9px",
            fontFamily: "Poppins",
            fontWeight: 100,
          },
          offsetX: 0,
          offsetY: 80,
        },
      },
      fill: {
        // colors: ["#13795C", "#23A37F", "#2FE7B3"],
        colors: [barchartColor.graphicIcseColor, barchartColor.graphicCbseColor, barchartColor.graphicWbbseColor],
        opacity: 1,
      },

      /* tooltip: {
        y: {
          formatter: function (val) {
            return "$ " + val + " thousands";
          },
        },
      }, */
    },
  };

  useEffect(() => {
    getDta();
  }, []);

  const getDta = () => {
    // dispatch(globalLoadingAction(true));
    dispatch(MisDashboardInfoData());
  };


  const gotoLinkPageHandler = (item) => {
    props.history.push(item.menuLink);
  }

  /* dropdown list show */
  const showList = () => {
    setIsShowDropList(!isShowDropList)
  }
  /* dropdown list select */
  const selectItem = (item) => {
    setSelectOption(item)
    dropdownList.map(u => u.isSelect = u.id == item.id ? 1 : 0);
    setIsShowDropList(!isShowDropList)
  }

  const closeDateFilter = () => {
    setModal(false);
    setFilterFromDate()
    setFilterToDate()

  }

  const fromDateHandler = (date) => {
    setFilterFromDate(date)
    setFilterToDate()
  }

  const onResetHandler = () => {
    setFilterFromDate('');
    setFilterToDate('');
    closeDateFilter();
    // dashBoardDataCall();
    dispatch(MisDashboardDetailsData(dashboardBoxId, "", "", selectOption.id, props.history));
  }

  const filterDashboardHandler = () => {

    let error = false;
    const errorObj = { ...errorsObj };

    if (filterFromDate !== '' && filterToDate === undefined) {
      errorObj.filterToDate = 'To Date is Required';
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    setModal(false);
    dashBoardDataCall();
  }

  const viewPage = (item) => {
    props.history.push(item.page_link);
  }

  /* change card item */
  const cardItem = (item) => {
    setBarchartColor(item);
    setDashboardBoxId(item.id);
  }

  const onDownloadDashboardFile = () => {
    dispatch(misdashboardDataDownloadAction(dashboardBoxId, filterFromDate ? moment(filterFromDate).format("YYYY-MM-DD") : "", filterToDate ? moment(filterToDate).format("YYYY-MM-DD") : "", selectOption.id, props.history));
  }

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card dashboard_card_height">
                {viewDetails == 0 ?
                  <>
                    {/* top */}
                    {dashboardBoxDetails != '' && dashboardBoxDetails != undefined ?
                      <div className='d-flex flex-row justify-content-between align-items-center m-4'>
                        <div>
                          <p className='welcome_text'>Welcome back, {userName}! </p>
                        </div>
                        <div className='d-flex flex-row'>
                          <div className='btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 me-3 filter_background rounded cursorPointer' onClick={() => setModal(true)}>
                            <div className='image_container'>
                              <img src={sort} alt="" className='image_style' />
                            </div>
                            <div className='button_text font_color_black'>
                              Filter
                            </div>
                          </div>
                          {
                            (permission.download == "true") && (
                              <div className='btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 export_background rounded blue_border cursorPointer' onClick={() => onDownloadDashboardFile()}>
                                <div className='image_container'>
                                  <img src={export_details} alt="" className='image_style' />
                                </div>
                                <div className='button_text font_color_blue' >
                                  Export
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                      : null}
                    {/* middle */}

                    <div className='d-flex flex-row m-4 flex-wrap'>
                      {/* {misDashboardData?.map((item, index) => { */}
                      {dashboardBoxDetails != '' && dashboardBoxDetails != undefined ?
                        dashboardBoxDetails?.map((item, index) => {
                          return (
                            <div className='position-relative me-4' key={index}> {/* ${barchartColor.id === item.id ? 'border_red' : null} */}
                              <div className={`mis_small_card d-flex flex-row rounded mb-3 px-3 py-2  ${barchartColor?.id === item?.id ? 'border_red' : null} ${item?.isClick ? 'cursorPointer' : null}`} style={{ background: item.id % 2 === 0 ? '#F6FCFF' : '#F4F8FF' }} onClick={() => item.isClick ? cardItem(item) : null}>
                                <div className='d-flex flex-row mis_parent_container'>
                                  <div className='d-flex flex-column mis_card_left_container justify-content-between align-items-start'>
                                    <div className="mis_card_heading">{item?.heading}</div>
                                    <div className="mis_card_count">{item?.countValue}</div>
                                  </div>
                                  {item?.viewText != '' && item?.viewText != undefined ?
                                    < div className={`right_parent_container d-flex justify-content-end align-items-end`}>
                                      <div className={`mis_card_right_container ${item?.isClick ? 'cursorPointer' : null}`} onClick={() => viewPage(item)} >{item?.viewText} <i className="bi bi-arrow-right arrow_icon_size" style={{ marginLeft: '5px', position: 'relative', top: '3px' }} ></i></div>
                                    </div>
                                    : null}
                                </div>
                              </div>
                            </div>
                          )
                        })
                        :
                        <div className='d-flex flex-row m-4 justify-content-center flex-wrap w-100'>
                          <Spinner
                            as="span"
                            animation="border"
                            size="large"
                            role="status"
                            variant="danger"
                            aria-hidden="true"
                          />

                        </div>
                      }
                    </div>

                    {/* <div className='d-flex flex-row m-4 justify-content-around flex-wrap'>

                  {dashboardBoxDetails != '' && dashboardBoxDetails != undefined ?
                    dashboardBoxDetails?.map((item, index) => {
                      return (
                        <div className='position-relative'>
                          <div className={`small_card d-flex flex-row rounded mb-4 p-3  ${barchartColor.id === item.id ? 'border_red' : null} ${item.isClick ? 'cursorPointer' : null}`} key={index} onClick={() => item.isClick ? cardItem(item) : null}>
                            <div className={`card_icon_container`} style={{ backgroundColor: item.iconBaseColor }}>
                              <img src={item.iconImage} alt="" className='icon_image_style' />
                            </div>
                            <div className='d-flex flex-column justify-content-between right_box_width'>
                              <div className='card_heading'>{item.heading}</div>
                              <div className='card_counter'>{item.countValue}</div>
                            </div>
                          </div>
                          <div className={`d-flex align-self-end card_view_all view_text_position ${item.isClick ? 'cursorPointer' : null}`} style={{ color: item.iconBaseColor }} onClick={() => viewPage(item)} >{item.viewText} <i className="bi bi-arrow-right arrow_icon_size" style={{ color: item.iconBaseColor, marginLeft: '5px' }} ></i></div>
                        </div>
                      )
                    })
                    :
                    <div className='d-flex flex-row m-4 justify-content-center flex-wrap w-100'>
                      <Spinner
                        as="span"
                        animation="border"
                        size="large"
                        role="status"
                        variant="danger"
                        aria-hidden="true"
                      />

                    </div>
                  }
                </div> */}
                    {dashboardBoxDetails != '' && dashboardBoxDetails != undefined ?
                      <div className='d-flex flex-row m-4 justify-content-between flex-wrap'>
                        <div className='mis_registration_container rounded d-flex flex-column'>
                          <div className='d-flex flex-row justify-content-center align-items-center w-100 m-2'>
                            <div className='registration_heading'>{barchartColor != '' && barchartColor != undefined ? barchartColor?.heading?.split(' ')[1] : misDashboardDetailssList?.dashboard_box != undefined && misDashboardDetailssList?.dashboard_box[0].heading?.split(' ')[1]}</div>

                            <div className='day_text'>(Last 7 Days)</div>
                          </div>
                          <div className='d-flex flex-row justify-content-around align-items-center w-100 bottom_line_table_heading'>
                            <div className='table_heading_text mis_width_registration d-flex ps-2 align-items-center'>{'Date'}</div>

                            <div className='table_heading_text mis_width_board d-flex  align-items-center text-left'>{dashboardBoxId == 1 ? 'No of registration' : dashboardBoxId == 2 ? 'No of failed registration' : dashboardBoxId == 3 ? 'No of content assigned' : dashboardBoxId == 4 ? 'Amount subscribed (Rs)' : dashboardBoxId == 5 ? 'No of Invoice generated' : dashboardBoxId == 6 ? 'No of new Subscribed user' : dashboardBoxId == 7 ? 'Maximum' : dashboardBoxId == 8 ? 'Total no transaction' : dashboardBoxId == 9 ? 'Total feedback received' : null}</div>

                            {dashboardBoxId == 3 || dashboardBoxId == 7 ?
                              <div className='table_heading_text mis_width_user d-flex  align-items-center text-left'>{dashboardBoxId == 3 ? 'No of content received' : dashboardBoxId == 7 ? 'Minimum' : null}</div>
                              : null}


                          </div>
                          <div className='d-flex flex-column justify-content-around w-100 last_seven_days_container' >
                            {/* {console.log("sevenDaysRecords--11--", sevenDaysRecords)} */}
                            {sevenDaysRecords != '' ?
                              sevenDaysRecords?.map((item, index) => {
                                return (
                                  <div className='d-flex flex-row my-2  align-items-center' key={index}>
                                    <div className='table_heading_text mis_width_registration d-flex ps-2 align-items-center'>{item.date}</div>

                                    <div className='table_heading_text mis_width_board d-flex  align-items-center'>{dashboardBoxId == 7 ? item.maxCount : item.count}</div>

                                    {dashboardBoxId == 3 || dashboardBoxId == 7 ?
                                      <div className='table_heading_text mis_width_user d-flex  align-items-center'>{dashboardBoxId == 3 ? item.received_count : dashboardBoxId == 7 ? item.minCount : null}</div>
                                      : null}

                                  </div>
                                )
                              })
                              :
                              <div className="no_dataContainer">
                                No Data Found
                              </div>
                            }
                          </div>
                        </div>
                        <div className='mis_registration_analytics_container rounded d-flex flex-column'>
                          <div className='d-flex justify-content-between w-100 m-2 registration_analytics_top_container position-relative '>
                            <div className='registration_heading'>{barchartColor != '' && barchartColor != undefined ? barchartColor?.heading?.split(' ')[1] : misDashboardDetailssList?.dashboard_box != undefined && misDashboardDetailssList?.dashboard_box[0].heading?.split(' ')[1]} Analytics</div>
                            <div className={`d-flex flex-column  align-items-center mis_dorpdown_container me-4 rounded dropDownZindex ${!isShowDropList ? 'dorpdown_container_single_item_height' : 'dorpdown_container_all_item_height'} dropdown_container_absolute_position`}>
                              <div className='d-flex flex-row justify-content-between align-items-center dropdown_font w-100 select_value cursorPointer' onClick={() => showList()}>
                                <div>

                                  {selectOption.name}
                                </div>
                                <div>
                                  <i className={isShowDropList ? "bi bi-chevron-up" : "bi bi-chevron-down"} ></i>
                                </div>
                              </div>

                              {isShowDropList && dropdownList.map((item, index) => {
                                return (
                                  <div className='d-flex justify-content-between align-items-center dropdown_font w-100 my-1 cursorPointer' key={index} onClick={() => selectItem(item)}>
                                    <div>
                                      {item.name}
                                    </div>
                                    <div className='dropdown_circle'>
                                      {item.isSelect == 1 ? <div className='dropdown_circle_fill'></div> : null}
                                    </div>
                                  </div>
                                )
                              })
                              }
                            </div>
                          </div>
                          <div>
                            <div className="chart_body">
                              {console.log("barChartDetails--mis--", barChartDetails)}
                              {/* {barChartDetails != '' && barChartDetails?.series != undefined && barChartDetails?.options?.xaxis?.categories != '' ? */}
                              <ReactApexChart
                                options={barChartDetails?.options}
                                series={barChartDetails?.series}
                                type="bar"
                                height={260}
                              />
                              {/* : null
                              } */}
                            </div>
                          </div>

                        </div>

                      </div>
                      : null}
                  </>
                  : <div className='no-permission'>
                    You do not have access to any mis dashboards. <br /> For more information, contact Admin
                  </div>
                }

              </div>

            </div>
          </div>
        </div>
        {/* </div> */}
        {/* <!-- Modal --> */}

        <Modal className="fade" show={modal} size="md">
          <Modal.Header>
            <Modal.Title>Filter</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => closeDateFilter()}
            ></Button>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="question-modal-form">

              <div className="row">
                <div className="form-group mb-3">
                  <label className="mb-2">
                    <strong>From Date<span className="text-danger">*</span></strong>
                  </label>
                  <div className="date-control-view">
                    <DatePicker
                      ref={calendarRef}
                      selected={filterFromDate}
                      placeholderText="From Date"
                      onChange={(date) => fromDateHandler(date)}
                      showYearDropdown
                      showMonthDropdown
                    // minDate={new Date()}
                    // disabled
                    />
                    <button type='button' className="picker-btn"
                      onClick={() => calendarRef.current.setOpen(true)}
                    >
                      <i className="fa-solid fa-calendar-days"></i>
                    </button>
                  </div>

                </div>

                <div className={`form-group mb-3  ${filterFromDate === '' || filterFromDate === undefined || filterFromDate === null ? 'content_disable' : null}`}>
                  <label className="mb-2">
                    <strong>To Date<span className="text-danger">*</span></strong>
                  </label>
                  <div className="date-control-view">
                    <DatePicker
                      ref={toCalendarRef}
                      selected={filterToDate}
                      placeholderText="To Date"
                      onChange={(date) => setFilterToDate(date)}
                      showYearDropdown
                      showMonthDropdown
                      minDate={new Date(filterFromDate)}
                    // disabled={ true }
                    />
                    <button type='button' className="picker-btn"
                      onClick={() => toCalendarRef.current.setOpen(true)}
                    >
                      <i className="fa-solid fa-calendar-days"></i>
                    </button>
                  </div>
                  {errors.filterToDate && <div className="text-danger fs-12">{errors.filterToDate}</div>}
                </div>
              </div>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => onResetHandler()} variant={`btn btn-info light ${filterFromDate === '' || filterFromDate === undefined || filterFromDate === null ? 'content_disable' : null}`}>
              Reset
            </Button>
            <Button onClick={() => filterDashboardHandler()} variant={`primary ${filterFromDate === '' || filterFromDate === undefined || filterFromDate === null ? 'content_disable' : null}`} >
              Filter
            </Button>
          </Modal.Footer>
        </Modal>
        {/* </div> */}
      </div >
    </>
  )
};

const mapStateToProps = (state) => {
  return {
    dashboardInfo: state.MisDashboardInfo.data,
  };
};

export default connect(mapStateToProps)(MisDashBoard);
