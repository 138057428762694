import { Fragment, useEffect, useState, useRef } from "react";
import { Row, Col, Card, Table, Modal, Button } from "react-bootstrap";
import { useDispatch, connect, useSelector } from "react-redux";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import {
  misInvoicesData,
  invoiceDownloadExcelData,
} from "../../../store/actions/MisDashboardInfoAction";

import paymentmodeList from "./json/paymentmode.json";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import ReactPaginate from "react-paginate";

//images
import sort from "../../../images/dashboard_image/sort.png";
import export_details from "../../../images/dashboard_image/export.png";

const Invoice = (props) => {
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  const [paymentMode, setPaymentMode] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(1);

  const calendarRef = useRef();

  const [tempTransactionId, setTempTransactionId] = useState("");
  const [tempOrderId, settempOrderId] = useState("");
  const [tempPaymentMode, setTempPaymentMode] = useState("");
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");
  const [permission, setPermission] = useState("");

  const misInvoicesList = useSelector(
    (state) => state.MisDashboardInfo.misInvoicesList
  );
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    if (transactionId != "" && transactionId != undefined) {
      setIsFiltered(true);
    } else if (orderId != "" && orderId != undefined) {
      setIsFiltered(true);
    } else if (
      paymentMode != "select_payment_mode" &&
      paymentMode != "" &&
      paymentMode != undefined
    ) {
      setIsFiltered(true);
    } else if (
      startDate !== "" &&
      startDate !== null &&
      startDate !== undefined
    ) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
  }, [transactionId, orderId, paymentMode, startDate]);

  const onResetHandler = () => {
    setPaymentMode("");
    setTransactionId("");
    setOrderId("");
    setStartDate(null);
    setEndDate(null);
    setPage(1);
    dispatch(globalLoadingAction(true));
    dispatch(misInvoicesData("", "", "", "", "", 1));
    setTempTransactionId("");
    settempOrderId("");
    setTempPaymentMode("");
    setTempStartDate("");
    setTempEndDate("");
    setModal(false);
  };

  useEffect(() => {
    getList(startDate, endDate, transactionId, orderId, paymentMode, page);
    permissionCheck()
  }, []);

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 83) {
        console.log("83----------", item)
        setPermission(item);
      }
    });
  };


  const getList = (
    startDate,
    endDate,
    transactionId,
    orderId,
    paymentMode,
    page
  ) => {
    dispatch(globalLoadingAction(true));
    dispatch(
      misInvoicesData(
        startDate === "" || startDate === null ? "" : moment(startDate).format("YYYY-MM-DD"),
        endDate === "" || endDate === null ? "" : moment(endDate).format("YYYY-MM-DD"),
        transactionId,
        orderId,
        paymentMode == "select_payment_mode" ? "" : paymentMode,
        page
      )
    );
  };

  /* date handler */
  const onDateHandler = (date) => {
    //setStartDate(date);
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  const filterInvoiceHandler = () => {
    let page = 1;
    setModal(false);
    setPage(1);
    getList(startDate, endDate, transactionId, orderId, paymentMode, page);
    setTempTransactionId(transactionId);
    settempOrderId(orderId);
    setTempPaymentMode(paymentMode);
    setTempStartDate(startDate);
    setTempEndDate(endDate);
  };

  /* pagination */
  const pageHandler = (data) => {
    let page = data.selected + 1;
    setPage(data.selected + 1);
    getList(startDate, endDate, transactionId, orderId, paymentMode, page);
  };

  const onDownloadInvoiceHandler = () => {
    let page = 0;
    dispatch(globalLoadingAction(true));
    dispatch(
      invoiceDownloadExcelData(
        startDate === "" || startDate === null ? "" : moment(startDate).format("YYYY-MM-DD"),
        endDate === "" || endDate === null ? "" : moment(endDate).format("YYYY-MM-DD"),
        transactionId,
        orderId,
        paymentMode,
        page
      )
    );
  };

  const cancelModal = () => {
    setModal(false);

    /* if (tempStartDate == '' || tempStartDate == null) {
      setStartDate("")
    } else if (tempStartDate) {
      setStartDate(tempStartDate)
    } */

    if (tempTransactionId == "" || tempTransactionId == null) {
      setTransactionId("");
    } else if (tempTransactionId != "") {
      setTransactionId(tempTransactionId);
    }

    if (tempOrderId == "" || tempOrderId == null) {
      setOrderId("");
    } else if (tempOrderId != "") {
      setOrderId(tempOrderId);
    }

    if (tempPaymentMode == "") {
      setPaymentMode("");
    } else if (
      paymentMode != "select_payment_mode" &&
      paymentMode != "" &&
      paymentMode != undefined
    ) {
      setPaymentMode(tempPaymentMode);
    } else if (paymentMode == "select_payment_mode" || paymentMode == "") {
      setPaymentMode(tempPaymentMode);
    }

    if (tempStartDate == "") {
      setStartDate("");
    } else if (startDate != "" && startDate != null && startDate != undefined) {
      setStartDate(tempStartDate);
    } else if (startDate == "" || startDate == null) {
      setStartDate(tempStartDate);
    }

    if (tempEndDate == "") {
      setEndDate("");
    } else if (endDate != "" && endDate != null && endDate != undefined) {
      setEndDate(tempEndDate);
    } else if (endDate == "" || endDate == null) {
      setEndDate(tempEndDate);
    }
  };

  const handleRawChange = (e) => {
    e.preventDefault(); // Prevent typing in the input field
  };

  const isDisabled =
    (paymentMode === "" || paymentMode === "select_payment_mode") &&
    transactionId === "" &&
    orderId === "" &&
    (endDate === "" || endDate === null);

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="filtersAlign mt-3" style={{ marginRight: 25 }}>
                <div
                  className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 me-3 filter_background rounded cursorPointer ${isFiltered ? "border_red" : null
                    }`}
                  onClick={() => setModal(true)}
                >
                  <div className="image_container">
                    <img src={sort} alt="" className="image_style" />
                  </div>
                  <div className="button_text font_color_black">Filter</div>
                </div>
                {(permission.download == "true") && (
                  <div
                    className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 export_background rounded blue_border cursorPointer ${misInvoicesList?.data?.length == 0 ||
                        misInvoicesList?.data?.length == undefined
                        ? "content_disable"
                        : null
                      }`}
                    onClick={() => onDownloadInvoiceHandler()}
                  >
                    <div className="image_container">
                      <img src={export_details} alt="" className="image_style" />
                    </div>
                    <div className="button_text font_color_blue cursorPointer">
                      Export
                    </div>
                  </div>
                )}
              </div>
              {/* </div> */}
              {misInvoicesList?.data?.length == 0 ||
                misInvoicesList?.data?.length == undefined ? (
                <div className="no_data">
                  <strong>No data found</strong>
                </div>
              ) : (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Sl no.</strong>
                      </th>
                      <th>
                        <div style={{ width: 100 }}>
                          <strong>Date</strong>
                        </div>
                      </th>
                      <th>
                        <strong>Transaction id</strong>
                      </th>
                      <th>
                        <strong>Order id</strong>
                      </th>
                      <th>
                        <strong>Bank Reference</strong>
                      </th>
                      <th>
                        <strong>Payment Mode</strong>
                      </th>
                      <th>
                        <strong>Base Price</strong>
                      </th>
                      <th>
                        <strong>SGST ( 9%)</strong>
                      </th>
                      <th>
                        <strong>CGST ( 9%)</strong>
                      </th>
                      <th>
                        <strong>Total Amount</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {misInvoicesList?.data?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.sl_no}</td>
                          <td>{item.date}</td>
                          <td>{item.transaction_id}</td>
                          <td>{item.order_id}</td>
                          <td>{item.bank_ref}</td>
                          <td>{item.payment_mode}</td>
                          <td>{item.base_price}</td>
                          <td>{item.cgst}</td>
                          <td>{item.sgst}</td>
                          <td>{item.total_amount}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}

              {misInvoicesList?.data?.length != 0 &&
                misInvoicesList?.data?.length != undefined ? (
                <div className="pagination-container">
                  <div className="page-result">
                    <p>
                      Showing: {(page - 1) * 10 + 1} -{" "}
                      {10 * page - (10 - misInvoicesList?.data?.length)} of{" "}
                      {misInvoicesList?.total_record}
                    </p>
                  </div>
                  {misInvoicesList?.total_record > 10 ? (
                    <ReactPaginate
                      containerClassName="pagination-gutter  false pagination-circle pagination pagination-sm"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      nextLabel={<i className="la la-angle-right"></i>}
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      previousLabel={<i className="la la-angle-left"></i>}
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      renderOnZeroPageCount={null}
                      activeClassName="active"
                      onPageChange={pageHandler}
                      pageRangeDisplayed={3}
                      pageCount={misInvoicesList?.total_page}
                      forcePage={page - 1}
                    />
                  ) : null}
                </div>
              ) : null}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <!-- Modal --> */}
      <Modal className="fade" show={modal} size="md">
        <Modal.Header>
          <Modal.Title>Filter</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => cancelModal()}
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="question-modal-form">
            <div className="reset-filtter-value">
              <button
                disabled={isDisabled}
                className="btn btn-info btn-xs"
                onClick={() => onResetHandler()}
              >
                Reset
              </button>
            </div>

            <div className="row">
              <div className={`col-sm-6 px-1`}>
                <div className="date-control-view session-date-control date_picker_box_height">
                  <DatePicker
                    ref={calendarRef}
                    placeholderText="Select Date"
                    selectsRange
                    selected={startDate}
                    onChange={onDateHandler}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    startDate={startDate}
                    endDate={endDate}
                    dropdownMode="select"
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    onChangeRaw={handleRawChange}
                  />
                  <button
                    className="picker-btn"
                    onClick={() => calendarRef.current.setOpen(true)}
                  >
                    <i className="fa-solid fa-calendar-days"></i>
                  </button>
                </div>
              </div>

              <div className="col-sm-6 px-1 clear-question-type mb-3">
                <input
                  className="form-control filter-form-control"
                  placeholder="Transaction Id"
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                />
              </div>

              <div className="col-sm-6 px-1 clear-question-type">
                <input
                  className="form-control filter-form-control me-2"
                  placeholder="Order Id"
                  value={orderId}
                  onChange={(e) => setOrderId(e.target.value)}
                />
              </div>

              <div className={`form-group col-sm-6 px-1 mb-3`}>
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={paymentMode}
                    onChange={(e) => setPaymentMode(e.target.value)}
                  >
                    <option value="select_payment_mode">
                      Select Payment Mode
                    </option>
                    {paymentmodeList.map((item, index) => {
                      return (
                        <option key={index} value={item.showValue}>
                          {item.showValue}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => cancelModal()} variant="danger light">
            Cancel
          </Button>
          <Button
            disabled={isDisabled}
            onClick={() => filterInvoiceHandler()}
            variant="primary"
          >
            Filter
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const mapstateToProps = (state) => {
  return {
    boardList: state.board.list,
    classList: state.class.list,
    categoryList: state.category.list,
    subjectList: state.subject.subjectList,
    subscriptionDetailsList: state.subscriptiondetails.list,
  };
};

export default connect(mapstateToProps)(Invoice);
