import React, { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link, useLocation, useHistory } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown } from "react-bootstrap";

import {
  validateEmail,
  validatePhone,
  validateName,
  inputAlphabetOnlyWithSpace,
  inputNumberOnly,
  inputAlphabetOnly,
} from "../../../utility/Utility";
import {
  loadingUserAction,
  getUserTypeData,
  updateStudentData,
} from "../../../store/actions/UserAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

const UpdateStudent = (props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  let history = useHistory();

  let errorsObj = { name: "", email: "", number: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [name, setName] = useState(state.data.fname);
  const [email, setEmail] = useState(state.data.email);
  const [number, setNumber] = useState(state.data.mobile);
  const [previousPageData, setPreviousPageData] = useState("");

  useEffect(() => {
    setPreviousPageData(state.filter);
    //dispatch(loadingUserAction(true));
    //dispatch(getUserTypeData(props.history));
  }, []);

  /* form submit */
  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (name === "") {
      errorObj.name = "Name is Required";
      error = true;
    }
    /* if (validateName(name) === false) {
			errorObj.name = 'Invalid Name';
			error = true;
		} */

    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (validateEmail(email) === true) {
      errorObj.email = "Invalid Email";
      error = true;
    }
    if (number === "") {
      errorObj.number = "Number is Required";
      error = true;
    }
    if (validatePhone(number) === false) {
      errorObj.number = "Invalid Number";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(loadingUserAction(true));
    dispatch(globalLoadingAction(true));
    dispatch(
      updateStudentData(
        state.data.id,
        name,
        email,
        number,
        state.data.lname,
        state.data.dob,
        state.data.profile_pic !== "" || state.data.profile_pic !== null
          ? state.data.profile_pic
          : "",
        state.data.gender,
        state.data.address,
        state.data.pincode,
        state.data.standard,
        state.data.board,
        state.data.school_name,
        state.data.school_address,
        state.filter,
        props.history
      )
    );
    setName("");
    setEmail("");
    setNumber("");
  }

  /* navigation go back with state */
  const onGoBackHandler = () => {
    history.push({
      pathname: "/student-list",
      state: previousPageData,
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            {/* {console.log('state....', state)} */}
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="row">
                  <div className="col-xl-3">
                    <div className="back-arrow-container">
                      <Link onClick={onGoBackHandler}>
                        <i className="fa-solid fa-arrow-left"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={onSubmit}>
                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Name<span className="text-danger">*</span>
                              </strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter the name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              onKeyPress={inputAlphabetOnlyWithSpace}
                            />
                            {errors.name && (
                              <div className="text-danger fs-12">
                                {errors.name}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Email<span className="text-danger">*</span>
                              </strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter the email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              readOnly
                            />
                            {errors.email && (
                              <div className="text-danger fs-12">
                                {errors.email}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Phone No.<span className="text-danger">*</span>
                              </strong>
                            </label>
                            <input
                              className="form-control"
                              // type='number'
                              placeholder="Enter the phone no."
                              value={number}
                              onChange={(e) => setNumber(e.target.value)}
                              onKeyPress={inputNumberOnly}
                              maxLength="10"
                            />
                            {errors.number && (
                              <div className="text-danger fs-12">
                                {errors.number}
                              </div>
                            )}
                          </div>

                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block category-btn"
                              disabled={props.showLoading}
                            >
                              Submit
                              {props.showLoading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : null}
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.user.showLoading,
    userTypeList: state.user.typeList,
  };
};

export default connect(mapStateToProps)(UpdateStudent);
