import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { connect, useDispatch } from "react-redux";
import Moment from "react-moment";
import { Link, useLocation, useHistory } from "react-router-dom";
import swal from "sweetalert";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { BsJournalMedical } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  Pagination,
  Modal,
  Button,
  Tooltip,
  Spinner,
} from "react-bootstrap";

import {
  getExamCategory,
  loadingToggleAction,
} from "../../../store/actions/ExamCategoryAction";
import { getCancelQuestionsReasonData } from "../../../store/actions/ReasonAction";
import {
  getBoardData,
  loadingBoardAction,
} from "../../../store/actions/BoardAction";
import {
  getAssignQuestionContentData,
  getAssignQuestionListData,
  deleteQuestionRequestDetailsById,
} from "../../../store/actions/AssignContentAction";
import {
  getExamType,
  loadingAction,
} from "../../../store/actions/ExamTypeAction";
import {
  getClassData,
  loadingClassAction,
} from "../../../store/actions/ClassAction";
import {
  getbranchTypeData,
  getChaptersDeffarentsParamsData,
  loadingChaptersAction,
  getChaptersDeffarentsParamsAction,
} from "../../../store/actions/ChapterAction";
import {
  getNonGroupSubjectsList,
  getNonGroupSubjectsAction,
  subjectLoading,
} from "../../../store/actions/SujectActions";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

const AssignQuestionList = (props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  let errorsObj = {
    reason: "",
    otherReason: "",
  };

  const [errors, setErrors] = useState(errorsObj);
  const [category, setCategory] = useState(
    state?.category ? state.category : ""
  );
  const [board, setBoard] = useState(state?.board ? state.board : "");
  const [examType, setExamType] = useState(
    state?.examType ? state.examType : ""
  );
  const [classroom, setClassroom] = useState(
    state?.classroom ? state.classroom : ""
  );
  const [subject, setSubject] = useState(state?.subject ? state.subject : "");
  const [chapter, setChapter] = useState(state?.chapter ? state.chapter : "");
  const [status, setStatus] = useState(state?.status ? state.status : "");
  const [approvedStatus, setApprovedStatus] = useState(
    state?.approvedStatus ? state.approvedStatus : ""
  );
  const [active, setActive] = useState(state?.page ? state.page : 1);
  const [isModal, setIsModal] = useState(false);
  const [reason, setReason] = useState(null);
  const [otherReason, setOtherReason] = useState("");
  const [recId, setRecId] = useState(null);
  const [cancelLoading, setCancelLoading] = useState(false);

  const [permission, setPermission] = useState("");

  useEffect(() => {
    dispatch(globalLoadingAction(true));
    dispatch(
      getAssignQuestionListData(
        "Q",
        category ? parseInt(category) : "",
        category == 1 && board ? parseInt(board) : "",
        category == 2 && examType ? parseInt(examType) : "",
        subject ? parseInt(subject) : "",
        classroom ? parseInt(classroom) : "",
        chapter ? parseInt(chapter) : "",
        status ? parseInt(status) : "",
        approvedStatus ? parseInt(approvedStatus) : "",
        active,
        props.history
      )
    );
  }, [category, board, examType, classroom, subject, chapter, status, active]);

  useEffect(() => {
    dispatch(getExamCategory(props.history));
    dispatch(getExamType(props.history));
    dispatch(getBoardData(props.history));
    dispatch(getClassData(props.history));
    dispatch(getCancelQuestionsReasonData(props.history));
    permissionCheck();
  }, []);

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 60) {
        console.log("60------", item)
        setPermission(item);
      }
    });
  };

  /* cancel submition */
  const onCancelSubmission = () => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (reason === "" || reason === null) {
      errorObj.reason = "Reason is Required";
      error = true;
    }
    if (reason == 0 && otherReason.trim() === "") {
      errorObj.otherReason = "Reason is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setCancelLoading(true);
    dispatch(
      deleteQuestionRequestDetailsById(
        recId,
        reason == 0 ? otherReason : reason,
        postCancelRequest,
        props.history
      )
    );
  };

  /* cancel data submit */
  const postCancelRequest = () => {
    dispatch(
      getAssignQuestionListData(
        "Q",
        category ? parseInt(category) : "",
        category == 1 && board ? parseInt(board) : "",
        category == 2 && examType ? parseInt(examType) : "",
        subject ? parseInt(subject) : "",
        classroom ? parseInt(classroom) : "",
        chapter ? parseInt(chapter) : "",
        status ? parseInt(status) : "",
        approvedStatus ? parseInt(approvedStatus) : "",
        1,
        props.history
      )
    );
    setRecId(null);
    setIsModal(false);
    setCancelLoading(false);
  };

  /* on Change category  */
  const onChangeCategoryHandler = (val) => {
    setActive(1);
    setCategory(val);
    setBoard("");
    setExamType("");
    setSubject("");
    setChapter("");
    setClassroom("");
  };

  /* on change Board */
  const onChangeBoardHandler = (val) => {
    setActive(1);
    setBoard(val);
    setSubject("");
    setChapter("");
    setClassroom("");
  };

  /* on change Type */
  const onChangeTypeHandler = (val) => {
    setActive(1);
    setExamType(val);
    setSubject("");
    setChapter("");
    setClassroom("");
  };

  /* on change subject */
  const onSubjectHandler = (e) => {
    setActive(1);
    setSubject(e);
  };

  /* on change chapter */
  const onChapterHandler = (e) => {
    setActive(1);
    setChapter(e);
  };

  /* on change class */
  const onChangeClassHandler = (val) => {
    setActive(1);
    setClassroom(val);
  };

  /* get subject list */
  const getSubjectHandler = () => {
    dispatch(subjectLoading(true));
    dispatch(
      getNonGroupSubjectsList(category, examType, 0, board, props.history)
    );
  };

  /* get chapter list */
  const getChapterHandler = () => {
    if (category == 1 && board) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          board,
          0,
          0,
          subject,
          classroom,
          props.history
        )
      );
    } else if (category == 2 && examType == 1) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          0,
          examType,
          0,
          subject,
          0,
          props.history
        )
      );
    } else if (category == 2) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          0,
          // examTypeId,
          examType,
          0,
          subject,
          classroom,
          props.history
        )
      );
    } else {
      dispatch(getChaptersDeffarentsParamsAction([]));
    }
  };

  /* pagination */
  const paginationHandler = (data) => {
    setActive(data.selected + 1);
    dispatch(globalLoadingAction(true));
    // dispatch(
    //   getAssignQuestionListData(
    //     "Q",
    //     category ? parseInt(category) : "",
    //     category == 1 && board ? parseInt(board) : "",
    //     category == 2 && examType ? parseInt(examType) : "",
    //     subject ? parseInt(subject) : "",
    //     classroom ? parseInt(classroom) : "",
    //     chapter ? parseInt(chapter) : "",
    //     status ? parseInt(status) : "",
    //     approvedStatus ? parseInt(approvedStatus) : "",
    //     data.selected + 1,
    //     props.history
    //   )
    // );
  };

  /* filter reset */
  const onResetHandler = () => {
    setCategory("");
    setBoard("");
    setExamType("");
    setSubject("");
    setChapter("");
    setClassroom("");
    setStatus("");
    setActive(1);
  };

  /* on radion button change */
  const onChangeRadioHandler = (event) => {
    setReason(event.target.value);
  };

  /* on cancel Handler */
  const onCancelHandler = (item) => {
    setRecId(item.id);
    setIsModal(true);
  };

  const onStatusHandler = (val) => {
    setActive(1);
    setStatus(val);
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="row chapter-list-view">
                <div className="col-sm-12 px-1 reset-btn-view">
                  <button
                    onClick={onResetHandler}
                    className={`btn btn-secondary ${category == "" && classroom == "" && status == ""
                      ? "content_disable"
                      : null
                      }`}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-sm-2 px-1">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-status-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      // defaultValue={"option"}
                      className="form-control filter-form-control"
                      value={category}
                      onChange={(e) => onChangeCategoryHandler(e.target.value)}
                    >
                      <option value="">Select Category</option>
                      {props.categoryList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.category}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                {category == 1 ? (
                  <div className="col-sm-2 px-1">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow add-Question-status-arrow-position">
                        <i className="bi bi-caret-down-fill"></i>
                      </span>
                      <select
                        // defaultValue={"option"}
                        className="form-control filter-form-control"
                        value={board}
                        onChange={(e) => onChangeBoardHandler(e.target.value)}
                      >
                        <option value="">Select Board</option>
                        {props.boardList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                ) : category == "" ? (
                  <div className="col-sm-2 px-1">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow add-Question-status-arrow-position">
                        <i className="bi bi-caret-down-fill"></i>
                      </span>
                      <select
                        // defaultValue={"option"}
                        className="form-control filter-form-control content_disable"
                      >
                        <option value="">Select Board/Type</option>
                      </select>
                    </div>
                  </div>
                ) : (
                  <div className="col-sm-2 px-1">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow add-Question-status-arrow-position">
                        <i className="bi bi-caret-down-fill"></i>
                      </span>
                      <select
                        // defaultValue={"option"}
                        className="form-control filter-form-control"
                        value={examType}
                        onChange={(e) => onChangeTypeHandler(e.target.value)}
                      >
                        <option value="">Select type</option>
                        {props.typeList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.type_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}

                <div className="col-sm-2 px-1">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-status-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      // defaultValue={"option"}
                      className="form-control filter-form-control"
                      value={classroom}
                      onChange={(e) => onChangeClassHandler(e.target.value)}
                      disabled={examType == 1 ? true : false}
                    >
                      <option value="">Select class</option>
                      {props.classList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.short_code}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-sm-2 px-1">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow">
                      {props.showLoadingSubject ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <i className="bi bi-caret-down-fill"></i>
                      )}
                    </span>
                    <select
                      // defaultValue={"option"}
                      //className="form-control filter-form-control"
                      className={`form-control filter-form-control ${board || examType ? null : "content_disable"
                        }`}
                      value={subject}
                      onChange={(e) => onSubjectHandler(e.target.value)}
                      onClick={getSubjectHandler}
                    >
                      <option value="">Select subject</option>
                      {props.subjectList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                {subject != "" ? (
                  <div className="col-sm-2 px-1">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow">
                        {props.showLoadingChapter ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <i className="bi bi-caret-down-fill"></i>
                        )}
                      </span>
                      <select
                        // defaultValue={"option"}
                        className="form-control filter-form-control"
                        value={chapter}
                        onChange={(e) => onChapterHandler(e.target.value)}
                        onClick={getChapterHandler}
                      >
                        <option value="">Select Chapter</option>
                        {props.chapterList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.chapter_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                ) : (
                  <div className="col-sm-2 px-1">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow">
                        <i className="bi bi-caret-down-fill"></i>
                      </span>
                      <select
                        // defaultValue={"option"}
                        className="form-control filter-form-control content_disable"
                        value={chapter}
                        onChange={(e) => onChapterHandler(e.target.value)}
                      >
                        <option value="">Select Chapter</option>
                      </select>
                    </div>
                  </div>
                )}

                <div className="col-sm-2 px-1">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow">
                      {props.subjectLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <i className="bi bi-caret-down-fill"></i>
                      )}
                    </span>
                    <select
                      // defaultValue={"option"}
                      className="form-control filter-form-control"
                      value={status}
                      onChange={(e) => onStatusHandler(e.target.value)}
                    >
                      <option value="">Select Status</option>
                      <option value="0">Pending</option>
                      <option value="1">Published</option>
                    </select>
                  </div>
                </div>
              </div>

              {props.assignList.length > 0 ? (
                <>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className="col_heading">
                          <strong>Category</strong>
                        </th>
                        <th className="col_heading">
                          <strong>Type/Board</strong>
                        </th>
                        <th className="col_heading">
                          <strong>Class</strong>
                        </th>
                        <th className="col_heading">
                          <strong>Subject</strong>
                        </th>
                        <th className="col_heading">
                          <strong>Chapter</strong>
                        </th>
                        <th className="col_heading">
                          <strong>Question Creators</strong>
                        </th>
                        <th className="col_heading">
                          <strong>Create Date</strong>
                        </th>
                        <th className="col_heading">
                          <strong>No. of questions</strong>
                        </th>
                        <th className="col_heading">
                          <strong>Update Date</strong>
                        </th>
                        <th className="col_heading">
                          <strong>Creator Status</strong>
                        </th>
                        <th className="col_heading">
                          <strong>Admin Status</strong>
                        </th>
                        {/* {
                          (permission.edit == "true" || permission.delete == "true") && ( */}
                            <th className="col_heading">
                              <strong>Action</strong>
                            </th>
                          {/* )
                        } */}
                      </tr>
                    </thead>
                    <tbody className="col_table_body">
                      {props.assignList.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.category}</td>
                            <td>
                              {item.exam_category_id == 1
                                ? item.board_name
                                : item.exam_category_id == 2
                                  ? item.type_name
                                  : ""}
                            </td>
                            <td>{item.class_code ? item.class_code : "-"}</td>
                            <td>{item.subject_name}</td>
                            <td>{item.chapter_name}</td>
                            <td>
                              <div className="chapter_col">
                                <div>
                                  <storn>Developer:</storn>{" "}
                                  {item.developer_name}
                                </div>
                                <div>Amount : {item.developer_payment}/-</div>
                                <div>
                                  Content validator:{" "}
                                  {item.validator_name != ""
                                    ? item.validator_name
                                    : "Not assigned"}
                                </div>
                                <div>
                                  Amount :{" "}
                                  {item.validator_payment != ""
                                    ? item.validator_payment + "/-"
                                    : "0/-"}
                                </div>
                                <div>
                                  Language validator:{" "}
                                  {item.language_validator_name != ""
                                    ? item.language_validator_name
                                    : "Not assigned"}
                                </div>
                                <div>
                                  Amount :{" "}
                                  {item.language_validator_payment != ""
                                    ? item.language_validator_payment + "/-"
                                    : "0/-"}
                                </div>
                                <div>
                                  Designer:{" "}
                                  {item.designer_name != ""
                                    ? item.designer_name
                                    : "Not assigned"}
                                </div>
                                <div>
                                  Amount :{" "}
                                  {item.designer_payment != ""
                                    ? item.designer_payment + "/-"
                                    : "0/-"}
                                </div>
                              </div>
                            </td>
                            <td>
                              <Moment format="D MMM YYYY" withTitle>
                                {item.created_at}
                              </Moment>
                            </td>
                            <td>
                              {item.question_no_details.length > 0 && (
                                <div className="question-column-item-value">
                                  {item.question_no_details.map(
                                    (item, index) => {
                                      return (
                                        <p>
                                          {item.type} - {item.number}
                                        </p>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </td>
                            <td>
                              <Moment format="D MMM YYYY" withTitle>
                                {item.updated_at}
                              </Moment>
                            </td>
                            <td>
                              <div
                                className={`approve_status chapter_col ${item.is_deleted == 1
                                  ? "reject_background"
                                  : item.approved_status == 0
                                    ? "pending_background"
                                    : item.developer_approval_status != 4 &&
                                      item.approved_status == 1 &&
                                      item.admin_approval_status == 0
                                      ? "approved_background"
                                      : item.developer_approval_status != 4 &&
                                        item.approved_status == 1 &&
                                        item.admin_approval_status == 1
                                        ? "approved_background"
                                        : item.developer_approval_status == 4 &&
                                          item.approved_status == 1 &&
                                          item.admin_approval_status == 1
                                          ? "published_background"
                                          : item.developer_approval_status == 4 &&
                                            item.approved_status == 1 &&
                                            item.admin_approval_status == 2
                                            ? "reject_background"
                                            : item.developer_approval_status == 4 &&
                                              item.approved_status == 1 &&
                                              item.admin_approval_status == 0
                                              ? "approved_background"
                                              : item.admin_approval_status == 2
                                                ? "reject_background"
                                                : "reject_background"
                                  }`}
                              >
                                {item.approved_status_msg}
                              </div>
                            </td>
                            <td>
                              <div
                                style={{ cursor: "default" }}
                                className={`btn-xs btn ${item.status == 1 &&
                                  item.admin_approval_status == 1 &&
                                  item.approved_status == 1 &&
                                  item.developer_approval_status == 4
                                  ? "btn-success"
                                  : "btn-warning"
                                  }`}
                              >
                                <span>
                                  {item.status == 1 &&
                                    item.admin_approval_status == 1 &&
                                    item.approved_status == 1 &&
                                    item.developer_approval_status == 4
                                    ? "Approved"
                                    : "Pending"}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="assign-question-btn-group">
                                {
                                  (permission.edit == "true") && (
                                    <OverlayTrigger
                                      delay={{ hide: 450, show: 300 }}
                                      overlay={(props) => (
                                        <Tooltip {...props}>Edit</Tooltip>
                                      )}
                                      placement="top"
                                    >
                                      {item.admin_approval_status == 1 &&
                                        item.approved_status == 1 &&
                                        item.developer_approval_status == 4 ? (
                                        <button
                                          disabled
                                          className="btn btn-warning shadow btn-xs sharp me-1"
                                        >
                                          <i className="fas fa-pencil-alt"></i>
                                        </button>
                                      ) : (item.is_deleted == 0 &&
                                        item.admin_approval_status == 1) ||
                                        (item.is_deleted == 0 &&
                                          item.approved_status == 2) ? (
                                        <Link
                                          to={{
                                            pathname:
                                              "/update-assign-question-developer",
                                            state: {
                                              ...item,
                                              filterObj: {
                                                category,
                                                board,
                                                examType,
                                                subject,
                                                classroom,
                                                chapter,
                                                status,
                                                approvedStatus,
                                                page: active,
                                              },
                                            },
                                          }}
                                          className="btn btn-warning shadow btn-xs sharp me-1"
                                        >
                                          <i className="fas fa-pencil-alt"></i>
                                        </Link>
                                      ) : (
                                        <button
                                          disabled
                                          className="btn btn-warning shadow btn-xs sharp me-1"
                                        >
                                          <i className="fas fa-pencil-alt"></i>
                                        </button>
                                      )}
                                    </OverlayTrigger>
                                  )}

                                <OverlayTrigger
                                  delay={{ hide: 450, show: 300 }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>View</Tooltip>
                                  )}
                                  placement="top"
                                >
                                  {item.admin_approval_status == 1 &&
                                    item.approved_status == 1 &&
                                    item.developer_approval_status == 4 ? (
                                    <button
                                      disabled
                                      className="btn btn-warning shadow btn-xs btn-info-color sharp me-1"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </button>
                                  ) : item.approved_status == 1 &&
                                    item.status == 1 &&
                                    item.admin_approval_status != 1 &&
                                    item.is_deleted == 0 ? (
                                    <Link
                                      to={{
                                        pathname: "/view-question-details",
                                        state: {
                                          ...item,
                                          filterObj: {
                                            category,
                                            board,
                                            examType,
                                            subject,
                                            classroom,
                                            chapter,
                                            status,
                                            approvedStatus,
                                            page: active,
                                          },
                                        },
                                      }}
                                      className="btn btn-warning shadow btn-xs btn-info-color sharp me-1"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Link>
                                  ) : (
                                    <button
                                      disabled
                                      className="btn btn-warning shadow btn-xs btn-info-color sharp me-1"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </button>
                                  )}
                                </OverlayTrigger>

                                <OverlayTrigger
                                  delay={{ hide: 450, show: 300 }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>History List</Tooltip>
                                  )}
                                  placement="top"
                                >
                                  <Link
                                    to={{
                                      pathname: "/assign-question-history",
                                      state: {
                                        ...item,
                                        filterObj: {
                                          category,
                                          board,
                                          examType,
                                          subject,
                                          classroom,
                                          chapter,
                                          status,
                                          approvedStatus,
                                          page: active,
                                        },
                                      },
                                    }}
                                    className="btn btn-secondary history_background shadow btn-xs sharp me-1"
                                  >
                                    <BsJournalMedical size={15} />
                                  </Link>
                                </OverlayTrigger>
                                {
                                  (permission.delete == "true") && (
                                    <button
                                      disabled={item.is_cancel == 0 ? true : false}
                                      className={`btn btn-secondary btn-xs`}
                                      onClick={() => onCancelHandler(item)}
                                    >
                                      Cancel
                                    </button>
                                  )
                                }
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <div className="pagination-container">
                    <div className="page-result">
                      <p>
                        Showing: {(active - 1) * 10 + 1} -{" "}
                        {10 * active - (10 - props.assignList.length)} of({" "}
                        {props.totalRecord} items)
                      </p>
                    </div>
                    {props.totalPage > 1 ? (
                      <ReactPaginate
                        containerClassName="pagination-gutter  false pagination-circle pagination pagination-sm"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        nextLabel={<i className="la la-angle-right"></i>}
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        previousLabel={<i className="la la-angle-left"></i>}
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        renderOnZeroPageCount={null}
                        activeClassName="active"
                        onPageChange={paginationHandler}
                        pageRangeDisplayed={3}
                        pageCount={props.totalPage}
                        forcePage={active - 1}
                      />
                    ) : null}
                  </div>
                </>
              ) : (
                <div className="no_data">
                  <strong>No data found</strong>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal className="fade bd-example-modal-lg" show={isModal} size="md">
        <Modal.Header>
          <Modal.Title>Reason</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="basic-form">
            <div onChange={onChangeRadioHandler} className="mb-3">
              {props.reasonList.map((item, index) => {
                return (
                  <div className="form-check" key={item.id}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="reason"
                      value={item.message}
                      id={`rejectReason${item.id}`}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`rejectReason${item.id}`}
                    >
                      {item.message}
                    </label>
                  </div>
                );
              })}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="reason"
                  value="0"
                  id="other"
                />
                <label className="form-check-label" htmlFor="other">
                  Other
                </label>
              </div>
              {errors.reason && (
                <div className="text-danger fs-12">{errors.reason}</div>
              )}
            </div>
            {reason == 0 ? (
              <div className="form-group mb-3">
                <label className="mb-2">
                  <strong>
                    Reason<span className="text-danger">*</span>
                  </strong>
                </label>
                <textarea
                  className="form-control"
                  placeholder="Please specify the reason to cancel..."
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                />
                {errors.otherReason && (
                  <div className="text-danger fs-12">{errors.otherReason}</div>
                )}
              </div>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="preview-modal-btn-view">
            <Button
              variant="success"
              disabled={cancelLoading}
              className="spinner-btn"
              onClick={() => onCancelSubmission()}
            >
              Submit
              {cancelLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.elibrary.showLoading,
    elibraryList: state.elibrary.list,
    categoryList: state.category.list,
    categoryLoading: state.category.showLoading,
    branchList: state.branch.branchList,
    branchLoading: state.branch.showLoading,
    boardLoading: state.board.showLoading,
    boardList: state.board.list,
    typeList: state.type.list,
    classList: state.class.list,
    chapterList: state.chapter.typelist,
    subjectList: state.subject.nonGroupSubjectList,
    assignList: state.assign.questionContentlist,
    totalRecord: state.assign.questionTotalRecord,
    totalPage: state.assign.questionTotalPage,
    reasonList: state.reason.cancelReasonList,
  };
};

export default connect(mapStateToProps)(AssignQuestionList);
