import { ReactChild, Fragment, useEffect, useState, useRef } from "react";

import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Modal,
  Button,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect, useSelector } from "react-redux";
import swal from "sweetalert";

import {
  MisFailedRegistrationData,
  UpdateFailedRegistrationData,
  misFailedStudentListExcelData,
  misApprovedFailedRegistrationData,
} from "../../../store/actions/FailedRegistrationAction";
import {
  misSchoolListData,
  misRegisterStudentEmailListData,
  misRegisterStudentLocationPinListData,
} from "../../../store/actions/MisRegUserAction";

import { getClassData } from "../../../store/actions/ClassAction";
import { getBoardData } from "../../../store/actions/BoardAction";

import { globalLoadingAction } from "../../../store/actions/GlobalAction";

import RankingTextInput from "../Subscription/component/RankingTextInput";

import periodList from "./json/period.json";
import mediaviaList from "./json/mediavia.json";
import subscruptionstatusList from "./json/subscruptionstatus.json";
import failedReason from "./json/failedReason.json";
import approveStatusJson from "./json/approvestatus.json";

import ReactPaginate from "react-paginate";

//images
import sort from "../../../images/dashboard_image/sort.png";
import export_details from "../../../images/dashboard_image/export.png";

import AutocompleteSearch from "../AutocompleteSearch/AutocompleteSearch";
import { contentListTotalRecordAction } from "../../../store/actions/AssignContentAction";

const FailedRegistration = (props) => {
  const moment = require("moment");
  const dispatch = useDispatch();

  const calendarRef = useRef();

  /* filter const */
  const [period, setPeriod] = useState("");
  const [registrationVia, setRegistrationVia] = useState("");
  const [board, setboard] = useState("");
  const [className, setClassName] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [registrationFailed, setRegistrationFailed] = useState("");
  const [email, setEmail] = useState("");
  const [locationPin, setLocationPin] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(1);
  const [approveStatus, setApproveStatus] = useState("")

  const [modal, setModal] = useState(false);

  const [schoolData, setSchoolData] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [locationPinData, setLocationPinData] = useState([]);

  // misSchoolList
  const misSchoolList = useSelector(
    (state) => state.MisDashboardInfo.misSchoolList
  );
  const misRegisteredStudentEmail = useSelector(
    (state) => state.MisDashboardInfo.misRegisteredStudentEmail
  );
  const misRegisteredStudentLocationPin = useSelector(
    (state) => state.MisDashboardInfo.misRegisteredStudentLocationPin
  );
  const classList = useSelector((state) => state.class.list);
  const boardList = useSelector((state) => state.board.list);

  const [tempPeriod, setTempPeriod] = useState("");
  const [temPregistrationVia, setTemPregistrationVia] = useState("");
  const [temboard, setTemboard] = useState("");
  const [temclassName, setTemclassName] = useState("");
  const [tempSchoolName, setTempSchoolName] = useState("");

  const [tempEmail, setTempEmail] = useState("");
  const [tempLocationPin, setTempLocationPin] = useState("");
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");
  const [tempRegistrationFailed, setTempRegistrationFailed] = useState("");
  const [tempApproveStatus, setTempApproveStatus] = useState("");

  const [isFiltered, setIsFiltered] = useState(false);
  const [isStatusChange, setIsStatusChange] = useState(false)
  const [permission, setPermission] = useState("");

  useEffect(() => {
    dispatch(getClassData());
    dispatch(getBoardData());
    dispatch(misSchoolListData());
    dispatch(misRegisterStudentEmailListData());
    dispatch(misRegisterStudentLocationPinListData());
    permissionCheck()
  }, []);

  useEffect(() => {
    if (period != "select_period" && period != "" && period != undefined) {
      setIsFiltered(true);
    } else if (
      registrationVia != "select_reg_via" &&
      registrationVia != "" &&
      registrationVia != undefined
    ) {
      setIsFiltered(true);
    } else if (board != "select_baord" && board != "" && board != undefined) {
      setIsFiltered(true);
    } else if (
      className != "select_class" &&
      className != "" &&
      className != undefined
    ) {
      setIsFiltered(true);
    } else if (locationPin != "" && locationPin != undefined) {
      setIsFiltered(true);
    } else if (
      registrationFailed != "select_reg_failed_reason" &&
      registrationFailed != "" &&
      registrationFailed != undefined
    ) {
      setIsFiltered(true);
    } else if (schoolName != "" && schoolName != undefined) {
      setIsFiltered(true);
    } else if (email != "" && email != undefined) {
      setIsFiltered(true);
    } else if (
      startDate === "" &&
      startDate === null &&
      startDate != undefined
    ) {
      setIsFiltered(true);
    } else if (endDate === "" && endDate === null && endDate != undefined) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
  }, [
    period,
    registrationVia,
    board,
    className,
    locationPin,
    registrationFailed,
    schoolName,
    email,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    let schoolName = [];
    let schoolNameObj = {};

    misSchoolList.forEach((elm) => {
      schoolNameObj = {};
      schoolNameObj = elm.school_name;
      schoolName.push(schoolNameObj);
    });

    setSchoolData(schoolName);

    /* -------- */
    let email = [];
    let emailObj = {};
    misRegisteredStudentEmail.forEach((elm) => {
      emailObj = {};
      emailObj = elm.email;
      email.push(emailObj);
    });
    setEmailData(email);

    /* --------------- */
    let locationPinData = [];
    let locationPinObj = {};
    misRegisteredStudentLocationPin.forEach((elm) => {
      locationPinObj = {};
      locationPinObj = elm.pincode.toString();
      locationPinData.push(locationPinObj);
    });
    setLocationPinData(locationPinData);
  }, [misSchoolList]);

  useEffect(() => {
    getList(
      period,
      board,
      className,
      locationPin,
      registrationFailed,
      page,
      registrationVia,
      schoolName,
      email,
      startDate,
      endDate,
      approveStatus
    );
  }, [isStatusChange]);

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 80) {
        console.log("80----------", item)
        setPermission(item);
      }
    });
  };

  const getList = (
    period,
    board,
    className,
    locationPin,
    registrationFailed,
    page,
    registrationVia,
    schoolName,
    email,
    startDate,
    endDate,
    approveStatus
  ) => {
    dispatch(globalLoadingAction(true));
    dispatch(
      MisFailedRegistrationData(
        period == "select_period" ? "" : period,
        board == "select_baord" ? "" : board,
        className == "select_class" ? "" : className,
        locationPin,
        registrationFailed == "select_reg_failed_reason"
          ? ""
          : registrationFailed,
        page,
        registrationVia == "select_reg_via" ? "" : registrationVia,
        schoolName,
        email,
        startDate === "" || startDate === null
          ? ""
          : moment(startDate).format("YYYY-MM-DD"),
        endDate === "" || endDate === null
          ? ""
          : moment(endDate).format("YYYY-MM-DD"),
        approveStatus == "select_status" ? "" : approveStatus
      )
    );
  };

  const onResetHandler = () => {
    setPeriod("");
    setRegistrationVia("");
    setboard("");
    setClassName("");
    setSchoolName("");
    setRegistrationFailed("");
    setEmail("");
    setLocationPin("");
    setApproveStatus("");
    setStartDate(null);
    setEndDate(null);
    setPage(1);
    dispatch(globalLoadingAction(true));
    dispatch(
      MisFailedRegistrationData("", "", "", "", "", 1, "", "", "", "", "", "")
    );
    setModal(false);
    setTempPeriod("");
    setTemPregistrationVia("");
    setTemboard("");
    setTemclassName("");
    setTempSchoolName("");
    setTempRegistrationFailed("");
    setTempEmail("");
    setTempLocationPin("");
    setTempStartDate("");
    setTempEndDate("");
    setTempApproveStatus("");

  };

  /* delete handler */
  const onDateHandler = (date) => {
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  const filterRegisteredHandler = () => {
    setModal(false);
    setPage(1);
    getList(
      period,
      board,
      className,
      locationPin,
      registrationFailed,
      1,
      registrationVia,
      schoolName,
      email,
      startDate,
      endDate,
      approveStatus
    );
    setTempPeriod(period);
    setTemPregistrationVia(registrationVia);
    setTemboard(board);
    setTemclassName(className);
    setTempSchoolName(schoolName);
    setTempRegistrationFailed(registrationFailed);
    setTempEmail(email);
    setTempLocationPin(locationPin);
    setTempStartDate(startDate);
    setTempEndDate(endDate);
    setTempApproveStatus(approveStatus)
  };

  const onDownloadFailedStudentHandler = () => {
    dispatch(
      misFailedStudentListExcelData(
        period == "select_period" ? "" : period,
        board == "select_baord" ? "" : board,
        className == "select_class" ? "" : className,
        locationPin,
        registrationFailed == "select_reg_failed_reason" ? "" : registrationFailed,
        0,
        registrationVia == "select_reg_via" ? "" : registrationVia,
        schoolName,
        email,
        startDate === "" || startDate === null
          ? ""
          : moment(startDate).format("YYYY-MM-DD"),
        endDate === "" || endDate === null
          ? ""
          : moment(endDate).format("YYYY-MM-DD"),
        approveStatus == "select_status" ? "" : approveStatus
      )
    );
  };

  /* pagination */
  const pageHandler = (data) => {
    let page = data.selected + 1;
    setPage(data.selected + 1);
    dispatch(globalLoadingAction(true));
    getList(
      period,
      board,
      className,
      locationPin,
      registrationFailed,
      page,
      registrationVia,
      schoolName,
      email,
      startDate,
      endDate,
      approveStatus
    );
    // dispatch(misFailedStudentListExcelData(period, board, className, locationPin, registrationFailed, data.selected + 1, registrationVia, schoolName, email, startDate === '' || startDate === null ? '' : moment(startDate).format("YYYY-MM-DD"), endDate === '' || endDate === null ? '' : moment(endDate).format("YYYY-MM-DD")));
  };

  const cancelModal = () => {
    setModal(false);

    if (tempPeriod == "") {
      setPeriod("");
    } else if (
      period != "select_period" &&
      period != "" &&
      period != undefined
    ) {
      setPeriod(tempPeriod);
    } else if (period == "select_period" || period == "") {
      setPeriod(tempPeriod);
    }

    if (temPregistrationVia == "") {
      setRegistrationVia("");
    } else if (
      registrationVia != "select_reg_via" &&
      registrationVia != "" &&
      registrationVia != undefined
    ) {
      setRegistrationVia(temPregistrationVia);
    } else if (registrationVia == "select_reg_via" || registrationVia == "") {
      setRegistrationVia(temPregistrationVia);
    }

    if (temboard == "") {
      setboard("");
    } else if (board != "select_baord" && board != "" && board != undefined) {
      setboard(temboard);
    } else if (board == "select_baord" || board == "") {
      setboard(temboard);
    }

    if (temclassName == "") {
      setClassName("");
    } else if (
      className != "select_class" &&
      className != "" &&
      className != undefined
    ) {
      setClassName(temclassName);
    } else if (className == "select_class" || className == "") {
      setClassName(temclassName);
    }

    if (tempSchoolName == "") {
      setSchoolName("");
    } else if (schoolName != "" && schoolName != undefined) {
      setSchoolName(tempSchoolName);
    } else if (schoolName == "") {
      setSchoolName(tempSchoolName);
    }

    if (tempRegistrationFailed == "") {
      setRegistrationFailed("");
    } else if (
      registrationFailed != "select_reg_failed_reason" &&
      registrationFailed != "" &&
      registrationFailed != undefined
    ) {
      setRegistrationFailed(tempRegistrationFailed);
    } else if (
      registrationFailed == "select_reg_failed_reason" ||
      registrationFailed == ""
    ) {
      setRegistrationFailed(tempRegistrationFailed);
    }

    if (tempEmail == "") {
      setEmail("");
    } else if (email != "" && email != undefined) {
      setEmail(tempEmail);
    } else if (email == "") {
      setEmail(tempEmail);
    }

    if (tempLocationPin == "") {
      setLocationPin("");
    } else if (locationPin != "" && locationPin != undefined) {
      setLocationPin(tempLocationPin);
    } else if (locationPin == "") {
      setLocationPin(tempLocationPin);
    }

    if (tempStartDate == "") {
      setStartDate("");
    } else if (startDate != "" && startDate != null && startDate != undefined) {
      setStartDate(tempStartDate);
    } else if (startDate == "" || startDate == null) {
      setStartDate(tempStartDate);
    }

    if (tempEndDate == "") {
      setEndDate("");
    } else if (endDate != "" && endDate != null && endDate != undefined) {
      setEndDate(tempEndDate);
    } else if (endDate == "" || endDate == null) {
      setEndDate(tempEndDate);
    }

    if (tempApproveStatus == "") {
      setApproveStatus("");
    } else if (approveStatus != "" && approveStatus != undefined) {
      setApproveStatus(tempApproveStatus);
    } else if (approveStatus == "") {
      setApproveStatus(tempApproveStatus);
    }
  };

  /* Approve confirmation */
  const approveHandeler = (item) => {
    swal({
      title: "Are you sure?",
      text:
        "You want to approve this failed registration!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(globalLoadingAction(true));
        dispatch(misApprovedFailedRegistrationData(item.id, statusChange, props.history));
      }
    })
  }

  const statusChange = () => {
    setIsStatusChange(!isStatusChange)
  }

  const periodHandle = (value) => {
    if (value != 2) {
      setStartDate("");
      setEndDate("");
    }
    setPeriod(value);
  };

  const isDisabled =
    (period === "" || period === "select_period") &&
    (board === "" || board === "select_baord") &&
    (className === "" || className === "select_class") &&
    locationPin === "" &&
    (registrationFailed === "" ||
      registrationFailed === "select_reg_failed_reason") &&
    (registrationVia === "" || registrationVia === "select_reg_via") && (approveStatus === "" || approveStatus === "select_status") &&
    schoolName === "" &&
    email === "";

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <div className="filtersAlign mt-3" style={{ marginRight: 25 }}>
              <div
                className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 me-3 filter_background rounded cursorPointer ${isFiltered ? "border_red" : null
                  }`}
                onClick={() => setModal(true)}
              >
                <div className="image_container">
                  <img src={sort} alt="" className="image_style" />
                </div>
                <div className="button_text font_color_black">Filter</div>
              </div>
              {(permission.download == "true") && (
                <div
                  className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 export_background rounded blue_border cursorPointer ${props.list?.data?.length == 0 ||
                    props.list?.data?.length == undefined
                    ? "content_disable"
                    : null
                    }`}
                  onClick={() => onDownloadFailedStudentHandler()}
                >
                  <div className="image_container">
                    <img src={export_details} alt="" className="image_style" />
                  </div>
                  <div className="button_text font_color_blue cursorPointer">
                    Export
                  </div>
                </div>
              )}
            </div>
            <Card.Body>
              {props.list?.data?.length == 0 ||
                props.list?.data?.length == undefined ? (
                <div className="no_data">
                  <strong>No data found</strong>
                </div>
              ) : (
                <>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>
                          <strong>Sl no.</strong>
                        </th>
                        <th>
                          <strong>Name</strong>
                        </th>
                        <th>
                          <strong>Class</strong>
                        </th>
                        <th>
                          <strong>Board</strong>
                        </th>
                        <th>
                          <strong>Email</strong>
                        </th>
                        <th>
                          <strong>Location Pin</strong>
                        </th>
                        <th>
                          <strong>Registration Via</strong>
                        </th>
                        <th>
                          <strong>Registration date</strong>
                        </th>
                        <th style={{ textAlign: "center" }}>
                          <strong>School Name</strong>
                        </th>
                        <th>
                          <div
                            className="table_th_font"
                            style={{ width: "100px", textAlign: 'left' }}
                          >
                            <strong>Reason</strong>
                          </div>
                        </th>
                        <th>
                          <strong>Mobile No.</strong>
                        </th>
                        {
                          permission.status == "true" && (
                            <th>
                              <strong>Action</strong>
                            </th>
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {props.list?.data?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.sl_no}</td>
                            <td>{item.student_name}</td>
                            <td>
                              {item.class == "" || item.class == 0
                                ? "-"
                                : item.class}
                            </td>
                            <td>{item.board}</td>
                            <td>{item.email}</td>
                            <td>
                              {item.location_pin == "" || item.location_pin == 0
                                ? "-"
                                : item.location_pin}
                            </td>
                            <td>{item.via_media}</td>
                            <td>{item.registration_date}</td>
                            <td>
                              {item.school_name == "" || item.school_name == 0
                                ? "-"
                                : item.school_name}
                            </td>
                            <td>
                              <div
                                className="table_th_font"
                                style={{ width: "100px", textAlign: 'left' }}
                              >{item.registration_failed_reason}
                              </div>
                            </td>
                            <td>{item.mobile_no}</td>
                            {
                              permission.status == "true" && (
                                <td>
                                  {item.status == 1 ?
                                    <div className="btn btn-danger" onClick={() => approveHandeler(item)}>
                                      Approve
                                    </div>
                                    : <div className="btn btn-success cursorNone">
                                      Approved
                                    </div>}
                                </td>
                              )}

                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </>
              )}
              {props.list?.data?.length != 0 &&
                props.list?.data != undefined ? (
                <div className="pagination-container">
                  <div className="page-result">
                    <p>
                      Showing: {(page - 1) * 10 + 1} -{" "}
                      {10 * page - (10 - props.list?.data?.length)} of{" "}
                      {props.list.total_records}
                    </p>
                  </div>
                  {props.list.total_records > 10 ? (
                    <ReactPaginate
                      containerClassName="pagination-gutter  false pagination-circle pagination pagination-sm"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      nextLabel={<i className="la la-angle-right"></i>}
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      previousLabel={<i className="la la-angle-left"></i>}
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      renderOnZeroPageCount={null}
                      activeClassName="active"
                      onPageChange={pageHandler}
                      pageRangeDisplayed={3}
                      pageCount={props.list.total_page_no}
                      forcePage={page - 1}
                    />
                  ) : null}
                </div>
              ) : null}
            </Card.Body>
          </Card>
        </Col>
        {/* <!-- Modal --> */}
        <Modal className="fade" show={modal} size="md">
          <Modal.Header>
            <Modal.Title>Filter</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => cancelModal()}
            ></Button>
          </Modal.Header>
          <Modal.Body className="p-0">
            <div className="question-modal-form">
              <div className="reset-filtter-value">
                <button
                  disabled={isDisabled}
                  className="btn btn-info btn-xs"
                  onClick={() => onResetHandler()}
                >
                  Reset
                </button>
              </div>

              <div className="row">
                {/* <div className={`form-group col-sm-6 px-1 mb-3 ${startDate == null ? null : 'content_disable'}`}> */}
                <div className={`form-group col-sm-6 px-1 mb-3`}>
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      value={period}
                      // onChange={(e) => setPeriod(e.target.value)}
                      onChange={(e) => periodHandle(e.target.value)}
                    >
                      <option value="select_period">Select Period</option>
                      {periodList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.showValue}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      value={registrationVia}
                      onChange={(e) => setRegistrationVia(e.target.value)}
                    >
                      <option value="select_reg_via">
                        Select Registration Via
                      </option>
                      {mediaviaList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.showValue}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      value={board}
                      onChange={(e) => setboard(e.target.value)}
                    >
                      <option value="select_baord">Select Board</option>
                      {boardList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      value={className}
                      onChange={(e) => setClassName(e.target.value)}
                    >
                      <option value="select_class">Select Class</option>
                      {classList?.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.class_no}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <AutocompleteSearch
                    dataDetails={schoolData}
                    placeholder={`Enter School Name`}
                    setNewValue={(e) => setSchoolName(e)}
                    selectValue={schoolName}
                  />
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      value={registrationFailed}
                      onChange={(e) => setRegistrationFailed(e.target.value)}
                    >
                      <option value="select_reg_failed_reason">
                        Select Reg. Failed Reason
                      </option>
                      {failedReason.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.showValue}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <AutocompleteSearch
                    dataDetails={emailData}
                    placeholder={`Enter Email`}
                    setNewValue={(e) => setEmail(e)}
                    selectValue={email}
                  />
                </div>

                <div className="form-group col-sm-6 px-1 mb-3">
                  <AutocompleteSearch
                    dataDetails={locationPinData}
                    placeholder={`Enter Location Pin`}
                    setNewValue={(e) => setLocationPin(e)}
                    selectValue={locationPin}
                  />
                </div>

                {/* <div className={`col-sm-6 px-1 ${period == '' ? null : 'content_disable'}`}> */}
                {period == 2 ? (
                  <div className={`col-sm-6 px-1`}>
                    <div className="date-control-view session-date-control">
                      <DatePicker
                        ref={calendarRef}
                        placeholderText="Select Date Range"
                        selectsRange
                        selected={startDate}
                        onChange={onDateHandler}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        startDate={startDate}
                        endDate={endDate}
                        dropdownMode="select"
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                      />
                      <button
                        className="picker-btn"
                        onClick={() => calendarRef.current.setOpen(true)}
                      >
                        <i className="fa-solid fa-calendar-days"></i>
                      </button>
                    </div>
                  </div>
                ) : null}

                <div className="form-group col-sm-6 px-1 mb-3">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      value={approveStatus}
                      onChange={(e) => setApproveStatus(e.target.value)}
                    >
                      <option value="select_status">
                        Select status
                      </option>
                      {approveStatusJson.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.showValue}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => cancelModal()} variant="danger light">
              Cancel
            </Button>
            <Button
              disabled={isDisabled}
              onClick={() => filterRegisteredHandler()}
              variant="primary"
            >
              Filter
            </Button>
          </Modal.Footer>
        </Modal>

        {/* ------------------ */}
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.MisFailedRegistration.list,
  };
};

export default connect(mapStateToProps)(FailedRegistration);
