import {
    DASHBOARD_DETAILS,
    DASHBOARD_DATA_LOADING
} from '../constants';

const initialState = {
    dashboardDetailsList: [],
    dashboardShowLoading: false,
};

export function DashboardReducer(state = initialState, action) {

    if (action.type === DASHBOARD_DETAILS) {
        return {
            ...state,
            dashboardDetailsList: action.payload,
            dashboardShowLoading:false
        };
    }
    if (action.type === DASHBOARD_DATA_LOADING) {
        return {
            ...state,
            dashboardShowLoading: action.payload,
        };
    }

    return state;
}


