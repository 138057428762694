import { combineReducers } from "redux";
import { GlobalReducer } from "./GlobalReducer";
import { AuthReducer } from "./AuthReducer";
import { UserReducer } from "./UserReducer";
import { ExamCategoryReducer } from "./ExamCategoryReducer";
import { ExamTypeReducer } from "./ExamTypeReducer";
import { ExamSubTypeReducer } from "./ExamSubTypeReducer";
import { BoardReducer } from "./BoardReducer";
import { ClassReducer } from "./ClassReducer";
import { BranchReducer } from "./BranchReducer";
import { SubjectReducer } from "./SubjectReducer";
import { QuestionPatternReducer } from "./QuestionPatternReducer";
import { ChapterReducer } from "./ChapterReducer";
import { ExamContentReducer } from "./ExamContentReducer";
import { TimeConfigurationReducer } from "./TimeConfigurationReducer";
import { ExamConfigurationReducer } from "./ExamConfigurationReducer";
import { SubscriptionReducer } from "./SubscriptionReducer";
import { ElibraryReducer } from "./ElibraryReducer";
import { ContentValidatorReducer } from "./ContentValidatorReducer";
import { ContentDesignerReducer } from "./ContentDesignerReducer";
import { ContentLanguageValidatorReducer } from "./ContentLanguageValidatorReducer";
import { EventHistoryReducer } from "./EventHistoryReducer";
import { ProfileReducer } from "./ProfileReducer";
import { AssignContentReducer } from "./AssignContentReducer";
import { TypeExamReducer } from "./TypeExamReducer";
import { ExamDetailsReducer } from "./ExamDetailsReducer";
import { SchoolReducer } from "./SchoolReducer";
import { SettingsReducer } from "./SettingsReducer";
import { FeedbackReducer } from "./FeedbackReducer";
import { PermissionReducer } from "./PermissionReducer";
import { MisRegUserReducer } from "./MisRegUserReducer";
import { MisFailedRegistrationReducer } from "./MisRegFailedReducer";
import { subscriptiondetailsReducer } from "./SubscriptionDetailsReducer";
import { FailedSubscriptionReducer } from "./FailedSubscriptionReducer";
import { MisDashboardInfoReducer } from "./MisDashboardInfoReducer";
import { subscribedUserActivityReducer } from "./SubscribedUserActivityReducer";
import { LocationReducer } from "./LocationReducer";
import { ResumeReducer } from "./ResumeReducer";
import { AdvertisementReducer } from "./AdvertisementReducer";
import { ReasonReducer } from "./ReasonReducer";
import { AcademicReducer } from "./AcademicReducer";
import { DashboardReducer } from "./DashboardReducer";
import { PaymentReducer } from "./PaymentReducer";

const rootReducers = combineReducers({
  global: GlobalReducer,
  auth: AuthReducer,
  user: UserReducer,
  category: ExamCategoryReducer,
  type: ExamTypeReducer,
  subtype: ExamSubTypeReducer,
  board: BoardReducer,
  class: ClassReducer,
  branch: BranchReducer,
  subject: SubjectReducer,
  pattern: QuestionPatternReducer,
  chapter: ChapterReducer,
  content: ExamContentReducer,
  time: TimeConfigurationReducer,
  ExamConfiguration: ExamConfigurationReducer,
  subscription: SubscriptionReducer,
  elibrary: ElibraryReducer,
  contentvalidator: ContentValidatorReducer,
  contentdesigner: ContentDesignerReducer,
  languagevalidator: ContentLanguageValidatorReducer,
  eventhistory: EventHistoryReducer,
  profile: ProfileReducer,
  assign: AssignContentReducer,
  typeExam: TypeExamReducer,
  examDetails: ExamDetailsReducer,
  school: SchoolReducer,
  settings: SettingsReducer,
  feedback: FeedbackReducer,
  permission: PermissionReducer,
  MisRegUser: MisRegUserReducer,
  MisFailedRegistration: MisFailedRegistrationReducer,
  subscriptiondetails: subscriptiondetailsReducer,
  FailedSubscription: FailedSubscriptionReducer,
  MisDashboardInfo: MisDashboardInfoReducer,
  subscribedUserActivity: subscribedUserActivityReducer,
  Location: LocationReducer,
  Resume: ResumeReducer,
  advertisement: AdvertisementReducer,
  academic: AcademicReducer,
  reason: ReasonReducer,
  payment: PaymentReducer,
  dashboard: DashboardReducer,
});

export default rootReducers;
