import React, { useEffect, useState, Fragment, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { globalLoadingAction } from "../../../../store/actions/GlobalAction";
import {
  addAssignContentData,
  assignDataClearAction,
} from "../../../../store/actions/AssignContentAction";
import {
  getExamSubTypeById,
  loadingSubTypeAction,
} from "../../../../store/actions/ExamSubTypeAction";
import {
  loadingUserAction,
  getUserTypeData,
  getDeveloperData,
} from "../../../../store/actions/UserAction";
import { getExamCategory } from "../../../../store/actions/ExamCategoryAction";
import { getBoardData } from "../../../../store/actions/BoardAction";
import { getExamType } from "../../../../store/actions/ExamTypeAction";
import { getClassData } from "../../../../store/actions/ClassAction";
import {
  subjectLoading,
  getSingleSubjectListDetails,
  getSingleSubjectListActions,
} from "../../../../store/actions/SujectActions";

import {
  getChaptersDeffarentsParamsData,
  loadingChaptersAction,
  getChaptersDeffarentsParamsAction,
} from "../../../../store/actions/ChapterAction";

const Elibrary = (props) => {
  const dispatch = useDispatch();
  const calendarRef = useRef();

  let errorsObj = {
    category: "",
    board: "",
    examType: "",
    subType: "",
    subject: "",
    classroom: "",
    chapter: "",
    developer: "",
    payment: "",
    date: "",
  };

  const [errors, setErrors] = useState(errorsObj);

  const [category, setCategory] = useState("");
  const [board, setBoard] = useState("");
  const [examType, setExamType] = useState("");
  const [subject, setsubject] = useState("");
  const [classroom, setClassroom] = useState("");
  const [chapter, setChapter] = useState("");
  const [developer, setDeveloper] = useState("");
  const [payment, setPayment] = useState("");
  const [submissionDate, setSubmissionDate] = useState("");

  useEffect(() => {
    dispatch(getExamCategory(props.history));
    dispatch(getClassData(props.history));
    dispatch(getExamType(props.history));
    dispatch(getBoardData(props.history));
    dispatch(getUserTypeData(props.history));
    dispatch(getDeveloperData(0, "", "", "", props.history));
  }, []);

  /* form submit */
  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (category === "") {
      errorObj.category = "Category is Required";
      error = true;
    }
    if (board === "" && (category == 1 || category == "")) {
      errorObj.board = "Board is Required";
      error = true;
    }
    if (examType === "" && category == 2) {
      errorObj.examType = "Examtype is Required";
      error = true;
    }
    if (subject === "") {
      errorObj.subject = "Subject is Required";
      error = true;
    }
    if (chapter === "") {
      errorObj.chapter = "Chapter is Required";
      error = true;
    }
    if (classroom === "" && examType != 1) {
      errorObj.classroom = "Class is Required";
      error = true;
    }

    if (submissionDate === "") {
      errorObj.date = "Submission Date is Required";
      error = true;
    }
    if (payment === "") {
      errorObj.payment = "Amount is Required";
      error = true;
    } else if (payment <= 0) {
      errorObj.payment = "Valid Amount is Required";
      error = true;
    }
    if (developer === "") {
      errorObj.developer = "Developer is Required";
      error = true;
    }
    // console.log("errorObj---", errorObj)

    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(globalLoadingAction(true));
    dispatch(
      addAssignContentData(
        category ? parseInt(category) : 0,
        category == 1 ? parseInt(board) : 0,
        category == 2 ? parseInt(examType) : 0,
        subject ? parseInt(subject) : 0,
        examType == 1 ? 0 : parseInt(classroom),
        chapter ? parseInt(chapter) : 0,
        submissionDate ? moment(submissionDate).format("YYYY-MM-DD") : "",
        payment ? parseInt(payment) : 0,
        developer ? parseInt(developer) : 0,
        onClear,
        props.history
      )
    );
  }

  const onClear = () => {
    setClassroom("");
    setChapter("");
    setDeveloper("");
    setCategory("");
    setBoard("");
    setExamType("");
    setPayment("");
    setSubmissionDate("");
    setsubject("");
  };

  /* select category Handeler */
  const selectCategoryHandeler = (e) => {
    dispatch(getSingleSubjectListActions([]));
    setCategory(e.target.value);
    setExamType("");
    setClassroom("");
    setBoard("");
    setChapter("");
  };

  /* select exam type handler */
  const selectExamTypeHandler = (examType) => {
    setExamType(examType);
    setClassroom("");
    setsubject("");
    setChapter("");
    dispatch(subjectLoading(true));
    dispatch(
      getSingleSubjectListDetails(0, 0, examType, 0, category, props.history)
    );
  };

  /* select board handler */
  const selectBoardHandler = (board) => {
    setBoard(board);
    dispatch(subjectLoading(true));
    setClassroom("");
    setChapter("");
    setsubject("");
    dispatch(
      getSingleSubjectListDetails(0, board, 0, 0, category, props.history)
    );
  };

  /* select chapter handler */
  const getChapterHandler = () => {
    dispatch(loadingChaptersAction(true));
    dispatch(
      getChaptersDeffarentsParamsData(
        category ? parseInt(category) : 0,
        category == 1 ? board : 0,
        category == 2 ? examType : 0,
        0,
        subject ? subject : 0,
        category == 1 || examType == 2 ? classroom : 0,
        props.history
      )
    );
  };

  const onPaymentHandler = (val) => {
    const regex = /^[0-9\b]+$/;
    if (val === "" || regex.test(val)) {
      setPayment(val);
    }
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  return (
    <Fragment>
      <div className="basic-form">
        <form onSubmit={onSubmit}>
          <div className="form-group mb-3">
            <label className="mb-2">
              <strong>
                Category<span className="text-danger">*</span>
              </strong>
            </label>
            <div className="basic-dropdown">
              <span className="drop_down_arrow">
                <i className="bi bi-caret-down-fill"></i>
              </span>

              <select
                defaultValue={"option"}
                className="form-control form-control"
                value={category}
                onChange={(e) => selectCategoryHandeler(e)}
              >
                <option value="">Select Category</option>
                {props.categoryList.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.category}
                    </option>
                  );
                })}
              </select>
              {errors.category && (
                <div className="text-danger fs-12">{errors.category}</div>
              )}
            </div>
          </div>

          {category == 2 ? (
            <div className="form-group mb-3">
              <label className="mb-2">
                <strong>
                  Exam Type<span className="text-danger">*</span>
                </strong>
              </label>
              <div className="basic-dropdown">
                <span className="drop_down_arrow">
                  <i className="bi bi-caret-down-fill"></i>
                </span>

                <select
                  defaultValue={"option"}
                  className="form-control form-control"
                  value={examType}
                  onChange={(e) => selectExamTypeHandler(e.target.value)}
                >
                  <option value="">Select Examtype</option>
                  {props.typeList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.type_name}
                      </option>
                    );
                  })}
                </select>
                {errors.examType && (
                  <div className="text-danger fs-12">{errors.examType}</div>
                )}
              </div>
            </div>
          ) : (
            <div className="form-group mb-3">
              <label className="mb-2">
                <strong>
                  Board<span className="text-danger">*</span>
                </strong>
              </label>
              <div className="basic-dropdown">
                <span className="drop_down_arrow">
                  <i className="bi bi-caret-down-fill"></i>
                </span>

                <select
                  defaultValue={"option"}
                  className="form-control form-control"
                  value={board}
                  onChange={(e) => selectBoardHandler(e.target.value)}
                >
                  <option value="">Select Board</option>
                  {props.boardList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {errors.board && (
                  <div className="text-danger fs-12">{errors.board}</div>
                )}
              </div>
            </div>
          )}

          {examType == 1 ? null : (
            <div className="form-group mb-3">
              <label className="mb-2">
                <strong>
                  Class<span className="text-danger">*</span>
                </strong>
              </label>
              <div className="basic-dropdown">
                <span className="drop_down_arrow">
                  <i className="bi bi-caret-down-fill"></i>
                </span>

                <select
                  defaultValue={"option"}
                  className="form-control form-control"
                  value={classroom}
                  onChange={(e) => {
                    setClassroom(e.target.value);
                    setChapter("");
                    setsubject("");
                  }}
                >
                  <option value="">Select Class</option>
                  {props.classList.map((item, index) => {
                    return (
                      <option key={index} value={item.class_no}>
                        {item.short_code}
                      </option>
                    );
                  })}
                </select>
                {errors.classroom && (
                  <div className="text-danger fs-12">{errors.classroom}</div>
                )}
              </div>
            </div>
          )}

          <div className="form-group mb-3">
            <label className="mb-2">
              <strong>
                Subjects<span className="text-danger">*</span>
              </strong>
            </label>
            <div className="basic-dropdown">
              <span className="drop_down_arrow">
                {props.subjectshowLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <i className="bi bi-caret-down-fill"></i>
                )}
              </span>

              <select
                defaultValue={"option"}
                className="form-control form-control"
                value={subject}
                onChange={(e) => {
                  setsubject(e.target.value);
                  setChapter("");
                }}
              >
                <option value="">Select Subject</option>
                {true &&
                  props.getSingleSubjectList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              {errors.subject && (
                <div className="text-danger fs-12">{errors.subject}</div>
              )}
            </div>
          </div>

          <div className="form-group mb-3">
            <label className="mb-2">
              <strong>
                Chapter<span className="text-danger">*</span>
              </strong>
            </label>
            <div className="basic-dropdown">
              <span className="drop_down_arrow">
                {props.showLoadingChapter ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <i className="bi bi-caret-down-fill"></i>
                )}
              </span>

              <select
                disabled={!subject}
                defaultValue={"option"}
                className="form-control form-control"
                value={chapter}
                onChange={(e) => setChapter(e.target.value)}
                onClick={getChapterHandler}
              >
                <option value="">Select Chapter</option>
                {props.chapterList.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.chapter_name}
                    </option>
                  );
                })}
              </select>
              {errors.chapter && (
                <div className="text-danger fs-12">{errors.chapter}</div>
              )}
            </div>
          </div>

          <div className="form-group mb-3">
            <label className="mb-2">
              <strong>
                Content Developer<span className="text-danger">*</span>
              </strong>
            </label>
            <div className="basic-dropdown">
              <span className="drop_down_arrow">
                <i className="bi bi-caret-down-fill"></i>
              </span>

              <select
                defaultValue={"option"}
                className="form-control form-control"
                value={developer}
                onChange={(e) => setDeveloper(e.target.value)}
              >
                <option value="">Select Content Developer</option>
                {props.developerlist.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {errors.developer && (
                <div className="text-danger fs-12">{errors.developer}</div>
              )}
            </div>
          </div>

          <div className="form-group mb-3">
            <label className="mb-2">
              <strong>
                Time Limit<span className="text-danger">*</span>
              </strong>
            </label>
            <div className="date-control-view">
              <DatePicker
                ref={calendarRef}
                selected={submissionDate}
                placeholderText="Enter the Submission Date"
                onChange={(date) => setSubmissionDate(date)}
                showYearDropdown
                showMonthDropdown
                // minDate={new Date() }
                minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                onChangeRaw={handleDateChangeRaw}
              />
              <button
                type="button"
                className="picker-btn"
                onClick={() => calendarRef.current.setOpen(true)}
              >
                <i className="fa-solid fa-calendar-days"></i>
              </button>
            </div>
            {errors.date && (
              <div className="text-danger fs-12">{errors.date}</div>
            )}
          </div>

          <div className="form-group mb-3">
            <label className="mb-2">
              <strong>
                Amount<span className="text-danger">*</span>
              </strong>
            </label>
            <input
              className="form-control place_Holder"
              placeholder="Enter the Amount"
              value={payment}
              onChange={(e) => onPaymentHandler(e.target.value)}
              maxLength="5"
            />
            {errors.payment && (
              <div className="text-danger fs-12">{errors.payment}</div>
            )}
          </div>

          <div className="text-center mt-4">
            <button
              type="submit"
              className="btn btn-primary btn-block category-btn"
              disabled={props.showLoading}
            >
              Assign
              {props.showLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.assign.showLoading,
    assignDataClear: state.assign.assignDataClear,
    chapterList: state.chapter.typelist,
    showLoadingChapter: state.chapter.showLoading,
    classList: state.class.list,
    boardList: state.board.list,
    categoryList: state.category.list,
    typeList: state.type.list,
    patternList: state.pattern.list,
    developerlist: state.user.contentDeveloperList,
    subjectList: state.subject.subjectList,
    subjectshowLoading: state.subject.showLoading,
    getSingleSubjectList: state.subject.getSingleSubjectList,
  };
};
export default connect(mapStateToProps)(Elibrary);
