import { Fragment, useEffect, useState, useRef } from "react";
import { Row, Col, Card, Table, Modal, Button } from "react-bootstrap";
import { useDispatch, connect, useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";

import {
  misContentMisContentDetailsData,
} from "../../../store/actions/MisDashboardInfoAction";

import { globalLoadingAction } from "../../../store/actions/GlobalAction";

import { getClassData } from "../../../store/actions/ClassAction";
import { getBoardData } from "../../../store/actions/BoardAction";

import {
  misRegisterStudentEmailListData,
} from "../../../store/actions/MisRegUserAction";

const SubscribedUsers = (props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const misRegisteredStudentEmail = useSelector((state) => state.MisDashboardInfo.misRegisteredStudentEmail);
  const misContentMisContentDetailsList = useSelector((state) => state.MisDashboardInfo.misContentMisContentDetailsList);

  //
  const [emailData, setEmailData] = useState([])

  useEffect(() => {
    console.log("state---------", state)
    dispatch(getClassData());
    dispatch(getBoardData());
    dispatch(misRegisterStudentEmailListData())
  }, []);


  useEffect(() => {
    getList()
  }, []);

  useEffect(() => {

    /* -------- */
    let email = [];
    let emailObj = {}
    misRegisteredStudentEmail.forEach((elm) => {
      emailObj = {}
      emailObj = elm.email
      email.push(emailObj);
    })
    setEmailData(email)

  }, [misRegisteredStudentEmail]);

  const getList = () => {
    dispatch(globalLoadingAction(true));
    dispatch(
      misContentMisContentDetailsData(
        state?.item?.content_id,
        state?.item?.request_type,
      )
    );
  };

  /* navigation go back with state */
  const onGoBackHandler = () => {
    props.history.push({
      pathname: "/content-mis",
      state: {page: state?.page, previousPageFilterData: state?.filterData },
    });
  };

  return (
    <Fragment>
      <Row>
        {/* {console.log("misContentMisContentDetailsList==========", misContentMisContentDetailsList)} */}
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div
                className="add-filter-group"
                style={{ justifyContent: "space-between" }}
              >
                <div className="back-arrow-container">
                  <Link onClick={onGoBackHandler}>
                    <i class="fa-solid fa-arrow-left"></i>
                  </Link>
                </div>

                <div className="filtersAlign mt-3" style={{ marginRight: 25 }}>

                </div>
              </div>

              <div className="student_details_container ">
                <div className="student_details_child_container ">
                  <div className="row">
                    <div className="form-group col-sm-6 px-1 mb-3 d-flex flex-row">
                      <div className="label_container">
                        <strong>Subject : </strong>
                      </div>
                      <div>
                        {state?.item?.subject_name}
                      </div>
                    </div>
                    <div className="form-group col-sm-6 px-1 mb-3 d-flex flex-row">
                      <div className="label_container">
                        <strong>Stage : </strong>
                      </div>
                      <div>
                        {state?.item?.stage}
                      </div>
                    </div>

                    <div className="form-group col-sm-6 px-1 mb-3 d-flex flex-row">
                      <div className="label_container">
                        <strong>Chapter Code :</strong>
                      </div>
                      <div>
                        {state?.item?.chapter_code}
                      </div>
                    </div>

                    <div className="form-group col-sm-6 px-1 mb-3 d-flex flex-row">
                      <div className="label_container">
                        <strong>Assign To :</strong>
                      </div>
                      <div>
                        {state?.item?.assign_to}
                      </div>
                    </div>

                    <div className="form-group col-sm-6 px-1 mb-3 d-flex flex-row">
                      <div className="label_container">
                        <strong>Chapter Name :</strong>
                      </div>
                      <div>
                        {state?.item?.chapter_name}
                      </div>
                    </div>

                    <div className="form-group col-sm-6 px-1 mb-3 d-flex flex-row">
                      <div className="label_container">
                        <strong>Status :</strong>
                      </div>
                      <div>
                        {state?.item?.status}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {misContentMisContentDetailsList != '' && state?.item?.request_type == 'E' ?
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Type of CM</strong>
                      </th>
                      <th>
                        <strong>No of Pages</strong>
                      </th>
                      <th>
                        <strong>List of Citation</strong>
                      </th>
                      <th>
                        <strong>Hyperlink</strong>
                      </th>

                    </tr>
                  </thead>
                  <tbody>
                    {misContentMisContentDetailsList?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.type_of_cm}</td>
                          <td>{item.no_of_pages}</td>
                          <td>{item.list_of_citation}</td>
                          <td>{item.hyperlink}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                : misContentMisContentDetailsList != '' && state?.item?.request_type == 'Q' ?
                  < Table responsive>
                    <thead>
                      <tr>
                        <th>
                          <strong>No. of SWA</strong>
                        </th>
                        <th>
                          <strong>No. of DES</strong>
                        </th>
                        <th>
                          <strong>No. of HOTS</strong>
                        </th>
                        <th>
                          <strong>No. of CS</strong>
                        </th>
                        <th>
                          <strong>No. of PQS</strong>
                        </th>
                        <th>
                          <strong>Total no. of Questions</strong>
                        </th>

                      </tr>
                    </thead>
                    <tbody>

                      {misContentMisContentDetailsList?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.no_of_swa}</td>
                            <td>{item.no_of_des}</td>
                            <td>{item.no_of_hots}</td>
                            <td>{item.no_of_cs}</td>
                            <td>{item.no_of_pqs}</td>
                            <td>{item.total_questions}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  :
                  <div className="no_data">
                    <strong>No data found</strong>
                  </div>
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment >
  );
};

const mapstateToProps = (state) => {
  return {

  };
};

export default connect(mapstateToProps)(SubscribedUsers);
