import swal from "sweetalert";
import {
    getUserType,
    addAdmin,
    addTeacher,
    getAdmin,
    getContentDeveloper,
    getStudent,
    getTeacher,
    updateAdmin,
    updateStudent,
    updateContentDeveloper,
    updaTeacher,
    removeUser,
    removeAdmin,
    removeContentDeveloper,
    removeStudent,
    removeTeacher,
    adminStatus,
    developerStatus,
    studentStatus,
    teacherStatus,
    addDeveloper,
    getDistrict,
    getState,
    addRole,
    getRoles,
    deleteRole,
    updateRole,
    createUsersDropdownData,
    getAllDeveloper
} from '../../services/UserServices';

import {
    USER_LOADING,
    GET_USER_TYPE,
    ADD_USER,
    ADD_TEACHER,
    GET_ADMIN_LIST,
    GET_CONTENT_DEVELOPER_LIST,
    GET_STUDENT_LIST,
    GET_TEACHER_LIST,
    UPDATE_ADMIN_LIST,
    UPDATE_CONTENT_DEVELOPER_LIST,
    UPDATE_STUDENT_LIST,
    UPDATE_TEACHER_LIST,
    REMOVE_USER,
    REMOVE_CONTENT_DEVELOPER,
    REMOVE_ADMIN,
    REMOVE_STUDENT,
    REMOVE_TEACHER,
    UPDATE_ADMIN_STATUS_LIST,
    UPDATE_DEVELOPER_STATUS_LIST,
    UPDATE_STUDENT_STATUS_LIST,
    UPDATE_TEACHER_STATUS_LIST,
    GET_DISTRICT_LIST,
    GET_STATE_LIST,
    GET_ROLE_LIST,
    CREATE_USERS_DROPDOWN_DATA,
    GET_ALL_DEVELOPER_LIST
} from '../constants';

import * as utility from '../../utility/Utility';
import { globalLoadingAction } from '../actions/GlobalAction';


export function getUserTypeData(history) {
    return (dispatch) => {
        getUserType()
            .then((response) => {
                dispatch(getUserTypeAction(response.data.data));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function addAdminData(userType, firstName, middleName, lastName, dob, gender, number, email, houseName, location, city, district, state, pincode, panNo, aadhar, qualification, experience, computerKnowledge, academicYear, enrollment, menuList, RollId, history) {
    return (dispatch) => {
        addAdmin(userType, firstName, middleName, lastName, dob, gender, number, email, houseName, location, city, district, state, pincode, panNo, aadhar, qualification, experience, computerKnowledge, academicYear, enrollment, menuList, RollId)
            .then((response) => {
                //console.log('response...r', response.data);
                dispatch(addUserAction(true));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);   
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function addDeveloperData(userType, firstName, middleName, lastName, dob, gender, number, email, houseName, location, city, district, state, state_id, pincode, panNo, aadhar, qualification, experience, subjectPreference, boardPreference, classPreference, timeSlotPreference, language, computerKnowledge, academicYear, enrollment, rollTime, board, standard, schoolName, inchargeName, menuList, history) {
    return (dispatch) => {
        addDeveloper(userType, firstName, middleName, lastName, dob, gender, number, email, houseName, location, city, district, state, state_id, pincode, panNo, aadhar, qualification, experience, subjectPreference, boardPreference, classPreference, timeSlotPreference, language, computerKnowledge, academicYear, enrollment, rollTime, board, standard, schoolName, inchargeName, menuList)
            .then((response) => {
                dispatch(addUserAction(true));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);   
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function addTeacherData(name, email, number, gender, history) {
    return (dispatch) => {
        addTeacher(name, email, number, gender)
            .then((response) => {

                dispatch(addTeacherAction(true));
                utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getAdminData(page, name, email, phoneno, history) {
    return (dispatch) => {
        getAdmin(page, name, email, phoneno)
            .then((response) => {
                dispatch(getAdminAction(response.data));
                //utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingUserAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function getDeveloperData(page, name, email, phoneno, history) {
    return (dispatch) => {
        getContentDeveloper(page, name, email, phoneno)
            .then((response) => {
                dispatch(getDeveloperAction(response.data));
                //utility.showSuccess(response.data.msg);
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingUserAction(false));
                utility.showError(error.response.data);
            });
    };
}
export function getStudentData(page, name, email, phoneno, start_date, end_date, history) {
    return (dispatch) => {
        getStudent(page, name, email, phoneno, start_date, end_date)
            .then((response) => {
                if (response.data.status == 200) {
                    dispatch(getStudentAction(response.data));
                } else {
                    dispatch(getStudentAction(response.data));
                    //utility.showError(response.data.msg);
                }
                dispatch(globalLoadingAction(false));

            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}
export function getTeacherData(page, name, email, phoneno, history) {
    return (dispatch) => {
        getTeacher(page, name, email, phoneno)
            .then((response) => {
                dispatch(getTeacherAction(response.data));
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function updateAdminData(id, firstName, middleName, lastName, dob, gender, number, email, houseName, location, city, district, state, pincode, panNo, aadhar, qualification, experience, knowledge, academicYear, enrollmentDate, menuList, RollId, history) {
    return (dispatch) => {
        updateAdmin(id, firstName, middleName, lastName, dob, gender, number, email, houseName, location, city, district, state, pincode, panNo, aadhar, qualification, experience, knowledge, academicYear, enrollmentDate, menuList, RollId)
            .then((response) => {
                dispatch(updateAdminAction(response.data.data));
                utility.showSuccess(response.data.msg);
                history.goBack();
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingUserAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function updateDeveloperData(id, userType, firstName, middleName, lastName, dob, gender, number, houseName, location, city, district, state, state_id, pincode, panNo, aadhar, qualification, experience, subjectPreference, boardPreference, classPreference, timeSlotPreference, language, computerKnowledge, academicYear, enrollment, rollTime, board, standard, schoolName, inchargeName, menuList, history) {
    return (dispatch) => {
        updateContentDeveloper(id, userType, firstName, middleName, lastName, dob, gender, number, houseName, location, city, district, state, state_id, pincode, panNo, aadhar, qualification, experience, subjectPreference, boardPreference, classPreference, timeSlotPreference, language, computerKnowledge, academicYear, enrollment, rollTime, board, standard, schoolName, inchargeName, menuList)
            .then((response) => {
                // console.log('response------111',response.data)
                dispatch(updateContentDeveloperAction(response.data.data));
                utility.showSuccess(response.data.msg);
                history.goBack();
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingUserAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function updateStudentData(id, name, email, phone,lname,dob,profile_pic,gender,address,pincode,standard,board,school_name,school_address, filter, history) {
    return (dispatch) => {
        updateStudent(id, name, email, phone,lname,dob,profile_pic,gender,address,pincode,standard,board,school_name,school_address)
            .then((response) => {
                dispatch(updateStudentAction(response.data.data));
                utility.showSuccess(response.data.msg);
                history.push({
                    pathname: '/student-list',
                    state: filter
                });
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingUserAction(false));
                utility.showError(error.response.data);
            });
    };
}
export function updateTeacherData(id, name, email, phone, gender, filter, history) {
    return (dispatch) => {
        updaTeacher(id, name, email, phone, gender)
            .then((response) => {
                dispatch(updateTeacherAction(response.data.data));
                utility.showSuccess(response.data.msg);
                //history.goBack();
                history.push({
                    pathname: '/teacher-list',
                    state: filter
                });
            })
            .catch((error) => {
                //console.log(error);
                dispatch(loadingUserAction(false));
                utility.showError(error.response.data);
            });
    };
}

export function updateAdminStatus(item, history) {
    return (dispatch) => {
        adminStatus(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                dispatch(updateAdminStatusAction(item));
                utility.showSuccess(response.data.msg);
            })
            .catch((error) => {
                utility.showError(error.response.data);
            });
    };
}

export function updateDeveloperStatus(item, history) {
    return (dispatch) => {
        developerStatus(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                dispatch(updateDeveloperStatusAction(item));
                utility.showSuccess(response.data.msg)
            })
            .catch((error) => {
                utility.showError(error.response.data);
            });
    };
}

export function updateStudentStatus(item, history) {
    return (dispatch) => {
        studentStatus(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                dispatch(updateStudentStatusAction(item));
                utility.showSuccess(response.data.msg)
            })
            .catch((error) => {
                utility.showError(error.response.data);
            });
    };
}

export function updateTeacherStatus(item, history) {
    return (dispatch) => {
        teacherStatus(item.id, parseInt(item.status) === 1 ? 0 : 1)
            .then((response) => {
                dispatch(updateTeacherStatusAction(item));
                utility.showSuccess(response.data.msg)
            })
            .catch((error) => {
                utility.showError(error.response.data);
            });
    };
}

export function removeUserDetails(id, type, history) {
    return (dispatch) => {
        removeUser(id, type)
            .then((response) => {
                dispatch(removeUserAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function removeAdminDetails(id, active, history) {
    return (dispatch) => {
        removeAdmin(id)
            .then((response) => {
                dispatch(getAdminData(active, '', '', '', history));
                dispatch(removeAdminAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function removeContentDeveloperDetails(id, active, history) {
    return (dispatch) => {
        removeContentDeveloper(id)
            .then((response) => {
                dispatch(getDeveloperData(active, "", "", "", history));
                dispatch(removeContentDeveloperAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function removeStudentDetails(id, active, history) {
    return (dispatch) => {
        removeStudent(id)
            .then((response) => {
                dispatch(getStudentData(active, "", "", "", "", "", history));
                dispatch(removeStudentAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}

export function removeTeacherDetails(id, active, history) {
    return (dispatch) => {
        removeTeacher(id)
            .then((response) => {
                dispatch(getTeacherData(active, '', '', '', history));
                dispatch(removeTeacherAction(id));
                swal(response.data.msg, { icon: "success", });
            })
            .catch((error) => {
                //console.log(error);
                swal("Oops", "Something went wrong!", "error");
            });
    };
}


export function getDistrictData(state_id) {

    return (dispatch) => {
        getDistrict(state_id)
            .then((response) => {


                dispatch(getDistricts(response.data.data))
                dispatch(globalLoadingAction(false))
            })
            .catch((error) => {

                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getStateData(history) {
    return (dispatch) => {

        getState()
            .then((response) => {

                dispatch(getStateAction(response.data.data))
                dispatch(globalLoadingAction(false))
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function addRoleData(role_name, description, role_permission, tab_permission, history) {
    return (dispatch) => {

        addRole(role_name, description, role_permission, tab_permission)
            .then((response) => {

                dispatch(globalLoadingAction(false))
                if (response.status == 200) {
                    utility.showSuccess(response.data.msg)
                    history.push('/role-list')
                }
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}


export function getRolesData() {
    return (dispatch) => {
        getRoles()
            .then((response) => {
                if (response.status == 200) {
                    dispatch(getRoleAction(response.data.data))
                }
                dispatch(globalLoadingAction(false))
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}


export function deleteRoleData(role_id) {
    return (dispatch) => {
        deleteRole(role_id)
            .then((response) => {
                if (response.status == 200) {
                    utility.showSuccess(response.data.msg)
                    dispatch(getRolesData())
                }
                console.log("get roles", response.data)
                dispatch(globalLoadingAction(false))
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}


export function updateRoleData(role_id, role_name, description, role_permission, tab_permission, history) {
    return (dispatch) => {
        updateRole(role_id, role_name, description, role_permission, tab_permission)
            .then((response) => {
                if (response.status == 200) {
                    utility.showSuccess(response.data.msg)
                    history.push('/role-list')
                }

                dispatch(globalLoadingAction(false))
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}


export function createUsersDropdownDetails(history) {
    return (dispatch) => {
        createUsersDropdownData()
            .then((response) => {
                if (response.status == 200) {
                    dispatch(createUsersDropdownDataAction(response.data.data))
                }
                dispatch(globalLoadingAction(false));
            })
            .catch((error) => {
                //console.log(error);
                utility.showError(error.response.data);
                dispatch(globalLoadingAction(false));
            });
    };
}

export function getAllDeveloperData(history) {
    return (dispatch) => {
        getAllDeveloper()
            .then((response) => {
                if(response.status == 200) {
                dispatch(getAllDeveloperAction(response.data.data));
                dispatch(globalLoadingAction(false));
                }
            })
            .catch((error) => {
                //console.log(error);
                dispatch(globalLoadingAction(false));
                utility.showError(error.response.data);
            });
    };
}



export function getUserTypeAction(data) {
    return {
        type: GET_USER_TYPE,
        payload: data,
    };
}
export function addUserAction(data) {
    return {
        type: ADD_USER,
        payload: data,
    };
}
export function addTeacherAction(data) {
    return {
        type: ADD_TEACHER,
        payload: data,
    };
}
export function getAdminAction(data) {
    return {
        type: GET_ADMIN_LIST,
        payload: data,
    };
}
export function getDeveloperAction(data) {
    return {
        type: GET_CONTENT_DEVELOPER_LIST,
        payload: data,
    };
}
export function getStudentAction(data) {
    return {
        type: GET_STUDENT_LIST,
        payload: data,
    };
}
export function getTeacherAction(data) {
    return {
        type: GET_TEACHER_LIST,
        payload: data,
    };
}
export function updateAdminAction(data) {
    return {
        type: UPDATE_ADMIN_LIST,
        payload: data,
    };
}
export function updateContentDeveloperAction(data) {
    return {
        type: UPDATE_CONTENT_DEVELOPER_LIST,
        payload: data,
    };
}
export function updateStudentAction(data) {
    return {
        type: UPDATE_STUDENT_LIST,
        payload: data,
    };
}
export function updateTeacherAction(data) {
    return {
        type: UPDATE_TEACHER_LIST,
        payload: data,
    };
}
export function updateAdminStatusAction(data) {
    return {
        type: UPDATE_ADMIN_STATUS_LIST,
        payload: data,
    };
}
export function updateDeveloperStatusAction(data) {
    return {
        type: UPDATE_DEVELOPER_STATUS_LIST,
        payload: data,
    };
}
export function updateStudentStatusAction(data) {
    return {
        type: UPDATE_STUDENT_STATUS_LIST,
        payload: data,
    };
}
export function updateTeacherStatusAction(data) {
    return {
        type: UPDATE_TEACHER_STATUS_LIST,
        payload: data,
    };
}
export function removeUserAction(id) {
    return {
        type: REMOVE_USER,
        payload: id,
    };
}
export function removeAdminAction(id) {
    return {
        type: REMOVE_ADMIN,
        payload: id,
    };
}
export function removeContentDeveloperAction(id) {
    return {
        type: REMOVE_CONTENT_DEVELOPER,
        payload: id,
    };
}
export function removeStudentAction(id) {
    return {
        type: REMOVE_STUDENT,
        payload: id,
    };
}

export function removeTeacherAction(id) {
    return {
        type: REMOVE_TEACHER,
        payload: id,
    };
}

export function getDistricts(value) {



    return {
        type: GET_DISTRICT_LIST,
        payload: value,
    };
}

export function getStateAction(value) {

    return {
        type: GET_STATE_LIST,
        payload: value,
    };
}

export function getRoleAction(value) {
    return {
        type: GET_ROLE_LIST,
        payload: value
    }
}
export function getAllDeveloperAction(data) {
    return {
        type: GET_ALL_DEVELOPER_LIST,
        payload: data
    }
}
export function loadingUserAction(status) {
    return {
        type: USER_LOADING,
        payload: status,
    };
}

export function createUsersDropdownDataAction(status) {
    return {
        type: CREATE_USERS_DROPDOWN_DATA,
        payload: status,
    };
}


