import React, { Fragment, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import swal from "sweetalert";
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import {
	Row,
	Col,
	Card,
	Table,
	Badge,
	Dropdown,
	ProgressBar,
	Modal,
	Button
} from "react-bootstrap";

import { getExamSubType, loadingSubTypeAction, removeExamSubType, updateStatusExamSubType } from '../../../store/actions/ExamSubTypeAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

const ExamSubtypeList = (props) => {

	const [modal, setModal] = useState(false);
	const [permission, setPermission] = useState("");
	const dispatch = useDispatch();

	useEffect(() => {
		permissionCheck();
		dispatch(globalLoadingAction(true));
		dispatch(getExamSubType(props.history));
	}, []);

	/* delect confirmation */
	const onDelete = (id) => {

		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(removeExamSubType(id, props.history));
			}
		})

	}
	/* exam type updated */
	const onUpdateStatus = (item) => {

		dispatch(globalLoadingAction(true));
		dispatch(updateStatusExamSubType(item, props.history));
	}

	const permissionCheck = () => {
		const userDetails = localStorage.getItem("userDetails");
		const tokenDetails = JSON.parse(userDetails);
		const menuList = tokenDetails.menu_list;
		menuList.map((item) => {
			if (item.id == 19) {
				setPermission(item);
			}
		});
	};

	return (
		<Fragment>
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Body>
							<Table responsive>
								<thead>
									<tr>
										<th>
											<strong>Exam Type</strong>
										</th>
										<th>
											<strong>Exam Sub Type</strong>
										</th>

										<th>
											<strong>Create Date</strong>
										</th>

										{
											permission.status == "true" && (
												<th>
													<strong>Status</strong>
												</th>
											)
										}
										{
											(permission.edit == "true" || permission.delete == "true") && (
												<th>
													<strong>Action</strong>
												</th>
											)
										}
									</tr>
								</thead>
								<tbody>
									{
										props.list.map((item, index) => {
											return <tr key={index}>
												<td>{item.exam_type}</td>
												<td>{item.subtype_name}</td>
												<td><Moment format="D MMM YYYY" withTitle>{item.created_at}</Moment></td>
												{
													permission.status == "true" && (
														<td>
															<Button
																//disabled={props.showLoading}
																className="btn-xs"
																variant={item.status == 1 ? "success" : "danger"}
																onClick={() => onUpdateStatus(item)}
															>
																{item.status == 1 ? "Active" : "Inactive"}
															</Button>
														</td>
													)
												}
												{
													(permission.edit == "true" || permission.delete == "true") && (
														<td>
															<div className="d-flex">
																{
																	permission.edit == "true" && (
																		<Link
																			to={{
																				pathname: '/update-subtype',
																				state: item
																			}}
																			className="btn btn-warning shadow btn-xs sharp me-1"
																		>
																			<i className="fas fa-pencil-alt"></i>
																		</Link>
																	)
																}

																{
																	permission.delete == "true" && (
																		<Button
																			className="btn btn-danger shadow btn-xs sharp"
																			onClick={() => onDelete(item.id)}
																		>
																			<i className="fa fa-trash"></i>
																		</Button>
																	)
																}
															</div>
														</td>
													)}
											</tr>
										})
									}
								</tbody>
							</Table>

						</Card.Body>
					</Card>
				</Col>
			</Row>

		</Fragment>
	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.subtype.showLoading,
		list: state.subtype.list,
	};
};

export default connect(mapStateToProps)(ExamSubtypeList);