import swal from "sweetalert";
import {
  addAssignContent,
  getAssignContent,
  getElibraryContentRequestDetails,
  getQuestionContentRequestDetails,
  assignValidator,
  updateContentAssign,
  deleteAssignContent,
  getRejectedList,
  getAddedDocumentDetails,
  acceptRejectUploadedContentDetails,
  assignToValidatorElibraryDetails,
  assignToLanguageValidatorElibraryDetails,
  assignToDesignerElibraryDetails,
  getlibraryhistorylogDetails,
  getNotificationDetailsDetails,
  getReadNotificationDetails,
  addAssignQuestionContent,
  getAssignQuestionList,
  getAssignQuestionById,
  updateAssignQuestionContent,
  cancelQuestionRequest,
  updateQuestionStatusByAdmin,
  deleteQuestionRequest,
  getQuestionHistorylog,
} from "../../services/AssignContentServices";

import {
  ADD_ASSIGN_CONTENT,
  GET_ASSIGN_E_LIBRARY_CONTENT_LIST,
  GET_ASSIGN_QUESTION_CONTENT_LIST,
  GET_E_LIBRARY_CONTENT_REQUEST_DETAILS,
  GET_QUESTION_CONTENT_REQUEST_DETAILS,
  ASSIGN_CONTENT_LOADING,
  REMOVE_ASSIGN_CONTENT_LIST,
  REMOVE_SUCCESSFULLY_FROM_LIST,
  GET_REJECTED_LIST,
  GET_ADDED_DOCUMENT_DETAILS,
  RE_RENDERING_CONTENT_LIST,
  CONTENT_LIST_TOTAL_RECORD,
  CONTENT_LIST_TOTAL_PAGE,
  GET_LIBRARY_HISTORY_LOG,
  NOTIFICATION_DETAILS,
  ASSIGN_DATA_CLEAR,
  FILTER_LIST_DATA,
  GET_QUESTION_HISTORY_LOG,
  NOTIFICATION_PAGINATION,
  GET_ASSIGN_QUESTION_DETAILS_BY_ID,
} from "../constants";

import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "../actions/GlobalAction";

export function addAssignContentData(
  category,
  board,
  exam_type,
  subject,
  classroom,
  chapter,
  date_of_submission,
  payment,
  developer,
  onClear,
  history
) {
  return (dispatch) => {
    addAssignContent(
      category,
      board,
      exam_type,
      subject,
      classroom,
      chapter,
      date_of_submission,
      payment,
      developer
    )
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(addAssignContentAction(true));
          dispatch(globalLoadingAction(false));
          dispatch(assignDataClearAction(1));
          utility.showSuccess(response.data.msg);
          onClear();
        } else if (response.data.status == 400) {
          dispatch(globalLoadingAction(false));
          utility.showError(response.data.msg);
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getAssignElibraryContentData(
  request_type,
  category,
  board_id,
  exam_type_id,
  class_no,
  subject_id,
  chapter_id,
  status,
  page,
  approved_status,
  history
) {
  return (dispatch) => {
    getAssignContent(
      request_type,
      category,
      board_id,
      exam_type_id,
      class_no,
      subject_id,
      chapter_id,
      status,
      page,
      approved_status
    )
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(getAssignElibraryContentAction(response.data.result));
          dispatch(contentListTotalRecordAction(response.data.total_record));
          dispatch(contentListTotalPageAction(response.data.page_count));
          dispatch(filterListDataAction(response.data.filterlistdata));
        } else {
          utility.showError(response.data.msg);
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getAssignQuestionContentData(request_type, history) {
  return (dispatch) => {
    getAssignContent(request_type)
      .then((response) => {
        dispatch(getAssignQuestionContentAction(response.data));
        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getDetailsDeveloperContentForElibraryData(rec_id, history) {
  return (dispatch) => {
    getElibraryContentRequestDetails(rec_id)
      .then((response) => {
        // console.log('response', response.data);
        if (response.data.status == 200) {
          dispatch(
            getElibraryContentRequestDetailsAction(response.data.result)
          );
          utility.showSuccess(response.data.msg);
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getDetailsDeveloperContentForQuestionData(rec_id, history) {
  return (dispatch) => {
    getQuestionContentRequestDetails(rec_id)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(
            getQuestionContentRequestDetailsAction(response.data.result)
          );
        } else {
          utility.showError(response.data.msg);
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function updateQuestionStatusByAdminData(
  rec_id,
  status,
  resetQuestionStatus,
  history
) {
  return (dispatch) => {
    updateQuestionStatusByAdmin(rec_id, status)
      .then((response) => {
        if (response.data.status == 200) {
          resetQuestionStatus();
          utility.showSuccess(response.data.msg);
        } else {
          utility.showError(response.data.msg);
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function assignValidatorData(rec_id, validator_id, history) {
  return (dispatch) => {
    assignValidator(rec_id, validator_id)
      .then((response) => {
        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function updateContentAssignData(
  user_type,
  payment_per_page,
  assigned_to_whom,
  date_of_submission,
  state,
  history
) {
  return (dispatch) => {
    updateContentAssign(
      user_type,
      payment_per_page,
      assigned_to_whom,
      date_of_submission,
      state.id
    )
      .then((response) => {
        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
        history.push({
          pathname: "/assign-elibrary-list",
          state: state.filterData,
        });
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function deleteAssignContentData(
  recid,
  rejection_msg,
  postCancelRequest,
  history
) {
  return (dispatch) => {
    deleteAssignContent(recid, rejection_msg)
      .then((response) => {
        if (response.data.status == 200) {
          utility.showSuccess(response.data.msg);
          postCancelRequest();
        } else if (response.data.status == 400) {
          utility.showError(response.data.msg);
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getRejectedListData(recid, history) {
  return (dispatch) => {
    getRejectedList(recid)
      .then((response) => {
        // utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
        dispatch(getRejectedListAction(response.data.data));
        // swal(response.data.msg, { icon: "success" });
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getAddedDocumentDetailsData(recid, history) {
  return (dispatch) => {
    getAddedDocumentDetails(recid)
      .then((response) => {
        console.log("response.......", response.data);
        if (response.data.status == 200) {
          dispatch(getAddedDocumentDetailsAction(response.data.data));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function acceptRejectUploadedContentData(recid, status, history) {
  return (dispatch) => {
    acceptRejectUploadedContentDetails(recid, status)
      .then((response) => {
        utility.showSuccess(response.data.msg);
        dispatch(globalLoadingAction(false));
        dispatch(reRenderingContentListAction(1));
        // dispatch(getAddedDocumentDetailsAction(response.data.data));
        // swal(response.data.msg, { icon: "success" });
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function assignToValidatorElibraryData(
  state,
  date_of_submission,
  payment_per_page,
  assigned_validator,
  history
) {
  return (dispatch) => {
    assignToValidatorElibraryDetails(
      state.id,
      date_of_submission,
      payment_per_page,
      assigned_validator
    )
      .then((response) => {
        utility.showSuccess(response.data.msg);
        dispatch(reRenderingContentListAction(1));
        history.push({
          pathname: "/assign-elibrary-list",
          state: state.filterData,
        });
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}
export function assignToLanguageValidatorElibraryDetailsData(
  state,
  date_of_submission,
  payment_per_page,
  assigned_validator,
  history
) {
  return (dispatch) => {
    assignToLanguageValidatorElibraryDetails(
      state.id,
      date_of_submission,
      payment_per_page,
      assigned_validator
    )
      .then((response) => {
        utility.showSuccess(response.data.msg);
        dispatch(reRenderingContentListAction(1));
        history.push({
          pathname: "/assign-elibrary-list",
          state: state.filterData,
        });
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function assignToDesignerElibrarysData(
  state,
  date_of_submission,
  payment_per_page,
  assigned_validator,
  history
) {
  return (dispatch) => {
    assignToDesignerElibraryDetails(
      state.id,
      date_of_submission,
      payment_per_page,
      assigned_validator
    )
      .then((response) => {
        utility.showSuccess(response.data.msg);
        dispatch(reRenderingContentListAction(1));
        history.push({
          pathname: "/assign-elibrary-list",
          state: state.filterData,
        });
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getLibraryHistoryLogData(recid, history) {
  return (dispatch) => {
    getlibraryhistorylogDetails(recid)
      .then((response) => {
        utility.showSuccess(response.data.msg);
        dispatch(getLibraryHistoryLogAction(response.data.data));
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getNotificationDetailsData(page, history) {
  return (dispatch) => {
    getNotificationDetailsDetails(page)
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(getNotificationDetailsAction(response.data));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        // console.log(error);
        // utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getNotificationPaginationData(page, callBack, history) {
  return (dispatch) => {
    getNotificationDetailsDetails(page)
      .then((response) => {
        if (response.data.status === 200) {
          dispatch(getNotificationPaginationAction(response.data));
          callBack();
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getReadNotificationData(item,callBack, history) {
  return (dispatch) => {
    getReadNotificationDetails(item.id)
      .then((response) => {
        if (response.data.status == 200) {
          callBack();
          history.push({
            pathname: "/assign-developer-edit",
            state: item,
          });
          utility.showSuccess(response.data.msg);
          dispatch(globalLoadingAction(false));
        }
      })
      .catch((error) => {
        // console.log(error);
        // utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function addAssignQuestionContentData(
  exam_category_id,
  board_id,
  exam_type_id,
  exam_subtype_id,
  subject_id,
  class_no,
  chapter_id,
  question_no_details,
  date_of_submission,
  total_payment,
  total_no_questions,
  assigned_developer,
  clearData,
  history
) {
  return (dispatch) => {
    addAssignQuestionContent(
      exam_category_id,
      board_id,
      exam_type_id,
      exam_subtype_id,
      subject_id,
      class_no,
      chapter_id,
      question_no_details,
      date_of_submission,
      total_payment,
      total_no_questions,
      assigned_developer
    )
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(globalLoadingAction(false));
          clearData();
          utility.showSuccess(response.data.msg);
        } else if (response.data.status == 400) {
          dispatch(globalLoadingAction(false));
          utility.showError(response.data.msg);
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function updateAssignQuestionContentData(
  id,
  user_type,
  question_no_details,
  date_of_submission,
  total_payment,
  total_no_questions,
  assigned_developer,
  filterObj,
  history
) {
  return (dispatch) => {
    updateAssignQuestionContent(
      id,
      user_type,
      question_no_details,
      date_of_submission,
      total_payment,
      total_no_questions,
      assigned_developer
    )
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(globalLoadingAction(false));
          utility.showSuccess(response.data.msg);
          history.replace({
            pathname: "/assign-question-list",
            state: filterObj,
          });
        } else if (response.data.status == 400) {
          dispatch(globalLoadingAction(false));
          utility.showError(response.data.msg);
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getAssignQuestionListData(
  request_type,
  exam_category_id,
  board_id,
  exam_type_id,
  subject_id,
  class_no,
  chapter_id,
  status,
  approved_status,
  page,
  history
) {
  return (dispatch) => {
    getAssignQuestionList(
      request_type,
      exam_category_id,
      board_id,
      exam_type_id,
      subject_id,
      class_no,
      chapter_id,
      status,
      approved_status,
      page
    )
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(getAssignQuestionContentAction(response.data));
        } else {
          utility.showError(response.data.msg);
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}
export function getAssignQuestionDetailsById(recid, history) {
  return (dispatch) => {
    getAssignQuestionById(recid)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(getAssignQuestionByIdAction(response.data.data));
          dispatch(globalLoadingAction(false));
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function cancelQuestionRequestDetailsById(
  state,
  rejection_msg,
  submit_date,
  status,
  history
) {
  return (dispatch) => {
    cancelQuestionRequest(state.id, rejection_msg, submit_date, status)
      .then((response) => {
        if (response.data.status == 200) {
          if (status == 1) {
            if (state.developer_approval_status == 4) {
              history.replace({
                pathname: "/assign-question-list",
                state: state.filterObj,
              });
            } else {
              history.replace({
                pathname: "/update-assign-question-developer",
                state: state,
              });
            }
          } else if (status == 2) {
            history.replace({
              pathname: "/assign-question-list",
              state: state.filterObj,
            });
          }
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function deleteQuestionRequestDetailsById(
  recid,
  rejection_msg,
  postCancelRequest,
  history
) {
  return (dispatch) => {
    deleteQuestionRequest(recid, rejection_msg)
      .then((response) => {
        if (response.data.status == 200) {
          postCancelRequest();
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getQuestionHistoryLogData(recid, history) {
  return (dispatch) => {
    getQuestionHistorylog(recid)
      .then((response) => {
        if (response.data.status == 200) {
          utility.showSuccess(response.data.msg);
          dispatch(getQuestionHistoryLogAction(response.data.data));
          dispatch(globalLoadingAction(false));
        }
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function addAssignContentAction(data) {
  return {
    type: ADD_ASSIGN_CONTENT,
    payload: data,
  };
}
export function getAssignElibraryContentAction(data) {
  return {
    type: GET_ASSIGN_E_LIBRARY_CONTENT_LIST,
    payload: data,
  };
}
export function getElibraryContentRequestDetailsAction(data) {
  return {
    type: GET_E_LIBRARY_CONTENT_REQUEST_DETAILS,
    payload: data,
  };
}
export function getQuestionContentRequestDetailsAction(data) {
  return {
    type: GET_QUESTION_CONTENT_REQUEST_DETAILS,
    payload: data,
  };
}
export function getAssignQuestionContentAction(data) {
  return {
    type: GET_ASSIGN_QUESTION_CONTENT_LIST,
    payload: data,
  };
}
export function loadingAssignAction(status) {
  return {
    type: ASSIGN_CONTENT_LOADING,
    payload: status,
  };
}

export function removeAssignContentListAction(data) {
  return {
    type: REMOVE_ASSIGN_CONTENT_LIST,
    payload: data,
  };
}
export function removeScuccessfullyAction(data) {
  return {
    type: REMOVE_SUCCESSFULLY_FROM_LIST,
    payload: data,
  };
}

export function getRejectedListAction(data) {
  return {
    type: GET_REJECTED_LIST,
    payload: data,
  };
}

export function getAddedDocumentDetailsAction(data) {
  return {
    type: GET_ADDED_DOCUMENT_DETAILS,
    payload: data,
  };
}

export function reRenderingContentListAction(data) {
  return {
    type: RE_RENDERING_CONTENT_LIST,
    payload: data,
  };
}

export function contentListTotalRecordAction(data) {
  return {
    type: CONTENT_LIST_TOTAL_RECORD,
    payload: data,
  };
}
export function contentListTotalPageAction(data) {
  return {
    type: CONTENT_LIST_TOTAL_PAGE,
    payload: data,
  };
}
export function filterListDataAction(data) {
  return {
    type: FILTER_LIST_DATA,
    payload: data,
  };
}

export function getLibraryHistoryLogAction(data) {
  return {
    type: GET_LIBRARY_HISTORY_LOG,
    payload: data,
  };
}

export function getNotificationDetailsAction(data) {
  return {
    type: NOTIFICATION_DETAILS,
    payload: data,
  };
}
export function getNotificationPaginationAction(data) {
  return {
    type: NOTIFICATION_PAGINATION,
    payload: data,
  };
}

export function getAssignQuestionByIdAction(data) {
  return {
    type: GET_ASSIGN_QUESTION_DETAILS_BY_ID,
    payload: data,
  };
}

export function getQuestionHistoryLogAction(data) {
  return {
    type: GET_QUESTION_HISTORY_LOG,
    payload: data,
  };
}

export function assignDataClearAction(data) {
  return {
    type: ASSIGN_DATA_CLEAR,
    payload: data,
  };
}
