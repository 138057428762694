import { Fragment, useEffect, useState, useRef } from "react";
import { Row, Col, Card, Table, Modal, Button } from "react-bootstrap";
import { useDispatch, connect, useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import moment from "moment";

import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { getBoardData } from "../../../store/actions/BoardAction";
import { getClassData } from "../../../store/actions/ClassAction";

import {
  misSchoolListData,
} from "../../../store/actions/MisRegUserAction";

import {
  misConcurrentUserDetailsStudentActivityData,
} from "../../../store/actions/MisDashboardInfoAction";

import ReactPaginate from 'react-paginate';


const StudentActivity = (props) => {

  const dispatch = useDispatch();
  const { state } = useLocation();

  const [page, setPage] = useState(1)

  const misConcurrentActiveUserDetailsStudentActivityList = useSelector((state) => state.MisDashboardInfo.misConcurrentActiveUserDetailsStudentActivityList);


  useEffect(() => {
    dispatch(getClassData());
    dispatch(getBoardData());
    dispatch(misSchoolListData())

  }, []);

  useEffect(() => {
    console.log('state',state)
    getList();
  }, []);

  /* state?.parent_item?.activity_id, state?.parent_item?.date, state?.parent_filterData?.endTime, state?.parent_filterData?.startTime,  state?.current_page_item?.student_id */

  const getList = () => {
    dispatch(globalLoadingAction(true));
    dispatch(
      misConcurrentUserDetailsStudentActivityData(
        state?.item?.activity_id,
        state?.item?.date,
        moment(state?.filterData?.endTime).format("HH:mm:ss"),
        moment(state?.filterData?.startTime).format("HH:mm:ss"),
        state?.current_page_item?.student_id
      ));
  };


  /* pagination */
  const pageHandler = (data) => {
    let page = (data.selected + 1)
    setPage(data.selected + 1);
    getList();
  }

  /* navigation go back with state */
  const onGoBackHandler = () => {
    props.history.push({
      pathname: "/user-details",
      state: state,
    });
  };

  



  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
            <div className="back-arrow-container mt-0 mb-4 ps-0">
                  <Link onClick={onGoBackHandler}>
                    <i className="fa-solid fa-arrow-left"></i>
                  </Link>
                </div>
              <div
                className="add-filter-group"
                style={{ justifyContent: "space-between" }}
              >

                <div className="subscription-details">
                  <div className="subscription-info">
                    <strong>Date:</strong>
                    <span className="count">
                      {/* 05-06-2024 */}
                      {state?.item?.date}
                    </span>
                  </div>
                  <div className="subscription-info">
                    <strong>Activity:</strong>
                    <span className="amount">
                      {/* e-library access */}
                      {state?.item?.acitivity}
                    </span>
                  </div>
                </div>
              </div>

              {misConcurrentActiveUserDetailsStudentActivityList?.length == 0 && misConcurrentActiveUserDetailsStudentActivityList?.length == undefined ? (
                <div className="no_data">
                  <strong>No data found</strong>
                </div>
              ) : (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Sl no.</strong>
                      </th>
                      <th>
                        <strong>Category</strong>
                      </th>
                      <th>
                        <strong>Board/Type</strong>
                      </th>
                      <th>
                        <strong>Class</strong>
                      </th>
                      <th>
                        <strong>Subject</strong>
                      </th>
                      <th>
                        <strong>Activity</strong>
                      </th>
                      <th>
                        <strong>Time</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {misConcurrentActiveUserDetailsStudentActivityList?.map((item, index) => {
                      return (
                        <tr>
                          <td>{item.sl_no}</td>
                          <td>{item.category}</td>
                          <td>{item.board_of_exam_type}</td>
                          <td>{item.class}</td>
                          <td>{item.subject}</td>
                          <td>{item.acitivity}</td>
                          <td>{item.time}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}

        
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapstateToProps = (state) => {
  return {

    subscriptionDetailsList: state.subscriptiondetails.list,

  };
};

export default connect(mapstateToProps)(StudentActivity);
