import React, { Fragment, useState,useEffect } from "react";
import { connect,useDispatch } from "react-redux";
import { Link, useHistory,useLocation } from "react-router-dom";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import LetestSession from "./component/LetestSession";
import SessionHistory from "./component/SessionHistory";
import {
  getAcademicYearMasterListData,
} from "../../../store/actions/AcademicAction";
import {
  getExamCategory,
} from "../../../store/actions/ExamCategoryAction";
import {
  getBoardData,
} from "../../../store/actions/BoardAction";
import {
  getExamType,
} from "../../../store/actions/ExamTypeAction";

const tabData = [
  {
    name: "Latest Session",
    component: LetestSession,
  },
  {
    name: "Session History",
    component: SessionHistory,
  },
];

const AcademicSession = (props) => {
  const {state} = useLocation();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(tabData[0].name.toLowerCase());
  const [permission, setPermission] = useState("");

  useEffect(() => {
    dispatch(getExamCategory(props.history));
    dispatch(getBoardData(props.history));
    dispatch(getExamType(props.history));
    permissionCheck();
    //dispatch(getAcademicYearMasterListData(props.history));
  }, []);

  const handleSelect = (key) => {
    setActiveTab(key);
  };

  const permissionCheck = () => {
		const userDetails = localStorage.getItem("userDetails");
		const tokenDetails = JSON.parse(userDetails);
		const menuList = tokenDetails.menu_list;
		menuList.map((item) => {
		  if (item.id == 104) {
			console.log("104----------", item)
			setPermission(item);
		  }
		});
	  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Tab.Container activeKey={activeTab} onSelect={handleSelect}>
                <Nav as="ul" className="nav-pills mb-4 light">
                  {tabData.map((data, i) => (
                    <Nav.Item as="li" key={i}>
                      <Nav.Link eventKey={data.name.toLowerCase()}>
                        {data.name}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
                <Tab.Content>
                  {tabData.map((data, i) => (
                    <Tab.Pane eventKey={data.name.toLowerCase()} key={i} active={activeTab === data.name.toLowerCase()}>
                      {activeTab === data.name.toLowerCase() && <data.component />}
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.academic.list,
  };
};

export default connect(mapStateToProps)(AcademicSession);
