class GlobalConfigs {
  get VERSION() {
    return "1";
  }
  get API_URL() {
    return process.env.REACT_APP_APIHOSTURL
  }
  get IMAGE_URL() {
    return process.env.REACT_APP_IMAGE_URL
  }
  get PDF_URL()
  {
    return process.env.REACT_APP_ADMIN_PDF_URL
  }
  get EXCEL_URL()
  {
    return process.env.REACT_APP_SCHOOL_EXCEL
  }
  get EXCEL_SAMPLE_QUESTIONS()
  {
    return process.env.REACT_APP_SAMPLE_QUESTIONS
  }
  get SAMPLE_CSS()
  {
    return process.env.REACT_APP_SAMPLE_CSS
  }
  get RESUME()
  {
    return process.env.REACT_APP_RESUME_BASE
  }
  get TINY_API_KEY()
  {
    return "s7cvuo3lye5fdiq7o74zvf13ateuc6lvii57x09056q7scji"
  }
}

const globalConfigs = new GlobalConfigs();

export default globalConfigs;
