// AutocompleteSearch.js
import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import './autocompletestyle.css'; // Import CSS file for custom styling

const AutocompleteSearch = (props) => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  // Mock data for suggestions
  /* const languages = [
    'React',
    'Angular',
    'Vue.js',
    'Ember.js',
    'Backbone.js',
    'Svelte',
    'Express.js',
    'Meteor.js',
    'Next.js',
    'Nuxt.js'
  ]; */

  const languages = props.dataDetails;

  useEffect(() => {
    if (props.selectValue === '' || props.selectValue === undefined) {
      setValue("");
    } else if (props.selectValue !== '' && props.selectValue !== undefined) {
      setValue(props.selectValue);
    }

  }, [props.selectValue]);

  const getSuggestions = (inputValue) => {
    const regex = new RegExp(inputValue.trim(), 'i');
    return languages.filter((language) => regex.test(language)); /* .slice(0, 10) */
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onChange = (event, { newValue }) => {
    setValue(newValue);
    props.setNewValue(newValue);
  };

  const getSuggestionValue = (suggestion) => suggestion;

  const renderSuggestion = (suggestion) => <div><div>{suggestion}</div></div>;

  const inputProps = {
    placeholder: props.placeholder,
    value,
    onChange,
    select: props.selectValue
  };

  // Custom theme for styling
  const theme = {
    container: 'autocomplete-container',
    suggestionsContainer: 'suggestions-container',
    suggestion: 'suggestion',
    suggestionHighlighted: 'suggestion-highlighted',
    input: 'autocomplete-input'
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      theme={theme} // Apply custom theme for styling
    />
  );
};

export default AutocompleteSearch;
