import { Fragment, useEffect, useState, useRef } from "react";
import { Row, Col, Card, Table, Modal, Button } from "react-bootstrap";
import { useDispatch, connect, useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { getBoardData } from "../../../store/actions/BoardAction";
import { getClassData } from "../../../store/actions/ClassAction";

import { misSchoolListData } from "../../../store/actions/MisRegUserAction";

import {
  misConcurrentActiveUserDetailsData,
  misConcurrentActiveUserDetailsExcelData,
} from "../../../store/actions/MisDashboardInfoAction";

//images
import sort from "../../../images/dashboard_image/sort.png";
import export_details from "../../../images/dashboard_image/export.png";

import AutocompleteSearch from "../AutocompleteSearch/AutocompleteSearch";

const UserDetails = (props) => {
  const [modal, setModal] = useState(false);
  const [studentPersonalDetailssModal, setStudentPersonalDetailssModal] =
    useState(false);
  const { state } = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();

  const [board, setboard] = useState(state.current_page_filterData?.board?? '');
  const [className, setClassName] = useState(state.current_page_filterData?.className?? '');
  const [schoolName, setSchoolName] = useState(state.current_page_filterData?.schoolName?? '');
  const [schoolData, setSchoolData] = useState([]);
  const [page, setPage] = useState(state.current_page_filterData?.page?? 1);
  const [currentUserDetails, setCurrentUserDetails] = useState();

  const [isFiltered, setIsFiltered] = useState(false);

  const [temboard, setTemboard] = useState(state.current_page_filterData?.board?? '');
  const [temclassName, setTemclassName] = useState(state.current_page_filterData?.className?? '');
  const [tempSchoolName, setTempSchoolName] = useState(state.current_page_filterData?.schoolName?? '');

  // misSchoolList

  const misSchoolList = useSelector(
    (state) => state.MisDashboardInfo.misSchoolList
  );
  const misConcurrentActiveUserDetailsList = useSelector(
    (state) => state.MisDashboardInfo.misConcurrentActiveUserDetailsList
  );

  const classList = useSelector((state) => state.class.list);
  const boardList = useSelector((state) => state.board.list);

  useEffect(() => {
    console.log("state...", state);
    dispatch(getClassData());
    dispatch(getBoardData());
    dispatch(misSchoolListData());
  }, []);

  useEffect(() => {
    if (board != "select_baord" && board != "" && board != undefined) {
      setIsFiltered(true);
    } else if (
      className != "select_class" &&
      className != "" &&
      className != undefined
    ) {
      setIsFiltered(true);
    } else if (schoolName != "" && schoolName != undefined) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
  }, [board, className, schoolName]);

  useEffect(() => {
    let schoolName = [];
    let schoolNameObj = {};

    misSchoolList.forEach((elm) => {
      schoolNameObj = {};
      schoolNameObj = elm.school_name;
      schoolName.push(schoolNameObj);
    });

    setSchoolData(schoolName);
  }, [misSchoolList]);

  const onResetHandler = () => {
    setboard("");
    setClassName("");
    setSchoolName("");
    setPage(1);
    getList("", "", "", 1, state?.item?.student_ids, state?.item?.activity_id);
    setTemboard("");
    setTemclassName("");
    setTempSchoolName("");
    setModal(false);
    const newState = {...state};
    delete newState.current_page_filterData;
    delete newState.current_page_item;
    history.replace({ state: newState });
  };

  useEffect(() => {
    getList(
      board,
      className,
      schoolName,
      page,
      state?.item?.student_ids,
      state?.item?.activity_id,
      state?.item?.date
    );
  }, []);

  //board, class, school_name, page, student_ids, activity_id

  const getList = (
    board,
    className,
    schoolName,
    page,
    student_ids,
    activity_id
  ) => {
    dispatch(globalLoadingAction(true));
    dispatch(
      misConcurrentActiveUserDetailsData(
        board == "select_baord" ? "" : board,
        className == "select_class" ? "" : className,
        schoolName,
        page,
        student_ids ? JSON.stringify(student_ids) : "",
        activity_id,
        state?.item?.date
      )
    );
  };

  const filterUserDetailsHandler = () => {
    let page = 1;
    setModal(false);
    setPage(1);
    getList(
      board,
      className,
      schoolName,
      page,
      state?.item?.student_ids,
      state?.item?.activity_id,
      state?.item?.date
    );
    setTemboard(board);
    setTemclassName(className);
    setTempSchoolName(schoolName);
  };

  //misRegisterStudentListExcelData(

  const onDownloadSubscriptionHandler = () => {
    let page = 0;
    dispatch(globalLoadingAction(true));
    dispatch(
      misConcurrentActiveUserDetailsExcelData(
        board,
        className,
        schoolName,
        page,
        JSON.stringify(state?.item?.student_ids),
        state?.item?.activity_id,
        state?.item?.date
      )
    );
  };

  /* navigation go back with state */
  const onGoBackHandler = () => {
    props.history.push({
      pathname: "/concurrent-active-user",
      state: {
        item: state?.item,
        page: state?.page,
        filterData: state?.filterData,
      },
    });
  };

  const goToStudentDetails = (item) => {
    props.history.push({
      pathname: "/user-activity",
      state: {
        ...state,
        current_page_filterData: { board, className, schoolName, page },
        current_page_item: item,
      },
    });
  };

  const viewStudentDetails = (item) => {
    setStudentPersonalDetailssModal(true);
    setCurrentUserDetails(item);
  };

  const cancelModal = () => {
    setModal(false);

    if (temboard == "") {
      setboard("");
    } else if (board != "select_baord" && board != "" && board != undefined) {
      setboard(temboard);
    } else if (board == "select_baord" || board == "") {
      setboard(temboard);
    }

    if (temclassName == "") {
      setClassName("");
    } else if (
      className != "select_class" &&
      className != "" &&
      className != undefined
    ) {
      setClassName(temclassName);
    } else if (className == "select_class" || className == "") {
      setClassName(temclassName);
    }

    if (tempSchoolName == "") {
      setSchoolName("");
    } else if (schoolName != "" && schoolName != undefined) {
      setSchoolName(tempSchoolName);
    } else if (schoolName == "") {
      setSchoolName(tempSchoolName);
    }
  };

  const isDisabled =
    (board === "" || board === "select_baord") &&
    (className === "" || className === "select_class") &&
    schoolName === "";

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
           
                <div className="back-arrow-container mt-0 mb-4 ps-0">
                  <Link onClick={onGoBackHandler}>
                    <i className="fa-solid fa-arrow-left"></i>
                  </Link>
                </div>
          
              <div
                className="add-filter-group"
                style={{ justifyContent: "space-between" }}
              >
                <div className="subscription-details">
                  <div className="subscription-info">
                    <strong>Date:</strong>
                    <span className="count">
                      {/* {moment(state?.item?.date).format("DD-MM-YYYY")} */}
                      {state?.item?.date}
                    </span>
                  </div>
                  <div className="subscription-info">
                    <strong>Activity:</strong>
                    <span className="amount">{state?.item?.acitivity}</span>
                  </div>
                </div>

                <div className="filtersAlign mt-3" style={{ marginRight: 5 }}>
                  <div
                    className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 me-3 filter_background rounded cursorPointer ${
                      isFiltered ? "border_red" : null
                    }`}
                    onClick={() => setModal(true)}
                  >
                    <div className="image_container">
                      <img src={sort} alt="" className="image_style" />
                    </div>
                    <div className="button_text font_color_black">Filter</div>
                  </div>
                  <div
                    className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 export_background rounded blue_border cursorPointer ${
                      misConcurrentActiveUserDetailsList?.length == 0 ||
                      misConcurrentActiveUserDetailsList?.length == undefined
                        ? "content_disable"
                        : null
                    }`}
                    onClick={() => onDownloadSubscriptionHandler()}
                  >
                    <div className="image_container">
                      <img
                        src={export_details}
                        alt=""
                        className="image_style"
                      />
                    </div>
                    <div className="button_text font_color_blue cursorPointer">
                      Export
                    </div>
                  </div>
                </div>
              </div>

              {misConcurrentActiveUserDetailsList?.length == 0 ||
              misConcurrentActiveUserDetailsList?.length == undefined ? (
                <div className="no_data">
                  <strong>No data found</strong>
                </div>
              ) : (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Sl no.</strong>
                      </th>
                      <th>
                        <strong>Name</strong>
                      </th>
                      <th>
                        <strong>Class</strong>
                      </th>
                      <th>
                        <strong>Board</strong>
                      </th>
                      <th>
                        <strong>Email</strong>
                      </th>
                      <th width={130}>
                        <strong>Phone Number</strong>
                      </th>
                      <th>
                        <strong>Time</strong>
                      </th>
                      <th>
                        <strong>School Name</strong>
                      </th>
                      <th>
                        <strong>Action</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {misConcurrentActiveUserDetailsList?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.sl_no}</td>
                          <td>{item.student_name}</td>
                          <td>{item.class}</td>
                          <td>{item.board}</td>
                          <td>{item.email}</td>
                          <td>{item.phone_number}</td>
                          <td>{item.time}</td>
                          <td>{item.school_name}</td>
                          <td>
                            <div className="d-flex justify-content-between w-100">
                              <Button
                                variant="info shadow btn-xs sharp"
                                onClick={() => viewStudentDetails(item)}
                              >
                                <i className="fa fa-info"></i>
                              </Button>
                              <Button
                                variant="warning shadow btn-xs sharp"
                                onClick={() => goToStudentDetails(item)}
                              >
                               <i className="fa fa-eye"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <!-- Modal --> */}
      <Modal className="fade" show={modal} size="md">
        <Modal.Header>
          <Modal.Title>Filter</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => cancelModal()}
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="question-modal-form">
            <div className="reset-filtter-value">
              <button
                disabled={isDisabled}
                className="btn btn-info btn-xs"
                onClick={() => onResetHandler()}
              >
                Reset
              </button>
            </div>

            <div className="row">
              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={board}
                    onChange={(e) => setboard(e.target.value)}
                  >
                    <option value="select_baord">Select Baord</option>
                    {boardList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={className}
                    onChange={(e) => setClassName(e.target.value)}
                  >
                    <option value="select_class">Select Class</option>
                    {classList?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.class_no}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <AutocompleteSearch
                  dataDetails={schoolData}
                  placeholder={`Enter School Name`}
                  setNewValue={(e) => setSchoolName(e)}
                  selectValue={schoolName}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => cancelModal()} variant="danger light">
            Cancel
          </Button>
          <Button
            disabled={isDisabled}
            onClick={() => filterUserDetailsHandler()}
            variant="primary"
          >
            Filter
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ------------------ */}
      {/* <!-- Modal --> */}
      <Modal className="fade" show={studentPersonalDetailssModal} size="md">
        <Modal.Header>
          <Modal.Title>Student Personal Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setStudentPersonalDetailssModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="question-modal-form">
            <div className="user_details_modal_container">
              <div className="leftBox_width">DOB :</div>
              <div className="box_width">{currentUserDetails?.dob}</div>
            </div>
            <div className="user_details_modal_container">
              <div className="leftBox_width">Gender :</div>
              <div className="box_width">{currentUserDetails?.gender}</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="footer_text">
            This details will be included in the export of Excel
          </div>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const mapstateToProps = (state) => {
  return {
    boardList: state.board.list,
    classList: state.class.list,
    categoryList: state.category.list,
    subscriptionDetailsList: state.subscriptiondetails.list,
    typeList: state.type.list,
    subjectList: state.subject.nonGroupSubjectList,
    subjectFilterSubLibrary: state.subject.subjectFilterSubLibrary,
  };
};

export default connect(mapstateToProps)(UserDetails);
