import swal from "sweetalert";
import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "../actions/GlobalAction";
import { MisFailedRegistration, UpdateFailedRegistration, misFailedStudentListExcel, misApprovedFailedRegistration } from "../../services/FailedRegistrationServices";
import { GET_MIS_FAILED_REGISTRATION_LIST } from "../constants";


export function MisFailedRegistrationData(
  period, board, className, locationPin, registrationFailed, page, registrationVia, schoolName, email, startDate, endDate, status
) {
  return (dispatch) => {
    MisFailedRegistration(period, board, className, locationPin, registrationFailed, page, registrationVia, schoolName, email, startDate, endDate, status)
      .then((response) => {
        if (response.data.status == 200) {

          dispatch(MisFailedRegistrationAction(response.data))
          dispatch(globalLoadingAction(false));
        } else {
          dispatch(
            MisFailedRegistrationAction([])
          )
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function UpdateFailedRegistrationData(id, message, reply_mail_date, status, period, userProblem, actionTaken) {
  return (dispatch) => {
    UpdateFailedRegistration(id, message, reply_mail_date, status)
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(MisFailedRegistrationData(period, userProblem, actionTaken));
          //dispatch(globalLoadingAction(false));
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function misFailedStudentListExcelData(period, board, className, locationPin, registrationFailed, page, registrationVia, schoolName, email, startDate, endDate, status) {
  return (dispatch) => {
    misFailedStudentListExcel(period, board, className, locationPin, registrationFailed, page, registrationVia, schoolName, email, startDate, endDate, status)
      .then((response) => {
        window.location.href = response.data.filename;
        utility.showSuccess(response.data.msg);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function misApprovedFailedRegistrationData(id, statusChange, history) {
  return (dispatch) => {
    misApprovedFailedRegistration(id)
      .then((response) => {
        if (response.data.status == 200) {
          utility.showSuccess(response.data.msg);
          statusChange()
          dispatch(globalLoadingAction(false));
        }

        if (response.data.status == 400) {
          utility.showError(response.data.msg);
          dispatch(globalLoadingAction(false));
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(globalLoadingAction(false));
        utility.showError(error.response.data);
      });
  };
}

export function MisFailedRegistrationAction(data) {
  return {
    type: GET_MIS_FAILED_REGISTRATION_LIST,
    payload: data
  }
}
