import React, { useEffect, useState, Fragment, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import { globalLoadingAction } from "../../../../store/actions/GlobalAction";
import {
  assignDataClearAction,
  addAssignQuestionContentData,
} from "../../../../store/actions/AssignContentAction";
import {
  loadingUserAction,
  getUserTypeData,
  getDeveloperData,
} from "../../../../store/actions/UserAction";
import {
  getQuestionPattern,
  loadingPatternAction,
} from "../../../../store/actions/QuestionPatternAction";
import { getExamCategory } from "../../../../store/actions/ExamCategoryAction";
import { getBoardData } from "../../../../store/actions/BoardAction";
import { getExamType } from "../../../../store/actions/ExamTypeAction";
import { getClassData } from "../../../../store/actions/ClassAction";
import { getBranchDetails } from "../../../../store/actions/BranchActions";
import {
  getSubjects,
  subjectLoading,
  getSingleSubjectListDetails,
  getSingleSubjectListActions,
} from "../../../../store/actions/SujectActions";

import {
  getbranchTypeData,
  getChaptersDeffarentsParamsData,
  loadingChaptersAction,
  getChaptersDeffarentsParamsAction,
} from "../../../../store/actions/ChapterAction";

import {
  getExamSubTypeById,
  loadingSubTypeAction,
} from "../../../../store/actions/ExamSubTypeAction";

const Question = (props) => {
  const dispatch = useDispatch();
  const calendarRef = useRef();

  let errorsObj = {
    category: "",
    board: "",
    chapter: "",
    classroom: "",
    developer: "",
    examType: "",
    subType: "",
    question: "",
    date: "",
    subject: "",
    amount: "",
    totalQuestion: "",
    totalAmount: "",
  };

  const [errors, setErrors] = useState(errorsObj);

  const [category, setCategory] = useState("");
  const [examType, setExamType] = useState("");
  const [classroom, setClassroom] = useState("");
  const [chapter, setChapter] = useState("");
  const [developer, setDeveloper] = useState("");
  const [board, setBoard] = useState("");
  const [examSubType, setExamSubType] = useState("");
  const [submissionDate, setSubmissionDate] = useState("");
  const [subject, setsubject] = useState("");
  const [amount, setAmount] = useState("");
  const [patternArray, setPatternArray] = useState([
    { type: "", number: "", sets: "", rate: "" },
  ]);

  useEffect(() => {
    dispatch(getExamCategory(props.history));
    dispatch(getClassData(props.history));
    dispatch(getBranchDetails(props.history));
    dispatch(getExamType(props.history));
    dispatch(getBoardData(props.history));
    dispatch(getUserTypeData(props.history));
    dispatch(getQuestionPattern(props.history));
    dispatch(getDeveloperData(0, "", "", "", props.history));
  }, []);

  const selectExamTypeHandler = (examType) => {
    setExamType(examType);
    setClassroom("");
    setExamSubType("");
    setsubject("");
    setChapter("");
    dispatch(subjectLoading(true));
    dispatch(
      getSingleSubjectListDetails(0, 0, examType, 0, category, props.history)
    );
    if (examType == 1) {
      dispatch(getExamSubTypeById(examType, props.history));
    }
  };

  /* select Board handler */
  const selectBoardHandler = (board) => {
    setBoard(board);
    dispatch(subjectLoading(true));
    setClassroom("");
    setChapter("");
    setsubject("");
    dispatch(
      getSingleSubjectListDetails(0, board, 0, 0, category, props.history)
    );
  };

  /* select Category handler */
  const selectCategoryHandeler = (e) => {
    dispatch(getSingleSubjectListActions([]));
    if (e.target.value != "") {
      setCategory(e.target.value);
      setExamType("");
      setClassroom(0);
      setBoard("");
      setChapter("");
      setExamSubType("");
      setsubject("");
    } else {
      setCategory(e.target.value);
      setBoard("");
    }
  };

  /* select chapter handler */
  const getChapterHandler = () => {
    if (category == 1 && board) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          board,
          0,
          0,
          subject,
          classroom,
          props.history
        )
      );
    } else if (category == 2 && examType == 1) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          0,
          examType,
          0,
          subject,
          0,
          props.history
        )
      );
    }
    // else if (category == 2 && examType == 2) {
    else if (category == 2) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          0,
          // examTypeId,
          examType,
          0,
          subject,
          classroom,
          props.history
        )
      );
    } else {
      dispatch(getChaptersDeffarentsParamsAction([]));
    }
  };

  /* form submit handler */
  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (category === "") {
      errorObj.category = "Category is Required";
      error = true;
    }
    if (board === "" && (category == 1 || category == "")) {
      errorObj.board = "Board is Required";
      error = true;
    }
    if (examType === "" && category == 2) {
      errorObj.examType = "Examtype is Required";
      error = true;
    }
    if (examType == 1 && examSubType == "") {
      errorObj.subType = "Sub Type is Required";
      error = true;
    }
    if (subject === "") {
      errorObj.subject = "Subject is Required";
      error = true;
    }
    if (chapter === "") {
      errorObj.chapter = "Chapter is Required";
      error = true;
    }
    if (category == 1 && (classroom === "" || classroom === 0)) {
      errorObj.classroom = "Class is required";
      error = true;
    } else if (
      category == 2 &&
      examType == 2 &&
      (classroom === "" || classroom === 0)
    ) {
      errorObj.classroom = "Class is required";
      error = true;
    }
    if (submissionDate === "") {
      errorObj.date = "Submission Date is Required";
      error = true;
    }
    // if (amount === "") {
    //   errorObj.amount = "Amount is Required";
    //   error = true;
    // }

    if (developer === "") {
      errorObj.developer = "Content developer is required";
      error = true;
    }

    patternArray.forEach((element) => {
      if (
        element.type === "" ||
        element.number === "" ||
        element.rate === "" ||
        (element.type === "CSS" && element.sets == "")
      ) {
        errorObj.question = "Question Type is Required";
        error = true;
      }
    });

    let totalAmount = 0;
    let totalQuestion = 0;
    let arry = [...patternArray];
    for (let x = 0; arry.length > x; x++) {
      if (arry[x].number && arry[x].rate) {
        totalQuestion += parseInt(arry[x].number);
        totalAmount += parseInt(arry[x].number) * parseInt(arry[x].rate);
      }
    }

    if (totalQuestion === 0) {
      errorObj.totalQuestion = "Total Question is Required";
      error = true;
    }

    if (totalAmount === 0) {
      errorObj.totalAmount = "Total Amount is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    // return;
    dispatch(globalLoadingAction(true));
    dispatch(
      addAssignQuestionContentData(
        category ? parseInt(category) : 0,
        category == 1 ? parseInt(board) : 0,
        category == 2 ? parseInt(examType) : 0,
        examType == 1 ? parseInt(examSubType) : 0,
        subject ? parseInt(subject) : 0,
        category == 1 || examType == 2 ? parseInt(classroom) : 0,
        chapter ? parseInt(chapter) : 0,
        patternArray,
        submissionDate ? moment(submissionDate).format("YYYY-MM-DD") : "",
        totalAmount,
        totalQuestion,
        developer ? parseInt(developer) : 0,
        clearData,
        props.history
      )
    );
  }

  /* form data clear */
  const clearData = () => {
    setClassroom("");
    setChapter("");
    setDeveloper("");
    setCategory("");
    setBoard("");
    setExamType("");
    setSubmissionDate("");
    setsubject("");
    setPatternArray([{ type: "", number: "", sets: "", rate: "" }]);
    setExamSubType("");
  };

  /* patten handler */
  const onRemoveHandler = (index) => {
    let arry = [...patternArray];
    arry.splice(index, 1);
    setPatternArray(arry);
  };

  /* QW type handler */
  const onChangeQwsType = (val, index) => {
    let arry = [...patternArray];
    arry[index].type = val;
    setPatternArray(arry);
  };

  /* Qws Sets change handler */
  const onChangeQwsSets = (val, index) => {
    const re = /^[0-9\b]+$/;
    let arry = [...patternArray];
    if (val === "" || re.test(val)) {
      arry[index].sets = val;
      let no = val * 5;
      arry[index].number = val ? no.toString() : "";
      setPatternArray(arry);
    }
  };

  /* Qws number change handler */
  const onChangeQwsNumber = (val, index) => {
    const re = /^[0-9\b]+$/;
    let arry = [...patternArray];
    if (val === "" || re.test(val)) {
      arry[index].number = val;
      setPatternArray(arry);
    }
  };

  /* Qws rate change handler */
  const onChangeQwsRate = (val, index) => {
    const re = /^[0-9\b]+$/;
    let arry = [...patternArray];
    if (val === "" || re.test(val)) {
      arry[index].rate = val;
      setPatternArray(arry);
    }
  };

  /* add new item handler */
  const onAddHandler = () => {
    let arry = [...patternArray];
    let obj = {
      type: "",
      number: "",
      sets: "",
      rate: "",
    };
    arry.push(obj);
    setPatternArray(arry);
  };

  /* disable handler */
  const onAddDisabledItem = () => {
    let arry = [...patternArray];
    let status = false;
    for (let x = 0; arry.length > x; x++) {
      if (arry[x].type === "" || arry[x].number === "" || arry[x].rate === "") {
        status = true;
      } else if (arry[x].type === "CSS" && arry[x].sets === "") {
        status = true;
      }
    }
    return status;
  };

  /* Qws type change handler */
  const onQwsType = (val) => {
    let status;
    let arry = [...patternArray];
    let temp = arry.find((element) => element.type == val);
    if (temp == undefined) {
      status = false;
    } else {
      status = true;
    }
    return status;
  };

  // const onPaymentHandler = (val) => {
  //   const regex = /^[0-9\b]+$/;
  //   if (val === "" || regex.test(val)) {
  //     setAmount(val);
  //   }
  // };

  /* get total question handler */
  const getTotalQuestions = () => {
    let status = 0;
    let arry = [...patternArray];
    for (let x = 0; arry.length > x; x++) {
      if (arry[x].number) {
        status += parseInt(arry[x].number);
      }
    }
    return status == 0 ? "" : status;
  };

  /* get total bumber handler */
  const getTotalAmount = () => {
    let status = 0;
    let arry = [...patternArray];
    for (let x = 0; arry.length > x; x++) {
      if (arry[x].number && arry[x].rate) {
        status += parseInt(arry[x].number) * parseInt(arry[x].rate);
      }
    }
    return status == 0 ? "" : status;
  };

  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  return (
    <Fragment>
      <div className="basic-form">
        <form onSubmit={onSubmit}>
          <div className="form-group mb-3">
            <label className="mb-2">
              <strong>
                Category<span className="text-danger">*</span>
              </strong>
            </label>
            <div className="basic-dropdown">
              <span className="drop_down_arrow">
                <i className="bi bi-caret-down-fill"></i>
              </span>

              <select
                defaultValue={"option"}
                className="form-control form-control"
                value={category}
                onChange={(e) => selectCategoryHandeler(e)}
              >
                <option value="">Select Category</option>
                {props.categoryList.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.category}
                    </option>
                  );
                })}
              </select>
              {errors.category && (
                <div className="text-danger fs-12">{errors.category}</div>
              )}
            </div>
          </div>

          {category == 2 ? (
            <div className="form-group mb-3">
              <label className="mb-2">
                <strong>
                  Exam Type<span className="text-danger">*</span>
                </strong>
              </label>
              <div className="basic-dropdown">
                <span className="drop_down_arrow">
                  <i className="bi bi-caret-down-fill"></i>
                </span>

                <select
                  defaultValue={"option"}
                  className="form-control form-control"
                  value={examType}
                  onChange={(e) => selectExamTypeHandler(e.target.value)}
                >
                  <option value="">Select Examtype</option>
                  {props.typeList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.type_name}
                      </option>
                    );
                  })}
                </select>
                {errors.examType && (
                  <div className="text-danger fs-12">{errors.examType}</div>
                )}
              </div>
            </div>
          ) : (
            <div className="form-group mb-3">
              <label className="mb-2">
                <strong>
                  Board<span className="text-danger">*</span>
                </strong>
              </label>
              <div className="basic-dropdown">
                <span className="drop_down_arrow">
                  <i className="bi bi-caret-down-fill"></i>
                </span>

                <select
                  defaultValue={"option"}
                  className="form-control form-control"
                  value={board}
                  onChange={(e) => selectBoardHandler(e.target.value)}
                >
                  <option value="">Select Board</option>
                  {props.boardList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {errors.board && (
                  <div className="text-danger fs-12">{errors.board}</div>
                )}
              </div>
            </div>
          )}

          {examType == 1 ? (
            <div className="form-group mb-3">
              <label className="mb-2">
                <strong>
                  Exam Sub Type<span className="text-danger">*</span>
                </strong>
              </label>
              <div className="basic-dropdown">
                <span className="drop_down_arrow">
                  <i className="bi bi-caret-down-fill"></i>
                </span>

                <select
                  defaultValue={"option"}
                  className="form-control form-control"
                  value={examSubType}
                  onChange={(e) => setExamSubType(e.target.value)}
                >
                  <option value="">Select Exam Sub Type</option>
                  {props.subList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.subtype_name}
                      </option>
                    );
                  })}
                </select>
                {errors.subType && (
                  <div className="text-danger fs-12">{errors.subType}</div>
                )}
              </div>
            </div>
          ) : null}

          {category == 1 || examType == 2 ? (
            <div className="form-group mb-3">
              <label className="mb-2">
                <strong>
                  Class<span className="text-danger">*</span>
                </strong>
              </label>
              <div className="basic-dropdown">
                <span className="drop_down_arrow">
                  <i className="bi bi-caret-down-fill"></i>
                </span>

                <select
                  defaultValue={"option"}
                  className="form-control form-control"
                  value={classroom}
                  onChange={(e) => {
                    setClassroom(e.target.value);
                    setChapter("");
                    setsubject("");
                  }}
                >
                  <option value="">Select Class</option>
                  {props.classList.map((item, index) => {
                    return (
                      <option key={index} value={item.class_no}>
                        {item.short_code}
                      </option>
                    );
                  })}
                </select>
                {errors.classroom && (
                  <div className="text-danger fs-12">{errors.classroom}</div>
                )}
              </div>
            </div>
          ) : null}

          <div className="form-group mb-3">
            <label className="mb-2">
              <strong>
                Subjects<span className="text-danger">*</span>
              </strong>
            </label>
            <div className="basic-dropdown">
              <span className="drop_down_arrow">
                {props.subjectshowLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <i className="bi bi-caret-down-fill"></i>
                )}
              </span>

              <select
                defaultValue={"option"}
                className="form-control form-control"
                value={subject}
                onChange={(e) => setsubject(e.target.value)}
              >
                <option value="">Select Subject</option>
                {true &&
                  props.getSingleSubjectList.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              {errors.subject && (
                <div className="text-danger fs-12">{errors.subject}</div>
              )}
            </div>
          </div>

          <div className="form-group mb-3">
            <label className="mb-2">
              <strong>
                Chapter<span className="text-danger">*</span>
              </strong>
            </label>
            <div className="basic-dropdown">
              <span className="drop_down_arrow">
                {props.showLoadingChapter ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  <i className="bi bi-caret-down-fill"></i>
                )}
              </span>

              <select
                defaultValue={"option"}
                className="form-control form-control"
                value={chapter}
                onChange={(e) => setChapter(e.target.value)}
                onClick={getChapterHandler}
              >
                <option value="">Select Chapter</option>
                {props.chapterList.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.chapter_name}
                    </option>
                  );
                })}
              </select>
              {errors.chapter && (
                <div className="text-danger fs-12">{errors.chapter}</div>
              )}
            </div>
          </div>

          <div className="form-group mb-3">
            <label className="mb-2">
              <strong>
                Content Developer<span className="text-danger">*</span>
              </strong>
            </label>
            <div className="basic-dropdown">
              <span className="drop_down_arrow">
                <i className="bi bi-caret-down-fill"></i>
              </span>

              <select
                defaultValue={"option"}
                className="form-control form-control"
                value={developer}
                onChange={(e) => setDeveloper(e.target.value)}
              >
                <option value="">Select Content Developer</option>
                {props.developerlist.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </select>
              {errors.developer && (
                <div className="text-danger fs-12">{errors.developer}</div>
              )}
            </div>
          </div>

          <div className="form-group mb-3">
            <label>
              <strong>
                Question Type<span className="text-danger">*</span>
              </strong>
            </label>
            {patternArray.map((item, index) => {
              return (
                <div key={index} className="inline-form-group-view">
                  <div className="basic-dropdown flex-1">
                    <span className="drop_down_arrow">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      defaultValue={"option"}
                      className="form-control"
                      value={item.type}
                      onChange={(e) => onChangeQwsType(e.target.value, index)}
                    >
                      <option value="">Select Question Type</option>
                      {props.patternList.map((i, idex) => {
                        return (
                          <option
                            key={idex}
                            value={i.short_code}
                            disabled={onQwsType(i.short_code)}
                          >
                            {i.short_code}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {item.type === "CSS" ? (
                    <input
                      className="form-control form-control-Qws-pattan"
                      type="number"
                      placeholder="Sets no."
                      value={item.sets}
                      onChange={(e) => onChangeQwsSets(e.target.value, index)}
                      maxLength="3"
                    />
                  ) : null}
                  <input
                    className="form-control form-control-Qws-pattan"
                    placeholder="Questions"
                    type="number"
                    value={item.number}
                    disabled={item.type === "CSS" ? true : false}
                    onChange={(e) => onChangeQwsNumber(e.target.value, index)}
                    maxLength="3"
                  />
                  <input
                    className="form-control form-control-Qws-pattan"
                    placeholder="Rate Per Question"
                    type="number"
                    value={item.rate}
                    onChange={(e) => onChangeQwsRate(e.target.value, index)}
                    maxLength="3"
                  />

                  {index === 0 ? (
                    <button
                      type="button"
                      className="plus-btn"
                      onClick={onAddHandler}
                      disabled={onAddDisabledItem()}
                    >
                      <i class="fa-solid fa-plus"></i>
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => onRemoveHandler(index)}
                      className="plus-btn"
                    >
                      <i className="fa-solid fa-minus"></i>
                    </button>
                  )}
                </div>
              );
            })}
            {errors.question && (
              <div className="text-danger fs-12">{errors.question}</div>
            )}
          </div>

          <div className="form-group mb-3">
            <label className="mb-2">
              <strong>
                Total No. of Questions<span className="text-danger">*</span>
              </strong>
            </label>
            <input
              className="form-control"
              placeholder="Total no. of Questions"
              disabled={true}
              value={getTotalQuestions()}
            />
            {errors.totalQuestion && (
              <div className="text-danger fs-12">{errors.totalQuestion}</div>
            )}
          </div>

          <div className="form-group mb-3">
            <label className="mb-2">
              <strong>
                Date Of Submission<span className="text-danger">*</span>
              </strong>
            </label>
            <div className="date-control-view">
              <DatePicker
                ref={calendarRef}
                selected={submissionDate}
                placeholderText="Select the Submission Date"
                onChange={(date) => setSubmissionDate(date)}
                showYearDropdown
                showMonthDropdown
                // minDate={new Date() }
                minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
                onChangeRaw={handleDateChangeRaw}
              />
              <button
                type="button"
                className="picker-btn"
                onClick={() => calendarRef.current.setOpen(true)}
              >
                <i className="fa-solid fa-calendar-days"></i>
              </button>
            </div>
            {errors.date && (
              <div className="text-danger fs-12">{errors.date}</div>
            )}
          </div>

          <div className="form-group mb-3">
            <label className="mb-2">
              <strong>
                Amount<span className="text-danger">*</span>
              </strong>
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Amount"
              disabled={true}
              value={getTotalAmount()}
            />
            {errors.totalAmount && (
              <div className="text-danger fs-12">{errors.totalAmount}</div>
            )}
          </div>

          <div className="text-center mt-4">
            <button
              type="submit"
              className="btn btn-primary btn-block category-btn"
              disabled={props.showLoading}
            >
              Assign
              {props.showLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.assign.showLoading,
    assignDataClear: state.assign.assignDataClear,
    branchList: state.branch.branchList,
    chapterList: state.chapter.typelist,
    showLoadingChapter: state.chapter.showLoading,
    classList: state.class.list,
    boardList: state.board.list,
    categoryList: state.category.list,
    typeList: state.type.list,
    patternList: state.pattern.list,
    developerlist: state.user.contentDeveloperList,
    subjectList: state.subject.subjectList,
    subjectshowLoading: state.subject.showLoading,
    getSingleSubjectList: state.subject.getSingleSubjectList,
    subList: state.subtype.list,
  };
};
export default connect(mapStateToProps)(Question);
