import { Row, Col, Card } from 'react-bootstrap'
import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { individulResumeData } from '../../../store/actions/ResumeActions';
import globalConfigs from '../../../configs/GlobalConfigs';
import { connect } from "react-redux";
import { Link } from 'react-router-dom'

const ResumeDetails = (props) => {
    const dispatch = useDispatch()
    const { state } = useLocation();
    const history = useHistory()

    const [permission, setPermission] = useState("");

    useEffect(() => {

        dispatch(individulResumeData(state.id))
        permissionCheck()
    }, [])

    const permissionCheck = () => {
        const userDetails = localStorage.getItem("userDetails");
        const tokenDetails = JSON.parse(userDetails);
        const menuList = tokenDetails.menu_list;
        menuList.map((item) => {
            if (item.id == 92) {
                console.log("92----------", item)
                setPermission(item);
            }
        });
    };


    const onDonwloadHandeler = (item) => {

        fetch(item.resume).then(response => {
            response.blob().then(blob => {

                const fileURL = window.URL.createObjectURL(blob);

                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'resume.pdf';
                alink.click();
            })
        }
        )
    }

    const onDonwloadVideoHandeler = (item) => {

        fetch(item.video_session).then(response => {
            response.blob().then(blob => {

                const fileURL = window.URL.createObjectURL(blob);

                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'video.mp4';
                alink.click();
            })
        }
        )
    }

    const onGoBackHandler = () => {
        //history.goBack();
        history.push({
            pathname: '/resume-list',
            state: state
        });
    }

    return (
        <>
            {/* {
                console.log("wow", props.record)
            } */}
            <Fragment>
                <Card>
                    <Card.Body>
                        <div style={{ marginTop: '10px' }} className="back-arrow-container">
                            <Link
                                onClick={onGoBackHandler}>
                                <i class="fa-solid fa-arrow-left"></i>
                            </Link>
                        </div>
                        <Row>
                            <Col lg={4}>
                                <div className="generator-details-container">
                                    <label><strong>First Name :&nbsp;&nbsp; </strong> {props.record.first_name}</label>
                                    <label><strong>DOB :&nbsp;&nbsp; </strong>{props.record.dob}</label>
                                    <label><strong>House/Flat Name :&nbsp;&nbsp; </strong>{props.record.house}</label>
                                    <label><strong>District :&nbsp;&nbsp; </strong>{props.record.district}</label>
                                    <label><strong>Experiance(in years) :&nbsp;&nbsp; </strong>{props.record.relevant_experience}</label>
                                    {
                                        permission.download == "true" && (
                                            <div className='successBox'> <a target="_blank" href={globalConfigs.RESUME + props.record.resume}>Download Resume</a></div>
                                        )}

                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="generator-details-container">
                                    <label><strong>Last Name :&nbsp;&nbsp; </strong> {props.record.last_name}</label>
                                    <label><strong>Gender :&nbsp;&nbsp; </strong>{props.record.gender}</label>
                                    <label><strong>Area/Location :&nbsp;&nbsp; </strong>{props.record.location}</label>
                                    <label><strong>State :&nbsp;&nbsp; </strong>{props.record.state}</label>
                                    <label><strong>Applied For The Post :&nbsp;&nbsp; </strong>{props.record.apply_for == "Sme" ? "SME" : props.record.apply_for}</label>
                                    {
                                        permission.download == "true" && (
                                            props.record.video_session && (<div className='successBox'><a target="_blank" href={globalConfigs.RESUME + props.record.video_session}>Download Video</a></div>)
                                        )}
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="generator-details-container">
                                    <label><strong>Contact Number :&nbsp;&nbsp; </strong>{props.record.phone}</label>
                                    <label><strong>Email :&nbsp;&nbsp; </strong> {props.record.email}</label>
                                    <label><strong>City/Town/Village :&nbsp;&nbsp; </strong>{props.record.town}</label>
                                    <label><strong>Highest Qualification :&nbsp;&nbsp; </strong>{props.record.highest_qualification}</label>


                                </div>
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col lg={12}>
                                <div style={{ padding: '20px 0 20px 0', minHeight: '200px' }} className='page-result'>

                                    {
                                        props.record.video_session == "" ? <span className='no_data'><strong>No Video Found</strong></span> : (
                                            <iframe
                                                width="750"
                                                height="422"
                                                src="https://www.youtube.com/embed/9xwazD5SyVg"
                                                title="YouTube video player"
                                                frameborder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowfullscreen>
                                            </iframe>
                                        )
                                    }

                                </div>
                            </Col>
                        </Row> */}
                    </Card.Body>
                </Card>
            </Fragment>
        </>
    )
}


const mapStateToProps = (state) => {
    return {
        record: state.Resume.individualDetails
    }
}

export default connect(mapStateToProps)(ResumeDetails)

