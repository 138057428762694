import axios from 'axios';
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function dashboardDataApi(selectOption, setDashboardBoxId, filterFromDate, filterToDate,) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    
    const postData = {
        record_type: selectOption == undefined || selectOption == '' ? 3 : selectOption,
        dashboardbox_id: setDashboardBoxId,
        from_date:filterFromDate,
        to_date:filterToDate,
    };
    console.log("dashboardDataApi-Service-postData--", postData )

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_DASHBOARD_DETAILS_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function dashboardDataDownloadApi(selectOption, filterFromDate, filterToDate,) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    const postData = {
        record_type: selectOption,
        from_date:filterFromDate,
        to_date:filterToDate,
    };
    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_DASHBOARD_DETAILS_DOWNLOAD_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

