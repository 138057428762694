import axios from 'axios';
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from '../apis/Apis';

export function getPaymentDetailsList(user_id,content_type,creator_type,category_id,class_no,board,exam_type,subject,chapter,payment_status,page) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;

    let postData ={
        user_id,
        content_type,
        creator_type,
        category_id,
        class_no,
        board,
        exam_type,
        subject,
        chapter,
        payment_status,
        page
    }

    return axios({
        url: GlobalConfigs.API_URL + Apis.GET_PAYMENT_DETAILS_LIST_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}

export function paymentDamUserbyAdmin(id,user_id,paid_amount,due_amount,transction_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let postData ={
        id,
        user_id,
        paid_amount,
        due_amount,
        transction_id
    }
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_PAYMENT_DAMUSER_BY_ADMIN_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });
}

export function paymentTransactionDetailsbyAdmin(id,user_id) {

    let getData = localStorage.getItem('userDetails');
    let token = JSON.parse(getData).token;
    let postData ={
        id,
        user_id
    }
    return axios({
        url: GlobalConfigs.API_URL + Apis.POST_PAYMENT_TRANSACTION_DETAILS_BY_ADMIN_API,
        method: "POST",
        headers: {
            ContentType: "application/json",
            Authorization: `Bearer ${token}`
        },
        data: postData,
    });

}
