import swal from "sweetalert";
import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "../actions/GlobalAction";
import { dashboardDataApi, dashboardDataDownloadApi } from "../../services/DashboardServices";
import { DASHBOARD_DETAILS, DASHBOARD_DATA_LOADING } from "../constants";


export function dashboardDataAction(selectOption, setDashboardBoxId, filterFromDate, filterToDate, callBack, history) {
  
  return (dispatch) => {
    dashboardDataApi(selectOption, setDashboardBoxId, filterFromDate, filterToDate,)
      .then((response) => {
        console.log("dashboardDataAction--Action--", response.data)
        if (response.data.status == 200) {
          if(response.data.dashboard_box != ''){
            dispatch(dashboardDetailsAction(response.data))
          }else{
            callBack()
          }
          
          // dispatch(globalLoadingAction(false));
        }
        // dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        console.log(error);
        /* dispatch(globalLoadingAction(false));
        utility.showError(error.response.data); */
      });
  };
}

export function dashboardDataDownloadAction(selectOption, filterFromDate, filterToDate, history) {
  return (dispatch) => {
    dashboardDataDownloadApi(selectOption, filterFromDate, filterToDate,)
      .then((response) => {
        window.location.href = response.data.filename;
        utility.showSuccess(response.data.msg);
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function dashboardDetailsAction(data) {
  return {
    type: DASHBOARD_DETAILS,
    payload: data
  }
}

export function loadingDashboardDataAction(status) {
  return {
      type: DASHBOARD_DATA_LOADING,
      payload: status,
  };
}