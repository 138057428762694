import React, { useContext, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link, useLocation, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "axios";
import GlobalConfigs from "../../../configs/GlobalConfigs";
import * as Apis from "../../../apis/Apis";

import Loader from "../../components/Loader";

import { validateTextOnly, validateShortCode } from "../../../utility/Utility";
import {
  getBoardData,
  loadingBoardAction,
} from "../../../store/actions/BoardAction";
import {
  getClassData,
  loadingClassAction,
} from "../../../store/actions/ClassAction";
import {
  getSubjects,
  subjectLoading,
  clearSubjectList,
} from "../../../store/actions/SujectActions";
import {
  getTypeExamListData,
  loadingTypeExamAction,
} from "../../../store/actions/TypeExamAction";
import {
  getQuestionPattern,
  loadingPatternAction,
} from "../../../store/actions/QuestionPatternAction";
import {
  editScholasticExamDetails,
  loadingExamDetailsAction,
} from "../../../store/actions/ExamDetailsAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

const UpdateScholasticExamDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();
  const calendarRef = useRef();

  let errorsObj = {
    title: "",
    board: "",
    class: "",
    subject: "",
    syllabus: "",
    academicSession: "",
    examDate: "",
    typeofExam: "",
    pattern: "",
    totalTime: "",
  };

  const [errors, setErrors] = useState(errorsObj);
  const [title, setTitle] = useState(state.item?.title);
  const [boardName, setBoardName] = useState(state.item?.board_id);
  const [fullBoardName, setFullBoardName] = useState("");
  const [conductedBy, setConductedBy] = useState("");
  const [boardShortCode, setBoardShortCode] = useState("");
  const [className, setClassName] = useState(state.item?.class_id);
  const [subject, setSubject] = useState(state.item?.subject_id);
  const [syllabus, setSyllabus] = useState("");
  const [academicSession, setAcademicSession] = useState(
    state.item?.academic_session
  );
  // const [examDate, setExamDate] = useState(new Date(state.item?.exam_date));
  const [examDate, setExamDate] = useState("");

  const [typeOfExam, setTypeOfExam] = useState(state.item?.type_exam);
  const [totalQuestion, setTotalQuestion] = useState(
    state.item?.total_no_question
  );
  const [totalMarks, setTotalMarks] = useState("");
  const [totalTime, setTotalTime] = useState(state.item?.total_time);
  const [patternArray, setPatternArray] = useState([
    {
      type: "",
      value: "",
    },
  ]);
  const [fileUpload, setFileUpload] = useState(null);
  const [oldPdfFile, setOldPdfFile] = useState(
    state.item?.syllabus_file_path.split("/")[
      state.item?.syllabus_file_path.split("/").length - 1
    ]
  );

  const [filterData, setFilterData] = useState("");
  const [fileExt, setFileExt] = useState("");

  useEffect(() => {
    let previousPdfFile =
      state.item?.syllabus_file_path.split("/")[
        state.item?.syllabus_file_path.split("/").length - 1
      ];
    if (previousPdfFile != "" && previousPdfFile != undefined) {
      setFileExt(
        previousPdfFile.substring(previousPdfFile.lastIndexOf(".") + 1)
      );
    }
    setExamDate(
      isDate(state.item?.exam_date)
        ? new Date(state.item?.exam_date)
        : new Date()
    );
    setFilterData(props?.location?.state?.filterData);

    getListOfData();
  }, []);

  const isDate = (str) => {
    let [y, M, d, h, m, s] = str.split(/[- : T Z]/);
    return y && M <= 12 && d <= 31 ? true : false;
  };

  /* form stubmit */
  function onSubmit(e) {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    let arrayObj = {};

    for (let x = 0; patternArray.length > x; x++) {
      if (patternArray[x].type && patternArray[x].value) {
        arrayObj[patternArray[x].type] = patternArray[x].value;
      }
    }

    let objKeys = Object.keys(arrayObj);

    if (title === "") {
      errorObj.title = "Title is Required";
      error = true;
    }
    if (boardName === "") {
      errorObj.board = "Board name is Required";
      error = true;
    }
    if (className === "") {
      errorObj.class = "Class name is Required";
      error = true;
    }
    if (subject === "") {
      errorObj.subject = "Subject is Required";
      error = true;
    }
    // if (oldPdfFile === '' || oldPdfFile === undefined || oldPdfFile === 'undefined') {
    // 	if (syllabus === '') {
    // 		errorObj.syllabus = 'Syllabus is Required';
    // 		error = true;
    // 	}
    // 	if (syllabus !== '' && fileExt != "pdf") {
    // 		errorObj.syllabus = 'Syllabus File Required in PDF Formate';
    // 		error = true;
    // 	}
    // }
    if (academicSession === "") {
      errorObj.academicSession = "Syllabus for academic session is Required";
      error = true;
    }
    if (academicSession === "") {
      errorObj.academicSession = "Syllabus for academic session is Required";
      error = true;
    }
    if (examDate === "") {
      errorObj.examDate = "Tentative exam date is Required";
      error = true;
    }
    if (typeOfExam === "") {
      errorObj.typeofExam = "Type of exam is Required";
      error = true;
    }
    if (objKeys.length == 0) {
      errorObj.pattern = "Type of Question is Required";
      error = true;
    }
    if (totalTime === "") {
      errorObj.totalTime = "Total time is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }

    dispatch(globalLoadingAction(true));
    dispatch(
      editScholasticExamDetails(
        state.item?.id,
        title,
        1,
        boardName,
        className,
        subject,
        syllabus,
        academicSession,
        examDate,
        typeOfExam,
        arrayObj,
        totalQuestion,
        onTotalMarkHandler(),
        totalTime,
        props.history
      )
    );

    setTitle("");
    setBoardName("");
    setFullBoardName("");
    setConductedBy("");
    setBoardShortCode("");
    setClassName("");
    setSubject("");
    setSyllabus("");
    setAcademicSession("");
    setExamDate("");
    setTypeOfExam("");
    setTotalQuestion("");
    setTotalMarks("");
    setTotalTime("");
    setPatternArray([
      {
        type: "",
        value: "",
      },
    ]);
    setFileExt("");
  }

  /* get all data */
  const getListOfData = () => {
    dispatch(loadingBoardAction(true));
    dispatch(getBoardData(props.history));
    dispatch(clearSubjectList([]));
    if (props.boardList.length > 0) {
      const index = props.boardList.findIndex(
        (item) => item.id === parseInt(boardName)
      );
      setFullBoardName(props.boardList[index].full_name);
      setConductedBy(props.boardList[index].conducted_by);
      setBoardShortCode(props.boardList[index].short_code);
    }
    dispatch(getQuestionPattern(props.history));
    dispatch(loadingClassAction(true));
    dispatch(getClassData(props.history));
    dispatch(subjectLoading(true));
    dispatch(getSubjects(0, boardName, 0, 0, props.history));
    dispatch(loadingTypeExamAction(true));
    dispatch(getTypeExamListData(props.history));
    onParse();
  };

  /* set pattern */
  const onParse = () => {
    let arry = [];
    let configuration = state.item?.question_type;
    let parse = JSON.parse(configuration);
    let keys = Object.keys(parse);
    let values = Object.values(parse);
    keys.forEach((item, index) => {
      let temObj = {};
      temObj["type"] = item;
      temObj["value"] = parseInt(values[index]);
      arry.push(temObj);
    });
    setPatternArray(arry);
  };

  /* get board list */
  const getBoardHandler = () => {
    if (props.boardList.length == 0) {
      dispatch(loadingBoardAction(true));
      dispatch(getBoardData(props.history));
    }
  };

  /* change board */
  const onBoardHandler = (id) => {
    setBoardName(id);
    dispatch(clearSubjectList([]));
    const index = props.boardList.findIndex((item) => item.id === parseInt(id));
    setFullBoardName(props.boardList[index].full_name);
    setConductedBy(props.boardList[index].conducted_by);
    setBoardShortCode(props.boardList[index].short_code);
  };

  /* get class list */
  const getClassHandler = () => {
    if (props.classlist == 0) {
      dispatch(loadingClassAction(true));
      dispatch(getClassData(props.history));
    }
  };

  /* get subject list */
  const getSubjectHandler = () => {
    if (boardName) {
      dispatch(subjectLoading(true));
      dispatch(getSubjects(0, boardName, 0, 0, props.history));
    }
  };

  /* get type list */
  const getTypeofExamHandler = () => {
    if (props.typeExamList == 0) {
      dispatch(loadingTypeExamAction(true));
      dispatch(getTypeExamListData(props.history));
    }
  };

  /* get question pattern */
  const getQuesionsPatternHandler = () => {
    if (props.patternList == 0) {
      dispatch(loadingPatternAction(true));
      dispatch(getQuestionPattern(props.history));
    }
  };

  /* change qws type value */
  const onChangeQwsTypeValue = (val, index) => {
    const re = /^[0-9\b]+$/;
    let arry = [...patternArray];
    if (val === "" || re.test(val)) {
      arry[index].value = val;
      setPatternArray(arry);
    }
  };

  /* on blur change type value */
  const onBlurQwsTypeValue = (e) => {
    let arry = [...patternArray];
    let number = 0;
    arry.forEach((item, index) => {
      number += parseInt(item.value);
    });
    setTotalQuestion(number);
  };

  const onChangeQwsType = (val, index) => {
    let arry = [...patternArray];
    arry[index].type = val;
    setPatternArray(arry);
  };

  /* add new item */
  const onAddItem = () => {
    let arry = [...patternArray];
    let status;
    for (let x = 0; arry.length > x; x++) {
      if (arry[x].type === "" || arry[x].value === "") {
        status = true;
      } else {
        status = false;
      }
    }
    return status;
  };

  /* change qws type */
  const onQwsType = (val) => {
    let status;
    let arry = [...patternArray];
    let temp = arry.find((element) => element.type == val);
    if (temp == undefined) {
      status = false;
    } else {
      status = true;
    }
    return status;
  };

  /* add new item */
  const onAddHandler = () => {
    let arry = [...patternArray];
    let obj = {
      type: "",
      value: "",
    };
    arry.push(obj);
    setPatternArray(arry);
  };

  /* item remove */
  const onRemoveHandler = (index) => {
    let arry = [...patternArray];
    arry.splice(index, 1);
    setPatternArray(arry);
  };

  /* calculate total mark */
  const onTotalMarkHandler = () => {
    let arry = [...patternArray];
    let total = 0;

    arry.forEach((item, index) => {
      if (item.type && item.value) {
        props.patternList.forEach((data, i) => {
          if (item.type == data.short_code && data.short_code != "CSS") {
            total += parseInt(item.value) * parseInt(data.marks);
          } else if (item.type == data.short_code && data.short_code == "CSS") {
            data.marks = 10;
            total += parseInt(item.value) * parseInt(data.marks);
          }
        });
      }
    });
    return total;
  };

  /* file upload */
  const onFileHandler = (file) => {
    setFileUpload(0);
    let fileName = file.name;
    var ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    setFileExt(ext);
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("tag_pdf", file);
      axios({
        url: GlobalConfigs.API_URL + Apis.UPLOAD_PDF_API,
        method: "POST",
        headers: {
          ContentType: "multipart/form-data",
        },
        data: formData,
      })
        .then((res) => {
          setSyllabus(res.data.filepath);
          setFileUpload(null);
        })
        .then((json) => {
          //console.log('result2', json);
        });
    });
  };

  /* navigation go back with state */
  const onGoBackHandler = () => {
    history.push({
      pathname: "/scholastic-exam-details-list",
      state: filterData,
    });
  };

  return (
    <>
      <div className="row">

        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="row">
                  <div className="col-xl-3">
                    <div className="back-arrow-container">
                      <button onClick={onGoBackHandler}>
                        <i class="fa-solid fa-arrow-left"></i>
                      </button>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={onSubmit}>
                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Title<span className="text-danger">*</span>
                              </strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter the title"
                              value={title}
                              onChange={(e) => setTitle(e.target.value)}
                            />
                            {errors.title && (
                              <div className="text-danger fs-12">
                                {errors.title}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Board<span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="basic-dropdown">
                              <span className="drop_down_arrow add-Question-arrow-position">
                                {props.boardLoading ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  <i className="bi bi-caret-down-fill"></i>
                                )}
                              </span>
                              <select
                                defaultValue={"option"}
                                className="form-control form-control"
                                value={boardName}
                                onClick={getBoardHandler}
                                onChange={(e) => onBoardHandler(e.target.value)}
                              >
                                <option value="">Select board</option>
                                {props.boardList.map((item, index) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errors.board && (
                              <div className="text-danger fs-12">
                                {errors.board}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>Full board name</strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter the full board name"
                              value={fullBoardName}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>Conducted by</strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter the conducted by"
                              value={conductedBy}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>Usable code</strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter the short code"
                              value={boardShortCode}
                              onChange={(e) =>
                                setBoardShortCode(e.target.value)
                              }
                              disabled
                            />
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Class<span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="basic-dropdown">
                              <span className="drop_down_arrow add-Question-arrow-position">
                                {props.classLoading ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  <i className="bi bi-caret-down-fill"></i>
                                )}
                              </span>
                              <select
                                defaultValue={"option"}
                                className="form-control form-control"
                                value={className}
                                onClick={getClassHandler}
                                onChange={(e) => setClassName(e.target.value)}
                              >
                                <option value="">Select class</option>
                                {props.classlist.map((item, index) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.short_code}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errors.class && (
                              <div className="text-danger fs-12">
                                {errors.class}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Subjects<span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="basic-dropdown">
                              <span className="drop_down_arrow add-Question-arrow-position">
                                {props.subjectLoading ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  <i className="bi bi-caret-down-fill"></i>
                                )}
                              </span>
                              <select
                                defaultValue={"option"}
                                className="form-control form-control"
                                value={subject}
                                onClick={getSubjectHandler}
                                onChange={(e) => setSubject(e.target.value)}
                              >
                                <option value="">Select subjects</option>
                                {props.subjectList.map((item, index) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errors.subject && (
                              <div className="text-danger fs-12">
                                {errors.subject}
                              </div>
                            )}
                          </div>

                          <div className="mb-3">
                            <label className="form-label">
                              <strong>Syllabus</strong>
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              id="formFile"
                              onChange={(e) => onFileHandler(e.target.files[0])}
                              accept="application/pdf"
                              //accept="image/*"
                            />
                            <label className="form-label">
                              <strong>Uploaded file : {oldPdfFile}</strong>
                            </label>
                            {errors.syllabus && (
                              <div className="text-danger fs-12">
                                {errors.syllabus}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Syllabus for academic session
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Enter the academic session"
                              value={academicSession}
                              onChange={(e) =>
                                setAcademicSession(e.target.value)
                              }
                            />
                            {errors.academicSession && (
                              <div className="text-danger fs-12">
                                {errors.academicSession}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Tentative exam date
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="date-control-view">
                              <DatePicker
                                ref={calendarRef}
                                selected={examDate}
                                dateFormat="dd/MM/yyyy"
                                placeholderText="Enter the exam date"
                                onChange={(date) => setExamDate(date)}
                              />
                              <button
                                type="button"
                                className="picker-btn"
                                onClick={() =>
                                  calendarRef.current.setOpen(true)
                                }
                              >
                                <i class="fa-solid fa-calendar-days"></i>
                              </button>
                            </div>
                            {/* <input className="form-control" placeholder="Enter the exam date" value={examDate} onChange={(e) => setExamDate(e.target.value)} /> */}
                            {errors.examDate && (
                              <div className="text-danger fs-12">
                                {errors.examDate}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Type of Exam
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="basic-dropdown">
                              <span className="drop_down_arrow add-Question-arrow-position">
                                {props.typeExamLoading ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  <i className="bi bi-caret-down-fill"></i>
                                )}
                              </span>
                              <select
                                defaultValue={"option"}
                                className="form-control form-control"
                                value={typeOfExam}
                                onClick={getTypeofExamHandler}
                                onChange={(e) => setTypeOfExam(e.target.value)}
                              >
                                <option value="">Select type of exam</option>
                                {props.typeExamList.map((item, index) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.type_of_exam_name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errors.typeofExam && (
                              <div className="text-danger fs-12">
                                {errors.typeofExam}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <div className="question-form-group-view">
                              <label className="type-question-label">
                                <strong>
                                  Type of Question
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <label className="number-question-label">
                                <strong>
                                  No. of Question Type
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                            </div>
                            {patternArray.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="inline-form-group-view"
                                >
                                  <div className="basic-dropdown flex-1">
                                    <span className="drop_down_arrow add-Question-arrow-position">
                                      {props.patternLoading ? (
                                        <Spinner animation="border" size="sm" />
                                      ) : (
                                        <i className="bi bi-caret-down-fill"></i>
                                      )}
                                    </span>
                                    <select
                                      defaultValue={"option"}
                                      className="form-control"
                                      value={item.type}
                                      onClick={getQuesionsPatternHandler}
                                      onChange={(e) =>
                                        onChangeQwsType(e.target.value, index)
                                      }
                                    >
                                      <option value="">
                                        Select question type
                                      </option>
                                      {props.patternList.map((i, idex) => {
                                        return (
                                          <option
                                            key={idex}
                                            value={i.short_code}
                                            disabled={onQwsType(i.short_code)}
                                          >
                                            {i.short_code}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  <input
                                    type="number"
                                    className="form-control form-control-pattan"
                                    placeholder="Enter value"
                                    value={item.value}
                                    onChange={(e) =>
                                      onChangeQwsTypeValue(
                                        e.target.value,
                                        index
                                      )
                                    }
                                    onBlur={(e) => onBlurQwsTypeValue(e)}
                                    maxLength="3"
                                  />
                                  {index === 0 ? (
                                    <button
                                      type="button"
                                      className="plus-btn"
                                      onClick={onAddHandler}
                                      disabled={onAddItem()}
                                    >
                                      <i class="fa-solid fa-plus"></i>
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      onClick={() => onRemoveHandler(index)}
                                      className="plus-btn"
                                    >
                                      <i class="fa-solid fa-minus"></i>
                                    </button>
                                  )}
                                </div>
                              );
                            })}
                            {errors.pattern && (
                              <div className="text-danger fs-12">
                                {errors.pattern}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>Total number of Questions</strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Total number of Questions"
                              disabled
                              value={totalQuestion}
                            />
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>Total Marks</strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Total marks"
                              disabled
                              value={onTotalMarkHandler()}
                            />
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Total time (mins)
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <input
                              className="form-control"
                              placeholder="Total time"
                              value={totalTime}
                              onChange={(e) => setTotalTime(e.target.value)}
                            />
                            {errors.totalTime && (
                              <div className="text-danger fs-12">
                                {errors.totalTime}
                              </div>
                            )}
                          </div>

                          <div className="text-center mt-4">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block category-btn"
                              disabled={props.showLoading}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>{fileUpload === 0 ? <Loader /> : null}</div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    categoryLoading: state.category.showLoading,
    categoryList: state.category.list,
    boardLoading: state.board.showLoading,
    boardList: state.board.list,
    classLoading: state.class.showLoading,
    classlist: state.class.list,
    subjectLoading: state.subject.showLoading,
    subjectList: state.subject.subjectList,
    typeExamLoading: state.typeExam.showLoading,
    typeExamList: state.typeExam.list,
    patternLoading: state.pattern.showLoading,
    patternList: state.pattern.list,
  };
};
export default connect(mapStateToProps)(UpdateScholasticExamDetails);
