import React, { Fragment, useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import Spinner from "react-bootstrap/Spinner";
import { Row, Col, Card, Table, Button, Pagination } from "react-bootstrap";
import Moment from "react-moment";

import { validateEmail, validatePhone, validateName, inputAlphabetOnlyWithSpace, inputNumberOnly, inputAlphabetOnly } from '../../../utility/Utility';

import {
  getContentDesignerData,
  updateContentDesignerStatus,
  removeContentDesignerDetails,
} from "../../../store/actions/ContentDesignerAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { useDebounce } from "use-debounce";

const ContentDesignList = (props) => {
  const dispatch = useDispatch();

  const previousFilterData = props?.location.state?.previousPageFilterData;
  const [active, setActive] = useState(previousFilterData?.active ?? 1);
  const [name, setName] = useState(previousFilterData?.name ?? '');
  const [nameDebounce] = useDebounce(name, 1000);
  const [email, setEmail] = useState(previousFilterData?.email ?? '');
  const [emailDebounce] = useDebounce(email, 1000);
  const [phone, setPhone] = useState(previousFilterData?.phone ?? '');
  const [phoneDebounce] = useDebounce(phone, 1000);
  const [permission, setPermission] = useState("");

  useEffect(() => {
    permissionCheck();
    dispatch(globalLoadingAction(true));
    dispatch(getContentDesignerData(active, name, email, phone, props.history));
  }, []);

  useEffect(() => {
    setActive(1);
    dispatch(globalLoadingAction(true));
    dispatch(
      getContentDesignerData(active, nameDebounce, emailDebounce, phoneDebounce, props.history)
    );
  }, [nameDebounce, emailDebounce, phoneDebounce]);

  /* delete confirmation */
  const onDeleteHandler = (i) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this item !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        if (props.contentdesignerList.length == 1) {
          dispatch(removeContentDesignerDetails(i, active - 1, props.history));
          setActive(active - 1);
        } else {
          dispatch(removeContentDesignerDetails(i, active, props.history));
        }
      }
    });
  };

  /* change item */
  const onUpdateStatus = (item) => {
    dispatch(globalLoadingAction(true));
    dispatch(updateContentDesignerStatus(item, props.history));
  };

  /* name change into state */
  const onNameHandler = (value) => {
    setName(value);
    // setActive(1);
    // dispatch(globalLoadingAction(true));
    // dispatch(getContentDesignerData(1, value, email, props.history));
  };

  /* email change into store */
  const onEmailHandler = (value) => {
    setEmail(value);
    // setActive(1);
    // dispatch(globalLoadingAction(true));
    // dispatch(getContentDesignerData(1, name, value, props.history));
  };

  /* change phone into state */
  const onPhoneHandler = (value) => {
    setPhone(value);
  };


  /* pagination */
  const pageHandler = (data) => {
    setActive(data.selected + 1);
    dispatch(globalLoadingAction(true));
    dispatch(
      getContentDesignerData(data.selected + 1, name, email, phone, props.history)
    );
  };

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 8) {
        console.log("8---------", item);
        setPermission(item);
      }
    });
  };

  const onClearHandlerName = () => {
    dispatch(globalLoadingAction(true));
    dispatch(getContentDesignerData(1, "", email, phone, props.history));
    setActive(1);
    setName("");
  };

  const onClearHandlerEmail = () => {
    dispatch(globalLoadingAction(true));
    dispatch(getContentDesignerData(1, name, "", phone, props.history));
    setActive(1);
    setEmail("");
  };

  const onClearHandlerPhone = () => {
    dispatch(globalLoadingAction(true));
    dispatch(getContentDesignerData(1, name, email, "", props.history));
    setActive(1);
    setPhone("");
  };
  const resetHandler = () => {
    setName('')
    setEmail('')
    setPhone('')

    const stateCopy = { ...props?.location.state?.previousPageFilterData };
    delete stateCopy.active;
    delete stateCopy.name;
    delete stateCopy.email;
    delete stateCopy.phone;
    props.history.replace({ state: stateCopy });

  }

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="row add-Question-list-view">
                <div className="col-sm-3 px-1 clear-question-type">
                  <input
                    className="form-control filter-form-control me-2"
                    placeholder="Designer name"
                    value={name}
                    onChange={(e) => onNameHandler(e.target.value)}
                    onKeyPress={inputAlphabetOnlyWithSpace}
                    autoComplete="off"
                  />
                  {name ? (
                    <button
                      onClick={onClearHandlerName}
                      className="clear-date-btn"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  ) : null}
                </div>
                <div className="col-sm-3 px-1 clear-question-type">
                  <input
                    className="form-control filter-form-control me-2"
                    placeholder="Designer email"
                    value={email}
                    onChange={(e) => onEmailHandler(e.target.value)}
                    autoComplete="off"
                  /> {email ? (
                    <button
                      onClick={onClearHandlerEmail}
                      className="clear-date-btn"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  ) : null}
                </div>
                <div className="col-sm-3 px-1 clear-question-type">
                  <input
                    className="form-control filter-form-control me-2"
                    placeholder="Designer phone number"
                    value={phone}
                    onChange={(e) => onPhoneHandler(e.target.value)}
                    onKeyPress={inputNumberOnly}
                    maxlength="10"
                    autoComplete="off"
                  /> {phone ? (
                    <button
                      onClick={onClearHandlerPhone}
                      className="clear-date-btn"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  ) : null}
                </div>
                <div style={{ paddingTop: '5px' }} className="col-sm-1 px-1 ">
                  <button onClick={resetHandler} className={`btn btn-secondary ${name == '' && email == '' && phone == '' ? 'content_disable' : null}`}>Reset</button>
                </div>
              </div>


              {props.contentdesignerList.length == 0 ? (
                <div className="no_data">
                  <strong>No data found</strong>
                </div>
              ) : (
                <>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>
                          <strong>Name</strong>
                        </th>

                        <th>
                          <strong>Email</strong>
                        </th>
                        {/* <th>
                          <strong>User Type</strong>
                        </th> */}
                        <th>
                          <strong>Phone No.</strong>
                        </th>

                        <th>
                          <strong>Create Date</strong>
                        </th>

                        {permission.status == "true" && (
                          <th width={120}>
                            <strong>Status</strong>
                          </th>
                        )}

                        {(permission.edit == "true" ||
                          permission.delete == "true") && (
                            <th>
                              <strong>Action</strong>
                            </th>
                          )}
                      </tr>
                    </thead>
                    <tbody>
                      {props.contentdesignerList.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            {/* <td>{item.developer_type}</td> */}
                            <td>{item.phone}</td>
                            <td>
                              <Moment format="D MMM YYYY" withTitle>
                                {item.created_at}
                              </Moment>
                            </td>

                            {permission.status == "true" && (
                              <td>
                                <Button
                                  disabled={props.showLoading}
                                  className="btn-xs"
                                  variant={item.status == 1 ? "success" : "danger"}
                                  onClick={() => onUpdateStatus(item)}
                                >
                                  {item.status == 1 ? "Active" : "Inactive"}
                                </Button>
                              </td>
                            )}
                            {(permission.edit == "true" ||
                              permission.delete == "true") && (
                                <td>
                                  <div className="d-flex">
                                    {permission.edit == "true" && (
                                      <Link
                                        to={{
                                          pathname: "/update-content-designer",
                                          state: { item, filterData: { active, name, email, phone } }

                                        }}
                                        className="btn btn-warning shadow btn-xs sharp me-1"
                                      >
                                        <i className="fas fa-pencil-alt"></i>
                                      </Link>
                                    )}

                                    {permission.delete == "true" && (
                                      <Button
                                        className="btn btn-danger shadow btn-xs sharp"
                                        onClick={() => onDeleteHandler(item.id)}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </Button>
                                    )}
                                  </div>
                                </td>
                              )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  <div className="pagination-container">
                    <div className="page-result">
                      <p>
                        Showing: {(active - 1) * 10 + 1} -{" "}
                        {10 * active - (10 - props.contentdesignerList.length)} of{" "}
                        {props.totalRecord}
                      </p>
                    </div>
                    {props.totalRecord > 10 ?
                      <ReactPaginate
                        containerClassName="pagination-gutter  false pagination-circle pagination pagination-sm"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        nextLabel={<i class="la la-angle-right"></i>}
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        previousLabel={<i class="la la-angle-left"></i>}
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        renderOnZeroPageCount={null}
                        activeClassName="active"
                        onPageChange={pageHandler}
                        pageRangeDisplayed={3}
                        pageCount={props.totalPage}
                      />
                      : null
                    }
                  </div>
                </>)}



            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.contentdesigner.showLoading,
    contentdesignerList: state.contentdesigner.list,
    totalPage: state.contentdesigner.totalPage,
    totalRecord: state.contentdesigner.totalRecord,
  };
};
export default connect(mapStateToProps)(ContentDesignList);
