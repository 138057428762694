import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { connect, useDispatch } from "react-redux";
import { BsJournalMedical } from "react-icons/bs";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Pagination,
  Modal,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ReactPaginate from "react-paginate";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import {
  getPaymentDetailsListData,
  paymentDamUserbyAdminData,
  paymentTransactionDetailsbyAdminList,
  paymentLoadingAction,
} from "../../../store/actions/PaymentAction";
import { getExamCategory } from "../../../store/actions/ExamCategoryAction";
import { getBoardData } from "../../../store/actions/BoardAction";
import { getExamType } from "../../../store/actions/ExamTypeAction";
import { getClassData } from "../../../store/actions/ClassAction";
import {
  getSingleSubjectListDetails,
  subjectLoading,
} from "../../../store/actions/SujectActions";
import {
  getChaptersDeffarentsParamsData,
  loadingChaptersAction,
} from "../../../store/actions/ChapterAction";
import { getAllDeveloperData } from "../../../store/actions/UserAction";
import AutocompleteSearch from "../AutocompleteSearch/AutocompleteSearch";
import sort from "../../../images/dashboard_image/sort.png";

const ApprovedContentList = (props) => {
  const dispatch = useDispatch();

  let errorsObj = { paid: "", transId: "", due: "" };
  const [isFilter, setIsFilter] = useState(false);
  const [errors, setErrors] = useState(errorsObj);

  const [active, setActive] = useState(1);
  const [name, setName] = useState("");
  const [developerId, setDeveloperId] = useState("");
  const [creatorType, setCreatorType] = useState("");
  const [contentType, setContentType] = useState("");
  const [category, setCategory] = useState("");
  const [board, setBoard] = useState("");
  const [type, setType] = useState("");
  const [className, setClassName] = useState("");
  const [subject, setSubject] = useState("");
  const [chapter, setChapter] = useState("");
  const [payStatus, setPayStatus] = useState("");
  const [isModal, setIsModal] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [payment, setPayment] = useState({ paid: "", transId: "", due: 0 });
  const [nameData, setNameData] = useState([]);
  const [singleItem, setSingleItem] = useState({});
  const [transctionDetails, setTransctionDetails] = useState([]);
  const [filterObj, setFilterObj] = useState({
    name: "",
    developerId: "",
    creatorType: "",
    contentType: "",
    category: "",
    board: "",
    type: "",
    className: "",
    subject: "",
    chapter: "",
    payStatus: "",
  });
  const [permission, setPermission] = useState("");

  useEffect(() => {
    dispatch(getAllDeveloperData(props.history));
    dispatch(globalLoadingAction(true));
    dispatch(
      getPaymentDetailsListData(
        developerId,
        contentType,
        creatorType,
        category,
        className,
        board,
        type,
        subject,
        chapter,
        payStatus,
        active,
        props.history
      )
    );
    permissionCheck()
  }, []);

  useEffect(() => {
    if (isFilter) {
      dispatch(getExamCategory(props.history));
      dispatch(getBoardData(props.history));
      dispatch(getExamType(props.history));
      dispatch(getClassData(props.history));
    }
  }, [isFilter]);

  useEffect(() => {
    if (props.developerList.length > 0) {
      const arry = props.developerList.map((elm) => elm.name);
      setNameData(arry);
    }
  }, [props.developerList]);

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 103) {
        console.log("103------", item)
        setPermission(item);
      }
    });
  };

  const onNameHandler = (name) => {
    const developer = props.developerList.find(
      (element) => element.name === name
    );
    setDeveloperId(developer ? developer.id : "");
    setName(name);
  };

  const onFilterRegisteredHandler = () => {
    dispatch(globalLoadingAction(true));
    dispatch(
      getPaymentDetailsListData(
        developerId ? developerId : name,
        contentType,
        creatorType,
        category,
        className,
        board,
        type,
        subject,
        chapter,
        payStatus,
        1,
        props.history
      )
    );
    setActive(1);
    setIsFilter(false);
    setFilterObj((prev) => ({
      ...prev,
      name,
      developerId,
      creatorType,
      contentType,
      category,
      board,
      type,
      className,
      subject,
      chapter,
      payStatus,
    }));
  };

  const onResetHandler = () => {
    setIsFilter(false);
    dispatch(globalLoadingAction(true));
    dispatch(
      getPaymentDetailsListData(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        1,
        props.history
      )
    );
    setActive(1);
    setName("");
    setDeveloperId("");
    setCreatorType("");
    setContentType("");
    setCategory("");
    setBoard("");
    setType("");
    setClassName("");
    setSubject("");
    setChapter("");
    setPayStatus("");
    setFilterObj((prev) => ({
      ...prev,
      name: "",
      developerId: "",
      creatorType: "",
      contentType: "",
      category: "",
      board: "",
      type: "",
      className: "",
      subject: "",
      chapter: "",
      payStatus: "",
    }));
  };

  const handlePageClick = (data) => {
    setActive(data.selected + 1);
    dispatch(globalLoadingAction(true));
    dispatch(
      getPaymentDetailsListData(
        developerId,
        contentType,
        creatorType,
        category,
        className,
        board,
        type,
        subject,
        chapter,
        payStatus,
        data.selected + 1,
        props.history
      )
    );
  };

  const getContentDetails = (data) => {
    return data.map((element, index) => (
      <p className="approved-content-text" key={index}>
        {element.type}: {element.value}
      </p>
    ));
  };

  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (payment.paid === "") {
      errorObj.paid = "Paid Amount is Required";
      error = true;
    }
    if (payment.transId === "") {
      errorObj.transId = "Transaction Id is Required";
      error = true;
    }
    if (payment.due === "") {
      errorObj.due = "Due Amount is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(paymentLoadingAction(true));
    dispatch(
      paymentDamUserbyAdminData(
        singleItem.id,
        singleItem.user_id,
        payment.paid,
        payment.due,
        payment.transId,
        submitCallback,
        props.history
      )
    );
  }

  const submitCallback = () => {
    setIsPayment(false);
    dispatch(globalLoadingAction(true));
    dispatch(
      getPaymentDetailsListData(
        developerId,
        contentType,
        creatorType,
        category,
        className,
        board,
        type,
        subject,
        chapter,
        payStatus,
        active,
        props.history
      )
    );
  };

  const onPaidHandler = (val) => {
    let dueAmount = singleItem.due_amount;
    const regex = /^[0-9\b]+$/;
    if ((val === "" || regex.test(val)) && Number(val) <= dueAmount) {
      setPayment({
        ...payment,
        paid: Number(val),
        due: dueAmount - Number(val),
      });
    }
  };

  const onTransactionHandler = (val) => {
    const regex = /^[a-zA-Z0-9]+$/;
    if (val === "" || regex.test(val)) {
      setPayment({ ...payment, transId: val });
    }
  };

  const cancelModal = () => {
    setIsFilter(false);
  };

  const categoryHandler = (val) => {
    setCategory(val);
    setBoard("");
    setType("");
    setSubject("");
    setClassName("");
    setChapter("");
  };

  const boardTypeChangeHandler = (val) => {
    if (category == 1) {
      setBoard(val);
      setType("");
    } else {
      setType(val);
      setBoard("");
    }
    setSubject("");
    setClassName("");
    setChapter("");
  };

  const onClassNameHandler = (val) => {
    setClassName(val);
    setSubject("");
    setChapter("");
  };

  const onSubjectHandler = (val) => {
    setSubject(val);
    setChapter("");
  };

  /* on change subject */
  const getSubjectHandler = () => {
    dispatch(subjectLoading(true));
    dispatch(
      getSingleSubjectListDetails(
        0,
        category == 1 ? board : 0,
        category == 2 ? type : 0,
        0,
        category,
        props.history
      )
    );
  };

  /* get chapter list */
  const getChapterHandler = () => {
    dispatch(loadingChaptersAction(true));
    dispatch(
      getChaptersDeffarentsParamsData(
        category ? parseInt(category) : 0,
        category == 1 ? board : 0,
        category == 2 ? type : 0,
        0,
        subject ? subject : 0,
        category == 1 || type == 2 ? className : 0,
        props.history
      )
    );
  };

  const setPaymentHandler = (item) => {
    setPayment({ paid: "", transId: "", due: 0 });
    setSingleItem(item);
    setIsPayment(true);
  };

  const getTransactionDetails = (item) => {
    setIsModal(true);
    dispatch(globalLoadingAction(true));
    dispatch(
      paymentTransactionDetailsbyAdminList(
        item.id,
        item.user_id,
        getTransactionDetailsCallBack,
        props.history
      )
    );
  };

  const getTransactionDetailsCallBack = (item) => {
    setTransctionDetails(item.data);
    setIsModal(true);
  };

  const isDisabled =
    name ||
    creatorType ||
    contentType ||
    category ||
    board ||
    type ||
    className ||
    subject ||
    chapter ||
    payStatus;

  const enableFilter =
    (filterObj.name && filterObj.developerId) ||
    filterObj.creatorType ||
    filterObj.contentType ||
    filterObj.category ||
    filterObj.board ||
    filterObj.type ||
    filterObj.className ||
    filterObj.subject ||
    filterObj.chapter ||
    filterObj.payStatus;

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="contentlist-filter-view mb-3">
                <div className="reset-btn-view">
                  <div
                    className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 filter_background rounded cursorPointer ${enableFilter ? "border_red" : null
                      }`}
                    onClick={() => setIsFilter(true)}
                  >
                    <div className="image_container">
                      <img src={sort} alt="" className="image_style" />
                    </div>
                    <div className="button_text font_color_black">Filter</div>
                  </div>
                </div>
              </div>
              <Table responsive>
                <thead>
                  <tr>
                    <th className="col_heading">
                      <strong>Name</strong>
                    </th>
                    <th className="col_heading">
                      <strong>Creator Type</strong>
                    </th>
                    <th className="col_heading">
                      <strong>Content Type</strong>
                    </th>
                    <th className="col_heading">
                      <strong>Content Details</strong>
                    </th>
                    <th width={200} className="col_heading">
                      <strong>Date</strong>
                    </th>
                    <th className="chapter_col col_heading">
                      <strong>Payment Details</strong>
                    </th>

                    <th className="col_heading">
                      <strong>Payment Status</strong>
                    </th>

                    <th className="col_heading">
                      <strong>Action</strong>
                    </th>

                  </tr>
                </thead>
                {props.list.length > 0 ? (
                  <tbody>
                    {props.list.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.creator_type}</td>
                          <td>{item.content_type}</td>
                          <td>{getContentDetails(item.content_details)}</td>
                          <td>
                            <p className="approved-content-text">
                              Created Date:{item.created_date}
                            </p>
                            <p className="approved-content-text">
                              Submitted Date:{item.submited_date}
                            </p>
                            <p className="approved-content-text">
                              Approved Date:{item.approved_date}
                            </p>
                          </td>
                          <td>
                            <p className="approved-content-text">
                              Total Amount: {item.total_payment}
                            </p>
                            <p className="approved-content-text">
                              Paid Amount: {item.paid_amount}
                            </p>
                            <p className="approved-content-text">
                              Due Amount: {item.due_amount}
                            </p>
                          </td>
                          <td className="text-center">
                            {item.payment_status == 1 ? "Due" : "Paid"}
                          </td>
                          <td>
                            <div className="assign-question-btn-group">
                              {
                                (permission.pay_now == "true") && (
                                  <button
                                    disabled={
                                      item.payment_status == 2 ? true : false
                                    }
                                    className="btn btn-secondary btn-xs pay-btn"
                                    onClick={() => setPaymentHandler(item)}
                                  >
                                    Pay Now
                                  </button>
                                )}
                              {
                                <OverlayTrigger
                                  delay={{ hide: 450, show: 300 }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>Details</Tooltip>
                                  )}
                                  placement="top"
                                >
                                  <button
                                    className={`btn btn-secondary history_background shadow btn-xs sharp ms-1`}
                                    onClick={() => getTransactionDetails(item)}
                                  >
                                    <BsJournalMedical size={15} />
                                  </button>
                                </OverlayTrigger>
                              }
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={13}>
                        <div className="no_data">
                          <strong>No data found</strong>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              <div className="pagination-container">
                {parseInt(props.totalRecord) > 0 ? (
                  <div className="page-result">
                    <p>
                      Showing{" "}
                      {(active - 1) * 10 +
                        1 +
                        " - " +
                        ((active - 1) * 10 + props.list.length) +
                        " of " +
                        "( " +
                        props.totalRecord +
                        " items )"}
                    </p>
                  </div>
                ) : null}

                {parseInt(props.totalRecord) > 10 ? (
                  <ReactPaginate
                    containerClassName="pagination-gutter false pagination-circle pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    breakLabel="..."
                    nextLabel={<i className="la la-angle-right"></i>}
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    previousLabel={<i className="la la-angle-left"></i>}
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    renderOnZeroPageCount={null}
                    activeClassName="active"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={props.totalPage}
                    //initialPage={active - 1}
                    forcePage={active - 1}
                  />
                ) : null}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <!-- Modal --> */}
      <Modal className="fade" show={isFilter} size="md">
        <Modal.Header>
          <Modal.Title>Filter</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => cancelModal()}
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="question-modal-form">
            <div className="reset-filtter-value">
              <button
                disabled={!isDisabled}
                className="btn btn-info btn-xs"
                onClick={() => onResetHandler()}
              >
                Reset
              </button>
            </div>

            <div className="row">
              <div className="form-group col-sm-6 px-1 mb-3">
                <AutocompleteSearch
                  dataDetails={nameData}
                  placeholder="Name"
                  setNewValue={(e) => onNameHandler(e)}
                  selectValue={name}
                />
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={creatorType}
                    onChange={(e) => setCreatorType(e.target.value)}
                  >
                    <option value="select_category">Select Creator Type</option>
                    <option value="1">Developer</option>
                    <option value="2">Validator</option>
                    <option value="3">Language Validator</option>
                    <option value="4">Designer</option>
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={contentType}
                    onChange={(e) => setContentType(e.target.value)}
                  >
                    <option value="select_category">Select Content Type</option>
                    <option value="E">e-Library</option>
                    <option value="Q">Question</option>
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={category}
                    onChange={(e) => categoryHandler(e.target.value)}
                  >
                    <option value="select_category">Select Category</option>
                    {props.categoryList?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.category}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div
                className={`form-group col-sm-6 px-1 mb-3 ${category == "" ? "content_disable" : null
                  }`}
              >
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={category == 1 ? board : type}
                    onChange={(e) => boardTypeChangeHandler(e.target.value)}
                  >
                    <option
                      value={category == 1 ? "select_baord" : "select_type"}
                    >
                      {category == 1 ? "Select Baord" : "Select Type"}
                    </option>
                    {category == 1
                      ? props?.boardList?.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })
                      : props?.typeList?.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.type_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className={`form-control ${category == 2 && type == 1 ? "content_disable" : null
                      }`}
                    value={className}
                    onChange={(e) => onClassNameHandler(e.target.value)}
                  >
                    <option value="select_class">Select Class</option>
                    {props.classList?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.class_no}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    {props.subjectLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <i className="bi bi-caret-down-fill"></i>
                    )}
                  </span>
                  <select
                    defaultValue={"option"}
                    className={`form-control ${(category && board) || (category && type)
                      ? null
                      : "content_disable"
                      }`}
                    value={subject}
                    onClick={() => getSubjectHandler()}
                    onChange={(e) => onSubjectHandler(e.target.value)}
                  >
                    <option value="select_subjects">Select Subject</option>
                    {props.subjectList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group col-sm-6 px-1">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    {props.chapterLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <i className="bi bi-caret-down-fill"></i>
                    )}
                  </span>
                  <select
                    defaultValue={"option"}
                    className={`form-control ${subject ? null : "content_disable"
                      }`}
                    value={chapter}
                    onClick={() => getChapterHandler()}
                    onChange={(e) => setChapter(e.target.value)}
                  >
                    <option value="select_chapter_name">
                      Select Chapter Name
                    </option>
                    {props.chapterList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.chapter_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group col-sm-6 px-1">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={payStatus}
                    onChange={(e) => setPayStatus(e.target.value)}
                  >
                    <option value="select_chapter_name">
                      Select Payment Status
                    </option>
                    <option value="1">Due</option>
                    <option value="2">Paid</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => cancelModal()} variant="danger light">
            Cancel
          </Button>
          <Button
            disabled={!isDisabled}
            onClick={() => onFilterRegisteredHandler()}
            variant="primary"
          >
            Filter
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal className="fade bd-example-modal-lg" show={isModal} size="lg">
        <Modal.Header>
          <Modal.Title>Transaction Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <Table responsive>
            <thead>
              <tr>
                <th className="col_heading">
                  <strong>Transaction ID</strong>
                </th>
                <th className="col_heading">
                  <strong>Amount</strong>
                </th>
                <th className="col_heading">
                  <strong>Date</strong>
                </th>
              </tr>
            </thead>
            {transctionDetails.length > 0 ? (
              <tbody>
                {transctionDetails.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.transaction_id}</td>
                      <td>{item.paid_amount}</td>
                      <td>
                        {item.created_at
                          ? moment(item.created_at).format("DD/MM/YYYY")
                          : ""}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : null}
          </Table>
          {transctionDetails.length > 0 ? null : (
            <div className="mt-3">
              <h4 className="text-center">No Data Found</h4>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal className="fade bd-example-modal-lg" show={isPayment} size="lg">
        <Modal.Header>
          <Modal.Title>
            <h3>Payment Information</h3>
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsPayment(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <div className="basic-form">
            <form onSubmit={onSubmit}>
              <div className="form-group mb-3 two_column">
                <div className="mb-2 one_column">
                  <h4>
                    <strong>User Name :</strong> {singleItem.name}
                  </h4>
                </div>
                <div className="mb-2 one_column">
                  <h4>
                    <strong>Total Amount :</strong> {singleItem.total_payment}/-
                  </h4>
                </div>
                <div className="mb-2 one_column">
                  <h4>
                    <strong>Already Paid :</strong> {singleItem.paid_amount}/-
                  </h4>
                </div>
              </div>

              <div className="form-group mb-3">
                <label className="mb-2">
                  <strong>
                    Paid
                    <span className="text-danger">*</span>
                  </strong>
                </label>
                <input
                  className="form-control placeHolder"
                  placeholder="Enter the Amount"
                  value={payment.paid}
                  onChange={(e) => onPaidHandler(e.target.value)}
                  maxLength="5"
                />
                {errors.paid && (
                  <div className="text-danger fs-12">{errors.paid}</div>
                )}
              </div>
              <div className="form-group mb-3">
                <label className="mb-2">
                  <strong>
                    Transaction ID
                    <span className="text-danger">*</span>
                  </strong>
                </label>
                <input
                  className="form-control placeHolder"
                  placeholder="Enter the Transaction ID"
                  value={payment.transId}
                  onChange={(e) => onTransactionHandler(e.target.value)}
                  maxLength="20"
                />
                {errors.transId && (
                  <div className="text-danger fs-12">{errors.transId}</div>
                )}
              </div>
              <div className="form-group mb-3">
                <label className="mb-2">
                  <strong>
                    Due
                    <span className="text-danger">*</span>
                  </strong>
                </label>
                <input
                  className="form-control placeHolder"
                  placeholder="Enter the Amount"
                  value={payment.due}
                // onChange={(e) =>
                //   setPayment({ ...payment, due: e.target.value })
                // }
                //maxLength="5"
                />
                {errors.due && (
                  <div className="text-danger fs-12">{errors.due}</div>
                )}
              </div>

              <div className="text-center mt-4">
                <button
                  type="submit"
                  className="btn btn-primary btn-block category-btn"
                  disabled={props.showLoading}
                >
                  Submit
                  {props.showLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    showLoading: state.payment.showLoading,
    list: state.payment.list,
    totalRecord: state.payment.totalRecord,
    totalPage: state.payment.totalPage,
    categoryList: state.category.list,
    boardList: state.board.list,
    typeList: state.type.list,
    classList: state.class.list,
    subjectList: state.subject.getSingleSubjectList,
    subjectLoading: state.subject.showLoading,
    chapterList: state.chapter.typelist,
    chapterLoading: state.chapter.showLoading,
    developerList: state.user.allDeveloperList,
  };
};
export default connect(mapStateToProps)(ApprovedContentList);
