import axios from "axios";
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function MisDashboardInfo() {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  /* const postData = {
    datediff,
  }; */

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_DASHBOARD_INFO,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    // data: postData,
  });
}

export function misInvoicesApi(start_date, end_date, transaction_id, order_id, payment_mode, page) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    start_date,
    end_date,
    transaction_id,
    order_id,
    payment_mode,
    page
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_INVOICES_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function invoiceDownloadExcelDataApi(start_date, end_date, transaction_id, order_id, payment_mode, page) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    start_date,
    end_date,
    transaction_id,
    order_id,
    payment_mode,
    page
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_INVOICES_EXCEL_EXPORT_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misTransactionListApi(transactionId, orderId, studentId, transactionstatus, mobileNo, paymentMode, page) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    page,
    trans_id: transactionId,
    order_id: orderId,
    student_id: studentId,
    trans_status: transactionstatus,
    mobile: mobileNo,
    payment_mode: paymentMode
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_PAYMENT_TRANSACTION_DETAILS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misTransactionExcelDownloadApi(transactionId, orderId, studentId, transactionstatus, mobileNo, paymentMode, page) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    page,
    trans_id: transactionId,
    order_id: orderId,
    student_id: studentId,
    trans_status: transactionstatus,
    mobile: mobileNo,
    payment_mode: paymentMode
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_PAYMENT_TRANSACTION_DETAILS_EXCEL_EXPORT_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misPaymentRefundApi(trans_id, order_id, amount, pay_mode, id) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    trans_id,
    order_id,
    amount,
    pay_mode,
    id
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_REFUND_PAYMENT_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misAutoSuggestOrderIdApi() {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_AUTO_SUGGEST_ORDER_ID_LIST_API,
    method: "GET",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },

  });
}

export function misAutoSuggestMobileNoApi() {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_AUTO_SUGGEST_MOBILE_NO_LIST_API,
    method: "GET",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },

  });
}

export function misAutoSuggestTransactionNoApi() {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_AUTO_SUGGEST_TRANSACTION_NO_LIST_API,
    method: "GET",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },

  });
}

export function misMarketingFeedbackRatingApi(select_services) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    select_services
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_MARKETING_FEEDBACK_RATING_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misMarketingFeedbackRatingByquestionsApi(question_id, select_services) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    question_id,
    select_services
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_MARKETING_FEEDBACK_RATING_BY_QUESTIONS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misMarketingFeedbackRatingExcelDownloadApi(select_services) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    select_services
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_MARKETING_FEEDBACK_RATING_Excel_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misSubscribedUsersApi(select_board, select_class, course_completion, select_services, email, subscribe_type, min_price, max_price, page) {

  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    select_board,
    select_class,
    course_completion,
    select_services,
    email, subscribe_type,
    min_price,
    max_price,
    page
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_SUBSCRIBE_USER_ACTIVITY_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misSubscribedUsersExcelDownloadDataApi(select_board, select_class, course_completion, select_services, email, subscribe_type, min_price, max_price, page) {

  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    select_board,
    select_class,
    course_completion,
    select_services,
    email,
    subscribe_type,
    // select_subscribe_from_date,
    // select_subscribe_to_date,
    min_price, max_price,
    page
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_SUBSCRIBED_USER_ACTIVITY_EXCEL_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misSubscribedUsersStudentSubscriptionApi(student_id) {

  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    student_id
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_SUBSCRIBE_USER_GET_STUDENT_SUBSCRIPTION_DETAILS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misSubscribedUsersStudentActivitiesApi(student_id) {

  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    student_id
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_SUBSCRIPTION_DETAILS_DETAILS_BY_STUDENT_ID_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misSubscribedUserstudentActivitiesExcelDownloadApi(student_id) {

  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    student_id,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_SUBSCRIPTION_DETAILS_BY_STUDENT_ID_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misContentMisListDataApi(category, board, type, subtype, className, subject, chapterCode, assignTo, stage, status, contentType, chapter, page) {

  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    exam_category: category,
    board,
    exam_type: type,
    sub_type: subtype,
    class_no: className,
    subject,
    chapter_code: chapterCode,
    assign_to: assignTo,
    stage,
    status,
    content_type: contentType,
    chapter_name: chapter,
    page
  };
  console.log('postData..', postData);
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_CONTENT_MIS_REPORT_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misContentMisContentDetailsApi(content_id, request_type) {

  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    content_id, request_type
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_CONTENTMIS_REPORT_DETAILS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}



export function misContentMisExcelApi(category, board, type, subtype, className, subject, chapterCode, assignTo, stage, status, contentType, chapter, page) {

  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    exam_category: category,
    board,
    exam_type: type,
    sub_type: subtype,
    class_no: className,
    subject,
    chapter_code: chapterCode,
    assign_to: assignTo,
    stage,
    status,
    content_type: contentType,
    chapter_name: chapter,
    page
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_CONTENTMIS_REPORT_EXCEL_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misConcurrentActiveUserApi(period, activity, startDate, endDate, startTime, endTime, page) {

  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    period,
    activity,
    from_date: startDate,
    to_date: endDate,
    from_time: startTime,
    to_time: endTime,
    page
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_CONCURRENT_ACTIVE_USERS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misConcurrentActiveUserDetailsApi(board, className, schoolName, page, student_ids, activity_id, search_date) {

  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    board,
    className,
    school_name: schoolName,
    page,
    student_ids,
    activity_id,
    search_date
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_CONCURRENT_USER_DETAILS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misConcurrentUserDetailsStudentActivityApi(activity, search_date, to_time, from_time, student_id) {

  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    activity, search_date, to_time, from_time, student_id
  };

  console.log("postData----------1212", postData)

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_CONCURRENT_USER_ACTIVITY_STUDENT_WISE_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misStudentSubscriptionDetailsExcelDownloadApi(student_id) {

  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    student_id
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_STUDENT_SUBSCRIPTION_DETAILS_EXCEL_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misAutoSuggestAssigntoApi() {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_AUTOSUGGEST_ASSIGNTO_API,
    method: "GET",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },

  });
}

export function misAutosuggestChaptercodeApi() {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_AUTOSUGGEST_CHAPTERCODE_API,
    method: "GET",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },

  });
}

export function concurrentActiveUserDownloadExcelApi(period, activity, startDate, endDate, startTime, endTime, page) {

  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    period,
    activity,
    from_date: startDate,
    to_date: endDate,
    from_time: startTime,
    to_time: endTime,
    page
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_CONCURRENT_ACTIVE_USERS_EXCEL_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

//

export function misConcurrentActiveUserDetailsExcelApi(board, className, schoolName, page, student_ids, activity_id, search_date) {

  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    board,
    className,
    school_name: schoolName,
    page,
    student_ids,
    activity_id,
    search_date
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_CONCURRENT_USER_DETAILS_EXCEL_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function MisDashboardDetailsApi(dashboardbox_id, from_date, to_date, record_type) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    dashboardbox_id,
    from_date,
    to_date,
    record_type: record_type == undefined || record_type == '' ? 3 : record_type
  };

  console.log("MisDashboardDetailsApi -- Service -- postData-----", postData)

  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_DASHBOARD_DETAILS_DATA,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function misdashboardDataDownloadApi(dashboardbox_id, from_date, to_date, record_type,) {

  let getData = localStorage.getItem('userDetails');
  let token = JSON.parse(getData).token;

  const postData = {
    dashboardbox_id,
    from_date,
    to_date,
    record_type,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_DASHBOARD_DETAILS_DOWNLOAD_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`
    },
    data: postData,
  });

}
