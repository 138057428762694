import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import { Link, useLocation, useHistory } from "react-router-dom";
import swal from "sweetalert";
import Spinner from "react-bootstrap/Spinner";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  // Pagination,
  Modal,
  Button,
  Tooltip,
} from "react-bootstrap";
import {
  getExamCategory,
  loadingToggleAction,
} from "../../../store/actions/ExamCategoryAction";
import { getCancelQuestionsReasonData } from "../../../store/actions/ReasonAction";
import {
  getBoardData,
  loadingBoardAction,
} from "../../../store/actions/BoardAction";
import { getAssignElibraryContentData } from "../../../store/actions/AssignContentAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import {
  getExamType,
  loadingAction,
} from "../../../store/actions/ExamTypeAction";
import {
  getClassData,
  loadingClassAction,
} from "../../../store/actions/ClassAction";
import {
  getNonGroupSubjectsList,
  getNonGroupSubjectsAction,
  getSingleSubjectListDetails,
  subjectLoading,
} from "../../../store/actions/SujectActions";

import ReactPaginate from "react-paginate";

import {
  getbranchTypeData,
  getChaptersDeffarentsParamsData,
  loadingChaptersAction,
  getChaptersDeffarentsParamsAction,
} from "../../../store/actions/ChapterAction";

import {
  deleteAssignContentData,
  removeScuccessfullyAction,
  getRejectedListData,
} from "../../../store/actions/AssignContentAction";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { BsJournalMedical } from "react-icons/bs";

const AssignElibraryList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { state } = useLocation();

  let errorsObj = {
    reason: "",
    otherReason: "",
  };

  const [errors, setErrors] = useState(errorsObj);

  const [active, setActive] = useState(state?.active ?? 1);
  const [isModal, setIsModal] = useState(false);
  const [reason, setReason] = useState(null);
  const [otherReason, setOtherReason] = useState("");
  const [recId, setRecId] = useState(null);
  const [cancelLoading, setCancelLoading] = useState(false);

  const [category, setCategory] = useState(state?.category ?? "");
  const [board, setBoard] = useState(state?.board ?? "");
  const [type, setType] = useState(state?.type ?? "");
  const [classRoom, setClassRoom] = useState(state?.classRoom ?? "");
  const [subject, setSubject] = useState(state?.subject ?? "");
  const [chapter, setChapter] = useState(state?.chapter ?? "");
  const [contentStatus, setContentStatus] = useState(
    state?.contentStatus ?? ""
  );
  const [approveStatus, setApproveStatus] = useState(
    state?.approveStatus ?? ""
  );

  const [rejectListModal, setRejectListModal] = useState("");
  const [rejectToolTipsHide, setRejectToolTipsHide] = useState(350);

  const [permission, setPermission] = useState("");

  const prevCategoryRef = useRef(category);
  const rejectOverlayRef = useRef();

  const againCallListData = useSelector(
    (state) => state.assign.againCallListData
  );

  useEffect(() => {
    dispatch(getExamCategory(props.history));
    dispatch(loadingAction(true));
    dispatch(getExamType(props.history));
    dispatch(loadingBoardAction(true));
    dispatch(getBoardData(props.history));
    dispatch(loadingClassAction(true));
    dispatch(getClassData(props.history));
    dispatch(getChaptersDeffarentsParamsAction([]));
    dispatch(getCancelQuestionsReasonData(props.history));
    //console.log("state......", state);
    permissionCheck();
  }, []);

  useEffect(() => {
    dispatch(globalLoadingAction(true));
    dispatch(
      getAssignElibraryContentData(
        "E",
        category,
        category == 1 ? board : "",
        category == 2 ? type : "",
        classRoom,
        subject,
        chapter,
        contentStatus,
        active,
        approveStatus,
        props.history
      )
    );
    // setActive(1);
  }, [
    category,
    board,
    type,
    subject,
    classRoom,
    chapter,
    contentStatus,
    approveStatus,
  ]);

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 59) {
        console.log("59------", item)
        setPermission(item);
      }
    });
  };

  /* get subject  */
  const getSubjectHandler = () => {
    dispatch(subjectLoading(true));
    //dispatch(getNonGroupSubjectsList(category, type, 0, board, props.history));
    dispatch(
      getSingleSubjectListDetails(0, board, type, "", category, props.history)
    );
  };

  /* get Chapter  */
  const getChapterHandler = () => {
    dispatch(loadingChaptersAction(true));
    dispatch(
      getChaptersDeffarentsParamsData(
        parseInt(category),
        category == 1 ? board : 0,
        category == 1 && board ? 0 : type,
        0,
        subject,
        classRoom,
        props.history
      )
    );
  };

  /* form reset */
  const onResetHandler = () => {
    setCategory("");
    setBoard("");
    setType("");
    setSubject("");
    setChapter("");
    setClassRoom("");
    setContentStatus("");
    setApproveStatus("");
    const newState = {};
    history.replace({ state: newState });
  };

  /* on change category */
  const onChangeCategoryHandler = (e) => {
    setActive(1);
    if (e != prevCategoryRef.current) {
      setBoard("");
      setType("");
      setSubject("");
      setChapter("");
      setClassRoom("");
      dispatch(getChaptersDeffarentsParamsAction([]));
      dispatch(getNonGroupSubjectsAction([]));
    }

    if (e == "") {
      setCategory("");
      setBoard("");
      setType("");
      setSubject("");
      setChapter("");
      setClassRoom("");
      setContentStatus("");
    } else {
      setCategory(e);
    }
  };

  /* on change board */
  const onChangeBoardHandler = (e) => {
    setActive(1);
    setBoard(e);
  };

  /* on change Type */
  const onChangeTypeHandler = (e) => {
    setActive(1);
    setType(e);
  };

  /* on change Subject */
  const onSubjectHandler = (e) => {
    setActive(1);
    setSubject(e);
  };

  /* on change Chapter */
  const onChapterHandler = (e) => {
    setActive(1);
    setChapter(e);
  };

  /* on change Class */
  const onChangeClassHandler = (e) => {
    setActive(1);
    setClassRoom(e);
  };

  /* on change Status */
  const onStatusHandler = (e) => {
    setActive(1);
    setContentStatus(e);
  };

  /* on reject List */
  const rejectListHandeler = (id) => {
    dispatch(globalLoadingAction(true));
    dispatch(getRejectedListData(id));
    setRejectListModal(true);
  };

  /* get History List */
  const HistoryListHandeler = (item) => {
    props.history.push({
      pathname: "/assigned-content-history-list",
      state: {
        ...item,
        filterData: {
          active,
          category,
          board,
          type,
          classRoom,
          subject,
          chapter,
          contentStatus,
          approveStatus,
        },
      },
    });
  };

  /* pagination */
  const pageHandler = (data) => {
    setActive(data.selected + 1);
    dispatch(globalLoadingAction(true));
    dispatch(
      getAssignElibraryContentData(
        "E",
        category,
        category == 1 ? board : "",
        category == 2 ? type : "",
        classRoom,
        subject,
        chapter,
        contentStatus,
        data.selected + 1,
        approveStatus,
        props.history
      )
    );
  };

  /* modal close */
  const closeRejectModal = () => {
    setRejectListModal(false);
  };

  /* cancel submition */
  const onCancelSubmission = () => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (reason === "" || reason === null) {
      errorObj.reason = "Reason is Required";
      error = true;
    }
    if (reason == 0 && otherReason.trim() === "") {
      errorObj.otherReason = "Reason is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setCancelLoading(true);
    dispatch(
      deleteAssignContentData(
        recId,
        reason == 0 ? otherReason : reason,
        postCancelRequest,
        props.history
      )
    );
  };

  /* cancel data submit */
  const postCancelRequest = () => {
    dispatch(
      getAssignElibraryContentData(
        "E",
        category,
        category == 1 ? board : "",
        category == 2 ? type : "",
        classRoom,
        subject,
        chapter,
        contentStatus,
        active,
        approveStatus,
        props.history
      )
    );
    setRecId(null);
    setIsModal(false);
    setCancelLoading(false);
  };

  /* on radion button change */
  const onChangeRadioHandler = (event) => {
    setReason(event.target.value);
  };

  /* on cancel Handler */
  const onCancelHandler = (item) => {
    setRecId(item.id);
    setIsModal(true);
  };

  const getApprovedStatusClass = (item) => {
    if (item.approved_status === 0 && item.admin_approval_status === 0)
      return "pending_background";
    if (
      item.developer_approval_status !== 4 &&
      item.approved_status === 1 &&
      item.admin_approval_status === 0
    )
      return "approved_background";
    if (
      item.developer_approval_status !== 4 &&
      item.approved_status === 1 &&
      item.admin_approval_status === 1
    )
      return "approved_background";
    if (
      item.developer_approval_status === 4 &&
      item.approved_status === 1 &&
      item.admin_approval_status === 1
    )
      return "published_background";
    if (
      item.developer_approval_status === 4 &&
      item.approved_status === 1 &&
      item.admin_approval_status === 2
    )
      return "reject_background";
    if (
      item.developer_approval_status === 4 &&
      item.approved_status === 1 &&
      item.admin_approval_status === 0
    )
      return "approved_background";
    if (item.admin_approval_status === 2) return "reject_background";
    return "reject_background";
  };

  return (
    <Fragment>
      {/* {console.log("assignList......", props.assignList)} */}
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="row chapter-list-view">
                <div className="col-sm-12 px-1 reset-btn-view">
                  <button
                    onClick={onResetHandler}
                    className={`btn btn-secondary ${category == "" &&
                      classRoom == "" &&
                      chapter == "" &&
                      contentStatus == "" &&
                      approveStatus == ""
                      ? "content_disable"
                      : null
                      }`}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-sm-2 px-1">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-status-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      // defaultValue={"option"}
                      className="form-control filter-form-control"
                      value={category}
                      onChange={(e) => onChangeCategoryHandler(e.target.value)}
                    >
                      <option value="">Select Category</option>
                      {props.categoryList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.category}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                {category == 1 ? (
                  <div className="col-sm-2 px-1">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow add-Question-status-arrow-position">
                        <i className="bi bi-caret-down-fill"></i>
                      </span>
                      <select
                        // defaultValue={"option"}
                        className="form-control filter-form-control"
                        value={board}
                        onChange={(e) => onChangeBoardHandler(e.target.value)}
                      >
                        <option value="">Select Board</option>
                        {props.boardList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                ) : category == "" ? (
                  <div className="col-sm-2 px-1">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow add-Question-status-arrow-position">
                        <i className="bi bi-caret-down-fill"></i>
                      </span>
                      <select
                        // defaultValue={"option"}
                        className={`form-control filter-form-control content_disable`}
                      // disabled
                      >
                        <option value="">Select Board/Type</option>
                      </select>
                    </div>
                  </div>
                ) : (
                  <div className="col-sm-2 px-1">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow add-Question-status-arrow-position">
                        <i className="bi bi-caret-down-fill"></i>
                      </span>
                      <select
                        // defaultValue={"option"}
                        className="form-control filter-form-control"
                        value={type}
                        onChange={(e) => onChangeTypeHandler(e.target.value)}
                      >
                        <option value="">Select Type</option>
                        {props.typeList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.type_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                )}

                <div className="col-sm-2 px-1">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow add-Question-status-arrow-position">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      // defaultValue={"option"}
                      value={classRoom}
                      className={`form-control filter-form-control ${type == 1 ? "content_disable" : null
                        }`}
                      // disabled={type == 1 ? true : false}
                      onChange={(e) => onChangeClassHandler(e.target.value)}
                    >
                      <option value="">Select Class</option>
                      {props.classList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.short_code}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-sm-2 px-1">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow">
                      {props.showLoadingSubject ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <i className="bi bi-caret-down-fill"></i>
                      )}
                    </span>
                    <select
                      // defaultValue={"option"}
                      className={`form-control filter-form-control ${category == "" && board == "" && type == ""
                        ? "content_disable"
                        : null
                        }`}
                      value={subject}
                      onChange={(e) => onSubjectHandler(e.target.value)}
                      onClick={getSubjectHandler}
                    >
                      <option value="">Select Subject</option>
                      {props.subjectList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                {subject != "" ? (
                  <div className="col-sm-2 px-1">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow">
                        {props.showLoadingChapter ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <i className="bi bi-caret-down-fill"></i>
                        )}
                      </span>
                      {/* category, board, type, subtype_id, subject, classRoom, */}
                      <select
                        // defaultValue={"option"}
                        className="form-control filter-form-control"
                        value={chapter}
                        onChange={(e) => onChapterHandler(e.target.value)}
                        onClick={getChapterHandler}
                      >
                        <option value="">Select Chapter</option>
                        {props.chapterList.map((item, index) => {
                          return (
                            <option key={index} value={item.id}>
                              {item.chapter_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                ) : (
                  <div className="col-sm-2 px-1">
                    <div className="basic-dropdown">
                      <span className="drop_down_arrow">
                        <i className="bi bi-caret-down-fill"></i>
                      </span>
                      {/* category, board, type, subtype_id, subject, classRoom, */}
                      <select
                        // defaultValue={"option"}
                        className="form-control filter-form-control content_disable"
                        value={chapter}
                        onChange={(e) => onChapterHandler(e.target.value)}
                      >
                        <option value="">Select Chapter</option>
                      </select>
                    </div>
                  </div>
                )}

                <div className="col-sm-2 px-1">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow">
                      {props.subjectLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        <i className="bi bi-caret-down-fill"></i>
                      )}
                    </span>
                    <select
                      // defaultValue={"option"}
                      className="form-control filter-form-control"
                      value={contentStatus}
                      onChange={(e) => onStatusHandler(e.target.value)}
                    >
                      <option value="">Select Status</option>
                      <option value="0">Pending</option>
                      <option value="1">Published</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-3 px-1 mt-2">
                  <div className="basic-dropdown">
                    <span className="drop_down_arrow">
                      <i className="bi bi-caret-down-fill"></i>
                    </span>
                    <select
                      className="form-control filter-form-control"
                      value={approveStatus}
                      onChange={(e) => setApproveStatus(e.target.value)}
                      isSearchable={true}
                      styles={{
                        height: 200,
                      }}
                    >
                      <option value="">Select Approve Status</option>
                      {props.filterDataList != ""
                        ? props.filterDataList.map((item, index) => {
                          return (
                            <option key={index} value={item.key}>
                              {item.value}
                            </option>
                          );
                        })
                        : null}
                    </select>
                  </div>
                </div>
              </div>

              <Table responsive>
                <thead>
                  <tr>
                    <th className="col_heading">
                      <strong>Category</strong>
                    </th>
                    <th className="col_heading">
                      <strong>Exam Type</strong>
                    </th>
                    <th className="col_heading">
                      <strong>Class</strong>
                    </th>
                    <th className="col_heading">
                      <strong>Board</strong>
                    </th>
                    <th className="col_heading">
                      <strong>Subject</strong>
                    </th>
                    <th className="col_heading">
                      <strong>Chapter</strong>
                    </th>
                    <th className="chapter_col col_heading">
                      <strong>Content Creators</strong>
                    </th>
                    <th className="col_heading">
                      <strong>Create Date</strong>
                    </th>
                    <th className="col_heading">
                      <strong>Update Date</strong>
                    </th>
                    <th className="chapter_col col_heading">
                      <strong>Approve Status</strong>
                    </th>
                    <th className="col_heading">
                      <strong>Content Status</strong>
                    </th>
                    {/* {
                      (permission.edit == "true" || permission.delete == "true") && ( */}
                        <th className="col_heading">
                          <strong>Action</strong>
                        </th>
                      {/* )} */}
                  </tr>
                  {/* //13 items */}
                </thead>
                {props.assignList != "" ? (
                  <tbody>
                    {props.assignList.map((item, index) => {
                      return (
                        <tr key={index}>
                          {/* <td>{item.serial_no}</td> */}
                          <td>{item.category}</td>
                          <td style={{ textAlign: "center" }}>
                            {item.type_name ?? "-"}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.class_code ?? "-"}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.board_name ?? "-"}
                          </td>
                          <td>{item.subject_name}</td>
                          <td>{item.chapter_name}</td>
                          <td>
                            <div className="chapter_col">
                              <div>
                                <storn>Developer:</storn> {item.developer_name}
                              </div>
                              <div>Amount : {item.developer_payment}/-</div>
                              <div>
                                Content validator:{" "}
                                {item.validator_name != ""
                                  ? item.validator_name
                                  : "Not assigned"}
                              </div>
                              <div>
                                Amount :{" "}
                                {item.validator_payment != ""
                                  ? item.validator_payment + "/-"
                                  : "0/-"}
                              </div>
                              <div>
                                Language validator:{" "}
                                {item.language_validator_name != ""
                                  ? item.language_validator_name
                                  : "Not assigned"}
                              </div>
                              <div>
                                Amount :{" "}
                                {item.language_validator_payment != ""
                                  ? item.language_validator_payment + "/-"
                                  : "0/-"}
                              </div>
                              <div>
                                Designer:{" "}
                                {item.designer_name != ""
                                  ? item.designer_name
                                  : "Not assigned"}
                              </div>
                              <div>
                                Amount :{" "}
                                {item.designer_payment != ""
                                  ? item.designer_payment + "/-"
                                  : "0/-"}
                              </div>
                            </div>
                          </td>
                          <td>
                            <Moment format="D MMM YYYY" withTitle>
                              {item.created_at}
                            </Moment>
                          </td>
                          <td>
                            <Moment format="D MMM YYYY" withTitle>
                              {item.updated_at}
                            </Moment>
                          </td>
                          <td>
                            <div
                              className={`approve_status chapter_col ${getApprovedStatusClass(
                                item
                              )}`}
                            >
                              {item.approved_status_msg}
                            </div>
                          </td>

                          <td>
                            {item.developer_approval_status == 4 &&
                              item.approved_status == 1 &&
                              item.admin_approval_status == 1
                              ? "Published"
                              : "Pending"}
                          </td>
                          <td>
                            <div className="assign-question-btn-group">
                              {
                                permission.edit == "true" && (
                                  <OverlayTrigger
                                    delay={{ hide: 450, show: 300 }}
                                    overlay={(props) => (
                                      <Tooltip {...props}>Edit</Tooltip>
                                    )}
                                    placement="top"
                                  >
                                    {(item.status == 1 && item.admin_approval_status !== 4) ||
                                      item.approved_status == 2 && item.admin_approval_status !== 4 ? (
                                      <Link
                                        to={{
                                          pathname: "/assign-developer-edit",
                                          state: {
                                            ...item,
                                            filterData: {
                                              active,
                                              category,
                                              board,
                                              type,
                                              classRoom,
                                              subject,
                                              chapter,
                                              contentStatus,
                                              approveStatus,
                                            },
                                          },
                                        }}
                                        className="btn btn-warning shadow btn-xs sharp me-1"
                                      >
                                        <i className="fas fa-pencil-alt"></i>
                                      </Link>
                                    ) : (
                                      <button
                                        disabled
                                        className="btn btn-warning shadow btn-xs sharp me-1"
                                      >
                                        <i className="fas fa-pencil-alt"></i>
                                      </button>
                                    )}
                                  </OverlayTrigger>
                                )
                              }

                              {/* {
                                <OverlayTrigger
                                  delay={{ hide: 450, show: 300 }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>Delete</Tooltip>
                                  )}
                                  placement="top"
                                >
                                  <Button
                                    className={`btn btn-danger shadow btn-xs sharp me-1 ${
                                      item.developer_approval_status > 2 ||
                                      (item.developer_approval_status == 1 &&
                                        item.approved_status == 1)
                                        ? "item_disable"
                                        : null
                                    }`}
                                    onClick={() => onDeleteHandler(item.id)}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Button>
                                </OverlayTrigger>
                              } */}

                              {
                                <OverlayTrigger
                                  ref={rejectOverlayRef}
                                  delay={{
                                    hide: rejectToolTipsHide,
                                    show: 300,
                                  }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>Reject List</Tooltip>
                                  )}
                                  placement="top"
                                >
                                  <button
                                    className={`btn btn-secondary shadow btn-xs sharp me-1 ${item.total_rejected_record == 0
                                      ? "item_disable"
                                      : null
                                      }`}
                                    onClick={() => rejectListHandeler(item.id)}
                                  >
                                    <i className="fa fa-window-restore"></i>
                                  </button>
                                </OverlayTrigger>
                              }

                              {
                                <OverlayTrigger
                                  delay={{ hide: 450, show: 300 }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>History List</Tooltip>
                                  )}
                                  placement="top"
                                >
                                  <button
                                    className={`btn btn-secondary history_background shadow btn-xs sharp me-1`}
                                    onClick={() => HistoryListHandeler(item)}
                                  >
                                    <BsJournalMedical size={15} />
                                  </button>
                                </OverlayTrigger>
                              }
                              {
                                (permission.delete == "true") && (
                                  <button
                                    disabled={item.is_cancel == 0 ? true : false}
                                    className={`btn btn-secondary btn-xs`}
                                    onClick={() => onCancelHandler(item)}
                                  >
                                    Cancel
                                  </button>
                                )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <tbody>
                    <td colSpan={12}>
                      <div className="no_data">
                        <strong>No data found</strong>
                      </div>
                    </td>
                  </tbody>
                )}
              </Table>

              <div className="pagination-container">
                <div className="page-result">
                  <p>
                    Showing: {(active - 1) * 10 + 1} -{" "}
                    {10 * active - (10 - props.assignList.length)} of({" "}
                    {props.contentListTotalRecord} items)
                  </p>
                </div>
                {props.contentListTotalPage > 1 ? (
                  <ReactPaginate
                    containerClassName="pagination-gutter  false pagination-circle pagination pagination-sm"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    nextLabel={<i className="la la-angle-right"></i>}
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    previousLabel={<i className="la la-angle-left"></i>}
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    renderOnZeroPageCount={null}
                    activeClassName="active"
                    onPageChange={pageHandler}
                    pageRangeDisplayed={3}
                    pageCount={props.contentListTotalPage}
                    forcePage={active - 1}
                  />
                ) : null}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal className="fade" show={rejectListModal}>
        <Modal.Header>
          <Modal.Title className="modal_title">
            Rejected Reason List
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="reject_list_parent_container">
            {props.getRejectedListIdWise.map((item, index) => {
              return (
                <div className="col-lg-12">
                  <div className="reject_list_container">
                    <div className="name_icon">
                      <div className="name_text">
                        {item.user_type.split("")[0]}
                      </div>
                    </div>
                    <div className="reject_details_container">
                      <div className="reject_name_container">
                        {item.user_type}/{item.name}
                      </div>
                      <div className="reject_reason_container">
                        {item.reason}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal_footer_right_container show_right">
            <Button
              variant="warning text-center m-1"
              onClick={() => closeRejectModal()}
            >
              OK
            </Button>
          </div>
        </Modal.Footer>
      </Modal>

      <Modal className="fade bd-example-modal-lg" show={isModal} size="md">
        <Modal.Header>
          <Modal.Title>Reason</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setIsModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body className="py-0">
          <div className="basic-form">
            <div className="reasone-alert my-3">
              <span>
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>{" "}
                Once you cancel the request, the entire record will be
                cancelled.
              </span>
            </div>
            <div onChange={onChangeRadioHandler} className="mb-3">
              {props.reasonList.map((item, index) => {
                return (
                  <div className="form-check" key={item.id}>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="reason"
                      value={item.message}
                      id={`rejectReason${item.id}`}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`rejectReason${item.id}`}
                    >
                      {item.message}
                    </label>
                  </div>
                );
              })}
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="reason"
                  value="0"
                  id="other"
                />
                <label className="form-check-label" htmlFor="other">
                  Other
                </label>
              </div>
              {errors.reason && (
                <div className="text-danger fs-12">{errors.reason}</div>
              )}
            </div>
            {reason == 0 ? (
              <div className="form-group mb-3">
                <label className="mb-2">
                  <strong>
                    Reason<span className="text-danger">*</span>
                  </strong>
                </label>
                <textarea
                  className="form-control"
                  placeholder="Please specify the reason to cancel..."
                  value={otherReason}
                  onChange={(e) => setOtherReason(e.target.value)}
                />
                {errors.otherReason && (
                  <div className="text-danger fs-12">{errors.otherReason}</div>
                )}
              </div>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="preview-modal-btn-view">
            <Button
              variant="success"
              disabled={cancelLoading}
              className="spinner-btn"
              onClick={() => onCancelSubmission()}
            >
              Submit
              {cancelLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  return {
    showLoading: state.elibrary.showLoading,
    elibraryList: state.elibrary.list,
    categoryList: state.category.list,
    categoryLoading: state.category.showLoading,
    branchList: state.branch.branchList,
    branchLoading: state.branch.showLoading,
    boardLoading: state.board.showLoading,
    boardList: state.board.list,
    totalPage: state.elibrary.totalPage,
    totalRecord: state.elibrary.totalRecord,
    assignList: state.assign.eLibraryContentlist,
    typeList: state.type.list,
    classList: state.class.list,
    subjectList: state.subject.getSingleSubjectList,
    chapterList: state.chapter.typelist,
    showLoadingSubject: state.subject.showLoading,
    showLoadingChapter: state.chapter.showLoading,
    getRejectedListIdWise: state.assign.getRejectedListIdWise,
    contentListTotalRecord: state.assign.contentListTotalRecord,
    contentListTotalPage: state.assign.contentListTotalPage,
    filterDataList: state.assign.filterDataList,
    reasonList: state.reason.cancelReasonList,
  };
};

export default connect(mapStateToProps)(AssignElibraryList);
