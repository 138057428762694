import swal from "sweetalert";
import {
  addAcademicSession,
  getAcademicSession,
  updateAcademicSession,
  removeAcademicSession,
  updateStatusAcademicSession,
  getAcademicYearMasterList,
  getAcademicSessionHistory
} from "../../services/AcademicServices";

import {
  GET_ACADEMIC_SESSION_LIST,
  POST_ACADEMIC_SESSION_STATUS_UPDATE,
  GET_ACADEMIC_YEAR_MASTER_LIST,
  GET_ACADEMIC_SESSION_HISTORY_LIST
} from "../constants";

import * as utility from "../../utility/Utility";
import { globalLoadingAction } from "./GlobalAction";

export function addAcademicSessionDetails(
  category_id,
  exam_board_type,
  name,
  academy_start_date,
  academy_end_date,
  course_start_date,
  course_end_date,
  cleardata,
  history
) {
  return (dispatch) => {
    addAcademicSession(
      category_id,
      exam_board_type,
      name,
      academy_start_date,
      academy_end_date,
      course_start_date,
      course_end_date
    )
      .then((response) => {
        if (response.data.status === 200) {
          utility.showSuccess(response.data.msg);
          cleardata();
        } else {
          utility.showError(response.data.msg);
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function updateAcademicSessionDetails(id,category_id,exam_board_type,name,academy_start_date,academy_end_date,course_start_date,course_end_date,cleardata,history) {
  return (dispatch) => {
    updateAcademicSession(id,category_id,exam_board_type,name,academy_start_date,academy_end_date,course_start_date,course_end_date)
      .then((response) => {
        if (response.data.status === 200) {
          utility.showSuccess(response.data.msg);
          cleardata();
        }else{
          utility.showError(response.data.msg);
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getAcademicSessionData(category,exam_board_type,name,academy_start_date,academy_end_date,start_date,end_date,status,page,history) {
  return (dispatch) => {
    getAcademicSession(category,exam_board_type,name,academy_start_date,academy_end_date,start_date,end_date,status,page)
      .then((response) => {
        //console.log('response..',response.data);
        if (response.data.status == 200) {
          dispatch(getAcademicSessionAction(response.data));
        } else {
          utility.showError(response.data.msg);
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getAcademicSessionHistoryData(
  category,
  exam_board_type,
  name,
  academy_start_date,
  academy_end_date,
  start_date,
  end_date,
  status,
  page,
  history
) {
  return (dispatch) => {
    getAcademicSessionHistory(
      category,
      exam_board_type,
      name,
      academy_start_date,
      academy_end_date,
      start_date,
      end_date,
      status,
      page
    )
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(getAcademicSessionHistoryAction(response.data));
        } else {
          utility.showError(response.data.msg);
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function updateStatusAcademicSessionData(item, history) {
  return (dispatch) => {
    updateStatusAcademicSession(item.id)
      .then((response) => {
        if (response.data.status == 200) {
          utility.showSuccess(response.data.msg);
          dispatch(updateStatusAcademicSessionAction(item));
        } else {
          utility.showError(response.data.msg);
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function getAcademicYearMasterListData(history) {
  return (dispatch) => {
    getAcademicYearMasterList()
      .then((response) => {
        if (response.data.status == 200) {
          dispatch(getAcademicYearMasterAction(response.data.data));
        } else {
          utility.showError(response.data.msg);
        }
        dispatch(globalLoadingAction(false));
      })
      .catch((error) => {
        //console.log(error);
        utility.showError(error.response.data);
        dispatch(globalLoadingAction(false));
      });
  };
}

export function removeAcademicSessionData(id,removeCallback,history) {
  return (dispatch) => {
    removeAcademicSession(id)
      .then((response) => {
        if(response.data.status == 200){
          swal(response.data.msg, { icon: "success" });
          removeCallback();
        }else{
          swal("Oops", response.data.msg, "error");
        }
       
      })
      .catch((error) => {
        //console.log(error);
        swal("Oops", "Something went wrong!", "error");
      });
  };
}

export function getAcademicSessionAction(data) {
  return {
    type: GET_ACADEMIC_SESSION_LIST,
    payload: data,
  };
}

export function getAcademicSessionHistoryAction(data) {
  return {
    type: GET_ACADEMIC_SESSION_HISTORY_LIST,
    payload: data,
  };
}

export function updateStatusAcademicSessionAction(data) {
  return {
    type: POST_ACADEMIC_SESSION_STATUS_UPDATE,
    payload: data,
  };
}

export function getAcademicYearMasterAction(data) {
  return {
    type: GET_ACADEMIC_YEAR_MASTER_LIST,
    payload: data,
  };
}
