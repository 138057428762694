import React, { Fragment, useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import ReactPaginate from "react-paginate";
import Spinner from "react-bootstrap/Spinner";
import {
  Row,
  Col,
  Card,
  Table,
  Pagination,
  Modal,
  Button,
} from "react-bootstrap";
import Moment from "react-moment";

import {
  getDeveloperData,
  loadingUserAction,
  removeContentDeveloperDetails,
  updateDeveloperStatus,
} from "../../../store/actions/UserAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { useDebounce } from "use-debounce";
import { validateEmail, validatePhone, validateName, inputAlphabetOnlyWithSpace, inputNumberOnly, inputAlphabetOnly } from '../../../utility/Utility';

const ContentDeveloperList = (props) => {
  const dispatch = useDispatch();

  const previousFilterData = props?.location.state?.previousPageFilterData;


  const [active, setActive] = useState(previousFilterData?.active ?? 1);
  const [name, setName] = useState(previousFilterData?.name ?? '');
  const [nameDebounce] = useDebounce(name, 1000);
  const [email, setEmail] = useState(previousFilterData?.email ?? '');
  const [emailDebounce] = useDebounce(email, 1000);
  const [phone, setPhone] = useState(previousFilterData?.phone ?? '');
  const [phoneDebounce] = useDebounce(phone, 1000);
  const [permission, setPermission] = useState("");

  useEffect(() => {
    permissionCheck();
    dispatch(globalLoadingAction(true));
    dispatch(getDeveloperData(active, name, email, phone, props.history));
  }, []);

  useEffect(() => {
    setActive(1);
    dispatch(globalLoadingAction(true));
    dispatch(
      getDeveloperData(active, nameDebounce, emailDebounce, phoneDebounce, props.history)
    );
  }, [nameDebounce, emailDebounce, phoneDebounce]);

  /* delete confirmation */
  const onDeleteModal = (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this item !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        //dispatch(globalLoadingAction(true));
        if (props.list.length == 1) {
          dispatch(
            removeContentDeveloperDetails(id, active - 1, props.history)
          );
          setActive(active - 1);
        } else {
          dispatch(removeContentDeveloperDetails(id, active, props.history));
        }
      }
    });
  };

  /* change status */
  const onUpdateStatus = (item) => {
    dispatch(loadingUserAction(true));
    dispatch(updateDeveloperStatus(item, props.history));
  };

  /* name change into state */
  const onNameHandler = (value) => {
    setName(value);
    // setActive(1);
    // dispatch(globalLoadingAction(true));
    // dispatch(getDeveloperData(1, value, email, props.history));
  };

  /* email change into state */
  const onEmailHandler = (value) => {
    setEmail(value);
    // setActive(1);
    // dispatch(globalLoadingAction(true));
    // dispatch(getDeveloperData(1, name, value, props.history));
  };

  /* phone change into state */
  const onPhoneHandler = (value) => {
    setPhone(value);

  };

  /* pagination */
  const pageHandler = (data) => {
    setActive(data.selected + 1);
    dispatch(globalLoadingAction(true));
    dispatch(getDeveloperData(data.selected + 1, name, email, phone, props.history));
  };

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 5) {
        setPermission(item);
      }
    });
  };

  /* clear name */
  const onClearHandlerName = () => {
    setName('');
    setActive(1);
  }

  /* clear email */
  const onClearHandlerEmail = () => {
    setEmail('');
    setActive(1);
  }

  /* clear phone */
  const onClearHandlerPhone = () => {
    setPhone('')
    setActive(1);
  }

  /* all field reset */
  const resetHandler = () => {
    setName('')
    setEmail('')
    setPhone('')

    const stateCopy = { ...props?.location.state?.previousPageFilterData };
    delete stateCopy.active;
    delete stateCopy.name;
    delete stateCopy.email;
    delete stateCopy.phone;
    props.history.replace({ state: stateCopy });

  }

  return (
    <Fragment>

      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="row add-Question-list-view">

                <div className="col-sm-3 px-1 clear-question-type">
                  <input
                    className="form-control filter-form-control me-2"
                    placeholder="Developer name"
                    value={name}
                    onChange={(e) => onNameHandler(e.target.value)}
                    onKeyPress={inputAlphabetOnlyWithSpace}
                  />
                  {name ? (
                    <button
                      onClick={onClearHandlerName}
                      className="clear-date-btn"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  ) : null}
                </div>
                <div className="col-sm-3 px-1 clear-question-type">
                  <input
                    className="form-control filter-form-control me-2"
                    placeholder="Developer email"
                    value={email}
                    onChange={(e) => onEmailHandler(e.target.value)}
                  />
                  {email ? (
                    <button
                      onClick={onClearHandlerEmail}
                      className="clear-date-btn"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  ) : null}
                </div>
                <div className="col-sm-3 px-1 clear-question-type">
                  <input
                    className="form-control filter-form-control me-2"
                    placeholder="Developer phone number"
                    value={phone}
                    onChange={(e) => onPhoneHandler(e.target.value)}
                    onKeyPress={inputNumberOnly}
                    maxlength="10"
                  />
                  {phone ? (
                    <button
                      onClick={onClearHandlerPhone}
                      className="clear-date-btn"
                    >
                      <i class="fa fa-times" aria-hidden="true"></i>
                    </button>
                  ) : null}
                </div>
                <div style={{ paddingTop: '5px' }} className="col-sm-1 px-1 ">
                  <button onClick={resetHandler} className={`btn btn-secondary ${name == '' && email == '' && phone == '' ? 'content_disable' : null} `}>Reset</button>
                </div>
              </div>

              {props.list.length == 0 ? (
                <div className="no_data">
                  <strong>No data found</strong>
                </div>
              ) : (
                <>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>
                          <strong>Name</strong>
                        </th>

                        <th>
                          <strong>Email</strong>
                        </th>
                        {/* <th>
                      <strong>User Type</strong>
                    </th> */}
                        <th>
                          <strong>Phone No.</strong>
                        </th>

                        <th>
                          <strong>Create Date</strong>
                        </th>
                        {permission.status == "true" && (
                          <th width={120}>
                            <strong>Status</strong>
                          </th>
                        )}
                        {(permission.edit == "true" || permission.delete == "true") && (
                          <th>
                            <strong>Action</strong>
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {props.list.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.email}</td>
                            {/* <td>{item.developer_type}</td> */}
                            <td>{item.phone}</td>
                            <td>
                              <Moment format="D MMM YYYY" withTitle>
                                {item.created_at}
                              </Moment>
                            </td>
                            {permission.status == "true" && (
                              <td>
                                <Button
                                  disabled={props.showLoading}
                                  className="btn-xs"
                                  variant={
                                    item.status == 1 ? "success" : "danger"
                                  }
                                  onClick={() => onUpdateStatus(item)}
                                >
                                  {item.status == 1 ? "Active" : "Inactive"}
                                </Button>
                              </td>
                            )}
                            {
                              (permission.edit == "true" || permission.delete == "true") && (
                                <td>
                                  <div className="d-flex">
                                    {permission.edit == "true" && (
                                      <Link
                                        to={{
                                          pathname: "/update-content-developer",
                                          state: { item, filterData: { active, name, email, phone } },

                                        }}
                                        className="btn btn-warning shadow btn-xs sharp me-1"
                                      >
                                        <i className="fas fa-pencil-alt"></i>
                                      </Link>
                                    )}
                                    {permission.delete == "true" && (
                                      <Button
                                        className="btn btn-danger shadow btn-xs sharp"
                                        onClick={() => onDeleteModal(item.id)}
                                      >
                                        <i className="fa fa-trash"></i>
                                      </Button>
                                    )}
                                  </div>
                                </td>
                              )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>

                  <div className="pagination-container">
                    <div className="page-result">
                      <p>
                        Showing: {(active - 1) * 10 + 1} -{" "}
                        {10 * active - (10 - props.list.length)} of{" "}
                        {props.totalRecord}
                      </p>
                    </div>
                    {props.totalRecord > 10 ?
                      <ReactPaginate
                        containerClassName="pagination-gutter  false pagination-circle pagination pagination-sm"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        nextLabel={<i class="la la-angle-right"></i>}
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        previousLabel={<i class="la la-angle-left"></i>}
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        renderOnZeroPageCount={null}
                        activeClassName="active"
                        onPageChange={pageHandler}
                        pageRangeDisplayed={3}
                        pageCount={props.totalPage}
                      />
                      : null}
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};
const mapStateToProps = (state) => {

  return {
    showLoading: state.user.showLoading,
    list: state.user.contentDeveloperList,
    totalPage: state.user.totalDeveloperPage,
    totalRecord: state.user.totalDeveloperRecord,
  };
};
export default connect(mapStateToProps)(ContentDeveloperList);
