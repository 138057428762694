import React, { Fragment, useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Moment from "react-moment";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import Spinner from "react-bootstrap/Spinner";
import {
  Row,
  Col,
  Card,
  Table,
  Badge,
  Dropdown,
  ProgressBar,
  Modal,
  Button,
} from "react-bootstrap";

import {
  getAdvertisementData,
  removeAdvertisementData,
  updateStatusAdvertisementData,
} from "../../../store/actions/AdvertisementAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

const AdvertisementList = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();

  const [isModal, setIsModal] = useState(false);
  const [isDetals, setIsDetals] = useState(null);
  const [permission, setPermission] = useState("");

  useEffect(() => {
    dispatch(globalLoadingAction(true));
    dispatch(getAdvertisementData(props.history));
    permissionCheck()
  }, []);

  /* confirmation for delete */
  const onDeleteModal = (id) => {
    swal({
      title: "Are you sure?",
      text: "You want to remove this item !",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(removeAdvertisementData(id, props.history));
      }
    });
  };

  /* updated item */
  const onUpdateStatus = (item) => {
    dispatch(globalLoadingAction(true));
    dispatch(updateStatusAdvertisementData(item.id, props.history));
  };

  /* got to add screen */
  const addAdvertiesmentHandler = () => {
    history.push("add-advertisement");
  };

  /* delete handler */
  const onDetailsHander = (item) => {
    setIsModal(true);
    setIsDetals(item);
  };

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 93) {
        console.log("93----------", item)
        setPermission(item);
      }
    });
  };

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="add-filter-group">
                {(permission.add == "true") && (
                  <Button
                    disabled={props.list.length >= 10 ? true : false}
                    className="btn btn-success mb-3"
                    variant={"success"}
                    onClick={() => addAdvertiesmentHandler()}
                  >
                    Add Advertisement
                  </Button>
                )}
              </div>
              {props.list.length > 0 ? (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Title</strong>
                      </th>
                      <th>
                        <strong>Screen name</strong>
                      </th>
                      <th>
                        <strong>Description</strong>
                      </th>
                      <th>
                        <strong>Create Date</strong>
                      </th>
                      {(permission.status == "true") && (
                        <th>
                          <strong>Status</strong>
                        </th>
                      )}
                      <th>
                        <strong>Action</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.list.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.title}</td>
                          <td>{item.screen_name}</td>
                          <td>{item.description}</td>
                          <td>
                            <Moment format="D MMM YYYY" withTitle>
                              {item.created_at}
                            </Moment>
                          </td>

                          {(permission.status == "true") && (
                            <td>
                              <Button
                                //disabled={props.showLoading}
                                className="btn-xs"
                                variant={item.status == 1 ? "success" : "danger"}
                                onClick={() => onUpdateStatus(item)}
                              >
                                {item.status == 1 ? "Active" : "Inactive"}
                              </Button>
                            </td>
                          )}

                          <td>
                            <div className="d-flex">
                              {(permission.edit == "true") && (
                                <Link
                                  to={{
                                    pathname: "/update-advertisement",
                                    state: item,
                                  }}
                                  className="btn btn-warning shadow btn-xs sharp me-1"
                                >
                                  <i className="fas fa-pencil-alt"></i>
                                </Link>
                              )}

                              <Button
                                className="btn btn-info shadow btn-xs sharp btn-info-color me-1"
                                onClick={() => onDetailsHander(item)}
                              >
                                <i className="fa fa-eye"></i>
                              </Button>
                              {(permission.delete == "true") && (
                                <Button
                                  className="btn btn-danger shadow btn-xs sharp"
                                  onClick={() => onDeleteModal(item.id)}
                                >
                                  <i className="fa fa-trash"></i>
                                </Button>
                              )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              ) : (
                <div className="no_data mt-5 mb-5">
                  <strong>No data found</strong>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal className="fade" show={isModal}>
        <Modal.Header>
          <Modal.Title className="modal_title">
            Advertisement Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="form-group mb-3">
              <label className="mb-2">
                <strong>Title: </strong>
                {isDetals?.title}
              </label>
            </div>
            <div className="form-group mb-3">
              <label className="mb-2">
                <strong>Screen Name: </strong>
                {isDetals?.screen_name}
              </label>
            </div>
            <div className="form-group mb-3">
              <label className="mb-2">
                <strong>Description: </strong>
                {isDetals?.description}
              </label>
            </div>
            <div className="form-group mb-3">
              <label className="mb-2">
                <strong>Image: </strong>
              </label>
            </div>
            <div className="upload-image-modal">
              <img
                className="upload-image"
                src={isDetals?.image_upload}
                alt="img"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modal_footer_right_container show_right">
            <Button
              variant="warning text-center m-1"
              onClick={() => setIsModal(false)}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.advertisement.list,
  };
};

export default connect(mapStateToProps)(AdvertisementList);
