import React, { useContext, useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Alert, Button, Modal } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import QuestionArea from "./component/QuestionArea";
import axios from "axios";
import * as Apis from "../../../apis/Apis";
import GlobalConfigs from "../../../configs/GlobalConfigs";
import MathJax from "react-mathjax-preview";

import {
  getExamCategory,
  loadingToggleAction,
} from "../../../store/actions/ExamCategoryAction";
import {
  getExamType,
  loadingAction,
} from "../../../store/actions/ExamTypeAction";
import {
  getExamSubType,
  loadingSubTypeAction,
} from "../../../store/actions/ExamSubTypeAction";
import {
  getNonGroupSubjectsList,
  subjectLoading,
  getNonGroupSubjectsAction,
} from "../../../store/actions/SujectActions";
import {
  getClassData,
  loadingClassAction,
} from "../../../store/actions/ClassAction";
import {
  getQuestionPattern,
  loadingPatternAction,
} from "../../../store/actions/QuestionPatternAction";
import {
  getBoardData,
  loadingBoardAction,
} from "../../../store/actions/BoardAction";
import {
  getChaptersDeffarentsParamsData,
  loadingChaptersAction,
  getChaptersDeffarentsParamsAction,
} from "../../../store/actions/ChapterAction";
import {
  addQuestionData,
  loadingContentAction,
} from "../../../store/actions/ExamContentAction";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";

const AddQuestion = (props) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();
  let errorsObj = {
    category: "",
    board: "",
    type: "",
    subtype: "",
    className: "",
    subject: "",
    chapter: "",
    quesType: "",
    quesNo: "",
    quesBody: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    anws: "",
    resone: "",
    file: "",
    groupId: "",
    answersbody: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [category, setCategory] = useState("");
  const [examtype, setExamtype] = useState("");
  const [isClass, setIsClass] = useState();
  const [subtype, setSubtype] = useState("");
  const [board, setBoard] = useState("");
  const [subject, setSubject] = useState("");
  const [classroom, setClassroom] = useState("");
  const [chapter, setChapter] = useState("");
  const [questiontype, setQuestiontype] = useState("");
  const [questiono, setQuestiono] = useState("");
  const [cssGroupId, setCssGroupId] = useState("");
  const [questiobody, setQuestiobody] = useState("");
  const [option, setOption] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [option4, setOption4] = useState("");
  const [answers, setAnswers] = useState("");
  const [answersbody, setAnswersbody] = useState("");
  const [imagesArray, setImagesArray] = useState([]);
  const [reasonArray, setReasonArray] = useState([]);
  const [firstOptionArray, setFirstOptionArray] = useState([]);
  const [secondOptionArray, setSecondOptionArray] = useState([]);
  const [thirdOptionArray, setThirdOptionArray] = useState([]);
  const [fourthOptionArray, setFourthOptionArray] = useState([]);
  const [modal, setModal] = useState(false);
  const [htmlResult, setHtmlResult] = useState("");
  const [demo, setDemo] = useState(0);
  const [questionEditor, setQuestionEditor] = useState(false);
  const [optionAEditor, setOptionAEditor] = useState(false);
  const [optionBEditor, setOptionBEditor] = useState(false);
  const [optionCEditor, setOptionCEditor] = useState(false);
  const [optionDEditor, setOptionDEditor] = useState(false);
  const [reasonEditor, setReasonEditor] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [activeNumber, setActiveNumber] = useState(0);
  const [text, setText] = useState("");

  useEffect(() => { }, []);

  /* form stubmit */
  function onSubmit(e) {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };

    if (category === "") {
      errorObj.category = "Exam category is required";
      error = true;
    }
    if (
      (parseInt(demo) == 0 && parseInt(category) == 1 && board === "") ||
      (parseInt(demo) == 2 && category == 1 && board === "")
    ) {
      errorObj.board = "Board is Required";
      error = true;
    }
    if (parseInt(demo) === 0 && parseInt(category) === 2 && examtype === "") {
      errorObj.type = "Exam type is Required";
      error = true;
    }
    if (parseInt(demo) == 0 && parseInt(examtype) == 1 && subtype === "") {
      errorObj.subtype = "Exam sub type is Required";
      error = true;
    }
    if (
      classroom === "" &&
      ((parseInt(demo) === 0 &&
        (parseInt(examtype) == 2 || parseInt(category) == 1)) ||
        parseInt(demo) === 2)
    ) {
      errorObj.className = "Class is required";
      error = true;
    }
    if (subject === "" && parseInt(demo) === 0) {
      // errorObj.branch = 'Branch is Required';
      // errorObj.subject = 'Branch is Required';
      errorObj.subject = "Subject is Required";
      error = true;
    }
    if (chapter === "" && parseInt(demo) === 0) {
      errorObj.chapter = "Chapter is Required";
      error = true;
    }
    if (questiontype === "") {
      errorObj.quesType = "Question type is Required";
      error = true;
    }
    if (questiono === "") {
      errorObj.quesNo = "Question no is Required";
      error = true;
    }
    if (cssGroupId === "" && questiontype == "CSS") {
      errorObj.groupId = "Group id is Required";
      error = true;
    }
    if (questiobody === "") {
      errorObj.quesBody = "Question body is Required";
      error = true;
    }
    if (option === "") {
      errorObj.optionA = "First option is Required";
      error = true;
    }
    if (option2 === "") {
      errorObj.optionB = "Second option is Required";
      error = true;
    }
    if (option3 === "") {
      errorObj.optionC = "Third option is Required";
      error = true;
    }
    if (option4 === "") {
      errorObj.optionD = "Fourth is Required";
      error = true;
    }
    if (answers === "") {
      errorObj.anws = "Answers is Required";
      error = true;
    }
    if (answersbody === "") {
      errorObj.answersbody = "Resone is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    let que_array = [...imagesArray];
    let resone_array = [...reasonArray];
    let firstOption_array = [...firstOptionArray];
    let secondOption_array = [...secondOptionArray];
    let thirdOption_array = [...thirdOptionArray];
    let fourthOption_array = [...fourthOptionArray];

    let que_img = [];
    let resone_img = [];
    let firstOption_img = [];
    let secondOption_img = [];
    let thirdOption_img = [];
    let fourthOption_img = [];

    que_array.forEach((item, index) => {
      que_img.push(item.file);
    });

    resone_array.forEach((item, index) => {
      resone_img.push(item.file);
    });

    firstOption_array.forEach((item, index) => {
      firstOption_img.push(item.file);
    });

    secondOption_array.forEach((item, index) => {
      secondOption_img.push(item.file);
    });

    thirdOption_array.forEach((item, index) => {
      thirdOption_img.push(item.file);
    });

    fourthOption_array.forEach((item, index) => {
      fourthOption_img.push(item.file);
    });
    // console.log('hello world', que_img);
    //return;
    let exam_type = examtype.split("^")[0];
    let is_class = examtype.split("^")[1];

    dispatch(globalLoadingAction(true));
    dispatch(
      addQuestionData(
        parseInt(category),
        (parseInt(demo) == 0 && parseInt(category) == 1) ||
          (parseInt(demo) == 2 && category == 1)
          ? board
          : 0,
        parseInt(demo) == 0 && parseInt(category) == 2 ? exam_type : 0,
        parseInt(demo) == 0 && parseInt(exam_type) == 1 ? subtype : 0,
        (parseInt(demo) == 0 &&
          (parseInt(category) == 1 || parseInt(is_class) == 1)) ||
          parseInt(demo) == 2
          ? classroom
          : 0,
        parseInt(demo) == 0 ? subject : 0,
        parseInt(demo) == 0 ? chapter : 0,
        questiontype,
        questiono,
        questiobody,
        que_img,
        option,
        firstOption_img,
        option2,
        secondOption_img,
        option3,
        thirdOption_img,
        option4,
        fourthOption_img,
        answers,
        answersbody,
        resone_img,
        demo,
        questiontype == "CSS" ? cssGroupId : "",
        props.history
      )
    );

    setCategory("");
    setBoard("");
    setExamtype("");
    setSubtype("");
    setClassroom("");
    setSubject("");
    setChapter("");
    setQuestiontype("");
    setQuestiono("");
    setQuestiobody("");
    setOption("");
    setOption2("");
    setOption3("");
    setOption4("");
    setAnswers("");
    setImagesArray([]);
    setReasonArray([]);
    setAnswersbody("");
    setFirstOptionArray([]);
    setSecondOptionArray([]);
    setThirdOptionArray([]);
    setFourthOptionArray([]);
    setHtmlResult([]);
    setDemo(0);
    setQuestionEditor(false);
    setOptionAEditor(false);
    setOptionBEditor(false);
    setOptionCEditor(false);
    setOptionDEditor(false);
    setReasonEditor(false);
    setActiveNumber(0);
    setCssGroupId("");
  }

  /* Question preview handler */
  const onQueModalHandlers = () => {
    setModal(true);
    let str = questiobody;
    imagesArray.forEach((item, index) => {
      str = str.replace(
        `${item.name}`,
        `<img src="${item.path}" class="img-modal-text-view" />`
      );
    });
    setHtmlResult(str);
  };

  /* Reason preview handler */
  const onReasonPreviewHandler = () => {
    setModal(true);
    let str = answersbody;
    reasonArray.forEach((item, index) => {
      str = str.replace(
        `${item.name}`,
        `<img src="${item.path}" class="img-modal-text-view" />`
      );
    });
    setHtmlResult(str);
  };

  /* First Option preview handler */
  const onFirstOptionPreviewHandler = () => {
    setModal(true);
    let str = option;
    firstOptionArray.forEach((item, index) => {
      str = str.replace(
        `${item.name}`,
        `<img src="${item.path}" class="img-modal-text-view" />`
      );
    });
    setHtmlResult(str);
  };

  /* Second Option preview handler */
  const onSecondOptionPreviewHandler = () => {
    setModal(true);
    let str = option2;
    secondOptionArray.forEach((item, index) => {
      str = str.replace(
        `${item.name}`,
        `<img src="${item.path}" class="img-modal-text-view" />`
      );
    });
    setHtmlResult(str);
  };

  /* Third Option preview handler */
  const onThirdOptionPreviewHandler = () => {
    setModal(true);
    let str = option3;
    thirdOptionArray.forEach((item, index) => {
      str = str.replace(
        `${item.name}`,
        `<img src="${item.path}" class="img-modal-text-view" />`
      );
    });
    setHtmlResult(str);
  };

  /* Fourth Option preview handler */
  const onFourthOptionPreviewHandler = () => {
    setModal(true);
    let str = option4;
    fourthOptionArray.forEach((item, index) => {
      str = str.replace(
        `${item.name}`,
        `<img src="${item.path}" class="img-modal-text-view" />`
      );
    });
    setHtmlResult(str);
  };

  /* navigation go back */
  const onGoBackHandler = () => {
    history.goBack();
  };

  /* set question */
  const onQuestionInputHandler = (val) => {
    setQuestiobody(val);
  };

  /* set question image */
  const onQuestionImageHandler = (item) => {
    setImagesArray(item);
  };

  /* set option A Input */
  const onOptionAInputHandler = (val) => {
    setOption(val);
  };

  /* set option A Image */
  const onOptionAImageHandler = (item) => {
    setFirstOptionArray(item);
  };
  /* set option B Input */
  const onOptionBInputHandler = (val) => {
    setOption2(val);
  };
  /* set option B Image */
  const onOptionBImageHandler = (item) => {
    setSecondOptionArray(item);
  };
  /* set option c Input */
  const onOptionCInputHandler = (val) => {
    setOption3(val);
  };
  /* set option c Image */
  const onOptionCImageHandler = (item) => {
    setThirdOptionArray(item);
  };
  /* set option D Input */
  const onOptionDInputHandler = (val) => {
    setOption4(val);
  };
  /* set option D Image */
  const onOptionDImageHandler = (item) => {
    setFourthOptionArray(item);
  };
  /* set Reason Input */
  const onReasonInputHandler = (val) => {
    setAnswersbody(val);
  };
  /* set Reason Image */
  const onReasonImageHandler = (item) => {
    setReasonArray(item);
  };

  /* Question Change */
  const onQuestionEditHandler = (val, status) => {
    if (questionEditor !== status) {
      setConfirmModal(true);
      setActiveNumber(val);
    }
  };

  /* OptionA Change */
  const onOptionAEditHandler = (val, status) => {
    if (optionAEditor !== status) {
      setConfirmModal(true);
      setActiveNumber(val);
    }
  };

  /* OptionB Change */
  const onOptionBEditHandler = (val, status) => {
    if (optionBEditor !== status) {
      setConfirmModal(true);
      setActiveNumber(val);
    }
  };

  /* OptionC Change */
  const onOptionCEditHandler = (val, status) => {
    if (optionCEditor !== status) {
      setConfirmModal(true);
      setActiveNumber(val);
    }
  };
  /* OptionD Change */
  const onOptionDEditHandler = (val, status) => {
    if (optionDEditor !== status) {
      setConfirmModal(true);
      setActiveNumber(val);
    }
  };

  /* Reason Change */
  const onReasonEditHandler = (val, status) => {
    if (reasonEditor !== status) {
      setConfirmModal(true);
      setActiveNumber(val);
    }
  };

  const onConfirmHandler = () => {
    if (activeNumber == 1) {
      setQuestionEditor(!questionEditor);
    }
    if (activeNumber == 2) {
      setOptionAEditor(!optionAEditor);
    }
    if (activeNumber == 3) {
      setOptionBEditor(!optionBEditor);
    }
    if (activeNumber == 4) {
      setOptionCEditor(!optionCEditor);
    }
    if (activeNumber == 5) {
      setOptionDEditor(!optionDEditor);
    }
    if (activeNumber == 6) {
      setReasonEditor(!reasonEditor);
    }
    setConfirmModal(false);
  };

  /* change radio option */
  const onChangeRadioHandler = (event) => {
    setDemo(event.target.value);
  };

  /* get category list */
  const getCategoryHandler = () => {
    if (props.categoryList.length == 0) {
      dispatch(loadingToggleAction(true));
      dispatch(getExamCategory(props.history));
    }
  };

  /* change category */
  const onCategoryHandler = (val) => {
    setCategory(val);
    setExamtype("");
    setBoard("");
    setSubtype("");
    setChapter("");
    dispatch(getNonGroupSubjectsAction([]));
  };

  /* get board  */
  const getBoardHandler = () => {
    if (props.boardList.length == 0) {
      dispatch(loadingBoardAction(true));
      dispatch(getBoardData(props.history));
    }
  };

  /* change board */
  const onBoardHandler = (val) => {
    setBoard(val);
    setChapter("");
    dispatch(getNonGroupSubjectsAction([]));
  };

  /* get exam type list */
  const getExamtypeHandler = () => {
    if (props.typeList.length == 0) {
      dispatch(loadingAction(true));
      dispatch(getExamType(props.history));
    }
  };

  /* change exam type */
  const onExamTypeHandler = (val) => {
    // let ex = val.split('^');
    // console.log('exmtype',ex);
    // return
    //setIsClass(val[2])
    setExamtype(val);
    setSubtype("");
    setChapter("");
    dispatch(getNonGroupSubjectsAction([]));
  };

  /* get sub type list */
  const getSubtypeHandler = () => {
    if (props.subTypeList.length == 0) {
      dispatch(loadingSubTypeAction(true));
      dispatch(getExamSubType(props.history));
    }
  };

  /* change sub type */
  const onSubtypeTypeHandler = (val) => {
    setSubtype(val);
    dispatch(getNonGroupSubjectsAction([]));
  };

  /* get subject list */
  const getSubjectHandler = () => {
    if (category == 1 && board) {
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(category),
          0,
          0,
          parseInt(board),
          props.history
        )
      );
    } else if (category == 2 && examtype == 1 && subtype) {
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(category),
          parseInt(examtype),
          parseInt(subtype),
          0,
          props.history
        )
      );
    } else if (category == 2 && examtype != 1) {
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(category),
          parseInt(examtype),
          0,
          0,
          props.history
        )
      );
    }
  };

  /* get class list */
  const getClassHandler = () => {
    if (props.classList.length == 0) {
      dispatch(loadingClassAction(true));
      dispatch(getClassData(props.history));
    }
  };

  /* get chapter list */
  const getChapterHandler = () => {
    if (category == 1 && board) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          board,
          0,
          0,
          subject,
          classroom,
          props.history
        )
      );
    } else if (category == 2 && examtype.split("^")[0] == 1 && subtype) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          0,
          examtype.split("^")[0],
          subtype,
          subject,
          0,
          props.history
        )
      );
    } else if (category == 2 && examtype.split("^")[0] != 1) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          0,
          examtype.split("^")[0],
          0,
          subject,
          classroom,
          props.history
        )
      );
    } else {
      dispatch(getChaptersDeffarentsParamsAction([]));
    }
  };

  /* get Question list */
  const getQuestionTypeHandler = () => {
    if (props.questionTypeList.length == 0) {
      dispatch(loadingPatternAction(true));
      dispatch(getQuestionPattern(props.history));
    }
  };

  /* on image upload */
  const onImageHandler = (blobInfo) => {
    return new Promise((success, reject) => {
      const formData = new FormData();
      formData.append("tag_image", blobInfo.blob());
      axios({
        url: GlobalConfigs.API_URL + Apis.UPLOAD_IMAGE_API,
        method: "POST",
        headers: {
          ContentType: "multipart/form-data",
        },
        data: formData,
      })
        .then((res) => {
          success(res.data.imagepath);
        })
        .then((json) => {
          //console.log('result2', json);
        });
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="row">
                  <div className="col-xl-2">
                    <div className="back-arrow-container">
                      <Link onClick={onGoBackHandler}>
                        <i class="fa-solid fa-arrow-left"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xl-8">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={onSubmit}>
                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Exam category name
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="basic-dropdown">
                              <span className="drop_down_arrow add-Question-arrow-position">
                                {props.categoryLoading ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  <i className="bi bi-caret-down-fill"></i>
                                )}
                              </span>
                              <select
                                defaultValue={"option"}
                                className="form-control form-control"
                                value={category}
                                onClick={getCategoryHandler}
                                onChange={(e) =>
                                  onCategoryHandler(e.target.value)
                                }
                              >
                                <option value="">Select category</option>
                                {props.categoryList.map((item, index) => {
                                  return (
                                    <option key={index} value={item.id}>
                                      {item.category}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errors.category && (
                              <div className="text-danger fs-12">
                                {errors.category}
                              </div>
                            )}
                          </div>

                          <div
                            onChange={onChangeRadioHandler}
                            className="content-radio-view"
                          >
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="gridRadios"
                                value="0"
                                defaultChecked
                                id="live"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="live"
                              >
                                Live
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="gridRadios"
                                value="1"
                                id="guest"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="guest"
                              >
                                Guest Demo
                              </label>
                            </div>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="gridRadios"
                                value="2"
                                id="register"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="register"
                              >
                                Register Demo
                              </label>
                            </div>
                          </div>

                          {parseInt(demo) == 0 ? (
                            <>
                              {parseInt(category) == 1 ? (
                                <div className="form-group mb-3">
                                  <label className="mb-2">
                                    <strong>Board</strong>
                                  </label>
                                  <div className="basic-dropdown">
                                    <span className="drop_down_arrow add-Question-arrow-position">
                                      {props.boardLoading ? (
                                        <Spinner animation="border" size="sm" />
                                      ) : (
                                        <i className="bi bi-caret-down-fill"></i>
                                      )}
                                    </span>
                                    <select
                                      defaultValue={"option"}
                                      className="form-control form-control"
                                      value={board}
                                      onClick={getBoardHandler}
                                      onChange={(e) =>
                                        onBoardHandler(e.target.value)
                                      }
                                    >
                                      <option value="">Select Board</option>
                                      {props.boardList.map((item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  {errors.board && (
                                    <div className="text-danger fs-12">
                                      {errors.board}
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <>
                                  <div
                                    className={`form-group mb-3 ${category == "" ? "content_disable" : null
                                      }`}
                                  >
                                    <label className="mb-2">
                                      <strong>
                                        Exam Type
                                        <span className="text-danger">*</span>
                                      </strong>
                                    </label>
                                    <div className="basic-dropdown">
                                      <span className="drop_down_arrow add-Question-arrow-position">
                                        {props.typeLoading ? (
                                          <Spinner
                                            animation="border"
                                            size="sm"
                                          />
                                        ) : (
                                          <i className="bi bi-caret-down-fill"></i>
                                        )}
                                      </span>
                                      <select
                                        defaultValue={"option"}
                                        className="form-control form-control"
                                        value={examtype}
                                        onClick={getExamtypeHandler}
                                        onChange={(e) =>
                                          onExamTypeHandler(e.target.value)
                                        }
                                      >
                                        <option value="">
                                          Select Exam type
                                        </option>
                                        {props.typeList.map((item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={[
                                                item.id + "^" + item.is_class,
                                              ]}
                                            >
                                              {item.type_name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    {errors.type && (
                                      <div className="text-danger fs-12">
                                        {errors.type}
                                      </div>
                                    )}
                                  </div>

                                  {parseInt(category) == 2 &&
                                    examtype.split("^")[0] == 1 ? (
                                    <div className={`form-group mb-3`}>
                                      <label className="mb-2">
                                        <strong>Exam Sub Type</strong>
                                      </label>
                                      <div className="basic-dropdown">
                                        <span className="drop_down_arrow add-Question-arrow-position">
                                          {props.subTypeLoading ? (
                                            <Spinner
                                              animation="border"
                                              size="sm"
                                            />
                                          ) : (
                                            <i className="bi bi-caret-down-fill"></i>
                                          )}
                                        </span>
                                        <select
                                          defaultValue={"option"}
                                          className="form-control form-control"
                                          value={subtype}
                                          onClick={getSubtypeHandler}
                                          onChange={(e) =>
                                            onSubtypeTypeHandler(e.target.value)
                                          }
                                        >
                                          <option value="">
                                            Select Sub type
                                          </option>
                                          {props.subTypeList.map(
                                            (item, index) => {
                                              return (
                                                <option
                                                  key={index}
                                                  value={item.id}
                                                >
                                                  {item.subtype_name}
                                                </option>
                                              );
                                            }
                                          )}
                                        </select>
                                      </div>
                                      {errors.subtype && (
                                        <div className="text-danger fs-12">
                                          {errors.subtype}
                                        </div>
                                      )}
                                    </div>
                                  ) : null}
                                </>
                              )}
                            </>
                          ) : null}

                          {parseInt(demo) == 0 ? (
                            <>
                              <div className="form-group mb-3">
                                <label className="mb-2">
                                  <strong>
                                    Subjects{" "}
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <div className="basic-dropdown">
                                  <span className="drop_down_arrow add-Question-arrow-position">
                                    {props.subjectLoading ? (
                                      <Spinner animation="border" size="sm" />
                                    ) : (
                                      <i className="bi bi-caret-down-fill"></i>
                                    )}
                                  </span>
                                  <select
                                    defaultValue={"option"}
                                    className="form-control form-control"
                                    value={subject}
                                    onClick={getSubjectHandler}
                                    onChange={(e) => setSubject(e.target.value)}
                                  >
                                    <option value="">Select subjects</option>
                                    {props.subjectList.map((item, index) => {
                                      return (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                {errors.subject && (
                                  <div className="text-danger fs-12">
                                    {errors.subject}
                                  </div>
                                )}
                              </div>

                              {parseInt(category) == 1 ||
                                examtype.split("^")[1] == "1" ? (
                                <div className="form-group mb-3">
                                  <label className="mb-2">
                                    <strong>Class</strong>
                                  </label>
                                  <div className="basic-dropdown">
                                    <span className="drop_down_arrow add-Question-arrow-position">
                                      {props.classLoading ? (
                                        <Spinner animation="border" size="sm" />
                                      ) : (
                                        <i className="bi bi-caret-down-fill"></i>
                                      )}
                                    </span>
                                    <select
                                      defaultValue={"option"}
                                      className="form-control form-control"
                                      value={classroom}
                                      onClick={getClassHandler}
                                      onChange={(e) =>
                                        setClassroom(e.target.value)
                                      }
                                    >
                                      <option value="">Select Class</option>
                                      {props.classList.map((item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item.short_code}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  {errors.className && (
                                    <div className="text-danger fs-12">
                                      {errors.className}
                                    </div>
                                  )}
                                </div>
                              ) : null}

                              <div className="form-group mb-3">
                                <label className="mb-2">
                                  <strong>
                                    Chapter
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <div className="basic-dropdown">
                                  <span className="drop_down_arrow add-Question-arrow-position">
                                    {props.chapterLoading ? (
                                      <Spinner animation="border" size="sm" />
                                    ) : (
                                      <i className="bi bi-caret-down-fill"></i>
                                    )}
                                  </span>
                                  <select
                                    defaultValue={"option"}
                                    className="form-control form-control"
                                    value={chapter}
                                    onClick={getChapterHandler}
                                    onChange={(e) => setChapter(e.target.value)}
                                  >
                                    <option value="">Select chapter</option>
                                    {props.chapterList.map((item, index) => {
                                      return (
                                        <option key={index} value={item.id}>
                                          {item.chapter_name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                {errors.chapter && (
                                  <div className="text-danger fs-12">
                                    {errors.chapter}
                                  </div>
                                )}
                              </div>
                            </>
                          ) : null}

                          {demo == 2 ? (
                            <div className="form-group mb-3">
                              <label className="mb-2">
                                <strong>Class</strong>
                              </label>
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow add-Question-arrow-position">
                                  {props.classLoading ? (
                                    <Spinner animation="border" size="sm" />
                                  ) : (
                                    <i className="bi bi-caret-down-fill"></i>
                                  )}
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={classroom}
                                  onClick={getClassHandler}
                                  onChange={(e) => setClassroom(e.target.value)}
                                >
                                  <option value="">Select Class</option>
                                  {props.classList.map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.short_code}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.className && (
                                <div className="text-danger fs-12">
                                  {errors.className}
                                </div>
                              )}
                            </div>
                          ) : null}

                          {demo == 2 && category == 1 && (
                            <div className="form-group mb-3">
                              <label className="mb-2">
                                <strong>Board</strong>
                              </label>
                              <div className="basic-dropdown">
                                <span className="drop_down_arrow add-Question-arrow-position">
                                  {props.boardLoading ? (
                                    <Spinner animation="border" size="sm" />
                                  ) : (
                                    <i className="bi bi-caret-down-fill"></i>
                                  )}
                                </span>
                                <select
                                  defaultValue={"option"}
                                  className="form-control form-control"
                                  value={board}
                                  onClick={getBoardHandler}
                                  onChange={(e) =>
                                    onBoardHandler(e.target.value)
                                  }
                                >
                                  <option value="">Select Board</option>
                                  {props.boardList.map((item, index) => {
                                    return (
                                      <option key={index} value={item.id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              {errors.board && (
                                <div className="text-danger fs-12">
                                  {errors.board}
                                </div>
                              )}
                            </div>
                          )}

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Question Type
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="basic-dropdown">
                              <span className="drop_down_arrow add-Question-arrow-position">
                                {props.questionTypeLoading ? (
                                  <Spinner animation="border" size="sm" />
                                ) : (
                                  <i className="bi bi-caret-down-fill"></i>
                                )}
                              </span>
                              <select
                                defaultValue={"option"}
                                className="form-control form-control"
                                value={questiontype}
                                onClick={getQuestionTypeHandler}
                                onChange={(e) =>
                                  setQuestiontype(e.target.value)
                                }
                              >
                                <option value="">Select Question type</option>
                                {props.questionTypeList.map((item, index) => {
                                  return (
                                    <option key={index} value={item.short_code}>
                                      {item.short_code}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            {errors.quesType && (
                              <div className="text-danger fs-12">
                                {errors.quesType}
                              </div>
                            )}
                          </div>
                          {questiontype == "CSS" && (
                            <div className="form-group mb-3">
                              <label className="mb-2">
                                <strong>
                                  CSS Group Id
                                  <span className="text-danger">*</span>
                                </strong>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter CSS group ID"
                                value={cssGroupId}
                                onChange={(e) => setCssGroupId(e.target.value)}
                              />
                              {errors.groupId && (
                                <div className="text-danger fs-12">
                                  {errors.groupId}
                                </div>
                              )}
                            </div>
                          )}

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Question No.
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter question No"
                              value={questiono}
                              onChange={(e) => setQuestiono(e.target.value)}
                            />

                            {errors.quesNo && (
                              <div className="text-danger fs-12">
                                {errors.quesNo}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label>
                              <strong>
                                Question Body
                                <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="image-preview-btn">
                              <div className="tab-btn-view">
                                <button
                                  onClick={() =>
                                    onQuestionEditHandler(1, false)
                                  }
                                  className={
                                    questionEditor
                                      ? "inactive-editor-btn radius-lft"
                                      : "active-editor-btn radius-lft"
                                  }
                                  type="button"
                                >
                                  Tiny Editor
                                </button>
                                <button
                                  onClick={() => onQuestionEditHandler(1, true)}
                                  className={
                                    questionEditor
                                      ? "active-editor-btn radius-right"
                                      : "inactive-editor-btn radius-right"
                                  }
                                  type="button"
                                >
                                  Custom Editor
                                </button>
                              </div>
                              <button
                                onClick={onQueModalHandlers}
                                type="button"
                                className="editor-preview-btn"
                              >
                                Preview
                              </button>
                            </div>

                            {questionEditor ? (
                              <>
                                <QuestionArea
                                  id="addformQuestion"
                                  rows="4"
                                  //questionId={props.questionDetails.id}
                                  questionName={"question_image"}
                                  question={questiobody}
                                  questionImg={imagesArray}
                                  listData={props.characterList}
                                  onQuestionInput={(val) =>
                                    onQuestionInputHandler(val)
                                  }
                                  onQuestionImg={(img) =>
                                    onQuestionImageHandler(img)
                                  }
                                />
                              </>
                            ) : (
                              <div className="ck-editer-view">
                                <Editor
                                  apiKey={GlobalConfigs.TINY_API_KEY}
                                  value={questiobody}
                                  onInit={(evt, editor) => {
                                    //setText(editor.getContent({ format: 'text' }));
                                  }}
                                  onEditorChange={(newValue, editor) => {
                                    setQuestiobody(newValue);
                                    //setText(editor.getContent({ format: 'text' }));
                                  }}
                                  init={{
                                    height: 250,
                                    menubar: false,
                                    image_advtab: true,
                                    images_upload_handler: onImageHandler,
                                    external_plugins: {
                                      tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                                    },
                                    plugins: [
                                      "advlist",
                                      "autolink",
                                      "lists",
                                      "link",
                                      "image",
                                      "charmap",
                                      "print",
                                      "preview anchor",
                                      "searchreplace",
                                      "visualblocks",
                                      "code",
                                      "fullscreen",
                                      "insertdatetime",
                                      "media",
                                      "table",
                                      "paste",
                                      "code",
                                      "help",
                                      "wordcount",
                                    ],
                                    toolbar:
                                      "undo redo | formatselect | " +
                                      "bold italic backcolor | alignleft aligncenter " +
                                      "alignright alignjustify | bullist numlist outdent indent | " +
                                      "removeformat | help | image |" +
                                      "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                                    //content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                  }}
                                />
                              </div>
                            )}
                            {errors.quesBody && (
                              <div className="text-danger fs-12">
                                {errors.quesBody}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label>
                              <strong>
                                Option A <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="image-preview-btn">
                              <div className="tab-btn-view">
                                <button
                                  onClick={() => onOptionAEditHandler(2, false)}
                                  className={
                                    optionAEditor
                                      ? "inactive-editor-btn radius-lft"
                                      : "active-editor-btn radius-lft"
                                  }
                                  type="button"
                                >
                                  Tiny Editor
                                </button>
                                <button
                                  onClick={() => onOptionAEditHandler(2, true)}
                                  className={
                                    optionAEditor
                                      ? "active-editor-btn radius-right"
                                      : "inactive-editor-btn radius-right"
                                  }
                                  type="button"
                                >
                                  Custom Editor
                                </button>
                              </div>
                              <button
                                onClick={onFirstOptionPreviewHandler}
                                type="button"
                                className="editor-preview-btn"
                              >
                                Preview
                              </button>
                            </div>

                            {optionAEditor ? (
                              <QuestionArea
                                id="formOptionA"
                                rows="4"
                                questionName={"option_1_image"}
                                question={option}
                                questionImg={firstOptionArray}
                                listData={props.characterList}
                                onQuestionInput={(val) =>
                                  onOptionAInputHandler(val)
                                }
                                onQuestionImg={(img) =>
                                  onOptionAImageHandler(img)
                                }
                              />
                            ) : (
                              <div className="ck-editer-view">
                                <Editor
                                  apiKey={GlobalConfigs.TINY_API_KEY}
                                  value={option}
                                  onInit={(evt, editor) => {
                                    //setText(editor.getContent({ format: 'text' }));
                                  }}
                                  onEditorChange={(newValue, editor) => {
                                    setOption(newValue);
                                    //setText(editor.getContent({ format: 'text' }));
                                  }}
                                  init={{
                                    height: 250,
                                    menubar: false,
                                    image_advtab: true,
                                    images_upload_handler: onImageHandler,
                                    external_plugins: {
                                      tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                                    },
                                    plugins: [
                                      "advlist",
                                      "autolink",
                                      "lists",
                                      "link",
                                      "image",
                                      "charmap",
                                      "print",
                                      "preview anchor",
                                      "searchreplace",
                                      "visualblocks",
                                      "code",
                                      "fullscreen",
                                      "insertdatetime",
                                      "media",
                                      "table",
                                      "paste",
                                      "code",
                                      "help",
                                      "wordcount",
                                    ],
                                    toolbar:
                                      "undo redo | formatselect | " +
                                      "bold italic backcolor | alignleft aligncenter " +
                                      "alignright alignjustify | bullist numlist outdent indent | " +
                                      "removeformat | help | image |" +
                                      "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                                    content_style:
                                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                  }}
                                />
                              </div>
                            )}
                            {errors.optionA && (
                              <div className="text-danger fs-12">
                                {errors.optionA}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label>
                              <strong>
                                Option B <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="image-preview-btn">
                              <div className="tab-btn-view">
                                <button
                                  onClick={() => onOptionBEditHandler(3, false)}
                                  className={
                                    optionBEditor
                                      ? "inactive-editor-btn radius-lft"
                                      : "active-editor-btn radius-lft"
                                  }
                                  type="button"
                                >
                                  Tiny Editor
                                </button>
                                <button
                                  onClick={() => onOptionBEditHandler(3, true)}
                                  className={
                                    optionBEditor
                                      ? "active-editor-btn radius-right"
                                      : "inactive-editor-btn radius-right"
                                  }
                                  type="button"
                                >
                                  Custom Editor
                                </button>
                              </div>
                              <button
                                onClick={onSecondOptionPreviewHandler}
                                type="button"
                                className="editor-preview-btn"
                              >
                                Preview
                              </button>
                            </div>

                            {optionBEditor ? (
                              <QuestionArea
                                id="formOptionB"
                                rows="4"
                                questionName={"option_2_image"}
                                question={option2}
                                questionImg={secondOptionArray}
                                listData={props.characterList}
                                onQuestionInput={(val) =>
                                  onOptionBInputHandler(val)
                                }
                                onQuestionImg={(img) =>
                                  onOptionBImageHandler(img)
                                }
                              />
                            ) : (
                              <div className="ck-editer-view">
                                <Editor
                                  apiKey={GlobalConfigs.TINY_API_KEY}
                                  value={option2}
                                  onInit={(evt, editor) => { }}
                                  onEditorChange={(newValue, editor) => {
                                    setOption2(newValue);
                                  }}
                                  init={{
                                    height: 250,
                                    menubar: false,
                                    image_advtab: true,
                                    images_upload_handler: onImageHandler,
                                    external_plugins: {
                                      tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                                    },
                                    plugins: [
                                      "advlist",
                                      "autolink",
                                      "lists",
                                      "link",
                                      "image",
                                      "charmap",
                                      "print",
                                      "preview anchor",
                                      "searchreplace",
                                      "visualblocks",
                                      "code",
                                      "fullscreen",
                                      "insertdatetime",
                                      "media",
                                      "table",
                                      "paste",
                                      "code",
                                      "help",
                                      "wordcount",
                                    ],
                                    toolbar:
                                      "undo redo | formatselect | " +
                                      "bold italic backcolor | alignleft aligncenter " +
                                      "alignright alignjustify | bullist numlist outdent indent | " +
                                      "removeformat | help | image |" +
                                      "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                                    content_style:
                                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                  }}
                                />
                              </div>
                            )}
                            {errors.optionB && (
                              <div className="text-danger fs-12">
                                {errors.optionB}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label>
                              <strong>
                                Option C <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="image-preview-btn">
                              <div className="tab-btn-view">
                                <button
                                  onClick={() => onOptionCEditHandler(4, false)}
                                  className={
                                    optionCEditor
                                      ? "inactive-editor-btn radius-lft"
                                      : "active-editor-btn radius-lft"
                                  }
                                  type="button"
                                >
                                  Tiny Editor
                                </button>
                                <button
                                  onClick={() => onOptionCEditHandler(4, true)}
                                  className={
                                    optionCEditor
                                      ? "active-editor-btn radius-right"
                                      : "inactive-editor-btn radius-right"
                                  }
                                  type="button"
                                >
                                  Custom Editor
                                </button>
                              </div>
                              <button
                                onClick={onThirdOptionPreviewHandler}
                                type="button"
                                className="editor-preview-btn"
                              >
                                Preview
                              </button>
                            </div>

                            {optionCEditor ? (
                              <QuestionArea
                                id="formOptionC"
                                rows="4"
                                questionName={"option_3_image"}
                                question={option3}
                                questionImg={thirdOptionArray}
                                listData={props.characterList}
                                onQuestionInput={(val) =>
                                  onOptionCInputHandler(val)
                                }
                                onQuestionImg={(img) =>
                                  onOptionCImageHandler(img)
                                }
                              />
                            ) : (
                              <div className="ck-editer-view">
                                <Editor
                                  apiKey={GlobalConfigs.TINY_API_KEY}
                                  value={option3}
                                  onInit={(evt, editor) => { }}
                                  onEditorChange={(newValue, editor) => {
                                    setOption3(newValue);
                                  }}
                                  init={{
                                    height: 250,
                                    menubar: false,
                                    image_advtab: true,
                                    images_upload_handler: onImageHandler,
                                    external_plugins: {
                                      tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                                    },
                                    plugins: [
                                      "advlist",
                                      "autolink",
                                      "lists",
                                      "link",
                                      "image",
                                      "charmap",
                                      "print",
                                      "preview anchor",
                                      "searchreplace",
                                      "visualblocks",
                                      "code",
                                      "fullscreen",
                                      "insertdatetime",
                                      "media",
                                      "table",
                                      "paste",
                                      "code",
                                      "help",
                                      "wordcount",
                                    ],
                                    toolbar:
                                      "undo redo | formatselect | " +
                                      "bold italic backcolor | alignleft aligncenter " +
                                      "alignright alignjustify | bullist numlist outdent indent | " +
                                      "removeformat | help | image |" +
                                      "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                                    content_style:
                                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                  }}
                                />
                              </div>
                            )}
                            {errors.optionC && (
                              <div className="text-danger fs-12">
                                {errors.optionC}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label>
                              <strong>
                                Option D <span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="image-preview-btn">
                              <div className="tab-btn-view">
                                <button
                                  onClick={() => onOptionDEditHandler(5, false)}
                                  className={
                                    optionDEditor
                                      ? "inactive-editor-btn radius-lft"
                                      : "active-editor-btn radius-lft"
                                  }
                                  type="button"
                                >
                                  Tiny Editor
                                </button>
                                <button
                                  onClick={() => onOptionDEditHandler(5, true)}
                                  className={
                                    optionDEditor
                                      ? "active-editor-btn radius-right"
                                      : "inactive-editor-btn radius-right"
                                  }
                                  type="button"
                                >
                                  Custom Editor
                                </button>
                              </div>
                              <button
                                onClick={onFourthOptionPreviewHandler}
                                type="button"
                                className="editor-preview-btn"
                              >
                                Preview
                              </button>
                            </div>

                            {optionDEditor ? (
                              <QuestionArea
                                id="formOptionD"
                                rows="4"
                                questionName={"option_4_image"}
                                question={option4}
                                questionImg={fourthOptionArray}
                                listData={props.characterList}
                                onQuestionInput={(val) =>
                                  onOptionDInputHandler(val)
                                }
                                onQuestionImg={(img) =>
                                  onOptionDImageHandler(img)
                                }
                              />
                            ) : (
                              <div className="ck-editer-view">
                                <Editor
                                  apiKey={GlobalConfigs.TINY_API_KEY}
                                  value={option4}
                                  onInit={(evt, editor) => { }}
                                  onEditorChange={(newValue, editor) => {
                                    setOption4(newValue);
                                  }}
                                  init={{
                                    height: 250,
                                    menubar: false,
                                    image_advtab: true,
                                    images_upload_handler: onImageHandler,
                                    external_plugins: {
                                      tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                                    },
                                    plugins: [
                                      "advlist",
                                      "autolink",
                                      "lists",
                                      "link",
                                      "image",
                                      "charmap",
                                      "print",
                                      "preview anchor",
                                      "searchreplace",
                                      "visualblocks",
                                      "code",
                                      "fullscreen",
                                      "insertdatetime",
                                      "media",
                                      "table",
                                      "paste",
                                      "code",
                                      "help",
                                      "wordcount",
                                    ],
                                    toolbar:
                                      "undo redo | formatselect | " +
                                      "bold italic backcolor | alignleft aligncenter " +
                                      "alignright alignjustify | bullist numlist outdent indent | " +
                                      "removeformat | help | image |" +
                                      "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                                    content_style:
                                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                  }}
                                />
                              </div>
                            )}
                            {errors.optionD && (
                              <div className="text-danger fs-12">
                                {errors.optionD}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label className="mb-2">
                              <strong>
                                Answers<span className="text-danger">*</span>
                              </strong>
                            </label>
                            <div className="basic-dropdown">
                              <span className="drop_down_arrow">
                                <i className="bi bi-caret-down-fill"></i>
                              </span>
                              <select
                                className="form-control form-control"
                                value={answers}
                                onChange={(e) => setAnswers(e.target.value)}
                              >
                                <option value="">Select Answers</option>
                                <option value="A">A</option>
                                <option value="B">B</option>
                                <option value="C">C</option>
                                <option value="D">D</option>
                              </select>
                            </div>
                            {errors.anws && (
                              <div className="text-danger fs-12">
                                {errors.anws}
                              </div>
                            )}
                          </div>

                          <div className="form-group mb-3">
                            <label>
                              <strong>Reason <span className="text-danger">*</span></strong>
                            </label>
                            <div className="image-preview-btn">
                              <div className="tab-btn-view">
                                <button
                                  onClick={() => onReasonEditHandler(6, false)}
                                  className={
                                    reasonEditor
                                      ? "inactive-editor-btn radius-lft"
                                      : "active-editor-btn radius-lft"
                                  }
                                  type="button"
                                >
                                  Tiny Editor
                                </button>
                                <button
                                  onClick={() => onReasonEditHandler(6, true)}
                                  className={
                                    reasonEditor
                                      ? "active-editor-btn radius-right"
                                      : "inactive-editor-btn radius-right"
                                  }
                                  type="button"
                                >
                                  Custom Editor
                                </button>
                              </div>
                              <button
                                onClick={onReasonPreviewHandler}
                                type="button"
                                className="editor-preview-btn"
                              >
                                Preview
                              </button>
                            </div>

                            {reasonEditor ? (
                              <QuestionArea
                                id="formReason"
                                rows="4"
                                questionName={"reason_image"}
                                question={answersbody}
                                questionImg={reasonArray}
                                listData={props.characterList}
                                onQuestionInput={(val) =>
                                  onReasonInputHandler(val)
                                }
                                onQuestionImg={(img) =>
                                  onReasonImageHandler(img)
                                }
                              />
                            ) : (
                              <div className="ck-editer-view">
                                <Editor
                                  apiKey={GlobalConfigs.TINY_API_KEY}
                                  value={answersbody}
                                  onInit={(evt, editor) => { }}
                                  onEditorChange={(newValue, editor) => {
                                    setAnswersbody(newValue);
                                  }}
                                  init={{
                                    height: 250,
                                    menubar: false,
                                    image_advtab: true,
                                    images_upload_handler: onImageHandler,
                                    external_plugins: {
                                      tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                                    },
                                    plugins: [
                                      "advlist",
                                      "autolink",
                                      "lists",
                                      "link",
                                      "image",
                                      "charmap",
                                      "print",
                                      "preview anchor",
                                      "searchreplace",
                                      "visualblocks",
                                      "code",
                                      "fullscreen",
                                      "insertdatetime",
                                      "media",
                                      "table",
                                      "paste",
                                      "code",
                                      "help",
                                      "wordcount",
                                    ],
                                    toolbar:
                                      "undo redo | formatselect | " +
                                      "bold italic backcolor | alignleft aligncenter " +
                                      "alignright alignjustify | bullist numlist outdent indent | " +
                                      "removeformat | help | image |" +
                                      "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                                    content_style:
                                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                  }}
                                />
                              </div>
                            )}
                            {errors.answersbody && (
                              <div className="text-danger fs-12">
                                {errors.answersbody}
                              </div>
                            )}
                          </div>



                          <div className="add-question-btn-container mt-4">
                            <button
                              onClick={onGoBackHandler}
                              type="button"
                              className="btn btn-primary"
                            >
                              Cancel
                            </button>

                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={props.showLoading}
                            >
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal className="fade bd-example-modal-lg" show={modal} size="lg">
          <Modal.Header>
            <Modal.Title>Preview</Modal.Title>
            <Button
              variant=""
              className="btn-close"
              onClick={() => setModal(false)}
            ></Button>
          </Modal.Header>
          <Modal.Body>
            {/* <div dangerouslySetInnerHTML={{ __html: htmlResult }} /> */}
            {/* <MathJax math={htmlResult} /> */}
            <div className="modal-editor">
              <Editor
                apiKey={GlobalConfigs.TINY_API_KEY}
                value={htmlResult}
                disabled={true}
                init={{
                  height: 300,
                  menubar: false,
                  external_plugins: {
                    tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                  },
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger light" onClick={() => setModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          className="fade bd-example-modal-lg"
          show={confirmModal}
          size="md"
        >
          <Alert
            //variant={data.variant}
            className="alert alert-primary notification mb-0"
          >
            <p className="notificaiton-title mb-2">
              <strong>Warning!</strong> Vampires The Romantic Ideology Behind
              Them
            </p>
            <p>
              The following article covers a topic that has recently moved to
              center stage-at lease it seems that way.
            </p>
            <Button
              //variant={data.variant}
              onClick={() => onConfirmHandler()}
              size="sm"
            >
              Confirm
            </Button>
            <Button
              variant="link"
              onClick={() => setConfirmModal(false)}
              size="sm"
            >
              Cancel
            </Button>
          </Alert>
        </Modal>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.content.showLoading,
    categoryLoading: state.category.showLoading,
    categoryList: state.category.list,
    boardLoading: state.board.showLoading,
    boardList: state.board.list,
    typeLoading: state.type.showLoading,
    typeList: state.type.list,
    subTypeLoading: state.subtype.showLoading,
    subTypeList: state.subtype.list,
    subjectLoading: state.subject.showLoading,
    subjectList: state.subject.nonGroupSubjectList,
    classLoading: state.class.showLoading,
    classList: state.class.list,
    chapterLoading: state.chapter.showLoading,
    chapterList: state.chapter.typelist,
    questionTypeLoading: state.pattern.showLoading,
    questionTypeList: state.pattern.list,
    characterList: state.content.characterList,
  };
};
export default connect(mapStateToProps)(AddQuestion);
