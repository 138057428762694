import { Fragment, useEffect, useState, useRef } from "react";
import { Row, Col, Card, Table, Modal, Button } from "react-bootstrap";
import { useDispatch, connect, useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";

import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import {
  misSubscribedUsersStudentActivitiesData,
  misSubscribedUserstudentActivitiesExcelDownloadData,
} from "../../../store/actions/MisDashboardInfoAction";

import moment from "moment";

import ReactPaginate from "react-paginate";

//images

import export_details from "../../../images/dashboard_image/export.png";

const StudentActivities = (props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const misSubscribedUsersStudentActivitiesList = useSelector(
    (state) => state.MisDashboardInfo.misSubscribedUsersStudentActivitiesList
  );
  const [examModal, setExamModal] = useState(false);
  const [eLibraryModal, setELibraryModal] = useState(false);
  const [studentExamPerformance, setStudentExamPerformance] = useState("");
  const [studentELibrary, setStudentELibrary] = useState("");

  const [page, setPage] = useState(1);

  useEffect(() => {
    getList(state?.item?.student_id);
    setPage(state?.page);
  }, []);

  const getList = (student_id) => {
    dispatch(globalLoadingAction(true));
    dispatch(misSubscribedUsersStudentActivitiesData(student_id));
  };

  const onDownloadSubscribedUserStudentActivitiesHandler = () => {
    let page = 0;
    dispatch(globalLoadingAction(true));
    dispatch(
      misSubscribedUserstudentActivitiesExcelDownloadData(
        state?.item?.student_id
      )
    );
  };

  /* navigation go back with state */
  const onGoBackHandler = () => {
    props.history.push({
      pathname: "/subscribed-users",
      state: { page, previousPageFilterData: state.filterData },
    });
  };

  const examPerformanceShowModal = (item) => {
    console.log("item..", item);
    setExamModal(true);
    setStudentExamPerformance(item);
  };

  const elibraryPerformanceShowModal = (item) => {
    setELibraryModal(true);
    setStudentELibrary(item);
  };

  return (
    <Fragment>
      {console.log("props.list", props.list)}
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div
                className="add-filter-group"
                style={{ justifyContent: "space-between" }}
              >
                <div className="back-arrow-container">
                  <Link onClick={onGoBackHandler}>
                    <i className="fa-solid fa-arrow-left"></i>
                  </Link>
                </div>

                <div
                  className="filtersAlign mt-3"
                  style={{ marginRight: 25 }}
                  onClick={() =>
                    onDownloadSubscribedUserStudentActivitiesHandler()
                  }
                >
                  <div className="btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 export_background rounded blue_border cursorPointer">
                    <div className="image_container">
                      <img
                        src={export_details}
                        alt=""
                        className="image_style"
                      />
                    </div>
                    <div className="button_text font_color_blue cursorPointer">
                      Export
                    </div>
                  </div>
                </div>
              </div>

              <div className="student_details_container ">
                <div className="student_details_child_container ">
                  <div className="row">
                    <div className="form-group col-sm-6 px-1 mb-3 d-flex flex-row">
                      <div className="label_container">
                        <strong>Name : </strong>
                      </div>
                      <div className="text-capitalize">
                        {state?.item?.student_name}
                      </div>
                    </div>
                    <div className="form-group col-sm-6 px-1 mb-3 d-flex flex-row">
                      <div className="label_container">
                        <strong>Services : </strong>
                      </div>
                      <div>{state?.item?.services}</div>
                    </div>

                    <div className="form-group col-sm-6 px-1 mb-3 d-flex flex-row">
                      <div className="label_container">
                        <strong>Class :</strong>
                      </div>
                      <div>{state?.item?.class}</div>
                    </div>

                    <div className="form-group col-sm-6 px-1 mb-3 d-flex flex-row">
                      <div className="label_container">
                        <strong>Amount :</strong>
                      </div>
                      <div>{state?.item?.amount}/-</div>
                    </div>

                    <div className="form-group col-sm-6 px-1 mb-3 d-flex flex-row">
                      <div className="label_container">
                        <strong>Board :</strong>
                      </div>
                      <div>{state?.item?.board}</div>
                    </div>

                    <div className="form-group col-sm-6 px-1 mb-3 d-flex flex-row">
                      <div className="label_container">
                        <strong>Course completion :</strong>
                      </div>
                      <div>{state?.item?.course_completion}%</div>
                    </div>
                  </div>
                </div>
              </div>

              <Table responsive>
                <thead className="thead-fix">
                  <tr>
                    <th>
                      <strong>Date</strong>
                    </th>
                    <th>
                      <strong>Time</strong>
                    </th>
                    <th>
                      <strong>Activity</strong>
                    </th>
                    <th style={{textAlign:'center'}}>
                      <strong>Action</strong>
                    </th>
                  </tr>
                </thead>
                <tbody className="tbody-scroll">
                  {misSubscribedUsersStudentActivitiesList.map(
                    (item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.date}</td>
                          <td>{item.time}</td>
                          <td>{item.activity}</td>
                          {/* <td>{item.action}</td> */}
                          <td style={{textAlign:'center'}}>
                            {item.action == 1 ? (
                              <Button
                                variant="info shadow btn-xs sharp"
                                onClick={() => examPerformanceShowModal(item)}
                              >
                                <i className="fa fa-eye"></i>
                              </Button>
                            ) : item.action == 2 ? (
                              <Button
                                variant="warning shadow btn-xs sharp"
                                onClick={() =>
                                  elibraryPerformanceShowModal(item)
                                }
                              >
                                <i className="fa fa-eye"></i>
                              </Button>
                            ) : null}
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <!-- Modal --> */}
      <Modal className="fade" show={examModal} size="md">
        <Modal.Header>
          <Modal.Title>Performance Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setExamModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="performance_details_contaner flex-wrap">
            <div className="individual_container">
              <div className="first_container">
                <strong>Subject Name </strong>
              </div>
              <div className="second_container">
                <strong>:</strong>
              </div>
              <div className="third_container">
                {studentExamPerformance?.subject_name}
              </div>
            </div>

            <div className="individual_container">
              <div className="first_container">
                <strong>Chapter No.</strong>
              </div>
              <div className="second_container">
                <strong>:</strong>
              </div>
              <div className="third_container">
                {studentExamPerformance?.chapter_no}
              </div>
            </div>

            <div className="individual_container">
              <div className="first_container">
                <strong>Total Marks </strong>
              </div>
              <div className="second_container">
                <strong>:</strong>
              </div>
              <div className="third_container">
                {studentExamPerformance?.total_marks}
              </div>
            </div>

            <div className="individual_container">
              <div className="first_container">
                <strong>Total no. of Question</strong>
              </div>
              <div className="second_container">
                <strong>:</strong>
              </div>
              <div className="third_container">
                {studentExamPerformance?.total_no_questions}
              </div>
            </div>

            <div className="individual_container">
              <div className="first_container">
                <strong>Correct</strong>
              </div>
              <div className="second_container">
                <strong>:</strong>
              </div>
              <div className="third_container">
                {studentExamPerformance?.correct}
              </div>
            </div>

            <div className="individual_container">
              <div className="first_container">
                <strong>Incorrect</strong>
              </div>
              <div className="second_container">
                <strong>:</strong>
              </div>
              <div className="third_container">
                {studentExamPerformance?.incorrect}
              </div>
            </div>

            <div className="individual_container">
              <div className="first_container">
                <strong>Not Attempted</strong>
              </div>
              <div className="second_container">
                <strong>:</strong>
              </div>
              <div className="third_container">
                {studentExamPerformance?.not_attempted}
              </div>
            </div>

            <div className="individual_container">
              <div className="first_container">
                <strong>Mark Obtained User</strong>
              </div>
              <div className="second_container">
                <strong>:</strong>
              </div>
              <div className="third_container">
                {studentExamPerformance?.mark_obtained_user}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* ------------------ */}
      {/* <!-- Modal --> */}
      <Modal className="fade" show={eLibraryModal} size="md">
        <Modal.Header>
          <Modal.Title>Performance Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setELibraryModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="performance_details_contaner  flex-column">
            <div className="individual_container">
              <div className="first_container">
                <strong>Subject Name </strong>
              </div>
              <div className="second_container">
                <strong>:</strong>
              </div>
              <div className="third_container">
                {studentELibrary.subject_name}
              </div>
            </div>

            <div className="individual_container">
              <div className="first_container">
                <strong>Chapter No.</strong>
              </div>
              <div className="second_container">
                <strong>:</strong>
              </div>
              <div className="third_container">
                {studentELibrary.chapter_no}
              </div>
            </div>

            <div className="individual_container">
              <div className="first_container">
                <strong>Session Time (Minute) </strong>
              </div>
              <div className="second_container">
                <strong>:</strong>
              </div>
              <div className="third_container">
                {studentELibrary.session_time}
              </div>
            </div>

            <div className="individual_container">
              <div className="first_container">
                <strong>Most Search Question</strong>
              </div>
              <div className="second_container">
                <strong>:</strong>
              </div>
              <div className="third_container">
                {studentELibrary.most_search_question}
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <div className='footer_text'>This details will be included in the export of Excel</div>
        </Modal.Footer> */}
      </Modal>
    </Fragment>
  );
};

const mapstateToProps = (state) => {
  return {
    boardList: state.board.list,
    classList: state.class.list,
    categoryList: state.category.list,
    subjectList: state.subject.subjectList,
    subscriptionDetailsList: state.subscriptiondetails.list,
  };
};

export default connect(mapstateToProps)(StudentActivities);
