import { Fragment, useEffect, useState, useRef } from "react";
import { Row, Col, Card, Table, Modal, Button } from "react-bootstrap";
import { useDispatch, connect, useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";

import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";

import Spinner from "react-bootstrap/Spinner";

import { globalLoadingAction } from "../../../store/actions/GlobalAction";

import { getClassData } from "../../../store/actions/ClassAction";
import { getBoardData } from "../../../store/actions/BoardAction";

import {
  misRegisterStudentEmailListData,
  // misSubscribedUsersData,
  // misSubscribedUsersExcelDownloadData,
  // misSubscribedUsersStudentSubscriptionData,
} from "../../../store/actions/MisRegUserAction";

import {
  misSubscribedUsersData,
  misSubscribedUsersExcelDownloadData,
  misSubscribedUsersStudentSubscriptionData,
  misSubscribedUsersStudentSubscriptionAction,
  misSubscribedUsersStudentSubscriptionListLoaderAction,
  misStudentSubscriptionDetailsExcelDownloadData,
} from "../../../store/actions/MisDashboardInfoAction";

import coursecompletionList from "./json/coursecompletion.json";
import serviceList from "./json/service.json";
import subscribetypeList from "./json/subscribetype.json";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import ReactPaginate from "react-paginate";

//images
import sort from "../../../images/dashboard_image/sort.png";
import export_details from "../../../images/dashboard_image/export.png";

import AutocompleteSearch from "../AutocompleteSearch/AutocompleteSearch";

const SubscribedUsers = (props) => {
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [studentSubscriptionDetailsModal, setStudentSubscriptionDetailsModal] =
    useState(false);

  const dispatch = useDispatch();
  const { state } = useLocation();

  const misRegisteredStudentEmail = useSelector(
    (state) => state.MisDashboardInfo.misRegisteredStudentEmail
  );
  const misSubscribedUsersList = useSelector(
    (state) => state.MisDashboardInfo.misSubscribedUsersList
  );
  const misSubscribedUsersStudentSubscriptionList = useSelector(
    (state) => state.MisDashboardInfo.misSubscribedUsersStudentSubscriptionList
  );
  const misSubscribedUsersStudentSubscriptionListLoader = useSelector(
    (state) =>
      state.MisDashboardInfo.misSubscribedUsersStudentSubscriptionListLoader
  );

  const classList = useSelector((state) => state.class.list);
  const boardList = useSelector((state) => state.board.list);

  const [board, setboard] = useState(
    state?.previousPageFilterData?.board ?? ""
  );
  const [className, setClassName] = useState(
    state?.previousPageFilterData?.className ?? ""
  );
  const [courseCompletion, setCourseCompletion] = useState(
    state?.previousPageFilterData?.courseCompletion ?? ""
  );
  const [service, setService] = useState(
    state?.previousPageFilterData?.service ?? ""
  );
  const [email, setEmail] = useState(
    state?.previousPageFilterData?.email ?? ""
  );
  const [subscribeType, setSubscribeType] = useState(
    state?.previousPageFilterData?.subscribeType ?? ""
  );

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minPrice, setMinPrice] = useState(
    state?.previousPageFilterData?.minPrice ?? 0
  );
  const [maxPrice, setMaxPrice] = useState(
    state?.previousPageFilterData?.maxPrice ?? 20000
  );

  /* board, className, courseCompletion, service, email, subscribeType, minPrice, maxPrice */

  const [currentStudent, setCurrentStudent] = useState("");

  const [emailData, setEmailData] = useState([]);

  const [page, setPage] = useState(state?.page ?? 1);

  const [defaultMinPrice, setDefaultMinPrice] = useState(0);
  const [defaultMaxPrice, setDefaultMaxPrice] = useState(20000);

  const calendarRef = useRef();

  const [tempBoard, setTempBoard] = useState("");
  const [tempClassName, setTempClassName] = useState("");
  const [tempCourseCompletion, setTempCourseCompletion] = useState("");
  const [tempService, setTempService] = useState("");
  const [tempEmail, setTempEmail] = useState("");
  const [tempSubscribeType, setTempSubscribeType] = useState("");
  const [tempMinPrice, setTempMinPrice] = useState(0);
  const [tempMaxPrice, setTempMaxPrice] = useState(0);
  const [permission, setPermission] = useState("");

  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    dispatch(getClassData());
    dispatch(getBoardData());
    dispatch(misRegisterStudentEmailListData());
    permissionCheck();
  }, []);

  useEffect(() => {
    if (state?.previousPageFilterData) {
      setTempBoard(state?.previousPageFilterData?.board ?? board);
      setTempClassName(state?.previousPageFilterData?.className ?? className);
      setTempCourseCompletion(
        state?.previousPageFilterData?.courseCompletion ?? courseCompletion
      );
      setTempService(state?.previousPageFilterData?.service ?? service);
      setTempEmail(state?.previousPageFilterData?.email ?? email);
      setTempSubscribeType(
        state?.previousPageFilterData?.subscribeType ?? subscribeType
      );
      setTempMinPrice(state?.previousPageFilterData?.minPrice ?? minPrice);
      setTempMaxPrice(state?.previousPageFilterData?.maxPrice ?? maxPrice);
    }
  }, [state]);

  useEffect(() => {
    if (board != "select_baord" && board != "" && board != undefined) {
      setIsFiltered(true);
    } else if (
      className != "select_class" &&
      className != "" &&
      className != undefined
    ) {
      setIsFiltered(true);
    } else if (
      courseCompletion != "select_course_completion" &&
      courseCompletion != "" &&
      courseCompletion != undefined
    ) {
      setIsFiltered(true);
    } else if (
      service != "select_service" &&
      service != "" &&
      service != undefined
    ) {
      setIsFiltered(true);
    } else if (email != "" && email != undefined) {
      setIsFiltered(true);
    } else if (
      subscribeType != "select_subscribe_type" &&
      subscribeType != "" &&
      subscribeType != undefined
    ) {
      setIsFiltered(true);
    } else if (
      minPrice != defaultMinPrice &&
      minPrice != "" &&
      minPrice != undefined
    ) {
      setIsFiltered(true);
    } else if (
      maxPrice != defaultMaxPrice &&
      maxPrice != "" &&
      maxPrice != undefined
    ) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
  }, [
    board,
    className,
    courseCompletion,
    service,
    email,
    subscribeType,
    minPrice,
    maxPrice,
  ]);

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 84) {
        console.log("84----------", item)
        setPermission(item);
      }
    });
  };

  const onResetHandler = () => {
    setboard("");
    setClassName("");
    setCourseCompletion("");
    setService("");
    setEmail("");
    setSubscribeType("");
    setStartDate("");
    setEndDate("");
    setMinPrice(defaultMinPrice);
    setMaxPrice(defaultMaxPrice);
    setPage(1);
    dispatch(globalLoadingAction(true));
    dispatch(
      misSubscribedUsersData(
        "",
        "",
        "",
        "",
        "",
        "",
        defaultMinPrice.toString(),
        defaultMaxPrice.toString(),
        1
      )
    );
    setModal(false);
    setTempBoard("");
    setTempClassName("");
    setTempCourseCompletion("");
    setTempService("");
    setTempEmail("");
    setTempSubscribeType("");
    setTempMinPrice(0);
    setTempMaxPrice(0);
    const newState = {};
    history.replace({ state: newState });
  };

  useEffect(() => {
    // getList(board, className, courseCompletion, service, email, subscribeType, startDate, endDate, minPrice, maxPrice, page)
    getList(
      board,
      className,
      courseCompletion,
      service,
      email,
      subscribeType,
      minPrice,
      maxPrice,
      page
    );
  }, []);

  useEffect(() => {
    /* -------- */
    let email = [];
    let emailObj = {};
    misRegisteredStudentEmail.forEach((elm) => {
      emailObj = {};
      emailObj = elm.email;
      email.push(emailObj);
    });
    setEmailData(email);
  }, [misRegisteredStudentEmail]);

  const getList = (
    board,
    className,
    courseCompletion,
    service,
    email,
    subscribeType,
    minPrice,
    maxPrice,
    page
  ) => {
    dispatch(globalLoadingAction(true));
    dispatch(
      misSubscribedUsersData(
        board == "select_baord" ? "" : board,
        className == "select_class" ? "" : className,
        courseCompletion == "select_course_completion" ? "" : courseCompletion,
        service == "select_service" ? "" : service,
        email,
        subscribeType == "select_subscribe_type" ? "" : subscribeType,
        // startDate === '' || startDate === null ? '' : moment(startDate).format("YYYY-MM-DD"),
        // endDate === '' || endDate === null ? '' : moment(endDate).format("YYYY-MM-DD"),
        minPrice.toString(),
        maxPrice.toString(),
        page
      )
    );
  };

  /* date handler */
  const onDateHandler = (date) => {
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  const filterSubscribedUsersHandler = () => {
    let page = 1;
    setModal(false);
    setPage(1);
    getList(
      board,
      className,
      courseCompletion,
      service,
      email,
      subscribeType,
      minPrice,
      maxPrice,
      page
    );

    setTempBoard(board);
    setTempClassName(className);
    setTempCourseCompletion(courseCompletion);
    setTempService(service);
    setTempEmail(email);
    setTempSubscribeType(subscribeType);
    setTempMinPrice(minPrice);
    setTempMaxPrice(maxPrice);
  };

  /* pagination */
  const pageHandler = (data) => {
    let page = data.selected + 1;
    setPage(data.selected + 1);
    getList(
      board,
      className,
      courseCompletion,
      service,
      email,
      subscribeType,
      minPrice,
      maxPrice,
      page
    );
  };

  const onDownloadSubscribedUsersHandler = () => {
    let page = 0;
    dispatch(globalLoadingAction(true));
    dispatch(
      misSubscribedUsersExcelDownloadData(
        board,
        className,
        courseCompletion,
        service,
        email,
        subscribeType,
        // startDate === '' || startDate === null ? '' : moment(startDate).format("YYYY-MM-DD"),
        // endDate === '' || endDate === null ? '' : moment(endDate).format("YYYY-MM-DD"),
        minPrice,
        maxPrice,
        page
      )
    );
  };

  const callSlider = (e) => {
    setMinPrice(e[0]);
    setMaxPrice(e[1]);
  };

  const goToStudentActivitiesScreen = (item) => {
    props.history.push({
      pathname: "/student-activities",
      state: {
        item,
        page,
        filterData: {
          board,
          className,
          courseCompletion,
          service,
          email,
          subscribeType,
          minPrice,
          maxPrice,
        },
      },
    });
  };

  const openStudentSubscriptionDetails = (item) => {
    setStudentSubscriptionDetailsModal(true);
    setCurrentStudent(item);
    dispatch(misSubscribedUsersStudentSubscriptionListLoaderAction(true));
    dispatch(misSubscribedUsersStudentSubscriptionData(item.student_id));
  };

  const closeStudentDetailsModal = () => {
    setStudentSubscriptionDetailsModal(false);
    dispatch(misSubscribedUsersStudentSubscriptionAction([]));
  };

  const onDownloadStudentSubscriptionDetailsHandler = () => {
    dispatch(globalLoadingAction(true));
    dispatch(
      misStudentSubscriptionDetailsExcelDownloadData(currentStudent.student_id)
    );
  };

  const cancelModal = () => {
    setModal(false);

    if (tempBoard == "") {
      setboard("");
    } else if (board != "select_baord" && board != "" && board != undefined) {
      setboard(tempBoard);
    } else if (board == "select_baord" || board == "") {
      setboard(tempBoard);
    }

    if (tempClassName == "") {
      setClassName("");
    } else if (
      className != "select_class" &&
      className != "" &&
      className != undefined
    ) {
      setClassName(tempClassName);
    } else if (className == "select_class" || className == "") {
      setClassName(tempClassName);
    }

    if (tempCourseCompletion == "") {
      setCourseCompletion("");
    } else if (
      courseCompletion != "select_course_completion" &&
      courseCompletion != "" &&
      courseCompletion != undefined
    ) {
      setCourseCompletion(tempCourseCompletion);
    } else if (
      courseCompletion == "select_course_completion" ||
      courseCompletion == ""
    ) {
      setCourseCompletion(tempCourseCompletion);
    }

    if (tempService == "") {
      setService("");
    } else if (
      service != "select_service" &&
      service != "" &&
      service != undefined
    ) {
      setService(tempService);
    } else if (service == "select_service" || service == "") {
      setService(tempService);
    }

    if (tempSubscribeType == "") {
      setSubscribeType("");
    } else if (
      subscribeType != "select_subscribe_type" &&
      subscribeType != "" &&
      subscribeType != undefined
    ) {
      setSubscribeType(tempSubscribeType);
    } else if (
      subscribeType == "select_subscribe_type" ||
      subscribeType == ""
    ) {
      setSubscribeType(tempSubscribeType);
    }

    if (tempEmail == "") {
      setEmail("");
    } else if (email != "" && email != undefined) {
      setEmail(tempEmail);
    } else if (email == "") {
      setEmail(tempEmail);
    }

    if (tempMinPrice == "") {
      setMinPrice(defaultMinPrice);
    } else if (minPrice != "" && minPrice != undefined) {
      setMinPrice(tempMinPrice);
    } else if (minPrice == "") {
      setMinPrice(tempMinPrice);
    }

    if (tempMaxPrice == "") {
      setMaxPrice(defaultMaxPrice);
    } else if (maxPrice != "" && maxPrice != undefined) {
      setMaxPrice(tempMaxPrice);
    } else if (maxPrice == "") {
      setMaxPrice(tempMaxPrice);
    }
  };

  const isDisabled =
    (board === "" || board === "select_baord") &&
    (className === "" || className === "select_class") &&
    (courseCompletion === "" ||
      courseCompletion === "select_course_completion") &&
    (service === "" || service === "select_service") &&
    (subscribeType === "" || subscribeType === "select_subscribe_type") &&
    minPrice === defaultMinPrice &&
    maxPrice === defaultMaxPrice &&
    email === "";

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="filtersAlign mt-3" style={{ marginRight: 25 }}>
                <div
                  className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 me-3 filter_background rounded cursorPointer ${isFiltered ? "border_red" : null
                    }`}
                  onClick={() => setModal(true)}
                >
                  <div className="image_container">
                    <img src={sort} alt="" className="image_style" />
                  </div>
                  <div className="button_text font_color_black">Filter</div>
                </div>
                {(permission.download == "true") && (
                  <div
                    className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 export_background rounded blue_border cursorPointer ${misSubscribedUsersList?.data?.length == 0 ||
                        misSubscribedUsersList?.data?.length == undefined
                        ? "content_disable"
                        : null
                      }`}
                    onClick={() => onDownloadSubscribedUsersHandler()}
                  >
                    <div className="image_container">
                      <img src={export_details} alt="" className="image_style" />
                    </div>
                    <div className="button_text font_color_blue cursorPointer">
                      Export
                    </div>
                  </div>
                )}
              </div>
              {/* </div> */}

              {misSubscribedUsersList?.data?.length == 0 ||
                misSubscribedUsersList?.data?.length == undefined ? (
                <div className="no_data">
                  <strong>No data found</strong>
                </div>
              ) : (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Sl no.</strong>
                      </th>
                      <th>
                        <strong>Name</strong>
                      </th>
                      <th>
                        <strong>Board</strong>
                      </th>
                      <th>
                        <strong>Class</strong>
                      </th>
                      <th>
                        <strong>Email</strong>
                      </th>
                      <th>
                        <strong>Services</strong>
                      </th>
                      <th>
                        <strong>Amount</strong>
                      </th>
                      <th>
                        <strong>Course Completion</strong>
                      </th>
                      <th>
                        <strong>Action</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {misSubscribedUsersList?.data?.map((item, index) => {
                      return (
                        <tr>
                          <td>{item.sl_no}</td>
                          <td>
                            <div
                              className="cursorPointer link_color"
                              onClick={() =>
                                openStudentSubscriptionDetails(item)
                              }
                            >
                              {item.student_name}
                            </div>
                          </td>
                          <td>{item.board}</td>
                          <td>{item.class}</td>
                          <td>{item.email}</td>
                          <td>{item.services}</td>
                          <td>{item.amount}</td>
                          <td>{item.course_completion}%</td>
                          <td>
                            <div className="d-flex">
                              <Button
                                variant="info shadow btn-xs sharp"
                                onClick={() =>
                                  goToStudentActivitiesScreen(item)
                                }
                              >
                                <i className="fa fa-eye"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}

              {misSubscribedUsersList?.data?.length != 0 &&
                misSubscribedUsersList?.data != undefined ? (
                <div className="pagination-container">
                  <div className="page-result">
                    <p>
                      Showing: {(page - 1) * 10 + 1} -{" "}
                      {10 * page - (10 - misSubscribedUsersList?.data?.length)}{" "}
                      of {misSubscribedUsersList.total_record}
                    </p>
                  </div>
                  {misSubscribedUsersList.total_record > 10 ? (
                    <ReactPaginate
                      containerClassName="pagination-gutter  false pagination-circle pagination pagination-sm"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      nextLabel={<i className="la la-angle-right"></i>}
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      previousLabel={<i className="la la-angle-left"></i>}
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      renderOnZeroPageCount={null}
                      activeClassName="active"
                      onPageChange={pageHandler}
                      pageRangeDisplayed={3}
                      pageCount={misSubscribedUsersList.total_page}
                      forcePage={page - 1}
                    />
                  ) : null}
                </div>
              ) : null}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <!-- Modal --> */}
      <Modal className="fade" show={modal} size="md">
        <Modal.Header>
          <Modal.Title>Filter</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => cancelModal()}
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="question-modal-form">
            <div className="reset-filtter-value">
              <button
                disabled={isDisabled}
                className="btn btn-info btn-xs"
                onClick={() => onResetHandler()}
              >
                Reset
              </button>
            </div>

            <div className="row">
              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={board}
                    onChange={(e) => setboard(e.target.value)}
                  >
                    <option value="select_baord">Select Board</option>
                    {boardList.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={className}
                    onChange={(e) => setClassName(e.target.value)}
                  >
                    <option value="select_class">Select Class</option>
                    {classList?.map((item) => {
                      return <option value={item.id}>{item.class_no}</option>;
                    })}
                  </select>
                </div>
              </div>

              <div className={`form-group col-sm-6 px-1 mb-3`}>
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    style={{ fontSize: 14 }}
                    value={courseCompletion}
                    onChange={(e) => setCourseCompletion(e.target.value)}
                  >
                    <option value="select_course_completion">
                      Select Course Completion
                    </option>
                    {coursecompletionList.map((item) => {
                      return <option value={item.id}>{item.showValue}</option>;
                    })}
                  </select>
                </div>
              </div>

              <div className={`form-group col-sm-6 px-1 mb-3`}>
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={service}
                    onChange={(e) => setService(e.target.value)}
                  >
                    <option value="select_service">Select Service</option>
                    {serviceList.map((item) => {
                      return <option value={item.id}>{item.showValue}</option>;
                    })}
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <AutocompleteSearch
                  dataDetails={emailData}
                  placeholder={`Enter Email`}
                  setNewValue={(e) => setEmail(e)}
                  selectValue={email}
                />
              </div>

              <div className={`form-group col-sm-6 px-1 mb-3`}>
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={subscribeType}
                    onChange={(e) => setSubscribeType(e.target.value)}
                  >
                    <option value="select_subscribe_type">
                      Select Subscribe Type
                    </option>
                    {subscribetypeList.map((item) => {
                      return <option value={item.id}>{item.showValue}</option>;
                    })}
                  </select>
                </div>
              </div>

              {/* <div className={`col-sm-6 px-1`}>
                <div className="date-control-view session-date-control date_picker_box_height">
                  <DatePicker
                    ref={calendarRef}
                    placeholderText="Select subscribe date"
                    selectsRange
                    selected={startDate}
                    onChange={onDateHandler}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    startDate={startDate}
                    endDate={endDate}
                    dropdownMode="select"
                  />
                  <button
                    className="picker-btn"
                    onClick={() => calendarRef.current.setOpen(true)}
                  >
                    <i className="fa-solid fa-calendar-days"></i>
                  </button>
                </div>
              </div> */}

              <div className={`form-group col-sm-6 px-1 mb-3`}>
                <p>{`Price Range: (${minPrice} - ${maxPrice})`}</p>
                <RangeSlider
                  id="range-slider-ab"
                  className="margin-sm"
                  min={defaultMinPrice}
                  max={defaultMaxPrice}
                  step={1}
                  defaultValue={[minPrice, maxPrice]}
                  value={[minPrice, maxPrice]}
                  onInput={callSlider}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => cancelModal()} variant="danger light">
            Cancel
          </Button>
          <Button
            disabled={isDisabled}
            onClick={() => filterSubscribedUsersHandler()}
            variant="primary"
          >
            Filter
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <!-- Modal --> */}
      <Modal className="fade" show={studentSubscriptionDetailsModal} size="md">
        <Modal.Header>
          <Modal.Title>
            {currentStudent.student_name}'s Subscription Details
          </Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => closeStudentDetailsModal()}
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="card-body">
            <div className="basic-form">
              {misSubscribedUsersStudentSubscriptionListLoader ? (
                <div className="d-flex flex-row m-4 justify-content-center ">
                  <Spinner
                    as="span"
                    animation="border"
                    size="large"
                    role="status"
                    variant="danger"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                <div className="row student_subscription_container">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>
                          <strong>Sl no.</strong>
                        </th>
                        <th>
                          <strong>Subscribe date</strong>
                        </th>
                        <th>
                          <strong>Services</strong>
                        </th>
                        <th>
                          <strong>Amount</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {misSubscribedUsersStudentSubscriptionList.map(
                        (item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.sl_no}</td>
                              <td>{item.subscribe_date}</td>
                              <td>{item.services}</td>
                              <td>{item.amount}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => closeStudentDetailsModal()}
            variant="danger light"
          >
            Cancel
          </Button>

          <div className="btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 export_background rounded blue_border cursorPointer"
            onClick={() => onDownloadStudentSubscriptionDetailsHandler()}
          >
            <div className="image_container">
              <img src={export_details} alt="" className="image_style" />
            </div>
            <div
              className="button_text font_color_blue cursorPointer"
            >
              Export
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

const mapstateToProps = (state) => {
  return {
    boardList: state.board.list,
    classList: state.class.list,
    categoryList: state.category.list,
    subjectList: state.subject.subjectList,
    subscriptionDetailsList: state.subscriptiondetails.list,
  };
};

export default connect(mapstateToProps)(SubscribedUsers);
