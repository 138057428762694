import React, { Fragment, useEffect, useState, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import * as utility from "../../../utility/Utility";
import { ToastContainer } from "react-toastify";
import Moment from "react-moment";

import {
  getContentValidatorData,
  loadingContentValidatorAction,
} from "../../../store/actions/ContentValidatorAction";
import {
  getContentDesignerData,
  loadingContentDesignerAction,
} from "../../../store/actions/ContentDesignerAction";
import {
  approveValidatorData,
  loadingElibraryAction,
  uploadConceptData,
  saveAndUploadConceptMapData,
  uploadConceptMapAction,
  removeUpdateDate,
  uploadOnlyConceptMapAction
} from "../../../store/actions/ElibraryAction";

import { decode as base64_decode, encode as base64_encode } from "base-64";

const ElibraryContentDetails = (props) => {
  let history = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const fileRef = useRef();

  const conceptMapPath = useSelector((state) => state.elibrary.conceptMapPath);
  const updateByFileUpload = useSelector((state) => state.elibrary.updateByFileUpload)
  const [previousScreenValue, setPreviousScreenValue] = useState(state);
  const [pdfPageNo, setPdfPageNo] = useState();
  const [conceptPDF, setConceptPDF] = useState("");
  // const [conceptPDF, setConceptPDF] = useState(state.data.pdf_path);
  const [file, setFile] = useState("");
  const [file2, setFile2] = useState("");
  const [previousPageFilterData, setPreviousPageFilterData] = useState('');

  let errorsObj = { file: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [onSaveLoader, setOnSaveLoader] = useState(false);
  const [onSavePubLoader, setOnSavePubLoader] = useState(false);
  const InstructionExamModalRef = useRef(true);


  useEffect(() => {
    setPreviousPageFilterData(props.location.state.filterData)
    // localStorage.setItem('elibraryFilterData', JSON.stringify(props.location.state.filterData));

    // let getData = localStorage.getItem('elibraryFilterData');
    // console.log("getData---elibraryFilterData---", getData)

    if (InstructionExamModalRef.current) {
      // dispatch(uploadConceptMapAction(null));
    }
    InstructionExamModalRef.current = false;
  }, []); //conceptMapPath

  useEffect(()=>{
    // if (previousScreenValue.data.concept_map_path != '') {
      dispatch(uploadOnlyConceptMapAction(previousScreenValue.data.concept_map_path));
    // }
    
  },[previousScreenValue.data.concept_map_path])

  useEffect(() => {
  
    return () => {
      dispatch(removeUpdateDate(true));
    }
  }, [])


  useEffect(() => {
    setConceptPDF(
      state.data.pdf_path
    );
  }, [conceptPDF]);


  /* useEffect(() => {
    setConceptPDF(
      props.conceptPDFPath ? props.conceptPDFPath : state.data.pdf_path
    );
  }, [props.conceptPDFPath, conceptPDF]); */

  /* navigation go back with state  */
  const onGoBackHandler = () => {
    history.push({
			pathname: "/elibrary-content-list",
			state: previousPageFilterData,
		  });

  };

  const onSaveAndPublish = async (e) => {
    if (previousScreenValue.data.is_demo != 1) {
      e.preventDefault();
      //dispatch(uploadConceptMapAction(null));
      let error = false;
      const errorObj = { ...errorsObj };
      // if (file === "") {
      //   errorObj.file = "File is Required";
      //   error = true;
      // } else if (file.type != "application/pdf") {
      //   errorObj.file = "Only PDFs are valid";
      //   error = true;
      // }
      setErrors(errorObj);
      if (error) {
        return;
      }
    }
    setOnSaveLoader(false);
    setOnSavePubLoader(true);
    dispatch(loadingElibraryAction(true));
    
    dispatch(
      saveAndUploadConceptMapData(
        previousScreenValue.data.id,
        // previousScreenValue.data.is_demo != 1 ? file : null,
        file,
        previousScreenValue.data.is_demo != 1 ? file2 : null,
        props.history
      )
    );
  };

  /* copy pdf link */
  const copyPath = () => {
    if (pdfPageNo == "" || pdfPageNo == undefined) {
      utility.showError("Please put PDF page number");
      return;
    } else {
      // console.log(previousScreenValue.pdf_path + '#page=' + pdfPageNo)
      const conceptMapEncodePdfPath = base64_encode(
        previousScreenValue.data.pdf_path + "#page=" + pdfPageNo
      );
      utility.showSuccess("Link copied");
      navigator.clipboard.writeText(
        "https://crestest.com/" + conceptMapEncodePdfPath
      );
    }
  };

  /* save handler */
  const OnSave = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    // if (file === "") {
    //   errorObj.file = "File is Required";
    //   error = true;
    // } else if (file.type != "application/pdf") {
    //   errorObj.file = "Only PDFs are valid";
    //   error = true;
    // }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setOnSaveLoader(true);
    setOnSavePubLoader(false);
    dispatch(loadingElibraryAction(true));
    dispatch(
      uploadConceptData(previousScreenValue.data.id, file, file2, props.history)
    );
  };

  /* set file */
  const handleChange = (event) => {
    setFile(event.target.files[0]);
  };

  /* set file */
  const handleChange2 = (event) => {
    setFile2(event.target.files[0]);
  };

  /* preview concept pdf */
  const previewConceptMap = () => {
    
    history.push({
      pathname: "/elibrary-concept-map",
      state: { conceptMapPath: conceptMapPath, filterData: previousPageFilterData },
    });
  };

  /* go to concept map pdf file */
  const listPreviewConceptMap = () => {
    
    history.push({
      pathname: "/elibrary-concept-map",
      state: { conceptMapPath: previousScreenValue.data.concept_map_path, filterData: props.location.filterData },
    });
  };

  /* concept map link copu */
  const copyConceptMapPath = () => {
    if (conceptMapPath && conceptMapPath != null) {
      navigator.clipboard.writeText(conceptMapPath);
      utility.showSuccess("Concept map Link copied");
    } else {
      navigator.clipboard.writeText(previousScreenValue.data.concept_map_path);
      utility.showSuccess("Concept map Link copied");
    }
  };

  return (
    <Fragment>
      <div className="row">
        {/* {console.log("hello world....", conceptPDF)} */}
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="row">
                  <div className="col-xl-2">
                    <div className="back-arrow-container">
                      <Link onClick={onGoBackHandler}>
                        <i className="fa-solid fa-arrow-left"></i>
                      </Link>
                    </div>
                  </div>
                  <div className="col-xl-8">
                    <div className="card-body">
                      <div className="basic-details">
                        <div className="row">
                          <div className="col-xl-6">
                            <div className="basic-details-item">
                              <label>Category:</label>
                              <span>{previousScreenValue.data.category}</span>
                            </div>
                            {previousScreenValue.data.is_demo != 1 ? (
                              <>
                                <div className="basic-details-item">
                                  <label>Subject Name:</label>
                                  <span>
                                    {previousScreenValue.data.branch_name}
                                  </span>
                                </div>
                                <div className="basic-details-item">
                                  <label>Board Name:</label>
                                  <span>
                                    {previousScreenValue.data.board_name}
                                  </span>
                                </div>
                              </>
                            ) : null}
                            <div className="basic-details-item">
                              <label>Create Date: &nbsp;</label>
                              <Moment format="D MMM YYYY" withTitle>
                                {previousScreenValue.data.created_at}
                              </Moment>
                            </div>

                            <div className="basic-details-item">
                              <label>Update Date:&nbsp;</label>
                              <Moment format="D MMM YYYY" withTitle>
                                {updateByFileUpload ? updateByFileUpload : previousScreenValue.data.updated_at}
                              </Moment>
                            </div>
                          </div>
                          <div className="col-xl-6">
                            {previousScreenValue.data.is_demo != 1 ? (
                              <>
                                {previousScreenValue.data.type_name ? (
                                  <div className="basic-details-item">
                                    <label>Exam Type:</label>
                                    <span>
                                      {previousScreenValue.data.type_name}
                                    </span>
                                  </div>
                                ) : null}

                                <div className="basic-details-item">
                                  <label>Chapter Name:</label>
                                  <span>
                                    {previousScreenValue.data.chapter_name}
                                  </span>
                                </div>
                                <div className="basic-details-item">
                                  <label>Class No:</label>
                                  <span>
                                    {previousScreenValue.data.class_no}
                                  </span>
                                </div>
                              </>
                            ) : null}
                            <div className="basic-details-item">
                              <label>Status:</label>
                              <span>
                                {previousScreenValue.data.status == 0
                                  ? "Inactive"
                                  : "Active"}
                              </span>
                            </div>
                            <div className="basic-details-item">
                              <label>Content Type:</label>
                              <span>
                                {previousScreenValue.data.content_status}
                              </span>
                            </div>
                          </div>

                          <div className="mb-3">
                            <label
                              htmlFor="formFileDisabled"
                              className="form-label file-form-label"
                            >
                              E-library PDF details
                            </label>
                            <input
                              ref={fileRef}
                              className="form-control"
                              type="file"
                              id="formFileDisabled"
                              accept=".pdf"
                              onChange={handleChange2}
                            />
                            {/* {errors.file && <div className="text-danger fs-12 mt-1">{errors.file}</div>} */}
                          </div>

                          {previousScreenValue.data.is_demo != 1 ? (
                            <div className="col-xl-12">
                              <div className="basic-details-item m-0 path_container">
                                <div className="pdf_left_side">
                                  <label>PDF Path:</label>
                                  {conceptPDF + "#page="}
                                  <input
                                    type="text"
                                    className="pdf_page_input"
                                    placeholder="page no."
                                    onChange={(e) =>
                                      setPdfPageNo(e.target.value)
                                    }
                                    value={pdfPageNo}
                                    name="pdfpageno"
                                    autoComplete="off"
                                  />
                                </div>

                                <div className="pdf_right_side">
                                  <Link to="#" onClick={copyPath}>
                                    <i className="bi bi-bookmarks"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <div className="mb-3">
                            <label
                              htmlFor="formFileDisabled"
                              className="form-label file-form-label"
                            >
                              Concept Map
                            </label>
                            <input
                              ref={fileRef}
                              className="form-control"
                              type="file"
                              id="formFileDisabled"
                              accept=".pdf"
                              onChange={handleChange}
                            />
                            {errors.file && (
                              <div className="text-danger fs-12 mt-1">
                                {errors.file}
                              </div>
                            )}
                          </div>

                          {conceptMapPath != null && conceptMapPath != "" ? (
                            <div className="col-xl-12">
                              <div className="basic-details-item m-0 path_container">
                                <div className="pdf_left_side">
                                  <label>Concept Map Path:</label>
                                  {conceptMapPath}
                                </div>

                                <div className="pdf_right_side">
                                  <Link to="#" onClick={copyConceptMapPath}>
                                    <i className="bi bi-bookmarks"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ) : (
                            null
                          )}
                          {/* 
                          <div className="col-xl-12">
                              <div className="basic-details-item m-0 path_container">
                                <div className="pdf_left_side">
                                  <label>Concept Map Path::</label>
                                  {previousScreenValue.data.concept_map_path}
                                </div>

                                <div className="pdf_right_side">
                                  <Link to="#" onClick={copyConceptMapPath}>
                                    <i className="bi bi-bookmarks"></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          */}

                          <div className="col-xl-3"></div>
                          <div className="col-xl-12 button_container">
                            <div className="text-center col-xl-3 mt-4">
                              <button
                                type="submit"
                                onClick={OnSave}
                                className="btn btn-primary btn-block category-btn"
                                disabled={props.loader}
                              >
                                Save
                                {props.loader && onSaveLoader ? (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : null}
                              </button>
                            </div>
                            <div className="text-center col-xl-4  mt-4">
                              <button
                                type="submit"
                                onClick={onSaveAndPublish}
                                className="btn btn-primary btn-block category-btn"
                                disabled={props.loader}
                              >
                                Save & Publish
                                {props.loader && onSavePubLoader ? (
                                  <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                ) : null}
                              </button>
                            </div>
                            {conceptMapPath != null && conceptMapPath != "" ? (
                              <div className="text-center col-xl-3  mt-4">
                                <button
                                  onClick={previewConceptMap}
                                  className="btn btn-primary btn-block category-btn"
                                >
                                  Preview
                                </button>
                              </div>
                            ) : null}

                            {previousScreenValue.data.concept_map_path !=
                              null &&
                              previousScreenValue.data.concept_map_path != "" &&
                              conceptMapPath == null ? (
                              <div className="text-center col-xl-3  mt-4">
                                <button
                                  onClick={listPreviewConceptMap}
                                  className="btn btn-primary btn-block category-btn"
                                >
                                  Preview
                                </button>
                              </div>
                            ) : null}
                          </div>
                          <div className="col-xl-3"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ToastContainer />
      </div>
    </Fragment>
  );
};
const mapStateToProps = (state) => {
  
  return {
    contentvalidatorList: state.contentvalidator.list,
    contentdesignerList: state.contentdesigner.list,
    conceptPDFPath: state.elibrary.pdfPath,
    loader: state.elibrary.showLoading,
  };
};

export default connect(mapStateToProps)(ElibraryContentDetails);
