import axios from "axios";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function addAcademicSession(
  exam_category_id,
  exam_board_type,
  session_name,
  academy_start_date,
  academy_end_date,
  course_start_date,
  course_end_date
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    exam_category_id,
    exam_board_type,
    session_name,
    academy_start_date,
    academy_end_date,
    course_start_date,
    course_end_date,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.ADD_ACADEMIC_SESSIONS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function updateAcademicSession(
  recid,
  exam_category_id,
  exam_board_type,
  session_name,
  academy_start_date,
  academy_end_date,
  course_start_date,
  course_end_date
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  const postData = {
    recid,
    exam_category_id,
    exam_board_type,
    session_name,
    academy_start_date,
    academy_end_date,
    course_start_date,
    course_end_date,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.UPDATE_ACADEMIC_SESSIONS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function getAcademicSession(
  exam_category_id,
  exam_board_type,
  session_name,
  academy_start_date,
  academy_end_date,
  course_start_date,
  course_end_date,
  status,
  page
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    exam_category_id,
    exam_board_type,
    session_name,
    academy_start_date,
    academy_end_date,
    course_start_date,
    course_end_date,
    status,
    page,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_ACADEMIC_SESSIONS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function getAcademicSessionHistory(
  exam_category_id,
  exam_board_type,
  session_name,
  academy_start_date,
  academy_end_date,
  course_start_date,
  course_end_date,
  status,
  page
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    exam_category_id,
    exam_board_type,
    session_name,
    academy_start_date,
    academy_end_date,
    course_start_date,
    course_end_date,
    status,
    page,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_ACADEMIC_SESSIONS_HISTORY_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function updateStatusAcademicSession(recid) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  const postData = {
    recid,
  };
  return axios({
    url: GlobalConfigs.API_URL + Apis.UPDATE_ACADEMIC_SESSIONS_STATUS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function getAcademicYearMasterList() {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_ACADEMIC_YEAR_MASTER_LIST_API,
    method: "GET",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
}

export function removeAcademicSession(recid) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;

  const postData = {
    recid,
  };

  return axios({
    url: GlobalConfigs.API_URL + Apis.REMOVE_ACADEMIC_SESSIONS_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}
