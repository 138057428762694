import axios from "axios";
import swal from "sweetalert";
import GlobalConfigs from "../configs/GlobalConfigs";
import * as Apis from "../apis/Apis";

export function SubscriptionDetails(
  period, category, board, className, service, nbf, subscriptionMethod, locationPin, subject, page, startDate, endDate, exam_type
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  
  const postData = {
    period,
    category,
    board,
    class:className,
    services : service,
    nbf,
    subscribe_method: subscriptionMethod,
    pincode: locationPin,
    subject,
    page,
    sub_exp_from_date: startDate,
    sub_exp_to_date: endDate,
    exam_type
  };
 
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_SUBSCRIPTION_DETAILS,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

export function SubscriptionDownloadExcelDetails(
  period, category, board, className, service, nbf, subscriptionMethod, locationPin, subject, page, startDate, endDate, exam_type
) {
  let getData = localStorage.getItem("userDetails");
  let token = JSON.parse(getData).token;
  
  const postData = {
    period,
    category,
    board,
    class:className,
    services : service,
    nbf,
    subscribe_method: subscriptionMethod,
    pincode: locationPin,
    subject,
    page,
    sub_exp_from_date: startDate,
    sub_exp_to_date: endDate,
    exam_type
  };
 
  return axios({
    url: GlobalConfigs.API_URL + Apis.GET_MIS_SUBSCRIPTION_EXCEL_EXPORT_API,
    method: "POST",
    headers: {
      ContentType: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  });
}

