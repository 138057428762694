import { Fragment, useEffect, useState, useRef } from "react";
import { Row, Col, Card, Table, Modal, Button } from "react-bootstrap";
import { useDispatch, connect, useSelector } from "react-redux";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { getBoardData } from "../../../store/actions/BoardAction";
import { getClassData } from "../../../store/actions/ClassAction";
import { getExamCategory } from "../../../store/actions/ExamCategoryAction";

import {
  SubscriptionDetailsData,
  subscriptionDownloadExcelData,
} from "../../../store/actions/SubscriptionDetailActions";
import {
  misSchoolListData,
  misRegisterStudentEmailListData,
  misRegisterStudentLocationPinListData,
} from "../../../store/actions/MisRegUserAction";

import {
  getExamType,
  loadingAction,
} from "../../../store/actions/ExamTypeAction";
import { getSubjectsFiltterSubLibraryList } from "../../../store/actions/SujectActions";

import periodList from "./json/period.json";
import serviceList from "./json/service.json";
import nbfdetailsList from "./json/nbfdetails.json";
import subscriptionstatusList from "./json/subscriptionstatus.json";
import subscriptionmethodList from "./json/subscriptionmethod.json";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import ReactPaginate from "react-paginate";

//images
import sort from "../../../images/dashboard_image/sort.png";
import export_details from "../../../images/dashboard_image/export.png";

import AutocompleteSearch from "../AutocompleteSearch/AutocompleteSearch";

const SubscriptionDatabase = (props) => {
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  const [period, setPeriod] = useState("");
  const [periodData, setPeriodData] = useState([]);
  const [board, setboard] = useState("");
  const [className, setClassName] = useState("");
  const [locationPin, setLocationPin] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(1);
  const [nbf, setNbf] = useState("");

  const [category, setCategory] = useState("");
  const [type, setType] = useState("");

  const [service, setService] = useState("");
  const [subject, setSubject] = useState("");

  const calendarRef = useRef();

  // misSchoolList

  const misRegisteredStudentLocationPin = useSelector(
    (state) => state.MisDashboardInfo.misRegisteredStudentLocationPin
  );

  const classList = useSelector((state) => state.class.list);
  const boardList = useSelector((state) => state.board.list);
  const [locationPinData, setLocationPinData] = useState([]);

  const [subscriptionDetailsModal, setSubscriptionDetailsModal] =
    useState(false);
  const [viewCurrentSubscriptionDetails, setviewCurrentSubscriptionDetails] =
    useState("");
  const [subscriptionMethod, setSubscriptionMethod] = useState("");

  const [tempPeriod, setTempPeriod] = useState("");
  const [tempCategory, setTempCategory] = useState("");
  const [tempType, setTempType] = useState("");
  const [tempBoard, setTempBoard] = useState("");
  const [tempClassName, setTempClassName] = useState("");
  const [tempService, setTempService] = useState("");
  const [tempNbf, setTempNbf] = useState("");
  const [tempSubscriptionMethod, setTempSubscriptionMethod] = useState("");
  const [tempSubject, setTempSubject] = useState("");
  const [tempLocationPin, setTempLocationPin] = useState("");
  const [tempStartDate, setTempStartDate] = useState("");
  const [tempEndDate, setTempEndDate] = useState("");

  const [isFiltered, setIsFiltered] = useState(false);
  const [permission, setPermission] = useState("");

  useEffect(() => {
    dispatch(getClassData());
    dispatch(getBoardData());
    dispatch(getExamCategory());
    dispatch(misRegisterStudentLocationPinListData());
    dispatch(getExamType(props.history));

    setPeriodData(periodList.filter((i) => i.id !== 2));
  }, []);

  useEffect(() => {
    if (period != "select_period" && period != "" && period != undefined) {
      setIsFiltered(true);
    } else if (
      category != "select_category" &&
      category != "" &&
      category != undefined
    ) {
      setIsFiltered(true);
    } else if (board != "select_baord" && board != "" && board != undefined) {
      setIsFiltered(true);
    } else if (
      className != "select_class" &&
      className != "" &&
      className != undefined
    ) {
      setIsFiltered(true);
    } else if (
      service != "select_service" &&
      service != "" &&
      service != undefined
    ) {
      setIsFiltered(true);
    } else if (nbf != "select_nbf" && nbf != "" && nbf != undefined) {
      setIsFiltered(true);
    } else if (
      subscriptionMethod != "select_subscriptionMethod" &&
      subscriptionMethod != "" &&
      subscriptionMethod != undefined
    ) {
      setIsFiltered(true);
    } else if (locationPin != "" && locationPin != undefined) {
      setIsFiltered(true);
    } else if (
      subject != "select_subject" &&
      subject != "" &&
      subject != undefined
    ) {
      setIsFiltered(true);
    } else if (type != "select_type" && type != "" && type !== undefined) {
      setIsFiltered(true);
    } else if (
      startDate !== "" &&
      startDate !== null &&
      startDate !== undefined
    ) {
      setIsFiltered(true);
    } else if (endDate !== "" && endDate !== null && endDate !== undefined) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
  }, [
    period,
    category,
    board,
    className,
    service,
    nbf,
    subscriptionMethod,
    locationPin,
    subject,
    type,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    let locationPinData = [];
    let locationPinObj = {};
    misRegisteredStudentLocationPin.forEach((elm) => {
      locationPinObj = {};
      locationPinObj = elm.pincode.toString();
      locationPinData.push(locationPinObj);
    });
    setLocationPinData(locationPinData);
  }, [misRegisteredStudentLocationPin]);

  const onResetHandler = () => {
    setPage(1);
    setPeriod("");
    setboard("");
    setType("");
    setClassName("");
    setCategory("");
    setService("");
    setSubject("");
    setNbf("");
    setSubscriptionMethod("");
    setLocationPin("");
    setStartDate(null);
    setEndDate(null);
    dispatch(globalLoadingAction(true));
    dispatch(
      SubscriptionDetailsData("", "", "", "", "", "", "", "", "", 1, "", "", "")
    );
    setTempPeriod("");
    setTempCategory("");
    setTempType("");
    setTempBoard("");
    setTempClassName("");
    setTempService("");
    setTempNbf("");
    setTempSubscriptionMethod("");
    setTempSubject("");
    setTempLocationPin("");
    setTempStartDate("");
    setTempEndDate("");
    setModal(false);
  };

  useEffect(() => {
    getList(
      period,
      category,
      board,
      className,
      service,
      nbf,
      subscriptionMethod,
      locationPin,
      subject,
      page,
      startDate,
      endDate,
      type
    );
    permissionCheck();
  }, []);

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 82) {
        console.log("82----------", item)
        setPermission(item);
      }
    });
  };

  const getList = (
    period,
    category,
    board,
    className,
    service,
    nbf,
    subscriptionMethod,
    locationPin,
    subject,
    page,
    startDate,
    endDate,
    type
  ) => {
    dispatch(globalLoadingAction(true));
    dispatch(
      SubscriptionDetailsData(
        period == "select_period" ? "" : period,
        category == "select_category" ? "" : category,
        board == "select_baord" ? "" : board,
        className == "select_class" ? "" : className,
        service == "select_service" ? "" : service,
        nbf == "select_nbf" ? "" : nbf,
        subscriptionMethod == "select_subscriptionMethod"
          ? ""
          : subscriptionMethod,
        locationPin,
        subject == "select_subject" ? "" : subject,
        page,
        startDate === "" || startDate === null
          ? ""
          : moment(startDate).format("YYYY-MM-DD"),
        endDate === "" || endDate === null
          ? ""
          : moment(endDate).format("YYYY-MM-DD"),
        type == "select_type" ? "" : type
      )
    );
  };

  /* date handler */
  const onDateHandler = (date) => {
    setStartDate(date[0]);
    setEndDate(date[1]);
  };

  const viewSubscriptionDetails = (item) => {
    setviewCurrentSubscriptionDetails(item);
    setSubscriptionDetailsModal(true);
  };

  const filterRegisteredHandler = () => {
    let page = 1;
    setModal(false);
    setPage(1);
    getList(
      period,
      category,
      board,
      className,
      service,
      nbf,
      subscriptionMethod,
      locationPin,
      subject,
      page,
      startDate,
      endDate,
      type
    );

    setTempPeriod(period);
    setTempCategory(category);
    setTempType(type);
    setTempBoard(board);
    setTempClassName(className);
    setTempService(service);
    setTempNbf(nbf);
    setTempSubscriptionMethod(subscriptionMethod);
    setTempSubject(subject);
    setTempLocationPin(locationPin);
    setTempStartDate(startDate);
    setTempEndDate(endDate);
  };

  /* pagination */
  const pageHandler = (data) => {
    let page = data.selected + 1;
    setPage(data.selected + 1);
    getList(
      period,
      category,
      board,
      className,
      service,
      nbf,
      subscriptionMethod,
      locationPin,
      subject,
      page,
      startDate,
      endDate,
      type
    );
  };

  const onDownloadSubscriptionHandler = () => {
    let page = 0;
    dispatch(globalLoadingAction(true));
    dispatch(
      subscriptionDownloadExcelData(
        period,
        category,
        board,
        className,
        service,
        nbf,
        subscriptionMethod,
        locationPin,
        subject,
        page,
        startDate === "" || startDate === null
          ? ""
          : moment(startDate).format("YYYY-MM-DD"),
        endDate === "" || endDate === null
          ? ""
          : moment(endDate).format("YYYY-MM-DD"),
        type
      ) /*  */
    );
  };

  const getSubjectHandler = () => {
    // dispatch(subjectLoading(true));
    dispatch(
      getSubjectsFiltterSubLibraryList(
        board,
        type,
        category,
        className,
        props.history
      )
    );
  };

  const boardTypeChangeHandler = (e) => {
    if (category == 1) {
      setboard(e);
      setType("");
    } else {
      setboard("");
      setType(e);
    }
    setSubject("");
  };

  const categoryHandler = (val) => {
    setCategory(val);
    setboard("");
    setType("");
    setSubject("");
  };

  const cancelModal = () => {
    setModal(false);

    if (tempPeriod == "") {
      setPeriod("");
    } else if (
      period != "select_period" &&
      period != "" &&
      period != undefined
    ) {
      setPeriod(tempPeriod);
    } else if (period == "select_period" || period == "") {
      setPeriod(tempPeriod);
    }

    if (tempCategory == "") {
      setCategory("");
    } else if (
      category != "select_category" &&
      category != "" &&
      category != undefined
    ) {
      setCategory(tempCategory);
    } else if (category == "select_category" || category == "") {
      setCategory(tempCategory);
    }

    if (tempBoard == "") {
      setboard("");
    } else if (board != "select_baord" && board != "" && board != undefined) {
      setboard(tempBoard);
    } else if (board == "select_baord" || board == "") {
      setboard(tempBoard);
    }

    if (tempType == "") {
      setType("");
    } else if (type != "select_type" && type != "" && type != undefined) {
      setType(tempType);
    } else if (type == "select_type" || type == "") {
      setType(tempType);
    }

    if (tempClassName == "") {
      setClassName("");
    } else if (
      className != "select_class" &&
      className != "" &&
      className != undefined
    ) {
      setClassName(tempClassName);
    } else if (className == "select_class" || className == "") {
      setClassName(tempClassName);
    }

    if (tempService == "") {
      setService("");
    } else if (
      service != "select_service" &&
      service != "" &&
      service != undefined
    ) {
      setService(tempService);
    } else if (service == "select_service" || service == "") {
      setService(tempService);
    }

    if (tempNbf == "") {
      setNbf("");
    } else if (nbf != "select_nbf" && nbf != "" && nbf != undefined) {
      setNbf(tempNbf);
    } else if (nbf == "select_nbf" || nbf == "") {
      setNbf(tempNbf);
    }

    if (tempSubscriptionMethod == "") {
      setSubscriptionMethod("");
    } else if (
      subscriptionMethod != "select_subscriptionMethod" &&
      subscriptionMethod != "" &&
      subscriptionMethod != undefined
    ) {
      setSubscriptionMethod(tempSubscriptionMethod);
    } else if (
      subscriptionMethod == "select_subscriptionMethod" ||
      subscriptionMethod == ""
    ) {
      setSubscriptionMethod(tempSubscriptionMethod);
    }

    if (tempSubject == "") {
      setSubject("");
    } else if (
      subject != "select_subject" &&
      subject != "" &&
      subject != undefined
    ) {
      setSubject(tempSubject);
    } else if (subject == "select_subject" || subject == "") {
      setSubject(tempSubject);
    }

    if (tempLocationPin == "") {
      setLocationPin("");
    } else if (locationPin != "" && locationPin != undefined) {
      setLocationPin(tempLocationPin);
    } else if (locationPin == "") {
      setLocationPin(tempLocationPin);
    }

    if (tempStartDate == "") {
      setStartDate("");
    } else if (startDate != "" && startDate != null && startDate != undefined) {
      setStartDate(tempStartDate);
    } else if (startDate == "" || startDate == null) {
      setStartDate(tempStartDate);
    }

    if (tempEndDate == "") {
      setEndDate("");
    } else if (endDate != "" && endDate != null && endDate != undefined) {
      setEndDate(tempEndDate);
    } else if (endDate == "" || endDate == null) {
      setEndDate(tempEndDate);
    }
  };

  const isDisabled =
    (period === "" || period === "select_period") &&
    (category === "" || category === "select_category") &&
    (board === "" || board === "select_baord") &&
    (className === "" || className === "select_class") &&
    (service === "" || service === "select_service") &&
    (nbf === "" || nbf === "select_nbf") &&
    (subscriptionMethod === "" ||
      subscriptionMethod === "select_subscriptionMethod") &&
    locationPin === "" &&
    (subject === "" || subject === "select_subject") &&
    (startDate === "" || startDate === null) &&
    (endDate === "" || endDate === null) &&
    (type === "" || type === "select_type");

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div
                className="add-filter-group"
                style={{ justifyContent: "space-between" }}
              >
                <div className="subscription-details">
                  <div className="subscription-info">
                    <strong>Total Count:</strong>
                    <span className="count">
                      {props.subscriptionDetailsList?.total_count}
                    </span>
                  </div>
                  <div className="subscription-info">
                    <strong>Total Amount:</strong>
                    <span className="amount">
                      <i className="fa fa-inr" aria-hidden="true"></i>&nbsp;
                      {props.subscriptionDetailsList?.total_amount}
                    </span>
                  </div>
                </div>

                <div className="filtersAlign mt-3" style={{ marginRight: 25 }}>
                  <div
                    className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 me-3 filter_background rounded cursorPointer ${isFiltered ? "border_red" : null
                      }`}
                    onClick={() => setModal(true)}
                  >
                    <div className="image_container">
                      <img src={sort} alt="" className="image_style" />
                    </div>
                    <div className="button_text font_color_black">Filter</div>
                  </div>
                  {(permission.download == "true") && (
                    <div
                      className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 export_background rounded blue_border cursorPointer ${props?.subscriptionDetailsList?.data?.length == 0 ||
                          props?.subscriptionDetailsList?.data?.length == undefined
                          ? "content_disable"
                          : null
                        }`}
                      onClick={() => onDownloadSubscriptionHandler()}
                    >
                      <div className="image_container">
                        <img
                          src={export_details}
                          alt=""
                          className="image_style"
                        />
                      </div>
                      <div className="button_text font_color_blue cursorPointer">
                        Export
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {props?.subscriptionDetailsList?.data?.length == 0 ||
                props?.subscriptionDetailsList?.data?.length == undefined ? (
                <div className="no_data">
                  <strong>No data found</strong>
                </div>
              ) : (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Sl no.</strong>
                      </th>
                      <th>
                        <strong>Name</strong>
                      </th>
                      <th>
                        <strong>Subscription date</strong>
                      </th>
                      <th>
                        <strong>Category</strong>
                      </th>
                      <th>
                        <strong>Pin</strong>
                      </th>
                      <th>
                        <strong>Board</strong>
                      </th>
                      <th>
                        <strong>Class</strong>
                      </th>
                      <th>
                        <strong>Subject</strong>
                      </th>
                      <th>
                        <strong>Subscription type</strong>
                      </th>
                      <th>
                        <strong>Subscribed method</strong>
                      </th>
                      <th>
                        <strong>NBF Details</strong>
                      </th>
                      <th>
                        <strong>Subscription Validity</strong>
                      </th>
                      {/* <th>
                      <strong>Subscription Status</strong>
                    </th> */}
                      <th>
                        <strong>Payment Method</strong>
                      </th>
                      <th>
                        <strong>Amount</strong>
                      </th>
                      <th>
                        <strong>View</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {props?.subscriptionDetailsList?.data?.map(
                      (item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.sl_no}</td>
                            <td>{item.student_name}</td>
                            <td>{item.subscription_date}</td>
                            <td>{item.category_name}</td>
                            <td>{item.pin}</td>
                            <td>{item.board_name}</td>
                            <td>{item.class}</td>
                            <td>{item.subject_name}</td>
                            <td>{item.subscription_type}</td>
                            <td>{item.subscriped_method}</td>
                            <td>{item.nbf_details}</td>
                            <td>{item.subscription_validity}</td>
                            {/* <td>{item.subscription_status}</td> */}
                            <td>{item.payment_method}</td>
                            <td>{item.amount}</td>
                            <td>
                              <div className="d-flex">
                                <Button
                                  variant="info shadow btn-xs sharp"
                                  onClick={() => viewSubscriptionDetails(item)}
                                >
                                  <i className="fa fa-eye"></i>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </Table>
              )}

              {props?.subscriptionDetailsList?.data?.length != 0 &&
                props?.subscriptionDetailsList?.data != undefined ? (
                <div className="pagination-container">
                  <div className="page-result">
                    <p>
                      Showing: {(page - 1) * 10 + 1} -{" "}
                      {10 * page -
                        (10 -
                          props?.subscriptionDetailsList?.data?.length)}{" "}
                      of {props?.subscriptionDetailsList?.total_records}
                    </p>
                  </div>
                  {props?.subscriptionDetailsList?.total_records > 10 ? (
                    <ReactPaginate
                      containerClassName="pagination-gutter  false pagination-circle pagination pagination-sm"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      nextLabel={<i className="la la-angle-right"></i>}
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      previousLabel={<i className="la la-angle-left"></i>}
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      renderOnZeroPageCount={null}
                      activeClassName="active"
                      onPageChange={pageHandler}
                      pageRangeDisplayed={3}
                      pageCount={props?.subscriptionDetailsList?.total_page_no}
                      forcePage={page - 1}
                    />
                  ) : null}
                </div>
              ) : null}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <!-- Modal --> */}
      <Modal className="fade" show={modal} size="md">
        <Modal.Header>
          <Modal.Title>Filter</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => cancelModal()}
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="question-modal-form">
            <div className="reset-filtter-value">
              <button
                disabled={isDisabled}
                className="btn btn-info btn-xs"
                onClick={() => onResetHandler()}
              >
                Reset
              </button>
            </div>

            <div className="row">
              <div className={`form-group col-sm-6 px-1 mb-3`}>
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={period}
                    onChange={(e) => setPeriod(e.target.value)}
                  >
                    <option value="select_period">Select Period</option>

                    {periodData.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.showValue}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={category}
                    onChange={(e) => categoryHandler(e.target.value)}
                  >
                    <option value="select_category">Select Category</option>
                    {props.categoryList?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.category}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div
                className={`form-group col-sm-6 px-1 mb-3 ${category == "" || category == "select_category" ? "content_disable" : null
                  }`}
              >
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={category == 1 ? board : type}
                    onChange={(e) => boardTypeChangeHandler(e.target.value)}
                  >
                    <option
                      value={category == 1 ? "select_baord" : "select_type"}
                    >
                      {category == 1 ? "Select Board" : "Select Type"}
                    </option>
                    {category == 1
                      ? boardList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })
                      : props.typeList.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.type_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div
                className={`form-group col-sm-6 px-1 mb-3 ${category == 1 && board != "" ? null : "content_disable"
                  }`}
              >
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    onClick={getSubjectHandler}
                  >
                    <option value="select_subject">Select Subject</option>
                    {props.subjectFilterSubLibrary.map((item, index) => {
                      return (
                        <option key={index} value={item.subject_id}>
                          {item.subject_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={className}
                    onChange={(e) => setClassName(e.target.value)}
                  >
                    <option value="select_class">Select Class</option>
                    {classList?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.class_no}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className={`form-group col-sm-6 px-1 mb-3`}>
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={service}
                    onChange={(e) => setService(e.target.value)}
                  >
                    <option value="select_service">Select Service</option>
                    {serviceList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.showValue}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className={`form-group col-sm-6 px-1 mb-3`}>
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={nbf}
                    onChange={(e) => setNbf(e.target.value)}
                  >
                    <option value="select_nbf">Select NBF Status</option>
                    {nbfdetailsList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.showValue}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              {/* <div className={`form-group col-sm-6 px-1 mb-3`}>
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={subscriptionStatus}
                    onChange={(e) => setSubscriptionStatus(e.target.value)}
                  >
                    <option value="">Select subscription status</option>
                    {subscriptionstatusList.map((item, index) => {
                      return <option key={index} value={item.id}>{item.showValue}</option>;
                    })}
                  </select>
                </div>
              </div> */}

              <div className={`form-group col-sm-6 px-1 mb-3`}>
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    style={{ fontSize: 14 }}
                    value={subscriptionMethod}
                    onChange={(e) => setSubscriptionMethod(e.target.value)}
                  >
                    <option value="select_subscriptionMethod">
                      Select Subscription Method
                    </option>
                    {subscriptionmethodList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.showValue}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <AutocompleteSearch
                  dataDetails={locationPinData}
                  placeholder={`Enter Location Pin`}
                  setNewValue={(e) => setLocationPin(e)}
                  selectValue={locationPin}
                />
              </div>

              {/* {period == 2 ? */}
              <div className={`col-sm-6 px-1`}>
                <div className="date-control-view session-date-control">
                  <DatePicker
                    ref={calendarRef}
                    placeholderText="Select Subscription Validity"
                    selectsRange
                    selected={startDate}
                    onChange={onDateHandler}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    startDate={startDate}
                    endDate={endDate}
                    dropdownMode="select"
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                  />
                  <button
                    className="picker-btn"
                    onClick={() => calendarRef.current.setOpen(true)}
                  >
                    <i className="fa-solid fa-calendar-days"></i>
                  </button>
                </div>
              </div>
              {/* : null} */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => cancelModal()} variant="danger light">
            Cancel
          </Button>
          <Button
            disabled={isDisabled}
            onClick={() => filterRegisteredHandler()}
            variant="primary"
          >
            Filter
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ------------------ */}
      {/* <!-- Modal --> */}
      <Modal className="fade" show={subscriptionDetailsModal} size="md">
        <Modal.Header>
          <Modal.Title>Subscription Details</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setSubscriptionDetailsModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="question-modal-form">
            <div className="user_details_modal_container">
              <div className="leftBox_width">DOB :</div>
              <div className="box_width">
                {viewCurrentSubscriptionDetails?.dob}
              </div>
            </div>
            <div className="user_details_modal_container">
              <div className="leftBox_width">Gender :</div>
              <div className="box_width">
                {viewCurrentSubscriptionDetails?.gender}
              </div>
            </div>
            <div className="user_details_modal_container">
              <div className="leftBox_width">Mobile :</div>
              <div className="box_width">
                {viewCurrentSubscriptionDetails?.mobile_no}
              </div>
            </div>
            <div className="user_details_modal_container">
              <div className="leftBox_width">Email id :</div>
              <div className="box_width">
                {viewCurrentSubscriptionDetails?.email_id}
              </div>
            </div>

            <div className="user_details_modal_container">
              <div className="leftBox_width">School Name :</div>
              <div className="box_width">
                {viewCurrentSubscriptionDetails?.school_name}
              </div>
            </div>

            <div className="user_details_modal_container">
              <div className="leftBox_width">School Address :</div>
              <div className="box_width">
                {viewCurrentSubscriptionDetails?.school_address}
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <div className='footer_text'>This details will be included in the export of Excel</div>
        </Modal.Footer> */}
      </Modal>
    </Fragment>
  );
};

const mapstateToProps = (state) => {
  return {
    boardList: state.board.list,
    classList: state.class.list,
    categoryList: state.category.list,
    // subjectList: state.subject.subjectList,
    subscriptionDetailsList: state.subscriptiondetails.list,
    typeList: state.type.list,
    subjectList: state.subject.nonGroupSubjectList,
    subjectFilterSubLibrary: state.subject.subjectFilterSubLibrary,
  };
};

export default connect(mapstateToProps)(SubscriptionDatabase);
