import { Fragment, useEffect, useState, useRef } from "react";
import { Row, Col, Card, Table, Modal, Button } from "react-bootstrap";
import { useDispatch, connect, useSelector } from "react-redux";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import { getExamCategory } from "../../../store/actions/ExamCategoryAction";

import { getBoardData } from "../../../store/actions/BoardAction";
import { getClassData } from "../../../store/actions/ClassAction";
import { Link, useLocation, useHistory } from "react-router-dom";

import moment from "moment";

import {
  getExamType,
  loadingAction,
} from "../../../store/actions/ExamTypeAction";
import {
  getSubjectsFiltterSubLibraryList,
  getNonGroupSubjectsList,
  subjectLoading,
  getNonGroupSubjectsAction,
} from "../../../store/actions/SujectActions";

import {
  getChaptersDeffarentsParamsData,
  loadingChaptersAction,
  getChaptersDeffarentsParamsAction,
} from "../../../store/actions/ChapterAction";

import { misRegisterStudentLocationPinListData } from "../../../store/actions/MisRegUserAction";

import {
  getExamSubType,
  loadingSubTypeAction,
} from "../../../store/actions/ExamSubTypeAction";

import {
  misContentMisListData,
  misContentMisExcelData,
  misAutoSuggestAssigntoData,
  misAutosuggestChaptercodeData,
} from "../../../store/actions/MisDashboardInfoAction";

import stageList from "./json/stage.json";
import statusList from "./json/status.json";
import contentTypeList from "./json/contenttype.json";

import ReactPaginate from "react-paginate";

//images
import sort from "../../../images/dashboard_image/sort.png";
import export_details from "../../../images/dashboard_image/export.png";

import AutocompleteSearch from "../AutocompleteSearch/AutocompleteSearch";

const ContentMis = (props) => {
  const [modal, setModal] = useState(false);
  const [assignPersonModal, setAssignPersonModal] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const classList = useSelector((state) => state.class.list);
  const boardList = useSelector((state) => state.board.list);

  const [board, setboard] = useState(
    state?.previousPageFilterData?.board ?? ""
  );
  const [category, setCategory] = useState(
    state?.previousPageFilterData?.category ?? ""
  );
  const [type, setType] = useState(state?.previousPageFilterData?.type ?? "");
  const [subtype, setSubtype] = useState(
    state?.previousPageFilterData?.subtype ?? ""
  );
  const [className, setClassName] = useState(
    state?.previousPageFilterData?.className ?? ""
  );
  const [subject, setSubject] = useState(
    state?.previousPageFilterData?.subject ?? ""
  );
  const [chapterCode, setChapterCode] = useState(
    state?.previousPageFilterData?.chapterCode ?? ""
  );
  const [assignTo, setAssignTo] = useState(
    state?.previousPageFilterData?.assignTo ?? ""
  );
  const [stage, setStage] = useState(
    state?.previousPageFilterData?.stage ?? ""
  );
  const [status, setStatus] = useState(
    state?.previousPageFilterData?.status ?? ""
  );
  const [contentType, setContentType] = useState(
    state?.previousPageFilterData?.contentType ?? ""
  );
  const [chapter, setChapter] = useState(
    state?.previousPageFilterData?.chapter ?? ""
  );
  const [page, setPage] = useState(state?.page ?? 1);

  const [assignedPerson, setAssignedPerson] = useState("");

  const [isFiltered, setIsFiltered] = useState(false);

  const misRegisteredStudentLocationPin = useSelector(
    (state) => state.MisDashboardInfo.misRegisteredStudentLocationPin
  );
  const contentMisList = useSelector(
    (state) => state.MisDashboardInfo.contentMisList
  );
  const misAutosuggestAssigntoData = useSelector(
    (state) => state.MisDashboardInfo.misAutosuggestAssignto
  );
  const misAutosuggestChaptercodeList = useSelector(
    (state) => state.MisDashboardInfo.misAutosuggestChaptercode
  );

  const [locationPinData, setLocationPinData] = useState([]);


  /* temp const */
  const [tempBoard, setTempBoard] = useState("");
  const [tempCategory, setTempCategory] = useState("");
  const [tempType, setTempType] = useState("");
  const [tempSubtype, setTempSubtype] = useState("");
  const [tempClassName, setTempClassName] = useState("");
  const [tempSubject, setTempSubject] = useState("");
  const [tempChapterCode, setTempChapterCode] = useState("");
  const [tempAssignTo, setTempAssignTo] = useState("");
  const [tempStage, setTempStage] = useState("");
  const [tempStatus, setTempStatus] = useState("");
  const [tempContentType, setTempContentType] = useState("");
  const [tempChapter, setTempChapter] = useState("");
  const [permission, setPermission] = useState("");

  useEffect(() => {
    dispatch(getClassData());
    dispatch(getBoardData());
    dispatch(getExamCategory());
    dispatch(getExamType(props.history));
    dispatch(misRegisterStudentLocationPinListData());
    dispatch(misAutoSuggestAssigntoData());
    dispatch(misAutosuggestChaptercodeData());
    permissionCheck()
  }, []);

  useEffect(() => {
    if (state?.previousPageFilterData) {
      setTempBoard(state?.previousPageFilterData?.board ?? board);
      setTempCategory(state?.previousPageFilterData?.category ?? category);
      setTempType(state?.previousPageFilterData?.type ?? type);
      setTempSubtype(state?.previousPageFilterData?.subtype ?? subtype);
      setTempClassName(state?.previousPageFilterData?.className ?? className);
      setTempSubject(state?.previousPageFilterData?.subject ?? subject);
      setTempChapterCode(
        state?.previousPageFilterData?.chapterCode ?? chapterCode
      );
      setTempAssignTo(state?.previousPageFilterData?.assignTo ?? assignTo);
      setTempStage(state?.previousPageFilterData?.stage ?? stage);
      setTempStatus(state?.previousPageFilterData?.status ?? status);
      setTempContentType(
        state?.previousPageFilterData?.contentType ?? contentType
      );
      setTempChapter(state?.previousPageFilterData?.chapter ?? chapter);
    }
  }, [state]);

  useEffect(() => {
    if (
      category != "select_category" &&
      category != "" &&
      category != undefined
    ) {
      setIsFiltered(true);
    } else if (board != "select_baord" && board != "" && board != undefined) {
      setIsFiltered(true);
    } else if (type != "select_type" && type != "" && type != undefined) {
      setIsFiltered(true);
    } else if (
      subtype != "select_sub_type" &&
      subtype != "" &&
      subtype != undefined
    ) {
      setIsFiltered(true);
    } else if (
      className != "select_class" &&
      className != "" &&
      className != undefined
    ) {
      setIsFiltered(true);
    } else if (
      subject != "select_subjects" &&
      subject != "" &&
      subject != undefined
    ) {
      setIsFiltered(true);
    } else if (chapterCode != "" && chapterCode != undefined) {
      setIsFiltered(true);
    } else if (assignTo != "" && assignTo != undefined) {
      setIsFiltered(true);
    } else if (stage != "select_stage" && stage != "" && stage != undefined) {
      setIsFiltered(true);
    } else if (
      status != "select_status" &&
      status != "" &&
      status != undefined
    ) {
      setIsFiltered(true);
    } else if (
      contentType != "select_content_type" &&
      contentType != "" &&
      contentType != undefined
    ) {
      setIsFiltered(true);
    } else if (
      chapter != "select_chapter_name" &&
      chapter != "" &&
      chapter != undefined
    ) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
  }, [
    category,
    board,
    type,
    subtype,
    className,
    subject,
    chapterCode,
    assignTo,
    stage,
    status,
    contentType,
    chapter,
  ]);

  useEffect(() => {
    if (state && state.page != undefined) {
      setPage(state?.page);
    }
  }, []);

  useEffect(() => {
    let locationPinData = [];
    let locationPinObj = {};
    misRegisteredStudentLocationPin.forEach((elm) => {
      locationPinObj = {};
      locationPinObj = elm.pincode.toString();
      locationPinData.push(locationPinObj);
    });
    setLocationPinData(locationPinData);
  }, [misRegisteredStudentLocationPin]);

  const permissionCheck = () => {
    const userDetails = localStorage.getItem("userDetails");
    const tokenDetails = JSON.parse(userDetails);
    const menuList = tokenDetails.menu_list;
    menuList.map((item) => {
      if (item.id == 81) {
        console.log("81----------", item)
        setPermission(item);
      }
    });
  };

  const onResetHandler = () => {
    setCategory("");
    setboard("");
    setType("");
    setSubtype("");
    setClassName("");
    setSubject("");
    setChapter("");
    setChapterCode("");
    setAssignTo("");
    setStage("");
    setStatus("");
    setContentType("");
    setPage(1);
    dispatch(globalLoadingAction(true));
    dispatch(
      misContentMisListData("", "", "", "", "", "", "", "", "", "", "", "", 1)
    );
    setTempBoard("");
    setTempCategory("");
    setTempType("");
    setTempSubtype("");
    setTempClassName("");
    setTempSubject("");
    setTempChapterCode("");
    setTempAssignTo("");
    setTempStage("");
    setTempStatus("");
    setTempContentType("");
    setTempChapter("");
    setModal(false);
    const newState = {};
    history.replace({ state: newState });
  };

  useEffect(() => {
    getList(
      category,
      board,
      type,
      subtype,
      className,
      subject,
      chapterCode,
      assignTo,
      stage,
      status,
      contentType,
      chapter,
      page
    );
  }, []);

  const getList = (
    category,
    board,
    type,
    subtype,
    className,
    subject,
    chapterCode,
    assignTo,
    stage,
    status,
    contentType,
    chapter,
    page
  ) => {
    dispatch(globalLoadingAction(true));
    dispatch(
      misContentMisListData(
        category == "select_category" ? "" : category,
        board == "select_baord" ? "" : board,
        type == "select_type" ? "" : type,
        subtype == "select_sub_type" ? "" : subtype,
        className == "select_class" ? "" : className,
        subject == "select_subjects" ? "" : subject,
        chapterCode,
        assignTo,
        stage == "select_stage" ? "" : stage,
        status == "select_status" ? "" : status,
        contentType == "select_content_type" ? "" : contentType,
        chapter == "select_chapter_name" ? "" : chapter,
        page
      )
    );
  };

  const filterRegisteredHandler = () => {
    let page = 1;
    setModal(false);
    setPage(1);
    getList(
      category,
      board,
      type,
      subtype,
      className,
      subject,
      chapterCode,
      assignTo,
      stage,
      status,
      contentType,
      chapter,
      page
    );

    setTempBoard(board);
    setTempCategory(category);
    setTempType(type);
    setTempSubtype(subtype);
    setTempClassName(className);
    setTempSubject(subject);
    setTempChapterCode(chapterCode);
    setTempAssignTo(assignTo);
    setTempStage(stage);
    setTempStatus(status);
    setTempContentType(contentType);
    setTempChapter(chapter);
  };

  /* pagination */
  const pageHandler = (data) => {
    let page = data.selected + 1;
    setPage(data.selected + 1);
    getList(
      category,
      board,
      type,
      subtype,
      className,
      subject,
      chapterCode,
      assignTo,
      stage,
      status,
      contentType,
      chapter,
      page
    );
  };

  const onDownloadContentMisHandler = () => {
    let page = 0;
    dispatch(globalLoadingAction(true));
    dispatch(
      misContentMisExcelData(
        category,
        board,
        type,
        subtype,
        className,
        subject,
        chapterCode,
        assignTo,
        stage,
        status,
        contentType,
        chapter,
        page
      )
    );
  };

  /* const getSubjectHandler = () => {
    // dispatch(subjectLoading(true));
    dispatch(getSubjectsFiltterSubLibraryList(board, type, category, className, props.history))
  } */

  /* on change subject */
  const getSubjectHandler = () => {
    if (category == 1 && board) {
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(category),
          0,
          0,
          parseInt(board),
          props.history
        )
      );
    } else if (category == 2 && type == 1 && subtype) {
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(category),
          parseInt(type),
          parseInt(subtype),
          0,
          props.history
        )
      );
    } else if (category == 2 && type == 2) {
      dispatch(subjectLoading(true));
      dispatch(
        getNonGroupSubjectsList(
          parseInt(category),
          parseInt(type),
          0,
          0,
          props.history
        )
      );
    }
  };

  const categoryHandler = (e) => {
    setCategory(e);
    setboard("");
    setType("");
    setSubtype("");
    setSubject("");
    setClassName("");
    setChapter("");
  };

  const boardTypeChangeHandler = (e) => {
    if (category == 1) {
      setboard(e);
      setType("");
    } else {
      setboard("");
      setType(e);
      setSubtype("");
    }
    setSubject("");
    setClassName("");
    setChapter("");
  };

  /* get subject list */
  const getSubtypeHandler = () => {
    if (props.subTypeList.length == 0) {
      dispatch(loadingSubTypeAction(true));
      dispatch(getExamSubType(props.history));
    }
  };

  /* on change subtype  */
  const onSubtypeTypeHandler = (val) => {
    setSubtype(val);
    setSubject("");
    setChapter("");
    setClassName("");
    dispatch(getNonGroupSubjectsAction([]));
    dispatch(getChaptersDeffarentsParamsAction([]));
  };

  const onClassNameHandler = (val) => {
    setClassName(val);
    setSubject("");
    setChapter("");
  }

  const onSubjectHandler = (val) => {
    setSubject(val);
    setChapter("");
  }

  /* get chapter list */
  const getChapterHandler = () => {
    if (category == 1 && board) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          board,
          0,
          0,
          subject,
          className,
          props.history
        )
      );
    } else if (category == 2 && type == 1 && subtype) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          0,
          type,
          subtype,
          subject,
          0,
          props.history
        )
      );
    } else if (category == 2 && type == 2) {
      dispatch(loadingChaptersAction(true));
      dispatch(
        getChaptersDeffarentsParamsData(
          parseInt(category),
          0,
          type,
          0,
          subject,
          className,
          props.history
        )
      );
    } else {
      dispatch(getChaptersDeffarentsParamsAction([]));
    }
  };

  const assignedPersonDetailsHandeler = (item) => {
    setAssignPersonModal(true);
    setAssignedPerson(item);
  };

  const contentDetailsHandeler = (item) => {
    props.history.push({
      pathname: "/content-details",
      state: {
        item,
        page,
        filterData: {
          category,
          board,
          type,
          subtype,
          className,
          subject,
          chapterCode,
          assignTo,
          stage,
          status,
          contentType,
          chapter,
        },
      },
    });
  };

  const cancelModal = () => {
    setModal(false);
    if (tempCategory == "") {
      setCategory("");
    } else if (
      category != "select_category" &&
      category != "" &&
      category != undefined
    ) {
      setCategory(tempCategory);
    } else if (category == "select_category" || category === "") {
      setCategory(tempCategory);
    }

    if (tempBoard == "") {
      setboard("");
    } else if (board != "select_baord" && board != "" && board != undefined) {
      setboard(tempBoard);
    } else if (board == "select_baord" || board == "") {
      setboard(tempBoard);
    }

    if (tempType == "") {
      setType("");
    } else if (type != "select_type" && type != "" && type != undefined) {
      setType(tempType);
    } else if (type == "select_type") {
      setType(tempType);
    }

    if (tempSubtype == "") {
      setSubtype("");
    } else if (
      subtype != "select_sub_type" &&
      subtype != "" &&
      subtype != undefined
    ) {
      setSubtype(tempSubtype);
    } else if (subtype == "select_sub_type") {
      setSubtype(tempSubtype);
    }

    if (tempClassName == "") {
      setClassName("");
    } else if (
      className != "select_class" &&
      className != "" &&
      className != undefined
    ) {
      setClassName(tempClassName);
    } else if (className == "select_class") {
      setClassName(tempClassName);
    }

    if (tempSubject == "") {
      setSubject("");
    } else if (
      subject != "select_subjects" &&
      subject != "" &&
      subject != undefined
    ) {
      setSubject(tempSubject);
    } else if (subject == "select_subjects") {
      setSubject(tempSubject);
    }

    if (tempChapterCode == "") {
      setChapterCode("");
    } else if (chapterCode != "" && chapterCode != undefined) {
      setChapterCode(tempChapterCode);
    } else if (chapterCode == "") {
      setChapterCode(tempChapterCode);
    }

    if (tempAssignTo == "") {
      setAssignTo("");
    } else if (assignTo != "" && assignTo != undefined) {
      setAssignTo(tempAssignTo);
    } else if (assignTo == "") {
      setAssignTo(tempAssignTo);
    }

    if (tempStage == "") {
      setStage("");
    } else if (stage != "select_stage" && stage != "" && stage != undefined) {
      setStage(tempStage);
    } else if (stage == "select_stage") {
      setStage(tempStage);
    }

    if (tempStatus == "") {
      setStatus("");
    } else if (
      status != "select_status" &&
      status != "" &&
      status != undefined
    ) {
      setStatus(tempStatus);
    } else if (status == "select_status") {
      setStatus(tempStatus);
    }
    if (tempContentType == "") {
      setContentType("");
    } else if (
      contentType != "select_content_type" &&
      contentType != "" &&
      contentType != undefined
    ) {
      setContentType(tempContentType);
    } else if (contentType == "select_content_type") {
      setContentType(tempContentType);
    }

    if (tempChapter == "") {
      setChapter("");
    } else if (
      chapter != "select_chapter_name" &&
      chapter != "" &&
      chapter != undefined
    ) {
      setChapter(tempChapter);
    } else if (chapter == "select_chapter_name") {
      setChapter(tempChapter);
    }
  };

  const isDisabled =
    (category === "" || category === "select_category") &&
    (board === "" || board === "select_baord") &&
    (type === "" || type === "select_type") &&
    (subtype === "" || subtype === "select_sub_type") &&
    (className === "" || className === "select_class") &&
    (subject === "" || subject === "select_subjects") &&
    chapterCode === "" &&
    assignTo === "" &&
    (stage === "" || stage === "select_stage") &&
    (status === "" || status === "select_status") &&
    (contentType === "" || contentType === "select_content_type") &&
    (chapter === "" || chapter === "select_chapter_name");

  return (
    <Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div className="filtersAlign mt-3" style={{ marginRight: 25 }}>
                <div
                  className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 me-3 filter_background rounded cursorPointer ${isFiltered ? "border_red" : null
                    }`}
                  onClick={() => setModal(true)}
                >
                  <div className="image_container">
                    <img src={sort} alt="" className="image_style" />
                  </div>
                  <div className="button_text font_color_black">Filter</div>
                </div>
                {(permission.download == "true") && (
                  <div
                    className={`btn_size d-flex flex-row justify-content-around align-items-center ps-1 pe-1 export_background rounded blue_border cursorPointer ${contentMisList?.data?.length == 0 ||
                        contentMisList?.data?.length == undefined
                        ? "content_disable"
                        : null
                      }`}
                    onClick={() => onDownloadContentMisHandler()}
                  >
                    <div className="image_container">
                      <img src={export_details} alt="" className="image_style" />
                    </div>
                    <div className="button_text font_color_blue cursorPointer">
                      Export
                    </div>
                  </div>
                )}
              </div>
              {/* </div> */}
              {contentMisList?.data?.length == 0 ||
                contentMisList?.data?.length == undefined ? (
                <div className="no_data">
                  <strong>No data found</strong>
                </div>
              ) : (
                <Table responsive>
                  <thead>
                    <tr>
                      <th>
                        <strong>Sl no.</strong>
                      </th>
                      <th>
                        <strong>Exam Category</strong>
                      </th>
                      <th>
                        <strong>Board / exam type</strong>
                      </th>
                      <th>
                        <strong>Class</strong>
                      </th>
                      <th>
                        <strong>Subject</strong>
                      </th>
                      <th>
                        <strong>Chapter code</strong>
                      </th>
                      <th>
                        <strong>Content type</strong>
                      </th>
                      <th>
                        <strong>Chapter name</strong>
                      </th>
                      <th>
                        <strong>Assign to</strong>
                      </th>
                      <th>
                        <strong>Stage</strong>
                      </th>
                      <th>
                        <strong>Status</strong>
                      </th>
                      <th>
                        <div style={{ width: "130px", whiteSpace: "pre-wrap" }}>
                          <strong>Date of Assignment</strong>
                        </div>
                      </th>
                      <th>
                        <div style={{ width: "130px", whiteSpace: "pre-wrap" }}>
                          <strong>Date of final submission</strong>
                        </div>
                      </th>
                      <th>
                        <div style={{ width: "130px", whiteSpace: "pre-wrap" }}>
                          <strong>Commercial for complete chapter</strong>
                        </div>
                      </th>
                      <th>
                        <div style={{ width: "130px", whiteSpace: "pre-wrap" }}>
                          <strong>Commercial for per questions</strong>
                        </div>
                      </th>
                      <th>
                        <strong>Action</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {contentMisList?.data?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.sl_no}</td>
                          <td>{item.category}</td>
                          <td>{item.board_or_exam_type}</td>
                          <td>{item.class}</td>
                          <td>{item.subject_name}</td>
                          <td>{item.chapter_code}</td>
                          <td>{item.content_type}</td>
                          <td>{item.chapter_name}</td>
                          <td>{item.assign_to}</td>
                          <td>{item.stage}</td>
                          <td>{item.status}</td>
                          <td>{item.assign_date}</td>
                          {/* <td>{item.final_submission_date}</td> */}
                          <td>{moment(item.updated_at).format("DD-MM-YYYY")}</td>
                          <td>{item.commercial_rate}</td>
                          <td>{item.commercial_rate_per_ques}</td>
                          <td>
                            <div className="d-flex justify-content-between w-100">
                              <Button
                                variant="info shadow btn-xs sharp"
                                onClick={() =>
                                  assignedPersonDetailsHandeler(item)
                                }
                              >
                                <i className="fa fa-eye"></i>
                              </Button>
                              <Button
                                variant={`${item.request_type == "Q"
                                    ? "warning"
                                    : "success"
                                  }  shadow btn-xs sharp`}
                                onClick={() => contentDetailsHandeler(item)}
                              >
                                <i className="fa fa-list"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              )}
              {contentMisList?.data?.length != 0 &&
                contentMisList?.data != undefined ? (
                <div className="pagination-container">
                  <div className="page-result">
                    <p>
                      Showing: {(page - 1) * 10 + 1} -{" "}
                      {10 * page - (10 - contentMisList?.data?.length)} of{" "}
                      {contentMisList.total_record}
                    </p>
                  </div>
                  {contentMisList.total_record > 10 ? (
                    <ReactPaginate
                      containerClassName="pagination-gutter  false pagination-circle pagination pagination-sm"
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      nextLabel={<i className="la la-angle-right"></i>}
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakLabel="..."
                      previousLabel={<i className="la la-angle-left"></i>}
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      renderOnZeroPageCount={null}
                      activeClassName="active"
                      onPageChange={pageHandler}
                      pageRangeDisplayed={3}
                      pageCount={contentMisList.total_page}
                      forcePage={page - 1}
                    />
                  ) : null}
                </div>
              ) : null}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* <!-- Modal --> */}
      <Modal className="fade" show={modal} size="md">
        <Modal.Header>
          <Modal.Title>Filter</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => cancelModal()}
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="question-modal-form">
            <div className="reset-filtter-value">
              <button
                disabled={isDisabled}
                className="btn btn-info btn-xs"
                onClick={() => onResetHandler()}
              >
                Reset
              </button>
            </div>

            <div className="row">
              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={category}
                    onChange={(e) => categoryHandler(e.target.value)}
                  >
                    <option value="select_category">Select Category</option>
                    {props.categoryList?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.category}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div
                className={`form-group col-sm-6 px-1 mb-3 ${category == "" ? "content_disable" : null
                  }`}
              >
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={category == 1 ? board : type}
                    onChange={(e) => boardTypeChangeHandler(e.target.value)}
                  >
                    <option
                      value={category == 1 ? "select_baord" : "select_type"}
                    >
                      {category == 1 ? "Select Board" : "Select Type"}
                    </option>
                    {category == 1
                      ? boardList?.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        );
                      })
                      : props?.typeList?.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.type_name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    {/* {props.subTypeLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>} */}
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className={`form-control ${category == 2 && type == 1 ? null : "content_disable"
                      }`}
                    // disabled={category == 2 && type == 1 ? false : true}
                    value={subtype}
                    onClick={getSubtypeHandler}
                    onChange={(e) => onSubtypeTypeHandler(e.target.value)}
                  >
                    <option value="select_sub_type">Select Sub Type</option>
                    {props.subTypeList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.subtype_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    // disabled={true}
                    defaultValue={"option"}
                    className={`form-control ${category == 2 && type == 1 ? "content_disable" : null
                      }`}
                    value={className}
                    onChange={(e) => onClassNameHandler(e.target.value)}
                  >
                    <option value="select_class">Select Class</option>
                    {classList?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.class_no}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              {/* className={`form-control ${(category && board) || (category && type) ? null : 'content_disable'}`} */}
              {/* <div className={`form-group col-sm-6 px-1 mb-3 ${category == 1 && board != '' ? null : "content_disable"}`}> */}
              {/* <div className={`form-group col-sm-6 px-1 mb-3 ${(category && board) || (category && type)  ? null : "content_disable"}`}>
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    onClick={getSubjectHandler}
                  >
                    <option value="">Select subjects</option>
                    {props.subjectFilterSubLibrary?.map((item, index) => {
                      return <option key={index} value={item.subject_id}>{item.subject_name}</option>;
                    })}
                  </select>
                </div>
              </div> */}

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    {/* {props.subjectLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>} */}
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className={`form-control ${(category && board) || (category && type)
                        ? null
                        : "content_disable"
                      }`}
                    // disabled={(category && board) || (category && type) ? false : true }
                    value={subject}
                    onClick={getSubjectHandler}
                    onChange={(e) => onSubjectHandler(e.target.value)}
                  >
                    <option value="select_subjects">Select Subjects</option>
                    {props.subjectList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    {/* {props.chapterLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>} */}
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={chapter}
                    onClick={getChapterHandler}
                    onChange={(e) => setChapter(e.target.value)}
                  >
                    <option value="select_chapter_name">
                      Select Chapter Name
                    </option>
                    {props.chapterList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.chapter_name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <AutocompleteSearch
                  dataDetails={misAutosuggestChaptercodeList}
                  placeholder={`Enter Chapter Code`}
                  setNewValue={(e) => setChapterCode(e)}
                  selectValue={chapterCode}
                />
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <AutocompleteSearch
                  dataDetails={misAutosuggestAssigntoData}
                  placeholder={`Enter Assign To`}
                  setNewValue={(e) => setAssignTo(e)}
                  selectValue={assignTo}
                />
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={stage}
                    onChange={(e) => setStage(e.target.value)}
                  >
                    <option value="select_stage">Select Stage</option>
                    {stageList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.showValue}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="select_status">Select Status</option>
                    {statusList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.showValue}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group col-sm-6 px-1 mb-3">
                <div className="basic-dropdown">
                  <span className="drop_down_arrow add-Question-arrow-position">
                    <i className="bi bi-caret-down-fill"></i>
                  </span>
                  <select
                    defaultValue={"option"}
                    className="form-control"
                    value={contentType}
                    onChange={(e) => setContentType(e.target.value)}
                  >
                    <option value="select_content_type">
                      Select Content Type
                    </option>
                    {contentTypeList.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.showValue}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => cancelModal()} variant="danger light">
            Cancel
          </Button>
          <Button
            disabled={isDisabled}
            onClick={() => filterRegisteredHandler()}
            variant="primary"
          >
            Filter
          </Button>
        </Modal.Footer>
      </Modal>

      {/* ------------------ */}
      {/* <!-- Modal --> */}
      <Modal className="fade" show={assignPersonModal} size="md">
        <Modal.Header>
          <Modal.Title>Assigned Person</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => setAssignPersonModal(false)}
          ></Button>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="performance_details_contaner  flex-column">
            <div className="individual_container">
              <div className="first_container">
                <strong>Content Developer Name </strong>
              </div>
              <div className="second_container">
                <strong>:</strong>
              </div>
              <div className="third_container">
                {assignedPerson?.content_developer_name}
              </div>
            </div>

            <div className="individual_container">
              <div className="first_container">
                <strong>Content Validator Name</strong>
              </div>
              <div className="second_container">
                <strong>:</strong>
              </div>
              <div className="third_container">
                {assignedPerson?.content_validator_name}
              </div>
            </div>

            <div className="individual_container">
              <div className="first_container">
                <strong>Language Validator Name</strong>
              </div>
              <div className="second_container">
                <strong>:</strong>
              </div>
              <div className="third_container">
                {assignedPerson?.language_validator_name}
              </div>
            </div>

            <div className="individual_container">
              <div className="first_container">
                <strong>Content Designer Name</strong>
              </div>
              <div className="second_container">
                <strong>:</strong>
              </div>
              <div className="third_container">
                {assignedPerson?.content_designer_name}
              </div>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <div className='footer_text'>This details will be included in the export of Excel</div>
        </Modal.Footer> */}
      </Modal>
    </Fragment>
  );
};

const mapstateToProps = (state) => {
  return {
    boardList: state.board.list,
    classList: state.class.list,
    categoryList: state.category.list,
    // subjectList: state.subject.subjectList,
    subjectList: state.subject.nonGroupSubjectList,
    subscriptionDetailsList: state.subscriptiondetails.list,
    subjectFilterSubLibrary: state.subject.subjectFilterSubLibrary,
    typeList: state.type.list,
    subTypeList: state.subtype.list,
    chapterList: state.chapter.typelist,
  };
};

export default connect(mapstateToProps)(ContentMis);
