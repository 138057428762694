import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link, useLocation, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { Editor } from "@tinymce/tinymce-react";
import GlobalConfigs from "../../../configs/GlobalConfigs";
import * as utility from "../../../utility/Utility";
import { globalLoadingAction } from "../../../store/actions/GlobalAction";
import {
  addAssignContentData,
  updateContentAssignData,
  getAddedDocumentDetailsData,
  acceptRejectUploadedContentData,
  getAddedDocumentDetailsAction,
  reRenderingContentListAction,
  assignToValidatorElibraryData,
  assignToLanguageValidatorElibraryDetailsData,
  assignToDesignerElibrarysData,
} from "../../../store/actions/AssignContentAction";
import {
  loadingUserAction,
  getUserTypeData,
  getDeveloperData,
} from "../../../store/actions/UserAction";

import {
  getContentValidatorData,
  removeContentValidatorDetails,
  updateContentValidatorStatus,
} from "../../../store/actions/ContentValidatorAction";

import { getLanguageValidatorData } from "../../../store/actions/ContentLanguageValidatorAction";
import { getContentDesignerData } from "../../../store/actions/ContentDesignerAction";

import AWS from "aws-sdk";

const AssignDeveloper = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const calendarRef = useRef();
  const contentValidatorCalendarRef = useRef();
  const languageValidatorCalendarRef = useRef();
  const designerCalendarRef = useRef();
  const { state } = useLocation(props.documentDetails);

  const fileRef = useRef();

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
  });

  let errorsObj = {
    requestType: "",
    category: "",
    board: "",
    branch: "",
    chapter: "",
    classroom: "",
    developer: "",
    examType: "",
    question: "",
    payment: "",
    date: "",
    subject: "",
    file: "",
    details: "",
    contentValidatorPayment: "",
    contentValidator: "",
    contentValidatorSubmissionDate: "",
    languageValidatorSubmissionDate: "",
    languageValidator: "",
    languageValidatorPayment: "",
    designerSubmissionDate: "",
    designer: "",
    designerPayment: "",
  };

  const [errors, setErrors] = useState(errorsObj);

  const [developer, setDeveloper] = useState("");
  const [payment, setPayment] = useState("");
  const [submissionDate, setSubmissionDate] = useState(new Date());
  const [details, setDetails] = useState("");

  const [contentValidatorSubmissionDate, setContentValidatorSubmissionDate] =
    useState("");
  const [contentValidator, setContentValidator] = useState("");
  const [contentValidatorPayment, setContentValidatorPayment] = useState("");
  /* languageValidator */
  const [languageValidatorSubmissionDate, setLanguageValidatorSubmissionDate] =useState("");
  const [languageValidator, setLanguageValidator] = useState("");
  const [languageValidatorPayment, setLanguageValidatorPayment] = useState("");
  /* designer */
  const [designerSubmissionDate, setDesignerSubmissionDate] = useState("");
  const [designer, setdesigner] = useState("");
  const [designerPayment, setDesignerPayment] = useState("");

  const [content, setContent] = useState("");
  const [filterData, setFilterData] = useState("");

  const documentDetails = useSelector((state) => state.assign.documentDetails);
  const reRenderingContentList = useSelector(
    (state) => state.assign.reRenderingContentList
  );

  const [conceptMapData, setConceptMapData] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setConceptMapData([]);
    setShow(false);
  };

  const handleShow = (data) => {
    setConceptMapData(JSON.parse(data));
    setShow(true);
  };


  useEffect(() => {
    dispatch(getDeveloperData(0, "", "", "", props.history));
    dispatch(getContentValidatorData(0, "", "", "", props.history));
    dispatch(getLanguageValidatorData(0, "", "", "", props.history));
    dispatch(getContentDesignerData(0, "", "", "", props.history));
    dispatch(getAddedDocumentDetailsData(state.id, props.history));

    if (props.documentDetails == "") {
      dispatch(globalLoadingAction(true));
    } else {
      dispatch(globalLoadingAction(false));
    }

    setFilterData(state.filterData);
    return () => {
      dispatch(getAddedDocumentDetailsAction([]));
    };
  }, []);

  useEffect(() => {
    if (props.documentDetails != "") {
      setDeveloper(props.documentDetails.assigned_developer);
      setSubmissionDate(new Date(props.documentDetails.date_of_submission));
      setPayment(props.documentDetails.developer_payment);
      setDetails(props.documentDetails.content_data);
      if (
        props.documentDetails.developer_approval_status == 2 ||
        props.documentDetails.developer_approval_status == 3 ||
        props.documentDetails.developer_approval_status == 4
      ) {
        setContentValidator(props.documentDetails.assigned_validator);
        setContentValidatorSubmissionDate(
          new Date(props.documentDetails.date_of_submission_validator)
        );
        onContentValidatorPaymentHandler(
          props.documentDetails.validator_payment
        );
      }
      if (
        props.documentDetails.developer_approval_status == 3 ||
        props.documentDetails.developer_approval_status == 4
      ) {
        setLanguageValidator(props.documentDetails.assigned_language_validator);
        setLanguageValidatorSubmissionDate(
          new Date(props.documentDetails.date_of_submission_language_validator)
        );
        onLanguageValidatorPaymentHandler(
          props.documentDetails.language_validator_payment
        );
      }

      // if (props.documentDetails.developer_approval_status == 3 && props.documentDetails.admin_approval_status == 1 || props.documentDetails.developer_approval_status == 4 ) {
      if (props.documentDetails.developer_approval_status == 4) {
        setdesigner(props.documentDetails.assigned_designer);
        setDesignerSubmissionDate(
          new Date(props.documentDetails.date_of_submission_designer)
        );
        ondesignerPaymentHandler(props.documentDetails.designer_payment);
      }
    }
  }, [props.documentDetails]);

  useEffect(() => {
    if (reRenderingContentList == 1) {
      dispatch(getAddedDocumentDetailsData(state.id, props.history));
      dispatch(reRenderingContentListAction(0));
    }
  }, [reRenderingContentList]);

  /* pdf file download from as3 */
  const handleDownloaConceptMapFile = (url) => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "concept_map_excel.xlsx"); // Optional: Set a default filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  /* download pdf file in local system */
  const handleDownloaWriteupFile = (url) => {
    const s3 = new AWS.S3();
    let splitUrl = url.split("/");
    let fileName = splitUrl[splitUrl.length - 1];

    const params = {
      Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
      //   Key: `templates/${template}`,
      Key: `elibrary/${fileName}`,
    };

    function downloadBlob(blob, name = `${fileName}`) {
      // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
      const blobUrl = URL.createObjectURL(blob);
      // Create a link element
      const link = document.createElement("a");
      // Set link's href to point to the Blob URL
      link.href = blobUrl;
      link.download = name;
      // Append link to the body
      document.body.appendChild(link);
      // Dispatch click event on the link
      // This is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
      // Remove link from body
      document.body.removeChild(link);
    }

    s3.getObject(params, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        let pdfBlob = new Blob([data.Body], {
          type: data.ContentType,
        });
        downloadBlob(pdfBlob, `${fileName}`);
      }
    });
  };

  /* form submit */
  function onSubmit(e) {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };
    if (
      (props.documentDetails.developer_approval_status == 1 &&
        props.documentDetails.approved_status == 0) ||
      (props.documentDetails.developer_approval_status == 1 &&
        props.documentDetails.approved_status == 1 &&
        props.documentDetails.admin_approval_status == 2 &&
        props.documentDetails.status == 1) ||
      (props.documentDetails.admin_approval_status == 0 &&
        props.documentDetails.approved_status == 2 &&
        props.documentDetails.developer_approval_status == 1 &&
        props.documentDetails.status == 0)
    ) {
      if (submissionDate === "") {
        errorObj.date = "Submission Date is Required";
        error = true;
      }
      if (payment === "") {
        errorObj.payment = "Amount is Required";
        error = true;
      }
      if (developer === "") {
        errorObj.developer = "Developer is Required";
        error = true;
      }
      setErrors(errorObj);
      if (error) {
        return;
      }
      dispatch(globalLoadingAction(true));
      dispatch(
        updateContentAssignData(
          state.developer_approval_status,
          payment,
          developer,
          moment(submissionDate).format("YYYY-MM-DD"),
          state,
          props.history
        )
      );
    } else if (
      (props.documentDetails.developer_approval_status == 2 &&
        props.documentDetails.approved_status == 0 &&
        props.documentDetails.admin_approval_status == 0) ||
      (props.documentDetails.developer_approval_status == 2 &&
        props.documentDetails.approved_status == 1 &&
        props.documentDetails.admin_approval_status == 2 &&
        props.documentDetails.status == 1) ||
      (props.documentDetails.developer_approval_status == 1 &&
        props.documentDetails.approved_status == 1 &&
        props.documentDetails.admin_approval_status == 1 &&
        props.documentDetails.status == 1) ||
      (props.documentDetails.developer_approval_status == 2 &&
        props.documentDetails.approved_status == 2)
    ) {
      if (contentValidator === "" || contentValidator === 0) {
        errorObj.contentValidator = "Content Validator is Required";
        error = true;
      }
      if (contentValidatorPayment === "" || contentValidatorPayment === 0) {
        errorObj.contentValidatorPayment = "Amount is Required";
        error = true;
      }
      if (
        contentValidatorSubmissionDate === undefined ||
        contentValidatorSubmissionDate === ""
      ) {
        errorObj.contentValidatorSubmissionDate = "Submission Date is Required";
        error = true;
      }
      setErrors(errorObj);
      if (error) {
        return;
      }

      dispatch(globalLoadingAction(true));
      dispatch(
        assignToValidatorElibraryData(
          state,
          moment(contentValidatorSubmissionDate).format("YYYY-MM-DD"),
          contentValidatorPayment,
          contentValidator,
          props.history
        )
      );
    } else if (
      (props.documentDetails.developer_approval_status == 3 &&
        props.documentDetails.approved_status == 0 &&
        props.documentDetails.admin_approval_status == 0) ||
      (props.documentDetails.developer_approval_status == 2 &&
        props.documentDetails.approved_status == 1 &&
        props.documentDetails.admin_approval_status == 1 &&
        props.documentDetails.status == 1) ||
      (props.documentDetails.developer_approval_status == 3 &&
        props.documentDetails.approved_status == 1 &&
        props.documentDetails.admin_approval_status == 2 &&
        props.documentDetails.status == 1) ||
      (props.documentDetails.developer_approval_status == 3 &&
        props.documentDetails.approved_status == 2)
    ) {
      if (languageValidator === "" || languageValidator === 0) {
        errorObj.languageValidator = "Language Validator is Required";
        error = true;
      }
      if (languageValidatorPayment === "" || languageValidatorPayment === 0) {
        errorObj.languageValidatorPayment = "Amount is Required";
        error = true;
      }
      if (
        languageValidatorSubmissionDate === undefined ||
        languageValidatorSubmissionDate === ""
      ) {
        errorObj.languageValidatorSubmissionDate =
          "Submission Date is Required";
        error = true;
      }
      setErrors(errorObj);
      if (error) {
        return;
      }
      dispatch(globalLoadingAction(true));
      dispatch(
        assignToLanguageValidatorElibraryDetailsData(
          state,
          moment(languageValidatorSubmissionDate).format("YYYY-MM-DD"),
          languageValidatorPayment,
          languageValidator,
          props.history
        )
      );
    } else if (
      (props.documentDetails.developer_approval_status == 3 &&
        props.documentDetails.approved_status == 1 &&
        props.documentDetails.admin_approval_status == 1 &&
        props.documentDetails.status == 1) ||
      props.documentDetails.developer_approval_status == 4 ||
      (props.documentDetails.developer_approval_status == 4 &&
        props.documentDetails.approved_status == 2) ||
      (props.documentDetails.developer_approval_status == 4 &&
        props.documentDetails.approved_status == 1 &&
        props.documentDetails.admin_approval_status == 2 &&
        props.documentDetails.status == 1)
    ) {
      if (designer === "" || designer === 0) {
        errorObj.designer = "Designer is Required";
        error = true;
      }
      if (designerPayment === "" || designerPayment === 0) {
        errorObj.designerPayment = "Amount is Required";
        error = true;
      }
      if (
        designerSubmissionDate === undefined ||
        designerSubmissionDate === ""
      ) {
        errorObj.designerSubmissionDate = "Submission Date is Required";
        error = true;
      }
      setErrors(errorObj);
      if (error) {
        return;
      }

      dispatch(globalLoadingAction(true));
      dispatch(
        assignToDesignerElibrarysData(
          state,
          moment(designerSubmissionDate).format("YYYY-MM-DD"),
          designerPayment,
          designer,
          props.history
        )
      );
    }
    clearData();
  }

  const clearData = () => {
    setDeveloper('');
    setPayment('');
    setSubmissionDate('');
    setContentValidator('');
    setContentValidatorSubmissionDate('');
    setContentValidatorPayment('');
    setLanguageValidator('');
    setLanguageValidatorSubmissionDate('');
    setLanguageValidatorPayment('');
    setdesigner('');
    setDesignerSubmissionDate('');
    setDesignerPayment('');
  }

  /* on change payment */
  const onPaymentHandler = (val) => {
    const regex = /^[0-9\b]+$/;
    if (val === "" || regex.test(val)) {
      setPayment(val);
    }
  };

  /* accept data handler */
  const acceptHandeler = (e) => {
    dispatch(globalLoadingAction(true));
    dispatch(acceptRejectUploadedContentData(state.id, e, props.history));
    // console.log("You press Accept button", e)
  };

  /* reject data handler */
  const rejectHandeler = (e) => {
    dispatch(globalLoadingAction(true));
    dispatch(acceptRejectUploadedContentData(state.id, e, props.history));
    // console.log("You press Reject button", e)
  };

  /* content validator payment handler */
  const onContentValidatorPaymentHandler = (val) => {
    const regex = /^[0-9\b]+$/;
    if (val === "" || regex.test(val)) {
      setContentValidatorPayment(val);
    }
  };

  /* Language Validator Payment Handler */
  const onLanguageValidatorPaymentHandler = (val) => {
    const regex = /^[0-9\b]+$/;
    if (val === "" || regex.test(val)) {
      setLanguageValidatorPayment(val);
    }
  };

  /* Designer Payment Handler */
  const ondesignerPaymentHandler = (val) => {
    const regex = /^[0-9\b]+$/;
    if (val === "" || regex.test(val)) {
      setDesignerPayment(val);
    }
  };

  /* image upload */
  const onImageHandler = (blobInfo) => {
    return new Promise((success, reject) => {
      const formData = new FormData();
      formData.append("content_image", blobInfo.blob());
      /* axios({
                url: GlobalConfigs.API_URL + Apis.POST_UPLOAD_IMAGE_API,
                method: "POST",
                headers: {
                    ContentType: "multipart/form-data",
                },
                data: formData,
            }).then((res) => {
                success(res.data.imagepath);
            }).then((json) => {
                console.log(json);
            }); */
    });
  };

  /* navigation go back with state value */
  const onGoBackHandler = () => {
    history.push({
      pathname: "/assign-elibrary-list",
      state: state.filterData,
    });
  };

  /* date change handler */
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  const getFilenameFromUrl = (url) =>
    url ? url.split("/").pop() : "No data found";

  const getConceptMapData = (() => {
    try {
      return JSON.parse(props.documentDetails?.concept_map_data) || [];
    } catch (error) {
      return [];
    }
  })();

  const copyHyperlinkPath = (item) => {
    if (item?.pdf_link) {
      navigator.clipboard.writeText(item.pdf_link);
    } else {
      navigator.clipboard.writeText(item.topic_heading + item.page_no);
    }
    utility.showSuccess("Link Copied Successfully");
  };

  const isDeveloper =
    (props.documentDetails?.developer_approval_status === 1 &&
      props.documentDetails?.approved_status === 1 &&
      props.documentDetails?.admin_approval_status !== 1) ||
    (props.documentDetails?.developer_approval_status === 1 &&
      props.documentDetails?.approved_status !== 1 &&
      props.documentDetails?.admin_approval_status === 0 &&
      props.documentDetails?.status === 0);

  const isValidator =
    (props.documentDetails?.developer_approval_status === 1 &&
      props.documentDetails?.approved_status === 1 &&
      props.documentDetails?.admin_approval_status === 1) ||
    (props.documentDetails?.developer_approval_status === 2 &&
      props.documentDetails?.approved_status === 1 &&
      props.documentDetails?.admin_approval_status !== 1) ||
    (props.documentDetails?.developer_approval_status === 2 &&
      props.documentDetails?.approved_status !== 1 &&
      props.documentDetails?.admin_approval_status === 0 &&
      props.documentDetails?.status === 0);

  const isLanguageValidator =
    (props.documentDetails?.developer_approval_status === 2 &&
      props.documentDetails?.approved_status === 1 &&
      props.documentDetails?.admin_approval_status === 1) ||
    (props.documentDetails?.developer_approval_status === 3 &&
      props.documentDetails?.admin_approval_status !== 1) ||
    (props.documentDetails?.developer_approval_status === 3 &&
      props.documentDetails?.approved_status === 0 &&
      props.documentDetails?.admin_approval_status === 0 &&
      props.documentDetails?.status === 0);

  const isDesigner =
    (props.documentDetails?.developer_approval_status === 3 &&
      props.documentDetails?.approved_status === 1 &&
      props.documentDetails?.admin_approval_status === 1) ||
    (props.documentDetails?.developer_approval_status === 4 &&
      props.documentDetails?.approved_status === 1 &&
      props.documentDetails?.admin_approval_status !== 1) ||
    (props.documentDetails?.developer_approval_status === 4 &&
      props.documentDetails?.approved_status !== 1 &&
      props.documentDetails?.admin_approval_status === 0 &&
      props.documentDetails?.status === 0);

      const previewConceptMap = () => {
        // let path = "https://crestestclv.s3.amazonaws.com/elibrary/library_pdf1686548725223.pdf";
        let path = props.documentDetails?.concept_map_pdf;
        history.push({
          pathname: "/elibrary-concept-map",
          //state: { conceptMapPath: props.documentDetails?.concept_map_pdf},
          state: { conceptMapPath: path},
        });
      };

  return (
    <>
      <div className="row">
        <div className="col-xl-6 col-xl-12">
          <div className="row">
            {console.log("Name:",designer,"Date:", designerSubmissionDate,"payment:",designerPayment)}
            <div className="col-xl-12">
              <div className="card pt-5 pb-5">
                <div className="col-xl-3">
                  <div className="back-arrow-container">
                    <Link onClick={onGoBackHandler}>
                      <i class="fa-solid fa-arrow-left"></i>
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-2"></div>
                  <div className="col-xl-8">
                    <div className="card-body">
                      <div className="basic-form">
                        <form onSubmit={onSubmit}>
                          <div className="form-group mb-3 two_column">
                            <div className="mb-2 one_column">
                              <h4>
                                <strong>Category :</strong>{" "}
                                {props.documentDetails.category}
                              </h4>
                            </div>
                            <div className="mb-2 one_column">
                              <h4>
                                <strong>Chapter :</strong>{" "}
                                {props.documentDetails.chapter_name}
                              </h4>
                            </div>
                          </div>
                          {props.documentDetails.exam_category_id == 1 ? (
                            <div className="form-group mb-3 two_column">
                              <div className="mb-2 one_column">
                                <h4>
                                  <strong>Board :</strong>{" "}
                                  {props.documentDetails.board_name}
                                </h4>
                              </div>
                              <div className="mb-2 one_column">
                                <h4>
                                  <strong>Class :</strong>{" "}
                                  {props.documentDetails.class_code}
                                </h4>
                              </div>
                            </div>
                          ) : (
                            <div className="form-group mb-3 two_column">
                              <div className="mb-2 one_column">
                                <h4>
                                  <strong>Exam Type :</strong>{" "}
                                  {props.documentDetails.type_name}
                                </h4>
                              </div>
                              <div className="mb-2 one_column">
                                {props.documentDetails?.class_code ? (
                                  <h4>
                                    <strong>Class :</strong>{" "}
                                    {props.documentDetails?.class_code}
                                  </h4>
                                ) : (
                                  <h4>
                                    <strong className="pe-4">Class :</strong>-
                                  </h4>
                                )}
                              </div>
                            </div>
                          )}
                          <div className="form-group mb-3 two_column">
                            <div className="mb-2 one_column">
                              <h4>
                                <strong>Subject :</strong>{" "}
                                {props.documentDetails.subject_name}
                              </h4>
                            </div>
                            <div className="mb-2 one_column">
                              <h4>
                                <strong>Type :</strong> {`Live`}
                              </h4>
                            </div>
                          </div>

                          {/* Developer */}
                          {isDeveloper ? (
                            <>
                              <div className="form-group mb-3">
                                <label className="mb-2">
                                  <strong>
                                    Content Developer
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <div className="basic-dropdown">
                                  <span className="drop_down_arrow">
                                    <i className="bi bi-caret-down-fill"></i>
                                  </span>

                                  <select
                                    defaultValue={"option"}
                                    className="form-control form-control"
                                    value={developer}
                                    onChange={(e) =>
                                      setDeveloper(e.target.value)
                                    }
                                    disabled={
                                      props.documentDetails
                                        .developer_approval_status == 1 &&
                                      props.documentDetails.approved_status ==
                                        1 &&
                                      props.documentDetails
                                        .admin_approval_status == 1
                                        ? true
                                        : props.documentDetails
                                            .developer_approval_status == 1 &&
                                          props.documentDetails
                                            .approved_status == 1 &&
                                          props.documentDetails
                                            .admin_approval_status == 0
                                        ? true
                                        : props.documentDetails
                                            .developer_approval_status == 2
                                        ? true
                                        : props.documentDetails
                                            .developer_approval_status == 3
                                        ? true
                                        : props.documentDetails
                                            .developer_approval_status == 4
                                        ? true
                                        : false
                                    }
                                  >
                                    <option value="">Select Developer</option>
                                    {props.developerlist.map((item, index) => {
                                      return (
                                        <option key={index} value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  {errors.developer && (
                                    <div className="text-danger fs-12">
                                      {errors.developer}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="form-group mb-3">
                                <label className="mb-2">
                                  <strong>
                                    Date of Submission
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <div className="date-control-view">
                                  <DatePicker
                                    ref={calendarRef}
                                    selected={submissionDate}
                                    placeholderText="Enter the Submission Date"
                                    onChange={(date) => setSubmissionDate(date)}
                                    showYearDropdown
                                    showMonthDropdown
                                    // minDate={new Date()}
                                    minDate={
                                      new Date(
                                        new Date().setDate(
                                          new Date().getDate() + 1
                                        )
                                      )
                                    }
                                    disabled={
                                      props.documentDetails
                                        .developer_approval_status == 1 &&
                                      props.documentDetails.approved_status ==
                                        1 &&
                                      props.documentDetails
                                        .admin_approval_status == 1
                                        ? true
                                        : props.documentDetails
                                            .developer_approval_status == 1 &&
                                          props.documentDetails
                                            .approved_status == 1 &&
                                          props.documentDetails
                                            .admin_approval_status == 0
                                        ? true
                                        : props.documentDetails
                                            .developer_approval_status == 2
                                        ? true
                                        : props.documentDetails
                                            .developer_approval_status == 3
                                        ? true
                                        : props.documentDetails
                                            .developer_approval_status == 4
                                        ? true
                                        : false
                                    }
                                    onChangeRaw={handleDateChangeRaw}
                                  />
                                  <button
                                    type="button"
                                    className="picker-btn"
                                    onClick={() =>
                                      calendarRef.current.setOpen(true)
                                    }
                                  >
                                    <i class="fa-solid fa-calendar-days"></i>
                                  </button>
                                </div>
                                {errors.date && (
                                  <div className="text-danger fs-12">
                                    {errors.date}
                                  </div>
                                )}
                              </div>

                              <div className="form-group mb-3">
                                <label className="mb-2">
                                  <strong>
                                    Amount<span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <input
                                  className="form-control placeHolder"
                                  placeholder="Enter the Amount"
                                  value={payment}
                                  onChange={(e) =>
                                    onPaymentHandler(e.target.value)
                                  }
                                  maxLength="5"
                                  disabled={
                                    props.documentDetails
                                      .developer_approval_status == 1 &&
                                    props.documentDetails.approved_status ==
                                      1 &&
                                    props.documentDetails
                                      .admin_approval_status == 1
                                      ? true
                                      : props.documentDetails
                                          .developer_approval_status == 1 &&
                                        props.documentDetails.approved_status ==
                                          1 &&
                                        props.documentDetails
                                          .admin_approval_status == 0
                                      ? true
                                      : props.documentDetails
                                          .developer_approval_status == 2
                                      ? true
                                      : props.documentDetails
                                          .developer_approval_status == 3
                                      ? true
                                      : props.documentDetails
                                          .developer_approval_status == 4
                                      ? true
                                      : false
                                  }
                                />
                                {errors.payment && (
                                  <div className="text-danger fs-12">
                                    {errors.payment}
                                  </div>
                                )}
                              </div>
                            </>
                          ) : null}
                          {/* End Developer */}

                          {/* Content Validator */}
                          {isValidator ? (
                            <>
                              <div
                                className={`form-group mb-3 ${
                                  props.documentDetails
                                    .developer_approval_status == 1 &&
                                  props.documentDetails.approved_status == 1 &&
                                  props.documentDetails.admin_approval_status ==
                                    1
                                    ? ""
                                    : props.documentDetails
                                        .developer_approval_status == 2
                                    ? ""
                                    : props.documentDetails
                                        .developer_approval_status == 3
                                    ? ""
                                    : props.documentDetails
                                        .developer_approval_status == 4
                                    ? ""
                                    : "item_disable"
                                }`}
                              >
                                <label className="mb-2">
                                  <strong>
                                    Content Validator
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <div className="basic-dropdown">
                                  <span className="drop_down_arrow">
                                    <i className="bi bi-caret-down-fill"></i>
                                  </span>

                                  <select
                                    defaultValue={"option"}
                                    className="form-control form-control"
                                    value={contentValidator}
                                    onChange={(e) =>
                                      setContentValidator(e.target.value)
                                    }
                                    disabled={
                                      props.documentDetails
                                        .developer_approval_status == 1 &&
                                      props.documentDetails.approved_status ==
                                        1 &&
                                      props.documentDetails
                                        .admin_approval_status == 1
                                        ? false
                                        : props.documentDetails
                                            .developer_approval_status == 2 &&
                                          props.documentDetails
                                            .approved_status != 1
                                        ? false
                                        : props.documentDetails
                                            .developer_approval_status == 2 &&
                                          props.documentDetails
                                            .admin_approval_status == 2
                                        ? false
                                        : props.documentDetails
                                            .developer_approval_status == 3
                                        ? true
                                        : props.documentDetails
                                            .developer_approval_status == 4
                                        ? true
                                        : true
                                    }
                                  >
                                    <option value="">
                                      Select Content Validator
                                    </option>
                                    {props.contentvalidatorList.map(
                                      (item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                  {errors.contentValidator && (
                                    <div className="text-danger fs-12">
                                      {errors.contentValidator}
                                    </div>
                                  )}
                                </div>
                              </div>

                              {(props.documentDetails
                                .developer_approval_status == 1 &&
                                props.documentDetails.approved_status == 1 &&
                                props.documentDetails.admin_approval_status ==
                                  1) ||
                              props.documentDetails.developer_approval_status ==
                                2 ||
                              props.documentDetails.developer_approval_status ==
                                3 ||
                              props.documentDetails.developer_approval_status ==
                                4 ? (
                                <>
                                  <div className="form-group mb-3">
                                    <label className="mb-2">
                                      <strong>
                                        Time Limit
                                        <span className="text-danger">*</span>
                                      </strong>
                                    </label>
                                    <div className="date-control-view">
                                      <DatePicker
                                        ref={contentValidatorCalendarRef}
                                        selected={
                                          contentValidatorSubmissionDate
                                        }
                                        placeholderText="Enter the Submission Date"
                                        onChange={(date) =>
                                          setContentValidatorSubmissionDate(
                                            date
                                          )
                                        }
                                        showYearDropdown
                                        showMonthDropdown
                                        // minDate={new Date()}
                                        minDate={
                                          new Date(
                                            new Date().setDate(
                                              new Date().getDate() + 1
                                            )
                                          )
                                        }
                                        disabled={
                                          props.documentDetails
                                            .developer_approval_status == 1 &&
                                          props.documentDetails
                                            .approved_status == 1 &&
                                          props.documentDetails
                                            .admin_approval_status == 1
                                            ? false
                                            : props.documentDetails
                                                .developer_approval_status ==
                                                2 &&
                                              props.documentDetails
                                                .approved_status != 1
                                            ? false
                                            : props.documentDetails
                                                .developer_approval_status ==
                                                2 &&
                                              props.documentDetails
                                                .admin_approval_status == 2
                                            ? false
                                            : props.documentDetails
                                                .developer_approval_status == 3
                                            ? true
                                            : true
                                        }
                                        onChangeRaw={handleDateChangeRaw}
                                      />
                                      <button
                                        type="button"
                                        className="picker-btn"
                                        onClick={() =>
                                          contentValidatorCalendarRef.current.setOpen(
                                            true
                                          )
                                        }
                                      >
                                        <i class="fa-solid fa-calendar-days"></i>
                                      </button>
                                    </div>
                                    {errors.contentValidatorSubmissionDate && (
                                      <div className="text-danger fs-12">
                                        {errors.contentValidatorSubmissionDate}
                                      </div>
                                    )}
                                  </div>

                                  <div className="form-group mb-3">
                                    <label className="mb-2">
                                      <strong>
                                        Amount
                                        <span className="text-danger">*</span>
                                      </strong>
                                    </label>
                                    <input
                                      className="form-control placeHolder"
                                      placeholder="Enter the Amount"
                                      value={contentValidatorPayment}
                                      onChange={(e) =>
                                        onContentValidatorPaymentHandler(
                                          e.target.value
                                        )
                                      }
                                      maxLength="5"
                                      disabled={
                                        props.documentDetails
                                          .developer_approval_status == 1 &&
                                        props.documentDetails.approved_status ==
                                          1 &&
                                        props.documentDetails
                                          .admin_approval_status == 1
                                          ? false
                                          : props.documentDetails
                                              .developer_approval_status == 2 &&
                                            props.documentDetails
                                              .approved_status != 1
                                          ? false
                                          : props.documentDetails
                                              .developer_approval_status == 2 &&
                                            props.documentDetails
                                              .admin_approval_status == 2
                                          ? false
                                          : props.documentDetails
                                              .developer_approval_status == 3
                                          ? true
                                          : true
                                      }
                                    />
                                    {errors.contentValidatorPayment && (
                                      <div className="text-danger fs-12">
                                        {errors.contentValidatorPayment}
                                      </div>
                                    )}
                                  </div>
                                </>
                              ) : null}
                            </>
                          ) : null}
                          {/* End Content Validator */}

                          {/* Language Validator */}
                          {isLanguageValidator ? (
                            <>
                              <div
                                className={`form-group mb-3 ${
                                  props.documentDetails
                                    .developer_approval_status == 1 &&
                                  props.documentDetails.approved_status == 1 &&
                                  props.documentDetails.admin_approval_status ==
                                    1 &&
                                  props.documentDetails.status != 1
                                    ? ""
                                    : props.documentDetails
                                        .developer_approval_status == 2 &&
                                      props.documentDetails.approved_status ==
                                        1 &&
                                      props.documentDetails
                                        .admin_approval_status == 0 &&
                                      props.documentDetails.status == 1
                                    ? "item_disable"
                                    : props.documentDetails
                                        .developer_approval_status == 2 &&
                                      props.documentDetails.approved_status ==
                                        1 &&
                                      props.documentDetails
                                        .admin_approval_status == 0 &&
                                      props.documentDetails.status == 0
                                    ? "item_disable"
                                    : props.documentDetails
                                        .developer_approval_status == 2 &&
                                      props.documentDetails.approved_status ==
                                        1 &&
                                      props.documentDetails
                                        .admin_approval_status == 1 &&
                                      props.documentDetails.status == 1
                                    ? ""
                                    : props.documentDetails
                                        .developer_approval_status == 3 &&
                                      props.documentDetails.approved_status ==
                                        1 &&
                                      props.documentDetails.approved_status != 0
                                    ? ""
                                    : props.documentDetails
                                        .developer_approval_status == 3 &&
                                      props.documentDetails.approved_status ==
                                        0 &&
                                      props.documentDetails.approved_status ==
                                        0 &&
                                      props.documentDetails.status == 0
                                    ? ""
                                    : props.documentDetails
                                        .developer_approval_status == 4
                                    ? ""
                                    : props.documentDetails
                                        .developer_approval_status == 3 &&
                                      props.documentDetails.approved_status == 2
                                    ? ""
                                    : "item_disable"
                                }`}
                              >
                                <label className="mb-2">
                                  <strong>
                                    Language Validator
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <div className="basic-dropdown">
                                  <span className="drop_down_arrow">
                                    <i className="bi bi-caret-down-fill"></i>
                                  </span>

                                  <select
                                    defaultValue={"option"}
                                    className="form-control form-control"
                                    value={languageValidator}
                                    onChange={(e) =>
                                      setLanguageValidator(e.target.value)
                                    }
                                    disabled={
                                      props.documentDetails
                                        .developer_approval_status == 1 ||
                                      props.documentDetails
                                        .developer_approval_status == 2
                                        ? false
                                        : props.documentDetails
                                            .developer_approval_status == 3 &&
                                          props.documentDetails
                                            .admin_approval_status != 1 &&
                                          props.documentDetails
                                            .approved_status != 1
                                        ? false
                                        : props.documentDetails
                                            .developer_approval_status == 3 &&
                                          props.documentDetails
                                            .admin_approval_status == 2 &&
                                          props.documentDetails
                                            .approved_status == 1 &&
                                          props.documentDetails.status == 1
                                        ? false
                                        : true
                                    }
                                  >
                                    <option value="">
                                      Select Language Validator
                                    </option>
                                    {props.languagevalidatorList.map(
                                      (item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                  {errors.languageValidator && (
                                    <div className="text-danger fs-12">
                                      {errors.languageValidator}
                                    </div>
                                  )}
                                </div>
                              </div>

                              {(props.documentDetails
                                .developer_approval_status == 1 &&
                                props.documentDetails.approved_status == 1 &&
                                props.documentDetails.admin_approval_status ==
                                  1 &&
                                props.documentDetails.status != 1) ||
                              (props.documentDetails
                                .developer_approval_status == 2 &&
                                props.documentDetails.approved_status == 1 &&
                                props.documentDetails.admin_approval_status ==
                                  1 &&
                                props.documentDetails.status == 1) ||
                              props.documentDetails.developer_approval_status ==
                                3 ||
                              props.documentDetails.developer_approval_status ==
                                4 ? (
                                <>
                                  <div className="form-group mb-3">
                                    <label className="mb-2">
                                      <strong>
                                        Time Limit
                                        <span className="text-danger">*</span>
                                      </strong>
                                    </label>
                                    <div className="date-control-view">
                                      <DatePicker
                                        ref={languageValidatorCalendarRef}
                                        selected={
                                          languageValidatorSubmissionDate
                                        }
                                        placeholderText="Enter the Submission Date"
                                        onChange={(date) =>
                                          setLanguageValidatorSubmissionDate(
                                            date
                                          )
                                        }
                                        showYearDropdown
                                        showMonthDropdown
                                        // minDate={new Date()}
                                        minDate={
                                          new Date(
                                            new Date().setDate(
                                              new Date().getDate() + 1
                                            )
                                          )
                                        }
                                        disabled={
                                          props.documentDetails
                                            .developer_approval_status == 1 ||
                                          (props.documentDetails
                                            .developer_approval_status == 2 &&
                                            props.documentDetails
                                              .approved_status == 1)
                                            ? false
                                            : props.documentDetails
                                                .developer_approval_status ==
                                                3 &&
                                              props.documentDetails
                                                .admin_approval_status != 1 &&
                                              props.documentDetails
                                                .approved_status != 1
                                            ? false
                                            : props.documentDetails
                                                .developer_approval_status ==
                                                3 &&
                                              props.documentDetails
                                                .admin_approval_status == 2 &&
                                              props.documentDetails
                                                .approved_status == 1 &&
                                              props.documentDetails.status == 1
                                            ? false
                                            : true
                                        }
                                        onChangeRaw={handleDateChangeRaw}
                                      />
                                      <button
                                        type="button"
                                        className="picker-btn"
                                        onClick={() =>
                                          languageValidatorCalendarRef.current.setOpen(
                                            true
                                          )
                                        }
                                      >
                                        <i class="fa-solid fa-calendar-days"></i>
                                      </button>
                                    </div>
                                    {errors.languageValidatorSubmissionDate && (
                                      <div className="text-danger fs-12">
                                        {errors.languageValidatorSubmissionDate}
                                      </div>
                                    )}
                                  </div>

                                  <div className="form-group mb-3">
                                    <label className="mb-2">
                                      <strong>
                                        Amount
                                        <span className="text-danger">*</span>
                                      </strong>
                                    </label>
                                    <input
                                      className="form-control placeHolder"
                                      placeholder="Enter the Amount"
                                      value={languageValidatorPayment}
                                      onChange={(e) =>
                                        onLanguageValidatorPaymentHandler(
                                          e.target.value
                                        )
                                      }
                                      maxLength="5"
                                      disabled={
                                        props.documentDetails
                                          .developer_approval_status == 1 ||
                                        (props.documentDetails
                                          .developer_approval_status == 2 &&
                                          props.documentDetails
                                            .approved_status == 1)
                                          ? false
                                          : props.documentDetails
                                              .developer_approval_status == 3 &&
                                            props.documentDetails
                                              .admin_approval_status != 1 &&
                                            props.documentDetails
                                              .approved_status != 1
                                          ? false
                                          : props.documentDetails
                                              .developer_approval_status == 3 &&
                                            props.documentDetails
                                              .admin_approval_status == 2 &&
                                            props.documentDetails
                                              .approved_status == 1 &&
                                            props.documentDetails.status == 1
                                          ? false
                                          : true
                                      }
                                    />
                                    {errors.languageValidatorPayment && (
                                      <div className="text-danger fs-12">
                                        {errors.languageValidatorPayment}
                                      </div>
                                    )}
                                  </div>
                                </>
                              ) : null}
                            </>
                          ) : null}
                          {/* End Language Validator */}

                          {/* Designer */}
                          {isDesigner ? (
                            <>
                              <div
                                className={`form-group mb-3 ${
                                  props.documentDetails
                                    .developer_approval_status == 1 &&
                                  props.documentDetails.approved_status == 1 &&
                                  props.documentDetails.admin_approval_status ==
                                    1 &&
                                  props.documentDetails.status != 1
                                    ? ""
                                    : props.documentDetails
                                        .developer_approval_status == 2 &&
                                      props.documentDetails.approved_status ==
                                        1 &&
                                      props.documentDetails
                                        .admin_approval_status == 0 &&
                                      props.documentDetails.status == 1
                                    ? "item_disable"
                                    : props.documentDetails
                                        .developer_approval_status == 2 &&
                                      props.documentDetails.approved_status ==
                                        1 &&
                                      props.documentDetails
                                        .admin_approval_status == 0 &&
                                      props.documentDetails.status == 0
                                    ? "item_disable"
                                    : props.documentDetails
                                        .developer_approval_status == 3 &&
                                      props.documentDetails
                                        .admin_approval_status == 0
                                    ? "item_disable"
                                    : props.documentDetails
                                        .developer_approval_status == 3 &&
                                      props.documentDetails
                                        .admin_approval_status == 1
                                    ? ""
                                    : props.documentDetails
                                        .developer_approval_status == 4
                                    ? ""
                                    : "item_disable"
                                }`}
                              >
                                <label className="mb-2">
                                  <strong>
                                    Designer
                                    <span className="text-danger">*</span>
                                  </strong>
                                </label>
                                <div className="basic-dropdown">
                                  <span className="drop_down_arrow">
                                    <i className="bi bi-caret-down-fill"></i>
                                  </span>
                                  <select
                                    defaultValue={"option"}
                                    className="form-control form-control"
                                    value={designer}
                                    onChange={(e) =>
                                      setdesigner(e.target.value)
                                    }
                                    disabled={
                                      props.documentDetails
                                        .developer_approval_status == 1 ||
                                      props.documentDetails
                                        .developer_approval_status == 2 ||
                                      (props.documentDetails
                                        .developer_approval_status == 3 &&
                                        props.documentDetails
                                          .admin_approval_status != 1 &&
                                        props.documentDetails.approved_status ==
                                          1) ||
                                      (props.documentDetails
                                        .developer_approval_status == 4 &&
                                        props.documentDetails.approved_status ==
                                          1 &&
                                        props.documentDetails
                                          .admin_approval_status != 2)
                                        ? true
                                        : false
                                    }
                                  >
                                    <option value="">Select Designer</option>
                                    {props.contentdesignerList.map(
                                      (item, index) => {
                                        return (
                                          <option key={index} value={item.id}>
                                            {item.name}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                  {errors.designer && (
                                    <div className="text-danger fs-12">
                                      {errors.designer}
                                    </div>
                                  )}
                                </div>
                              </div>

                              {props.documentDetails
                                .developer_approval_status == 4 ||
                              (props.documentDetails
                                .developer_approval_status == 3 &&
                                props.documentDetails.approved_status == 1 &&
                                props.documentDetails.admin_approval_status ==
                                  1 &&
                                props.documentDetails.status == 1) ? (
                                <>
                                  <div className="form-group mb-3">
                                    <label className="mb-2">
                                      <strong>
                                        Time Limit
                                        <span className="text-danger">*</span>
                                      </strong>
                                    </label>
                                    <div className="date-control-view">
                                      <DatePicker
                                        ref={designerCalendarRef}
                                        selected={designerSubmissionDate}
                                        placeholderText="Enter the Submission Date"
                                        onChange={(date) =>
                                          setDesignerSubmissionDate(date)
                                        }
                                        showYearDropdown
                                        showMonthDropdown
                                        // minDate={new Date()}
                                        minDate={
                                          new Date(
                                            new Date().setDate(
                                              new Date().getDate() + 1
                                            )
                                          )
                                        }
                                        disabled={
                                          props.documentDetails
                                            .developer_approval_status == 1 ||
                                          props.documentDetails
                                            .developer_approval_status == 2 ||
                                          (props.documentDetails
                                            .developer_approval_status == 3 &&
                                            props.documentDetails
                                              .admin_approval_status != 1 &&
                                            props.documentDetails
                                              .approved_status == 1) ||
                                          (props.documentDetails
                                            .developer_approval_status == 4 &&
                                            props.documentDetails
                                              .approved_status == 1 &&
                                            props.documentDetails
                                              .admin_approval_status != 2)
                                            ? true
                                            : false
                                        }
                                        onChangeRaw={handleDateChangeRaw}
                                      />
                                      <button
                                        type="button"
                                        className="picker-btn"
                                        onClick={() =>
                                          contentValidatorCalendarRef.current.setOpen(
                                            true
                                          )
                                        }
                                      >
                                        <i class="fa-solid fa-calendar-days"></i>
                                      </button>
                                    </div>
                                    {errors.designerSubmissionDate && (
                                      <div className="text-danger fs-12">
                                        {errors.designerSubmissionDate}
                                      </div>
                                    )}
                                  </div>

                                  <div className="form-group mb-3">
                                    <label className="mb-2">
                                      <strong>
                                        Amount
                                        <span className="text-danger">*</span>
                                      </strong>
                                    </label>
                                    <input
                                      className="form-control placeHolder"
                                      placeholder="Enter the Amount"
                                      value={designerPayment}
                                      onChange={(e) =>
                                        ondesignerPaymentHandler(e.target.value)
                                      }
                                      maxLength="5"
                                      disabled={
                                        props.documentDetails
                                          .developer_approval_status == 1 ||
                                        (props.documentDetails
                                          .developer_approval_status == 2 &&
                                          props.documentDetails
                                            .approved_status == 1) ||
                                        (props.documentDetails
                                          .developer_approval_status == 3 &&
                                          props.documentDetails
                                            .admin_approval_status != 1 &&
                                          props.documentDetails
                                            .approved_status == 1) ||
                                        (props.documentDetails
                                          .developer_approval_status == 4 &&
                                          props.documentDetails
                                            .approved_status == 1 &&
                                          props.documentDetails
                                            .admin_approval_status != 2)
                                          ? true
                                          : false
                                      }
                                    />
                                    {errors.designerPayment && (
                                      <div className="text-danger fs-12">
                                        {errors.designerPayment}
                                      </div>
                                    )}
                                  </div>
                                </>
                              ) : null}
                            </>
                          ) : null}
                          {/* End Designer */}

                          <div className={`form-group mb-3`}>
                            <label className="mb-2">
                              <strong>
                                Write Up
                              </strong>
                            </label>
                            <div className="ck-editer-view">
                              <Editor
                                apiKey={GlobalConfigs.TINY_API_KEY}
                                initialValue={props.documentDetails.content_data}
                                onInit={(evt, editor) => {
                                  //setText(editor.getContent({ format: 'text' }));
                                }}
                                onEditorChange={(newValue, editor) => {
                                  setContent(newValue);
                                  //setText(editor.getContent({ format: 'text' }));
                                }}
                                //initialValue="<p>This is the initial content of the editor.</p>"
                                init={{
                                  height: 400,
                                  menubar: true,
                                  image_advtab: true,
                                  external_plugins: {
                                    tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
                                  },
                                  images_upload_handler: onImageHandler,
                                  plugins: [
                                    "advlist",
                                    "autolink",
                                    "lists",
                                    "link",
                                    "image",
                                    "charmap",
                                    "print",
                                    "preview anchor",
                                    "searchreplace",
                                    "visualblocks",
                                    "code",
                                    "fullscreen",
                                    "insertdatetime",
                                    "media",
                                    "table",
                                    "paste",
                                    "code",
                                    "pagebreak",
                                    "help",
                                    "wordcount",
                                  ],
                                  toolbar:
                                    "undo redo | formatselect | " +
                                    "bold italic backcolor | alignleft aligncenter " +
                                    "alignright alignjustify | bullist numlist outdent indent | " +
                                    "removeformat |pagebreak| help | image |" +
                                    "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry",
                                  setup: (editor) => {
                                    editor.ui.registry.addButton("pagebreak", {
                                      text: "Page Break",
                                      onAction: function (_) {
                                        editor.execCommand(
                                          "mceInsertContent",
                                          false,
                                          '<p style="page-break-before:always;"></p>'
                                        );
                                      },
                                    });
                                  },
                                  content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                }}
                              />
                            </div>
                            {errors.details && (
                              <div className="text-danger fs-12">
                                {errors.details}
                              </div>
                            )}
                          </div>

                          {props.documentDetails?.developer_approval_status ==
                          4 ? null : (
                            <>
                              <div
                                className={`mb-3 position-relative ${
                                  props.documentDetails?.concept_map
                                    ? null
                                    : "item_disable"
                                }`}
                              >
                                <label
                                  htmlFor="formFileDisabled"
                                  className="form-label file-form-label"
                                >
                                  Concept Map (Before Design)
                                </label>
                                <div
                                  className="icon_right cursorPointer"
                                  onClick={() =>
                                    handleDownloaConceptMapFile(
                                      props.documentDetails?.concept_map
                                    )
                                  }
                                >
                                  <i
                                    class="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div
                                  ref={fileRef}
                                  className="form-control"
                                  type="file"
                                  id="formFileDisabled"
                                  onClick={() =>
                                    handleDownloaConceptMapFile(
                                      props.documentDetails?.concept_map
                                    )
                                  }
                                >
                                  <strong>
                                    {getFilenameFromUrl(
                                      props.documentDetails?.concept_map
                                    )}
                                  </strong>
                                </div>
                              </div>

                              <div
                                className={`mb-3 position-relative ${
                                  props.documentDetails.hyperlink
                                    ? null
                                    : "item_disable"
                                }`}
                              >
                                <label
                                  htmlFor="formFileDisabled"
                                  className="form-label file-form-label"
                                >
                                  Hyperlink
                                </label>
                                <div
                                  className="icon_right cursorPointer"
                                  onClick={() =>
                                    handleDownloaConceptMapFile(
                                      props.documentDetails?.hyperlink
                                    )
                                  }
                                >
                                  <i
                                    class="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                </div>
                                <div
                                  ref={fileRef}
                                  className="form-control"
                                  type="file"
                                  id="formFileDisabled"
                                  onClick={() =>
                                    handleDownloaConceptMapFile(
                                      props.documentDetails?.hyperlink
                                    )
                                  }
                                >
                                  <strong>
                                    {getFilenameFromUrl(
                                      props.documentDetails?.hyperlink
                                    )}
                                  </strong>
                                </div>
                              </div>

                              {getConceptMapData.length > 0 ? (
                                <div>
                                  <h4 className="text-center">OR</h4>
                                </div>
                              ) : null}
                            </>
                          )}
                          
                          {getConceptMapData.map((item, index) => (
                            <div className="basic-details-item mt-2 path_container">
                              <div className="pdf_left_side">
                                <label>Hyperlink Path: </label>{" "}
                                {item.topic_heading} ={" "}
                                <input
                                  disabled
                                  type="text"
                                  className="elibrary_pdf_page_input"
                                  placeholder="page no."
                                  value={item.page_no}
                                  name="pdfpageno"
                                  autoComplete="off"
                                />
                              </div>

                              <div className="pdf_right_side">
                                <button
                                  type="button"
                                  className="excel-copyright"
                                  onClick={() => copyHyperlinkPath(item)}
                                >
                                  <i className="bi bi-bookmarks"></i>
                                </button>
                              </div>
                            </div>
                          ))}

                          {props.documentDetails?.citation_no ? (
                            <div className="form-group mb-3 two_column">
                              <label className="form-label file-form-label">
                                No of Citation :{" "}
                                {props.documentDetails?.citation_no}
                              </label>
                            </div>
                          ) : null}
                          <div
                            className={`mb-3 position-relative ${
                              props.documentDetails.developer_approval_status ==
                                4 &&
                              props.documentDetails.approved_status == 1 &&
                              props.documentDetails.status == 1
                                ? null
                                : "item_disable"
                            }`}
                          >
                            <label
                              htmlFor="formFileDisabled"
                              className="form-label file-form-label"
                            >
                              Concept Map
                            </label>
                            <div
                              className="icon_right cursorPointer"
                              onClick={() =>
                                handleDownloaWriteupFile(
                                  props.documentDetails?.concept_map_pdf
                                )
                              }
                            >
                              <i class="fa fa-download" aria-hidden="true"></i>
                            </div>
                            <div
                              ref={fileRef}
                              className="form-control"
                              type="file"
                              id="formFileDisabled"
                              onClick={() =>
                                handleDownloaWriteupFile(
                                  props.documentDetails?.concept_map_pdf
                                )
                              }
                            >
                              <strong>
                                {getFilenameFromUrl(
                                  props.documentDetails?.concept_map_pdf
                                )}
                              </strong>
                            </div>
                          </div>

                          <div
                            className={`mb-3 position-relative ${
                              props.documentDetails.developer_approval_status ==
                                4 &&
                              props.documentDetails.approved_status == 1 &&
                              props.documentDetails.status == 1
                                ? null
                                : "item_disable"
                            }`}
                          >
                            <label
                              htmlFor="formFileDisabled"
                              className="form-label file-form-label"
                            >
                              Upload Write Up File
                            </label>
                            <div
                              className="icon_right cursorPointer"
                              onClick={() =>
                                handleDownloaWriteupFile(
                                  props.documentDetails?.write_up_pdf
                                )
                              }
                            >
                              <i class="fa fa-download" aria-hidden="true"></i>
                            </div>
                            <div
                              ref={fileRef}
                              className="form-control"
                              type="file"
                              id="formFileDisabled"
                              onClick={() =>
                                handleDownloaWriteupFile(
                                  props.documentDetails?.write_up_pdf
                                )
                              }
                            >
                              <strong>
                                {getFilenameFromUrl(
                                  props.documentDetails?.write_up_pdf
                                )}
                              </strong>
                            </div>
                          </div>
                          {(props.documentDetails.developer_approval_status ==
                            1 &&
                            props.documentDetails.approved_status == 1 &&
                            props.documentDetails.admin_approval_status == 0 &&
                            props.documentDetails.status == 1) ||
                          (props.documentDetails.developer_approval_status ==
                            2 &&
                            props.documentDetails.admin_approval_status == 0 &&
                            props.documentDetails.status == 1) ||
                          (props.documentDetails.developer_approval_status ==
                            3 &&
                            props.documentDetails.status == 1 &&
                            props.documentDetails.admin_approval_status == 0 &&
                            props.documentDetails.approved_status == 1) ||
                          (props.documentDetails.developer_approval_status ==
                            4 &&
                            props.documentDetails.approved_status == 1 &&
                            props.documentDetails.admin_approval_status == 0 &&
                            props.documentDetails.status == 1) ? (
                            <div className="accept_reject_button_container">
                              <div
                                className="accept_reject_btn accept_btn_background"
                                onClick={() => acceptHandeler(1)}
                              >
                                Accept
                              </div>
                              <div
                                className="accept_reject_btn reject_btn_background"
                                onClick={() => rejectHandeler(2)}
                              >
                                Reject
                              </div>
                              {isDesigner?
                              <div
                                className="accept_reject_btn preview_btn_background"
                                onClick={() => previewConceptMap()}
                              >
                                Preview
                              </div>
                              :null}
                            </div>
                          ) : null}

                          <div
                            className={`text-center mt-4 ${
                              props.documentDetails.developer_approval_status ==
                                1 &&
                              props.documentDetails.approved_status == 1 &&
                              props.documentDetails.admin_approval_status ==
                                0 &&
                              props.documentDetails.status == 0
                                ? "item_disable"
                                : props.documentDetails
                                    .developer_approval_status == 1 &&
                                  props.documentDetails.approved_status == 1 &&
                                  props.documentDetails.admin_approval_status ==
                                    0 &&
                                  props.documentDetails.status == 1
                                ? "item_disable"
                                : props.documentDetails
                                    .developer_approval_status == 2 &&
                                  props.documentDetails.status == 1 &&
                                  props.documentDetails.admin_approval_status ==
                                    0 &&
                                  props.documentDetails.approved_status == 1
                                ? "item_disable"
                                : props.documentDetails
                                    .developer_approval_status == 2 &&
                                  props.documentDetails.status == 0 &&
                                  props.documentDetails.admin_approval_status ==
                                    0 &&
                                  props.documentDetails.approved_status == 1
                                ? "item_disable"
                                : props.documentDetails
                                    .developer_approval_status == 3 &&
                                  props.documentDetails.approved_status == 1 &&
                                  props.documentDetails.admin_approval_status ==
                                    0
                                ? "item_disable"
                                : props.documentDetails
                                    .developer_approval_status == 4 &&
                                  props.documentDetails.approved_status == 1 &&
                                  props.documentDetails.admin_approval_status !=
                                    2
                                ? "item_disable"
                                : null
                            }`}
                          >
                            <button
                              type="submit"
                              className="btn btn-primary btn-block category-btn"
                              disabled={props.showLoading}
                            >
                              Assign
                              {props.showLoading ? (
                                <Spinner
                                  as="span"
                                  animation="border"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                              ) : null}
                            </button>
                          </div>
                          {/* props.documentDetails.developer_approval_status == 1 && props.documentDetails.approved_status == 0 && props.documentDetails.admin_approval_status == 0 */}
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Concept Map</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Array.isArray(conceptMapData) && conceptMapData.length !== 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Topic Heading</th>
                  <th>Page No</th>
                </tr>
              </thead>
              <tbody>
                {conceptMapData.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.topic_heading ? item?.topic_heading : "NA"}</td>
                    <td>{item?.page_no ? item?.page_no : "NA"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <h3>No Data Found</h3>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    showLoading: state.assign.showLoading,
    branchList: state.branch.branchList,
    chapterList: state.chapter.typelist,
    showLoadingChapter: state.chapter.showLoading,
    classList: state.class.list,
    boardList: state.board.list,
    categoryList: state.category.list,
    typeList: state.type.list,
    patternList: state.pattern.list,
    developerlist: state.user.contentDeveloperList,
    subjectList: state.subject.subjectList,
    documentDetails: state.assign.documentDetails,
    contentvalidatorList: state.contentvalidator.list,
    languagevalidatorList: state.languagevalidator.list,
    contentdesignerList: state.contentdesigner.list,
  };
};
export default connect(mapStateToProps)(AssignDeveloper);
