import React, { Fragment, useContext, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import { Link } from 'react-router-dom';
import swal from "sweetalert";
import Spinner from 'react-bootstrap/Spinner';
import {
	Row,
	Col,
	Card,
	Table,
	Button,
	Tooltip,
	OverlayTrigger,
} from "react-bootstrap";

import { getCompetitiveExamDetails, removeCompetitiveExamDetails, updateCompetitiveExamDetailsStatus, competitiveExamDetailsTypeAction, competitiveExamDetailsClassAction, competitiveExamDetailsSubjectAction, competitiveExamDetailsAccademicSessionAction, competitiveExamDetailsStausAction, competitiveExamDetailsSubTypeAction } from '../../../store/actions/ExamDetailsAction';
import { globalLoadingAction } from '../../../store/actions/GlobalAction';

import { getClassData, loadingClassAction } from '../../../store/actions/ClassAction';
import { getNonGroupSubjectsList, subjectLoading, clearSubjectList } from '../../../store/actions/SujectActions';
import { getExamType, loadingAction } from '../../../store/actions/ExamTypeAction';
import { getExamSubType, loadingSubTypeAction } from '../../../store/actions/ExamSubTypeAction';

const AWS = require('aws-sdk');
AWS.config.update({
	region: process.env.REACT_APP_S3_BUCKET_REGION,
	accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
	secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY
});

const s3 = new AWS.S3({ apiVersion: '2006-03-01' });

const CompetitiveExamDetailsList = (props) => {

	const dispatch = useDispatch();
	const [permission, setPermission] = useState("");

	const [board, setBoard] = useState('');

	const previousFilterData = props?.location.state;
	const [type, setType] = useState(previousFilterData?.type ?? '');

	const [subType, setSubType] = useState(previousFilterData?.subType ?? '');
	const [subject, setSubject] = useState(previousFilterData?.subject ?? '');

	const [academicSession, setAcademicSession] = useState(previousFilterData?.academicSession ?? '');
	const [contentStatus, setContentStatus] = useState(previousFilterData?.contentStatus ?? '');
	const [classRoom, setClassRoom] = useState(previousFilterData?.classRoom ?? '');

	useEffect(() => {
		/* ---- */
		dispatch(loadingClassAction(true));
		dispatch(getClassData(props.history));

		dispatch(loadingAction(true));
		dispatch(getExamType(props.history));
		/* ---- */
		permissionCheck();
		// dispatch(globalLoadingAction(true));
		// dispatch(getCompetitiveExamDetails(props.history));
	}, []);

	useEffect(() => {
		dispatch(globalLoadingAction(true));
		dispatch(getCompetitiveExamDetails(academicSession, contentStatus, type, classRoom, subType, props.history));
	}, [classRoom, academicSession, contentStatus, subject, type, subType]);


	useEffect(() => {
		onChangeClassHandler();
		getSubjectHandler();
		getSubTypeHandler();
	}, [previousFilterData]);

	/* confirmation delete */
	const onDeleteModal = (i) => {
		swal({
			title: "Are you sure?",
			text:
				"You want to remove this item !",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((willDelete) => {
			if (willDelete) {
				dispatch(removeCompetitiveExamDetails(i, props.history));
			}
		})
	}

	/* change status */
	const onUpdateStatus = (item) => {
		dispatch(globalLoadingAction(true));
		dispatch(updateCompetitiveExamDetailsStatus(item, props.history));
	}

	/* as3 pdf download*/
	const onDonwloadHandeler = (item) => {
		getPdfContentFromAWSS3BucketPrivately(item.syllabus_file_path)
		// console.log("onDonwloadHandeler----", item)
		// window.location.href = item.syllabus_file_path
		// using Java Script method to get PDF file
		/* fetch(item.syllabus_file_path).then(response => {
			response.blob().then(blob => {
				// Creating new object of PDF file
				const fileURL = window.URL.createObjectURL(blob);
				// Setting various property values
				let alink = document.createElement('a');
				alink.href = fileURL;
				alink.download = 'Syllabus.pdf';
				alink.click();
			})
		}) */

	}

	/* radio button change */
	const renderTooltip = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			Donwload Syllabus
		</Tooltip>
	);

	const permissionCheck = () => {
		const userDetails = localStorage.getItem("userDetails");
		const tokenDetails = JSON.parse(userDetails);
		const menuList = tokenDetails.menu_list;
		menuList.map((item) => {
			if (item.id == 45) {
				setPermission(item);
			}
		});
	};

	/* get subject list */
	const getSubjectHandler = () => {
		dispatch(subjectLoading(true));
		dispatch(getNonGroupSubjectsList(2, type, subType, board, props.history));
	}

	/* reset filter */
	const onResetHandler = () => {
		setClassRoom("");
		setAcademicSession("");
		setContentStatus("");
		setSubject("")
		setType("")
		setSubType("")

		const stateCopy = { ...props?.location.state };
		delete stateCopy.type;
		delete stateCopy.classRoom;
		delete stateCopy.subType;
		delete stateCopy.subject;
		delete stateCopy.academicSession;
		delete stateCopy.contentStatus;
		props.history.replace({ state: stateCopy });

		/* type, classRoom, subType, subject, academicSession, contentStatus */
	}

	/* change class */
	const onChangeClassHandler = (e) => {
		setClassRoom(e)
		getSubjectHandler()
		dispatch(competitiveExamDetailsClassAction(e))
	}

	/* change subject */
	const onSubjectHandler = (e) => {
		setSubject(e)
		dispatch(competitiveExamDetailsSubjectAction(e))
	}

	/* change academic session */
	const onAcademicSession = (e) => {
		setAcademicSession(e)
		dispatch(competitiveExamDetailsAccademicSessionAction(e))
	}

	/* change status */
	const onStatusHandler = (e) => {
		setContentStatus(e)
		dispatch(competitiveExamDetailsStausAction(e))
	}

	/* change type */
	const onChangeTypeHandler = (e) => {
		setType(e)
		setClassRoom("");
		setSubType("")
		dispatch(competitiveExamDetailsTypeAction(e))

		setSubject("");
		setAcademicSession("");
		setContentStatus("");
		dispatch(competitiveExamDetailsClassAction(0));
		dispatch(competitiveExamDetailsSubjectAction(0));
		dispatch(competitiveExamDetailsAccademicSessionAction(0));
		dispatch(competitiveExamDetailsStausAction(''))
		dispatch(competitiveExamDetailsSubTypeAction(0))
	}

	/* get subtype list */
	const getSubTypeHandler = () => {

		if (props.subTypeList.length == 0) {
			dispatch(loadingSubTypeAction(true));
			dispatch(getExamSubType(props.history));
		}
		getSubjectHandler()
	}

	/* change subtype */
	const onSubTypeHandler = (id) => {
		dispatch(competitiveExamDetailsSubTypeAction(id))
		setSubType(id);
		dispatch(clearSubjectList([]));
	}

	/* s3 pdf file fownload and download */
	const getPdfContentFromAWSS3BucketPrivately = (url) => {
		//const S3_BUCKET_NAME = "crestest-dam-bucket"; //process.env.S3_BUCKET_NAME;
		const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME;

		let parts = url.split("/");

		const pdfFileName = parts[parts.length - 1];

		let pdfFileWithoutPageNumber = pdfFileName.split("#");
		let finalPdfFileWithoutPageNumber = pdfFileWithoutPageNumber[0];

		// let fullPathPdf = `elibrary/${pdfFileName}`
		let fullPathPdf = `elibrary/${finalPdfFileWithoutPageNumber}`

		// console.log("$>>fullPathPdf>>", fullPathPdf)
		s3.getObject({
			Bucket: S3_BUCKET_NAME,
			// Key: "elibrary/1674811975750NTPHCH1CM.pdf",
			Key: fullPathPdf,
			ResponseContentType: 'Uint8Array',
		}, (err, data) => {
			if (err) {
				console.log("@@Error : " + err);
			} else {
				// console.log("@@data.Body : " + data.Body);
				// setPdfUrl(data.Body);
				let blob = new Blob([data.Body], { type: data.ContentType });
				let link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = url;
				link.click();
			}
		});
	}


	return (
		<Fragment>
			<Row>
				<Col lg={12}>
					<Card>
						<Card.Body>
							<div className="row chapter-list-view">
								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
										<select
											defaultValue={"option"}
											className="form-control filter-form-control"
											value={type}
											onChange={(e) => onChangeTypeHandler(e.target.value)}
										>
											<option value="">Select type</option>
											{
												props.typeList.map((item, index) => {
													return <option key={index} value={item.id}>{item.type_name}</option>
												})
											}
										</select>
									</div>
								</div>
								{type != 1 ?
									<div className="col-sm-2 px-1">
										<div className="basic-dropdown">
											<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
											<select
												defaultValue={"option"}
												className={`form-control filter-form-control ${type == 2 ? null : 'content_disable'}`}
												// disabled={type == 2 ? false : true}
												value={classRoom}
												onChange={(e) => onChangeClassHandler(e.target.value)}
											>
												<option value="">Select class</option>
												{
													props.classList.map((item, index) => {
														return <option key={index} value={item.id}>{item.short_code}</option>
													})
												}
											</select>
										</div>
									</div>
									:
									<div className="col-sm-2 px-1">
										<div className="basic-dropdown">
											<span className="drop_down_arrow add-Question-status-arrow-position"><i className="bi bi-caret-down-fill"></i></span>
											<select
												defaultValue={"option"}
												className={`form-control filter-form-control ${type == 1 ? null : 'content_disable'}`}
												value={subType}
												onClick={getSubTypeHandler}
												onChange={(e) => onSubTypeHandler(e.target.value)}
											>
												<option value="">Select exam sub type</option>
												{
													props.subTypeList.map((item, index) => {
														return <option key={index} value={item.id}>{item.subtype_name}</option>
													})
												}
											</select>
										</div>
									</div>
								}

								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow">
											{props.subjectLoading ?
												<Spinner animation="border" size="sm" />
												: <i className="bi bi-caret-down-fill"></i>}
										</span>
										<select
											defaultValue={"option"}
											className="form-control filter-form-control"
											value={subject}
											// disabled={classRoom ? false : true}
											onChange={(e) => onSubjectHandler(e.target.value)}
										// onClick={() => getSubjectHandler()}
										>
											<option value="">Select subject</option>
											{
												props.subjectList.map((item, index) => {
													return <option key={index} value={item.id}>{item.name}</option>
												})
											}
										</select>
									</div>
								</div>

								<div className="col-sm-3 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow">
											<i className="bi bi-caret-down-fill"></i>
										</span>
										<select
											defaultValue={"option"}
											className="form-control filter-form-control"
											value={academicSession}
											onChange={(e) => onAcademicSession(e.target.value)}
										>
											<option value="">Select Academic Session</option>
											{props.competitiveAcademicSession != '' ?

												props.competitiveAcademicSession.map((item, index) => {
													return <option key={index} value={item}>{item}</option>
												})
												: null}

										</select>
									</div>
								</div>

								<div className="col-sm-2 px-1">
									<div className="basic-dropdown">
										<span className="drop_down_arrow">
											<i className="bi bi-caret-down-fill"></i>
										</span>
										<select
											defaultValue={"option"}
											className="form-control filter-form-control"
											value={contentStatus}
											onChange={(e) => onStatusHandler(e.target.value)}
										>
											<option value="">Select Status</option>
											<option value="0">Pending</option>
											<option value="1">Published</option>

										</select>
									</div>
								</div>
								<div className={`col-sm-1 px-1 reset-btn-view ${type == '' && academicSession == '' && contentStatus == '' ? 'content_disable' : null}`}>
									<button onClick={onResetHandler} className="btn btn-secondary">Reset</button>
								</div>
							</div>
							<Table responsive>
								<thead>
									<tr>
										<th>
											<strong>Title</strong>
										</th>
										<th>
											<strong>Class</strong>
										</th>
										{/* <th>
											<strong>Subject</strong>
										</th> */}
										<th>
											<strong>Academic Session</strong>
										</th>
										<th>
											<strong>Exam Date</strong>
										</th>
										<th>
											<strong>Create date</strong>
										</th>
										{
											permission.status == "true" && (
												<th>
													<strong>Status</strong>
												</th>
											)
										}
										{
											(permission.edit == "true" || permission.delete == "true" || permission.download == "true") && (
												<th>
													<strong>Action</strong>
												</th>
											)}
									</tr>
								</thead>
								{props.competitiveList != '' ?
									<tbody>
										{
											props.competitiveList.map((item, index) => {
												return <tr key={index}>
													<td>{item.title}</td>
													<td>{item.short_code}</td>
													{/* <td>{`item.subject`}</td> */}
													<td>{item.academic_session}</td>
													<td><Moment format="D MMM YYYY" withTitle>{item.exam_date}</Moment></td>
													<td><Moment format="D MMM YYYY" withTitle>{item.created_at}</Moment></td>
													{
														permission.status == "true" && (
															<td>
																<Button
																	//disabled={props.showLoading}
																	className="btn-xs"
																	variant={item.status == 1 ? "success" : "danger"}
																	onClick={() => onUpdateStatus(item)}
																>
																	{item.status == 1 ? "Active" : "Inactive"}
																</Button>
															</td>
														)
													}
													{
														(permission.edit == "true" || permission.delete == "true" || permission.download == "true") && (
															<td>
																<div className="d-flex">
																	{
																		permission.edit == "true" && (
																			<Link
																				to={{
																					pathname: '/update-competitive-exam-details',
																					state: { item, filterData: { type, classRoom, subType, subject, academicSession, contentStatus } }
																				}}
																				className="btn btn-warning shadow btn-xs sharp me-1"
																			>
																				<i className="fas fa-pencil-alt"></i>
																			</Link>
																		)
																	}

																	{
																		permission.delete == "true" && (
																			<Button
																				className="btn btn-danger shadow btn-xs sharp me-1"
																				onClick={() => onDeleteModal(item.id)}
																			>
																				<i className="fa fa-trash"></i>
																			</Button>
																		)
																	}
																	{
																		permission.download == "true" && (
																			<OverlayTrigger
																				placement="bottom"
																				delay={{ show: 250, hide: 400 }}
																				overlay={renderTooltip}
																			>
																				<button
																					className="btn btn-secondary shadow btn-xs sharp me-1"
																					onClick={() => onDonwloadHandeler(item)}
																				>
																					<i class="fa fa-download"></i>

																				</button>
																			</OverlayTrigger>
																		)}
																</div>
															</td>
														)}
												</tr>
											})
										}
									</tbody>
									: <tbody>
										<td colSpan={12}>
											<div className='no_data'>
												<strong >No data found</strong>
											</div>
										</td>
									</tbody>}
							</Table>
						</Card.Body>
					</Card>
				</Col>
			</Row>

		</Fragment>
	)
}
const mapStateToProps = (state) => {
	return {
		showLoading: state.board.showLoading,
		list: state.board.list,
		competitiveList: state.examDetails.competitiveExamDetailslist,
		competitiveAcademicSession: state.examDetails.CompetitiveAcademicSession,

		boardLoading: state.board.showLoading,
		boardList: state.board.list,
		classList: state.class.list,
		subjectList: state.subject.nonGroupSubjectList,
		subjectLoading: state.subject.showLoading,

		typeList: state.type.list,
		subTypeList: state.subtype.list,
	};
};

export default connect(mapStateToProps)(CompetitiveExamDetailsList);